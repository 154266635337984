import React, {useContext, useEffect} from "react";
import {CallbacksContext} from "../../../context-components/context/callbacks-context/callbacks-context";
import {withI18N} from "../index";
import {shallowEqual, useSelector} from "react-redux";
import {withAdr} from "../with-adr";
import compose from "../../../utils/helper-functions/compose";
import {customValidation} from "../../../utils/validations-functions/validation-methods";

export const withComputeCallbacks = (type) => (Wrapped) => {
    const Hoc = (props) => {
        const {lang, langBack, s} = props;
        const store = useSelector(({resultOfPay, profileInfo, pendingTransactions, initTheme:{locale},
                                       currentGateway:{payment_method_key}, gatewaysList:{gateways}} ) => ({
            resultOfPay, profileInfo, pendingTransactions, payment_method_key, gateways, locale
        }), shallowEqual);

        const {
           resultOfPay, profileInfo, pendingTransactions, payment_method_key, gateways, locale
        } = store;

        const {customer, transaction_type = resultOfPay?.transaction_type} = profileInfo;

        const callbacksContext = useContext(CallbacksContext);
        const actionCallback = callbacksContext.readCallbacks()? callbacksContext.readCallbacks() : {}

        const {
            createPaymentPage, createResultPage, createGatewaysPage, initApp, initAmountSlider,
            initBillingInfo, initHeaderProfile, initTransactionModal, customerSettings = {}
        } = actionCallback;

        useEffect(() => callbackHandling(type), [])

        const computeCallback = (type, data={}) => {
            if(!data || typeof data !== 'object') {
                data={};
                console.error('computeCallback func arg "data" should be object')
            }
            callbackHandling(type, data);
        }

        const callback = (func, params={})  => {
            if(!func || typeof func !== "function") return;
            try {
                setTimeout(() => {
                    func({transaction_type, lang, langBack, locale, s, ...params})
                },0)
            } catch (e) {}
        }

        const callbackHandling = (type, data={}) => {
            // special case for the initSession callback, which is triggered before ReactDOM load,
            // where the computeCallback would be initialized later
            if (type === 9
                && typeof window.actionCallback === 'object'
                && typeof window.actionCallback.initSession === 'function'
            ) {
                try {
                    callback(window.actionCallback.initSession, {...data});
                } catch (e) {
                    console.error(e, type, data);
                }
            }
            if (!type) return;
            if (!actionCallback || typeof actionCallback !== "object") return;
            switch(type){
                case 1: callback(createPaymentPage, {return_url: profileInfo.submit_action, ...data}); break;
                case 2: callback(createResultPage, {resultOfPay, ...data}); break;
                case 3: callback(createGatewaysPage, {gateways, ...data}); break;
                case 4: callback(initApp, {customer,  ...data}); break;
                case 5: callback(initAmountSlider, {customer, payment_method_key, ...data}); break;
                case 6: callback(initBillingInfo, {customer, payment_method_key, customValidation,  ...data}); break;
                case 7: callback(initHeaderProfile, {profileInfo, ...data}); break;
                case 8: callback(initTransactionModal, {pendingTransactions, ...data}); break;
            }
        }

        return (
            <Wrapped {...props}
                     computeCallback={computeCallback}
                     customerSettings={customerSettings}
                     getCallbacks = {callbacksContext.getCallbacks}
                     callbacksUpdate = {callbacksContext.callbacksUpdate}/>
            )
    };
    return compose(withI18N, withAdr)(Hoc);
};
