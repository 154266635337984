export const el = {
  "Loading... Please Wait": "Φορτώνει παρακαλώ περιμένετε",
  yourTransactionIsPendingForPayOut: "Το αίτημά σας ανάληψης εκκρεμεί",
  status1:
    "Λάβετε υπόψη ότι η συναλλαγή σας ελέγχεται από την τράπεζα επεξεργασίας ή τον πάροχο υπηρεσιών πληρωμών. Εάν θέλετε περισσότερες πληροφορίες σχετικά με την κατάσταση της συναλλαγής σας, επικοινωνήστε με την υποστήριξη για να ζητήσετε βοήθεια σχετικά με το Trace ID: {{trace_id}}",
  Status: "Κατάσταση",
  "Deposit Review For Account:": "Έλεγχος κατάθεσης για λογαριασμό:",
  "New Transaction": "Νέα συναλλαγή",
  Update: "Εκσυγχρονίζω",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Ο τρόπος πληρωμής που έχετε επιλέξει ανοίγει σε ξεχωριστή σελίδα. Κάντε κλικ στο 'Προχωρώ' για να μεταβείτε σε ένα νέο παράθυρο του προγράμματος περιήγησης και να συνεχίσετε τη διαδικασία πληρωμής.",
  dod_error_text:
    "Πρέπει να διαβάσετε και να αποδεχτείτε τη δήλωση κατάθεσης για να προχωρήσετε",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Πρέπει να αποδεχτείτε τους Όρους και Προϋποθέσεις για να προχωρήσετε στην κατάθεσή σας",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Αναγνωρίζω ότι έχω διαβάσει και αποδέχομαι τους Όρους και Προϋποθέσεις",
  dod_url: "#",
  dod_url_text: "Οροι και Προϋποθέσεις",
  dod_text: "Αναγνωρίζω ότι έχω διαβάσει και αποδέχομαι τους {{dod_url}}",
  transactionSuccessfullyCanceled: "Η συναλλαγή {{id}} ακυρώθηκε με επιτυχία",
  "Fee should not exceed 20% of the amount":
    "Η χρέωση δεν πρέπει να υπερβαίνει το 20% του ποσού",
  "My Info": "Τα στοιχεία μου",
  "Billing info": "Πληροφορίες Χρέωσης",
  "Withdrawal Requests": "Αιτήματα ανάληψης",
  "No Withdrawal Requests found": "Δεν βρέθηκαν αιτήματα ανάληψης",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Ματαίωση",
  Amount: "Ποσό",
  "Last Update": "Τελευταία ενημέρωση",
  "Payment Method": "Μέθοδος πληρωμής",
  TID: "ΧΡΟΝΟΣ",
  Action: "Δράση",
  "Confirmation required": "Απαιτείται επιβεβαίωση",
  "Are you sure you want to cancel transaction":
    "Είστε βέβαιοι ότι θέλετε να ακυρώσετε τη συναλλαγή",
  Yes: "Ναί",
  No: "Οχι",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Ελάχιστα ποσά",
  "Fee (included)": "Χρέωση (συμπεριλαμβάνεται)",
  "- Amount between": "- Ποσό μεταξύ",
  "I have the Promocode!": "Έχω τον κωδικό προσφοράς!",
  "Please enter the Promocode": "Εισαγάγετε τον κωδικό προσφοράς",
  Promocode: "Προώθηση",
  noSavedTemplates: "Δεν αποθηκεύτηκαν κάρτες",
  noOptions: "Χωρίς επιλογές",
  "Please confirm your billing details": "Επιβεβαιώστε τα στοιχεία χρέωσής σας",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Αμοιβή επεξεργασίας",
  Fee: "Τέλη",
  Total: "Σύνολο",
  customer_support_link_text: "Υποστήριξη πελατών",
  terms_conditions_link_text: "Οροι και Προϋποθέσεις",
  "Edit Billing Info": "Επεξεργασία πληροφοριών χρέωσης",
  chooseAnAccount: "Επιλέξτε λογαριασμό",
  "Requested to Receive": "Ζητήθηκε να λάβει",
  "Receive With": "Λαμβάνω",
  "Unknown session": "Άγνωστη συνεδρία",
  transactionId: "Συναλλαγή ID",
  traceId: "Ιχνος ID",
  save: "Σώσει",
  fillTheBillingInformation: "Συμπληρώστε τα στοιχεία χρέωσης παρακάτω",
  correctionsRequired: "απαιτούνται διορθώσεις",
  billingInfo: "Πληροφορίες Χρέωσης",
  "Cancelled by client": "Ακυρώθηκε από τον πελάτη",
  "Your Cards": "Οι κάρτες σας",
  Done: "Ολοκληρώθηκε",
  redirect: "διευθύνω πάλιν",
  redirectingTo: "ανακατεύθυνση σε",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Η συναλλαγή δεν ήταν επιτυχής. Λυπούμαστε αλλά ο λογαριασμός PayPal που παρέχεται εμφανίζεται στα αρχεία μας κάτω από λογαριασμό συναλλαγών άλλου πελάτη. Οι πελάτες περιορίζονται σε έναν ενεργό λογαριασμό συναλλαγών πελατών στον ιστότοπό μας. Για να επιτρέψετε τη χρήση αυτού του λογαριασμού PayPal με τα στοιχεία σύνδεσής σας ή εάν χρειάζεστε βοήθεια με τον λογαριασμό σας, επικοινωνήστε με την εξυπηρέτηση πελατών.",
  "Not supported yet": "Δεν υποστηρίζεται ακόμα",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Συγγνώμη, φαίνεται ότι δεν έχετε καταχωρίσει στοιχεία πληρωμής για χρήση για αίτημα ανάληψης",
  "Not available gateways found": "Δεν υπάρχουν διαθέσιμες πύλες",
  "Token is invalid": "Το σύμβολο είναι άκυρο",
  "Payment method not available. Please contact your site administrator.":
    "Ο τρόπος πληρωμής δεν είναι διαθέσιμος. Επικοινωνήστε με τον διαχειριστή του ιστότοπού σας.",
  cardIsNotValid: "Η κάρτα δεν είναι έγκυρη",
  chooseAmount: "επιλέξτε Ποσό",
  invalidInputFormat:
    "Μη έγκυρη μορφή εισόδου. Τουλάχιστον {{length}} χαρακτήρες.",
  invalidDateFormat: "Μη έγκυρη μορφή ημερομηνίας.",
  maximumValueIs: "Η μέγιστη τιμή είναι",
  minimumValueIs: "Η ελάχιστη τιμή είναι",
  enterPayoutAmount:
    "Καταχωρίστε μια πληρωμή Ποσό μικρότερο ή ίσο με το διαθέσιμο υπόλοιπό σας.",
  maxLength2: "Μέγιστο μήκος 2 χαρακτήρες",
  footer1:
    "Ορισμένες εκδούσες τράπεζες έχουν περιορίσει τη χρήση πιστωτικών καρτών για διαδικτυακή ψυχαγωγία. Μη διστάσετε να χρησιμοποιήσετε έναν από τους εναλλακτικούς τρόπους πληρωμής τρίτου μέρους παραπάνω ή επικοινωνήστε με τους {{customer_support_url}} αντιπροσώπους μας χωρίς χρέωση στο N / A για χρηματοδότηση ο λογαριασμός σας. \n Σημείωση: Ανάλογα με την εκδούσα τράπεζά σας, οι συναλλαγές με πιστωτική κάρτα θα μπορούσαν να θεωρηθούν ως προκαταβολή σε μετρητά. {{application_key}} δεν μπορεί να θεωρηθεί υπεύθυνη για τυχόν προμήθειες μετρητών που εμπλέκονται σε αυτήν τη συναλλαγή, καθώς αυτό καθορίζεται από την πίστωση τράπεζα έκδοσης κάρτας. Συμβουλευτείτε την τράπεζά σας για περισσότερες λεπτομέρειες. ",
  footer2:
    "Για βοήθεια ή επιβεβαίωση μιας απόπειρας συναλλαγής επικοινωνήστε με {{customer_support_url}}. \n Σημείωση: Ανάλογα με την τράπεζά σας, αυτή η συναλλαγή θα μπορούσε να θεωρηθεί ως προκαταβολή σε μετρητά. {{application_key}} δεν θα είναι υπεύθυνη για τυχόν χρεώσεις προκαταβολής σε μετρητά που εμπλέκονται σε αυτήν τη συναλλαγή. Η συναλλαγή θα προσδιοριστεί στο αντίγραφο κίνησης της πιστωτικής σας κάρτας ως Περιγραφέας Πιστωτικής Κάρτας, εκτός εάν αναφέρεται διαφορετικά στην απόδειξη της συναλλαγής σας. ",
  footer3:
    "Όλα τα αιτήματα πληρωμής υπόκεινται στις {{terms_conditions_url}} μας. Εάν χρειάζεστε βοήθεια ή περισσότερες πληροφορίες, επικοινωνήστε με το {{customer_support_url}}. Μόλις υποβληθεί το αίτημά σας, οι πληροφορίες προωθούνται στο τμήμα ταμείων μας για επεξεργασία. . Θα λάβετε ένα μήνυμα ηλεκτρονικού ταχυδρομείου ως επιβεβαίωση κατά την επεξεργασία της πληρωμής σας. Το ποσό πληρωμής και το τέλος επεξεργασίας (εάν ισχύει) θα χρεωθούν από τον λογαριασμό σας μετά τον έλεγχο των πληρωμών μας. ",
  gatewaysTitle: "Πώς θα θέλατε να πληρώσετε?",
  gatewaysTitleDeposit: "Επιλέξτε μέθοδο κατάθεσης",
  gatewaysTitleWithdrawal: "Επιλέξτε τη μέθοδο ανάληψης",
  payInTitle: "Πληρώστε με πιστωτική ή χρεωστική κάρτα",
  allPaymentMethods: "όλες τις μεθόδους πληρωμής",
  submit: "υποβάλλουν",
  useAddressOnFile: "Χρησιμοποιήστε τη διεύθυνση στο αρχείο",
  customerName: "Όνομα πελάτη",
  country: "Χώρα",
  province: "Επαρχία",
  city: "Πόλη",
  postalCode: "Ταχυδρομικός Κώδικας",
  street: "Δρόμος",
  proceed: "Προχωρώ",
  cardNumber: "Αριθμός κάρτας",
  cardHolder: "Κάτοχος κάρτας",
  cardHolderName: "Ονομα ιδιοκτήτη κάρτας",
  cardExpires: "Η κάρτα λήγει",
  cvv: "CVV",
  firstName: "Ονομα",
  lastName: "Επίθετο",
  zipCode: "Ταχυδρομικός κώδικας",
  address: "Διεύθυνση",
  state: "κατάσταση",
  email: "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
  phone: "Τηλέφωνο",
  dateOfBirth: "Ημερομηνια γεννησης",
  transactionStatus: "Κατάσταση συναλλαγής",
  trace: "Ιχνος",
  amount: "Ποσό",
  min: "min",
  max: "Μέγιστη",
  overrideLimits: "Περιορίστε τα όρια",
  transaction: "Συναλλαγή",
  processed: "Επεξεργασμένο",
  transactionRejected: "Η συναλλαγή απορρίφθηκε",
  transactionApproved: "Η συναλλαγή εγκρίθηκε",
  yourTransactionIsPending: "Η συναλλαγή σας εκκρεμεί",
  transactionAuthorized: "Η συναλλαγή εξουσιοδοτείται",
  requestAccepted: "Αίτημα Αποδεκτό",
  status2:
    "Το αίτημά σας απόσυρσης έχει γίνει αποδεκτό. Λάβετε υπόψη ότι η περαιτέρω εξουσιοδότηση και επεξεργασία θα εκτελεστούν κατά τις ώρες λειτουργίας των εργάσιμων ημερών. Κατά τη διάρκεια αυτής της περιόδου, μπορείτε να επικοινωνήσετε με το εκτελεστικό προσωπικό για επιβεβαίωση ταυτότητας.",
  unexpectedErrorOccurred: "Παρουσιάστηκε μη αναμενόμενο σφάλμα",
  status3:
    "Ζητούμε συγγνώμη, κάτι πήγε στραβά κατά την επεξεργασία της συναλλαγής σας. Βρείτε τις παρακάτω τεχνικές λεπτομέρειες:",
  tryAgain: "Προσπάθησε ξανά",
  somethingWentWrong: "Κάτι πήγε στραβά...",
  newPayoutRequest: "Νέο αίτημα πληρωμής",
  makeAnotherPayment: "Κάντε άλλη πληρωμή",
  profile: "Προφίλ",
  justOneMoreStep: "Ένα ακόμη βήμα ...",
  "profile-auth":
    "Συμπληρώστε το προφίλ σας για να προχωρήσετε με την πληρωμή χρησιμοποιώντας {{title}}",
  payWith: "Πληρώνω με",
  ProceedWith: "Προχωρήστε με",
  vtTitle: "Εικονικό τερματικό:",
  pay: "Πληρωμή",
  deposit: "Κατάθεση",
  withdraw: "Αποσύρω",
  withdrawal: "Απόσυρση",
  hello: "Γειά σου,",
  currency: "Νόμισμα",
  currentIp: "Τρέχουσα διεύθυνση IP:",
  UseCurrentIp: "Χρησιμοποιήστε την τρέχουσα διεύθυνση IP:",
  UseCustomerIp: "Χρησιμοποιήστε IP πελάτη:",
  copyright: "Πνευματική ιδιοκτησία",
  cannotBeBlank: "Δεν μπορεί να είναι κενό.",
  noDepositFree: "Χωρίς προκαταβολή. χωρίς τέλος ανάληψης.",
  next: "Επόμενο",
  backToSite: "Επιστροφή στον ιστότοπο",
  maximumLength: "Μέγιστο μήκος {{length}} χαρακτήρες",
  minimumLength: "Ελάχιστο μήκος {{length}} χαρακτήρες",
  emailIsNotValid: "Το email δεν είναι έγκυρη διεύθυνση email.",
  phoneIsNotValid: "Το τηλέφωνο δεν είναι έγκυρο",
  cardHolderInvalid:
    "Ο κάτοχος της κάρτας δεν είναι έγκυρος. Ο κάτοχος της κάρτας πρέπει να περιέχει το όνομα και το επώνυμο.",
  pleaseEnterPayout: "Εισαγάγετε ένα ποσό πληρωμής μικρότερο ή ίσο",
  invalidAmount: "Μη έγκυρο ποσό",
  transactionWillAppear: "Η συναλλαγή σας θα εμφανίζεται στο δελτίο σας ως",
  "Customer does not exist": "Ο πελάτης δεν υπάρχει",
  "Sorry no available gateways found.":
    "Δυστυχώς δεν βρέθηκαν διαθέσιμες πύλες.",
  "Incorrect currency": "Λανθασμένο νόμισμα",
  "Sorry, not supported payment type":
    "Λυπούμαστε, δεν υποστηρίζεται τύπος πληρωμής",
  "Payment method has no payment type":
    "Ο τρόπος πληρωμής δεν έχει τύπο πληρωμής",
  "Incorrect payment type in payment method":
    "Εσφαλμένος τύπος πληρωμής στον τρόπο πληρωμής",
  "Gateway limit exceeded": "Υπέρβαση ορίου πύλης",
  "Not supported transaction type": "Δεν υποστηρίζεται τύπος συναλλαγής",
  "Sorry. Payout with credit card gateway not supported yet":
    "Συγνώμη. Η πληρωμή με πύλη πιστωτικής κάρτας δεν υποστηρίζεται ακόμη",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Ζητούμε συγγνώμη, αυτός ο τρόπος πληρωμής δεν ισχύει για τα καθορισμένα στοιχεία πληρωμής. Φορτώστε ξανά τη σελίδα και δοκιμάστε ένα διαφορετικό ποσό, νόμισμα ή τρόπο πληρωμής.",
  "Back to site": "Επιστροφή στον ιστότοπο",
  "Your transaction has been requested. Please wait for approval.":
    "Ζητήθηκε η συναλλαγή σας. Περιμένετε για έγκριση.",
  "Token expired": "Το κουπόνι έληξε",
  back: "πίσω",
  "Transaction not found.": "Η συναλλαγή δεν βρέθηκε.",
  "Continue in the New Window": "Συνεχίστε στο Νέο Παράθυρο",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Μην κλείσετε αυτό το παράθυρο. Θα ανακατευθυνθείτε ξανά όταν ολοκληρώσετε τη συναλλαγή σε νέο παράθυρο. Δεν βλέπετε ένα νέο παράθυρο που ανοίγει μια ασφαλή σύνδεση; Είμαστε εδώ για να σας βοηθήσουμε να ολοκληρώσετε τη συναλλαγή σας.",
  "Click to continue": "Κάντε κλικ για να συνεχίσετε",
  "Just One More Step...": "Ένα ακόμη βήμα ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Ο τρόπος πληρωμής που έχετε επιλέξει θα ανοίξει σε μια νέα σελίδα. Κάντε κλικ στο κουμπί 'ΔΙΑΔΙΚΑΣΙΑ' παρακάτω για να μεταβείτε στο τελικό βήμα.",
  Proceed: "Προχωρώ",
  "Opening a Secure Connection...": "Άνοιγμα ασφαλούς σύνδεσης ...",
  "You will be redirected to complete your transaction in just a moment":
    "Θα ανακατευθυνθείτε για να ολοκληρώσετε τη συναλλαγή σας σε λίγο",
  "Close window": "Κλείσε το παράθυρο",
  "Partial approval confirmation": "Μερική επιβεβαίωση έγκρισης",
  PartiallyApprovedAmount:
    "Το υπόλοιπο της κάρτας σας δεν έχει επαρκή κεφάλαια για να πιστώσετε ολόκληρο το ποσό. Η συναλλαγή εγκρίθηκε εν μέρει για {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    'Κάντε κλικ στο "OK" για επιβεβαίωση του μερικού ποσού κατάθεσης ή "CANCEL" για ακύρωση της συναλλαγής. Η κατάθεση θα επιβεβαιωθεί αυτόματα μετά από 5 λεπτά εάν δεν γίνει καμία ενέργεια.',
  fxRateSpreadText:
    "Η αναγραφόμενη συναλλαγματική ισοτιμία περιλαμβάνει όλες τις χρεώσεις και χρεώσεις.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Φορτώνει... παρακαλώ περιμένετε",
};
