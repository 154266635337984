const createFocus = (state, action) => {
    if (state === undefined) {
        return false
    }

    switch (action.type) {
        case 'MAIN_FOCUS':
            return action.payload;
        default:
            return state.focus;
    }
};

export { createFocus }