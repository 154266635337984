/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const session_information = /* GraphQL */ `
  query Session_information {
    session_information {
      balance
      lang
      locale
      communication_token
      session_duration
      session_theme
      validate_domain
      variable1
      variable2
      variable3
      cid
      amount
      currency
      gateway
      payment_method
      order_id
      valid_domains
      __typename
    }
  }
`;
export const application_configuration = /* GraphQL */ `
  query Application_configuration {
    application_configuration {
      encrypt_cashier_css_class_names
      enable_websockets
      powerslide_autoredirect_time
      currency_sign_amount
      enable_cashier_events
      promocode
      new_window_size
      __typename
    }
  }
`;
export const ui_presets = /* GraphQL */ `
  query Ui_presets {
    ui_presets {
      theme
      cashier_js
      cashier_css
      customization_settings {
        appearance
        button_style
        brand_colors_dark
        brand_colors_light
        brand_text_colors_dark
        brand_text_colors_light
        font
        submit_button_type
        submit_button_text_pay_in
        submit_button_text_pay_out
        layout
        payment_methods_view
        render_typ_image
        policies_data {
          render_policies_data
          support_website
          support_email
          support_phone
          privacy_policy_url
          terms_of_service_url
          __typename
        }
        promocode
        __typename
      }
      billing_info_settings {
        country
        customer_name
        email
        phone
        zip
        state
        city
        address
        __typename
      }
      __typename
    }
  }
`;
export const translations = /* GraphQL */ `
  query Translations {
    translations
  }
`;
