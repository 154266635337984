export const th = {
  "Loading... Please Wait": "กำลังโหลด ... กรุณารอสักครู่",
  yourTransactionIsPendingForPayOut: "คำขอถอนของคุณอยู่ระหว่างดำเนินการ",
  status1:
    "โปรดทราบว่าธุรกรรมของคุณกำลังได้รับการตรวจสอบโดยธนาคารผู้ดำเนินการหรือผู้ให้บริการการชำระเงิน หากคุณต้องการข้อมูลเพิ่มเติมเกี่ยวกับสถานะธุรกรรมของคุณโปรดติดต่อฝ่ายสนับสนุนเพื่อขอความช่วยเหลือเกี่ยวกับ Trace ID: {{trace_id}}",
  Status: "สถานะ",
  "Deposit Review For Account:": "ตรวจสอบเงินฝากสำหรับบัญชี:",
  "New Transaction": "ธุรกรรมใหม่",
  Update: "อัปเดต",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "วิธีการชำระเงินที่คุณเลือกจะเปิดขึ้นในหน้าแยกต่างหาก กรุณาคลิก 'ดำเนินการ' เพื่อไปยังหน้าต่างเบราว์เซอร์ใหม่และดำเนินการตามขั้นตอนการชำระเงินต่อไป",
  dod_error_text: "คุณต้องอ่านและยอมรับใบแจ้งการฝากเงินเพื่อดำเนินการต่อ",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "คุณต้องยอมรับข้อกำหนดและเงื่อนไขเพื่อดำเนินการฝากเงินของคุณ",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "ฉันรับทราบว่าฉันได้อ่านและยอมรับข้อกำหนดในการให้บริการ",
  dod_url: "#",
  dod_url_text: "ข้อกำหนดและเงื่อนไข",
  dod_text: "ฉันรับทราบว่าฉันได้อ่านและยอมรับ {{dod_url}}",
  transactionSuccessfullyCanceled: "ยกเลิกธุรกรรม {{id}} เรียบร้อยแล้ว",
  "Fee should not exceed 20% of the amount":
    "ค่าธรรมเนียมไม่ควรเกิน 20% ของจำนวนเงิน",
  "My Info": "ข้อมูลของฉัน",
  "Billing info": "ข้อมูลการเรียกเก็บเงิน",
  "Withdrawal Requests": "คำขอถอน",
  "No Withdrawal Requests found": "ไม่พบคำขอถอน",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "ยกเลิก",
  Amount: "จำนวน",
  "Last Update": "การปรับปรุงครั้งล่าสุด",
  "Payment Method": "วิธีการชำระเงิน",
  TID: "เวลา",
  Action: "หนังบู๊",
  "Confirmation required": "ต้องการการยืนยัน",
  "Are you sure you want to cancel transaction":
    "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกธุรกรรม",
  Yes: "ใช่",
  No: "ไม่",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "จำนวนเงินต่ำสุด - สูงสุด",
  "Fee (included)": "ค่าธรรมเนียม (รวม)",
  "- Amount between": "- จำนวนเงินระหว่าง",
  "I have the Promocode!": "ฉันมีรหัสโปรโมชั่น!",
  "Please enter the Promocode": "กรุณากรอกรหัสโปรโมชั่น",
  Promocode: "รหัสโปรโมชั่น",
  noSavedTemplates: "ไม่มีการ์ดที่บันทึกไว้",
  noOptions: "ไม่มีตัวเลือก",
  "Please confirm your billing details":
    "โปรดยืนยันรายละเอียดการเรียกเก็บเงินของคุณ",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "ค่าดำเนินการ",
  Fee: "ค่าธรรมเนียม",
  Total: "รวม",
  customer_support_link_text: "สนับสนุนลูกค้า",
  terms_conditions_link_text: "ข้อกำหนดและเงื่อนไข",
  "Edit Billing Info": "แก้ไขข้อมูลการเรียกเก็บเงิน",
  chooseAnAccount: "เลือกบัญชี",
  "Requested to Receive": "ร้องขอให้รับ",
  "Receive With": "รับด้วย",
  "Unknown session": "เซสชันที่ไม่รู้จัก",
  transactionId: "ID ธุรกรรม",
  traceId: "ID การติดตาม",
  save: "บันทึก",
  fillTheBillingInformation: "กรุณากรอกข้อมูลการเรียกเก็บเงินด้านล่าง",
  correctionsRequired: "ต้องมีการแก้ไข",
  billingInfo: "ข้อมูลการเรียกเก็บเงิน",
  "Cancelled by client": "ยกเลิกโดยลูกค้า",
  "Your Cards": "การ์ดของคุณ",
  Done: "เสร็จสิ้น",
  redirect: "การเปลี่ยนเส้นทาง",
  redirectingTo: "เปลี่ยนเส้นทางไปที่",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "การทำรายการไม่สำเร็จ เราต้องขออภัยเพราะบัญชี PayPal ระบุดูเหมือนว่าพบในบันทึกของเราภายใต้บัญชีการซื้อขายของลูกค้ารายอื่น เราจำกัดลูกค้าหนึ่งท่านต่อบัญชีซื้อขายที่ใช้งานบัญชีเดียวในเว็บไซต์ของเรา ในการอนุญาตให้บัญชี PayPal นี้ใช้ได้กับการเข้าสู่ระบบของคุณ หรือหากต้องการความช่วยเหลือทางบัญชี กรุณาติดต่อฝ่ายบริการลูกค้า",
  "Not supported yet": "ยังไม่รองรับ",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "ขออภัยปรากฏว่าคุณไม่มีรายละเอียดการชำระเงินที่ลงทะเบียนเพื่อใช้สำหรับคำขอถอนเงิน",
  "Not available gateways found": "ไม่พบเกตเวย์",
  "Token is invalid": "โทเค็นไม่ถูกต้อง",
  "Payment method not available. Please contact your site administrator.":
    "วิธีการชำระเงินไม่พร้อมใช้งาน กรุณาติดต่อผู้ดูแลเว็บไซต์ของคุณ",
  cardIsNotValid: "บัตรไม่ถูกต้อง",
  invalidInputFormat: "รูปแบบอินพุตไม่ถูกต้อง อย่างน้อย {{length}} ตัวอักษร",
  invalidDateFormat: "รูปแบบวันที่ไม่ถูกต้อง",
  maximumValueIs: "ค่าสูงสุดคือ",
  chooseAmount: "เลือกจำนวนเงิน",
  minimumValueIs: "ค่าต่ำสุดคือ",
  enterPayoutAmount:
    "โปรดป้อนจำนวนเงินที่ชำระน้อยกว่าหรือเท่ากับยอดเงินคงเหลือของคุณ",
  maxLength2: "ความยาวสูงสุด 2 ตัวอักษร",
  footer1:
    "ธนาคารผู้ออกบัตรบางแห่งได้ จำกัด การใช้บัตรเครดิตเพื่อความบันเทิงออนไลน์โปรดอย่าลังเลที่จะใช้วิธีการชำระเงินของบุคคลที่สามทางเลือกใดวิธีหนึ่งด้านบนหรือติดต่อ {{customer_support_url}} ตัวแทนของเราที่โทรฟรีที่ N / A เพื่อรับเงิน บัญชีของคุณ \n โปรดทราบ: ขึ้นอยู่กับธนาคารผู้ออกบัตรของคุณธุรกรรมบัตรเครดิตอาจถือเป็นการเบิกเงินสดล่วงหน้า {{application_key}} ไม่สามารถรับผิดชอบค่าธรรมเนียมการเบิกเงินสดล่วงหน้าใด ๆ ที่เกี่ยวข้องกับธุรกรรมนี้เนื่องจากเครดิตกำหนด ธนาคารผู้ออกบัตรปรึกษากับธนาคารของคุณสำหรับรายละเอียดเพิ่มเติม ",
  footer2:
    "หากต้องการความช่วยเหลือหรือยืนยันการพยายามทำธุรกรรมโปรดติดต่อ {{customer_support_url}} \n โปรดทราบ: ธุรกรรมนี้อาจถือเป็นการเบิกเงินสดล่วงหน้าทั้งนี้ขึ้นอยู่กับธนาคารของคุณ {{application_key}} จะไม่รับผิดชอบต่อ ค่าธรรมเนียมการเบิกเงินสดล่วงหน้าใด ๆ ที่เกี่ยวข้องกับธุรกรรมนี้ธุรกรรมจะถูกระบุในใบแจ้งยอดบัตรเครดิตของคุณเป็นตัวบอกข้อมูลบัตรเครดิตเว้นแต่จะระบุไว้เป็นอย่างอื่นในใบเสร็จรับเงินการทำธุรกรรมของคุณ ",
  footer3:
    "คำขอชำระเงินทั้งหมดอยู่ภายใต้ {{terms_conditions_url}} ของเราหากคุณต้องการความช่วยเหลือหรือข้อมูลเพิ่มเติมโปรดติดต่อ {{customer_support_url}} เมื่อส่งคำขอของคุณแล้วข้อมูลจะถูกส่งต่อไปยังแผนกแคชเชียร์เพื่อดำเนินการ คุณจะได้รับอีเมลยืนยันเมื่อการชำระเงินของคุณได้รับการดำเนินการจำนวนเงินที่ชำระและค่าธรรมเนียมการดำเนินการ (ถ้ามี) จะถูกหักจากบัญชีของคุณหลังจากการตรวจสอบการชำระคืนของเรา ",
  gatewaysTitle: "คุณต้องการจ่ายอย่างไร",
  gatewaysTitleDeposit: "กรุณาเลือกวิธีการฝากเงิน",
  gatewaysTitleWithdrawal: "กรุณาเลือกวิธีการถอน",
  payInTitle: "ชำระเงินด้วยบัตรเครดิตหรือบัตรเดบิต",
  allPaymentMethods: "วิธีการชำระเงินทั้งหมด",
  submit: "เสนอ",
  useAddressOnFile: "ใช้ที่อยู่ในไฟล์",
  customerName: "ชื่อลูกค้า",
  country: "ประเทศ",
  province: "จังหวัด",
  city: "เมือง",
  postalCode: "รหัสไปรษณีย์",
  street: "ถนน",
  proceed: "ดำเนินดำเนินการต่อ",
  cardNumber: "หมายเลขบัตร",
  cardHolder: "ผู้ถือบัตร",
  cardHolderName: "นามผู้ถือบัตร",
  cardExpires: "บัตรหมดอายุ",
  cvv: "CVV",
  firstName: "ชื่อจริง",
  lastName: "นามสกุล",
  zipCode: "รหัสไปรษณีย์",
  address: "ที่อยู่",
  state: "สถานะ",
  email: "อีเมล์",
  phone: "โทรศัพท์",
  dateOfBirth: "วันเกิด",
  transactionStatus: "สถานะการทำธุรกรรม",
  trace: "ติดตาม",
  amount: "จำนวน",
  min: "นาที",
  max: "สูงสุด",
  overrideLimits: "แทนที่ขีด จำกัด",
  transaction: "การซื้อขาย",
  processed: "การประมวลผล",
  transactionRejected: "ธุรกรรมถูกปฏิเสธ",
  transactionApproved: "ธุรกรรมได้รับการอนุมัติ",
  yourTransactionIsPending: "ธุรกรรมของคุณกำลังรอดำเนินการ",
  transactionAuthorized: "การทำธุรกรรมที่ได้รับอนุญาต",
  requestAccepted: "คำขอได้รับการยอมรับ",
  status2:
    "คำขอถอนของคุณได้รับการยอมรับ. โปรดรับทราบว่าการอนุญาตและการประมวลผลเพิ่มเติมจะดำเนินการในช่วงเวลาทำการของวันทำการ. ในช่วงเวลานั้นคุณอาจได้รับการติดต่อจากเจ้าหน้าที่บริหารเพื่อยืนยันตัวตน.",
  unexpectedErrorOccurred: "เกิดข้อผิดพลาดที่ไม่คาดคิด",
  status3:
    "ขออภัยเกิดข้อผิดพลาดบางอย่างขณะประมวลผลธุรกรรมของคุณ โปรดหารายละเอียดทางเทคนิคด้านล่าง: ",
  tryAgain: "ลองอีกครั้ง",
  somethingWentWrong: "บางอย่างผิดพลาด...",
  newPayoutRequest: "ใหม่คำขอการจ่ายเงิน",
  makeAnotherPayment: "ชำระเงินอีกครั้ง",
  profile: "ข้อมูลส่วนตัว",
  justOneMoreStep: "อีกหนึ่งขั้นตอน ...",
  "profile-auth": "โปรดกรอกโปรไฟล์ของคุณเพื่อดำเนินการชำระเงินโดยใช้ {{title}}",
  payWith: "ชำระด้วย",
  ProceedWith: "ดำเนินการต่อด้วย",
  vtTitle: "อาคารเสมือน: ",
  pay: "การชำระเงิน",
  deposit: "เงินฝาก",
  withdraw: "ถอน",
  withdrawal: "การถอนตัว",
  hello: "สวัสดี,,",
  currency: "เงินตรา",
  currentIp: "IP ปัจจุบัน: ",
  UseCurrentIp: "ใช้ IP ปัจจุบัน: ",
  UseCustomerIp: "ใช้ IP ของลูกค้า: ",
  copyright: "ลิขสิทธิ์",
  cannotBeBlank: "ต้องไม่เว้นว่าง",
  noDepositFree: "ไม่มีค่าธรรมเนียมการฝาก ไม่มีค่าธรรมเนียมการถอน",
  next: "ต่อไป",
  backToSite: "กลับไปที่ไซต์",
  maximumLength: "ความยาวสูงสุด {{length}} ตัวอักษร",
  minimumLength: "ความยาวขั้นต่ำ {{length}} อักขระ",
  emailIsNotValid: "อีเมลไม่ใช่ที่อยู่อีเมลที่ถูกต้อง",
  phoneIsNotValid: "โทรศัพท์ไม่ถูกต้อง",
  cardHolderInvalid: "ผู้ถือบัตรไม่ถูกต้องผู้ถือบัตรควรมีชื่อและนามสกุล",
  PleaseEnterPayout: "โปรดป้อนจำนวนเงินที่ชำระน้อยกว่าหรือเท่ากับ",
  invalidAmount: "จำนวนเงินไม่ถูกต้อง",
  transactionWillAppear: "ธุรกรรมของคุณจะปรากฏในรายการบัญชีของคุณเป็น",
  "Customer does not exist": "ลูกค้าไม่มีอยู่",
  "Sorry no available gateways found.": "ขออภัยไม่พบเกตเวย์ที่ใช้ได้",
  "Incorrect currency": "สกุลเงินไม่ถูกต้อง",
  "Sorry, not supported payment type": "ขออภัยไม่รองรับประเภทการชำระเงิน",
  "Payment method has no payment type": "วิธีการชำระเงินไม่มีประเภทการชำระเงิน",
  "Incorrect payment type in payment method":
    "ประเภทการชำระเงินไม่ถูกต้องในวิธีการชำระเงิน",
  "Gateway limit exceeded": "เกินขีด จำกัด เกตเวย์",
  "Not supported transaction type": "ประเภทธุรกรรมไม่รองรับ",
  "Sorry. Payout with credit card gateway not supported yet":
    "ขอโทษ ยังไม่รองรับการชำระเงินด้วยบัตรเครดิต",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "เราขออภัยวิธีการชำระเงินนี้ไม่สามารถใช้ได้สำหรับรายละเอียดการชำระเงินที่ระบุ โปรดโหลดหน้าซ้ำและลองใช้จำนวนเงินสกุลเงินหรือวิธีการชำระเงินอื่น",
  "Back to site": "กลับไปที่ไซต์",
  "Your transaction has been requested. Please wait for approval.":
    "ธุรกรรมของคุณได้รับการร้องขอ กรุณารอการอนุมัติ",
  "Token expired": "โทเค็นหมดอายุแล้ว",
  back: "กลับ",
  "Transaction not found.": "ไม่พบธุรกรรม",
  "Continue in the New Window": "ดำเนินการต่อในหน้าต่างใหม่",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "กรุณาอย่าปิดหน้าต่างนี้ คุณจะถูกเปลี่ยนเส้นทางกลับเมื่อทำธุรกรรมเสร็จสิ้นในหน้าต่างใหม่ ไม่เห็นหน้าต่างใหม่เปิดการเชื่อมต่อที่ปลอดภัยใช่หรือไม่ เราอยู่ที่นี่เพื่อช่วยคุณทำธุรกรรมให้เสร็จสมบูรณ์",
  "Click to continue": "คลิกเพื่อดำเนินการต่อ",
  "Just One More Step...": "อีกเพียงขั้นตอนเดียว ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "วิธีการชำระเงินที่คุณเลือกจะเปิดในหน้าใหม่ โปรดคลิกปุ่ม 'ดำเนินการ' ด้านล่างเพื่อไปยังขั้นตอนสุดท้าย",
  Proceed: "ดำเนินดำเนินการต่อ",
  "Opening a Secure Connection...": "การเปิดการเชื่อมต่อที่ปลอดภัย ...",
  "You will be redirected to complete your transaction in just a moment":
    "คุณจะถูกเปลี่ยนเส้นทางเพื่อทำธุรกรรมให้เสร็จสิ้นในเวลาเพียงครู่เดียว",
  "Close window": "ปิดหน้าต่าง",
  "Partial approval confirmation": "การยืนยันการอนุมัติบางส่วน",
  PartiallyApprovedAmount:
    "ยอดคงเหลือในบัตรของคุณมีเงินไม่เพียงพอที่จะเครดิตเต็มจำนวน การทำธุรกรรมได้รับการอนุมัติบางส่วนสำหรับ {{amount}} {{currency}}",
  ConfirmPartialDeposit:
    "โปรดคลิก 'ตกลง' เพื่อยืนยันจำนวนเงินฝากบางส่วน หรือ 'ยกเลิก' เพื่อยกเลิกธุรกรรม เงินฝากจะได้รับการยืนยันโดยอัตโนมัติหลังจาก 5 นาทีหากไม่มีการดำเนินการ",
  fxRateSpreadText:
    "อัตราแลกเปลี่ยนที่ระบุรวมค่าธรรมเนียมและค่าธรรมเนียมทั้งหมดแล้ว",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "กำลังโหลด... กรุณารอสักครู่",
};
