import {triggerAdvanceCashierEvent} from "./trigger-advance-cashier-event";
import store from "../../state/store";

const determinePaymentMethodPage = (method) => {
    switch (method) {
        case "card-method":
            return "card-details";
        case "ewalet-method":
            return "apm-details";
        case "custom-method":
            return "custom-details";
        case "mbw-method":
            return "manual-bank-wire-details";
        default:
            return "payment-details";
    }
}

const getPageName = () => {
    const locations = window.location.href.split('/')
    const pageName = Array.isArray(locations) && locations.length > 0 ? locations[locations.length - 1] : "";
    const {method = ""} = store.getState();
    if (pageName === "payment-method") {
        return determinePaymentMethodPage(method)
    }
    return pageName
}

export const clientClickEvent = (elementName, pageName = "") => {
    triggerAdvanceCashierEvent({
        event_type: 'client_click',
        element: elementName,
        page: pageName ? pageName : getPageName(),
    })
}