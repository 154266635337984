const createCurrentGateway = (state, action) => {
    if (state === undefined) {
        return false
    }
    switch (action.type) {
        case  'CREATE_CURRENT_GATEWAY':
            if(action.payload === false) return false;
            const {templates, additional_fields} = action.payload;
            const data = {
                ...action.payload,
                additional_fields: additional_fields? additional_fields : [],
                templates: templates? templates : {}
            };
            return data;
        default:
            return state.currentGateway;
    }
}

export { createCurrentGateway }
