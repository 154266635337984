const saveLocation = (state, action) => {
    if (state === undefined) {
        return false
    }
    switch (action.type) {
        case 'SAVE-LOCATION':
            return action.payload;
        default:
            return state.location;
    }
};

export {saveLocation}