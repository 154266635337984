import React from 'react';
import {PraxisServiceConsumer } from '../../../context-components/context/praxis-context/praxis-context'

export const withPraxisService = () => (Wrapped) => {
    return (props) => {
        return(
            <PraxisServiceConsumer>
                {
                    (praxisService) => {
                        return (
                            <Wrapped {...props} praxisService={praxisService}/>
                        )
                    }
                }
            </PraxisServiceConsumer>
        )
    }
};