import React, {useEffect} from 'react'
import {withI18N} from "../../../hoc-components";
import "./transactions-modal.sass"
import {CloseButtonSVG} from "../../../../@cashier-project/assets/images/close-button";
import RequestedTransactionsList from "../../requested-transactions/requested-transactions"
import compose from "../../../../utils/helper-functions/compose";
import {withComputeCallbacks} from "../../../hoc-components/hoc-context/with-compute-callbacks";
import {withAdr} from "../../../hoc-components/with-adr";
import {clientClickEvent} from "../../../../utils/custom-cashier-events/client-click-event";

const TransactionsModal = ({close, langBack, s}) => {
   
    useEffect(()=>{
        clientClickEvent("transactions-modal_open")
        return ()=> clientClickEvent("transactions-modal_close")
    },[])
    
    return(
        <>
            <div
                className={`${s('modal-container')} ${s('transactions-modal')}`}
                onClick={close}>
                <div
                    className={`${s('modal')}`}
                    onClick={(e) => e.stopPropagation()}>
                    <div className={`${s('modal-header')}`}>
                        <div className={`${s('modal-title')}`}>{langBack('Withdrawal Requests')}</div>
                        <div className={`${s('close')} ${s('close-button')}`} onClick={close}>
                            <CloseButtonSVG />
                        </div>
                    </div>
                    <RequestedTransactionsList/>
                </div>
            </div>
        </>
    )
};

export default compose(
    withI18N, withComputeCallbacks(8), withAdr
)(TransactionsModal)
