export const nl = {
  "Loading... Please Wait": "Laden even geduld aub",
  yourTransactionIsPendingForPayOut: "Uw uitbetalingsverzoek is in behandeling",
  status1:
    "Houd er rekening mee dat uw transactie wordt beoordeeld door de verwerkingsbank of betalingsdienstaanbieder. Als u meer informatie wilt over de status van uw transactie, neem dan contact op met de ondersteuning om hulp te vragen via Trace ID: {{trace_id}}",
  Status: "Toestand",
  "Deposit Review For Account:": "Stortingsoverzicht voor account:",
  "New Transaction": "Nieuwe transactie",
  Update: "Bijwerken",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "De door u gekozen betaalmethode wordt op een aparte pagina geopend. Klik op 'DOORGAAN' om naar een nieuw browservenster te navigeren en door te gaan met het betalingsproces.",
  dod_error_text:
    "U moet de verklaring van aanbetaling lezen en accepteren om verder te gaan",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "U moet de algemene voorwaarden accepteren om door te gaan met uw storting",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Ik erken dat ik de Algemene voorwaarden heb gelezen en accepteer hierbij",
  dod_url: "#",
  dod_url_text: "Voorwaarden",
  dod_text:
    "Ik erken dat ik de Algemene {{dod_url}} heb gelezen en accepteer hierbij",
  transactionSuccessfullyCanceled:
    "De transactie {{id}} is succesvol geannuleerd",
  "Fee should not exceed 20% of the amount":
    "De vergoeding mag niet meer bedragen dan 20% van het bedrag",
  "My Info": "Mijn informatie",
  "Billing info": "betaal informatie",
  "Withdrawal Requests": "Intrekkingsverzoeken",
  "No Withdrawal Requests found": "Geen uitbetalingsverzoeken gevonden",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Annuleren",
  Amount: "Bedrag",
  "Last Update": "Laatste update",
  "Payment Method": "Betalingsmiddel",
  TID: "TIJD",
  Action: "Actie",
  "Confirmation required": "Bevestiging vereist",
  "Are you sure you want to cancel transaction":
    "Weet u zeker dat u de transactie wilt annuleren?",
  Yes: "Ja",
  No: "Nee",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Min-Max-bedragen",
  "Fee (included)": "Vergoeding (inbegrepen)",
  "- Amount between": "- Bedrag tussen",
  "I have the Promocode!": "Ik heb de Promocode!",
  "Please enter the Promocode": "Voer de promotiecode in",
  Promocode: "Promotiecode",
  noSavedTemplates: "Geen kaarten opgeslagen",
  noOptions: "Geen opties",
  "Please confirm your billing details": "Bevestig uw factuurgegevens",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Verwerkingskosten",
  Fee: "Tarief",
  Total: "Totaal",
  customer_support_link_text: "Klantenservice",
  terms_conditions_link_text: "Voorwaarden",
  "Edit Billing Info": "Bewerk factuurgegevens",
  chooseAnAccount: "Kies een account",
  "Requested to Receive": "Gevraagd om te ontvangen",
  "Receive With": "Ontvangen met",
  "Unknown session": "Onbekende sessie",
  transactionId: "Transactie ID",
  traceId: "trace ID",
  save: "Opslaan",
  fillTheBillingInformation: "Vul hieronder de factuurgegevens in",
  correctionsRequired: "vereiste correcties",
  billingInfo: "betaal informatie",
  "Cancelled by client": "Geannuleerd door klant",
  "Your Cards": "Uw kaarten",
  Done: "Gedaan",
  redirect: "Omleiden",
  redirectingTo: "Omleiden naar",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "De transactie is mislukt. Volgens onze informatie hoort de gebruikte PayPal-rekening bij de handelsrekening van een andere klant. Klanten mogen op onze website maar één actieve klantenhandelsrekening gebruiken. Wilt u toch dat deze PayPal-rekening bij uw aanmelding wordt gebruikt, of wilt u wat hulp bij uw rekening, neem dan contact op met de klantenservice.",
  "Not supported yet": "Nog niet ondersteund",
  "Not available gateways found": "Geen beschikbare gateways gevonden",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Excuses, het lijkt erop dat u geen betalingsgegevens heeft geregistreerd om te gebruiken voor een opnameverzoek",
  "Token is invalid": "Token is ongeldig",
  chooseAmount: "kies hoeveelheid",
  "Payment method not available. Please contact your site administrator.":
    "Betalingswijze niet beschikbaar. Neem contact op met uw sitebeheerder.",
  cardIsNotValid: "Kaart is niet geldig",
  invalidInputFormat: "Ongeldig invoerformaat. Minstens {{length}} tekens.",
  invalidDateFormat: "Ongeldige datum.",
  maximumValueIs: "Maximale waarde is",
  minimumValueIs: "Minimale waarde is",
  enterPayoutAmount:
    "Voer een uitbetalingsbedrag in dat kleiner is dan of gelijk is aan uw Beschikbare Saldo.",
  maxLength2: "Maximale lengte 2 tekens.",
  footer1:
    "Sommige uitgevende banken hebben het gebruik van creditcards voor online entertainment beperkt. Gebruik gerust een van de alternatieve betalingsmethoden van derden hierboven of neem contact op met onze {{customer_support_url}} vertegenwoordigers gratis bij N / A om te financieren uw rekening. \n Let op: afhankelijk van de bank die u heeft uitgegeven, kunnen creditcardtransacties worden behandeld als een voorschot in contanten. {{application_key}} kan niet verantwoordelijk worden gehouden voor eventuele kosten voor contante voorschotten die bij deze transactie zijn betrokken, aangezien dit wordt bepaald door het krediet de bank die de kaart heeft uitgegeven. Raadpleeg uw bank voor meer informatie. ",
  footer2:
    "Neem voor hulp of bevestiging van een transactiepoging contact op met {{customer_support_url}}. \n Let op: afhankelijk van uw bank kan deze transactie worden behandeld als een contant voorschot. {{application_key}} is niet verantwoordelijk voor eventuele kosten voor contante voorschotten die bij deze transactie betrokken zijn. De transactie wordt op uw creditcardafschrift vermeld als creditcardbeschrijving, tenzij anders vermeld op uw transactiebewijs. ",
  footer3:
    "Alle betalingsverzoeken zijn onderhevig aan onze {{terms_conditions_url}}. Als u hulp of meer informatie nodig heeft, neem dan contact op met {{customer_support_url}}. Zodra uw verzoek is ingediend, wordt de informatie ter verwerking doorgestuurd naar onze kassamedewerker. . U ontvangt een e-mail ter bevestiging wanneer uw uitbetaling is verwerkt. Het uitbetalingsbedrag en verwerkingskosten (indien van toepassing) worden van uw rekening afgeschreven na onze uitbetalingscontrole. ",
  gatewaysTitle: "Hoe wilt u betalen?",
  gatewaysTitleDeposit: "Kies een stortingsmethode",
  gatewaysTitleWithdrawal: "Kies een opnamemethode",
  payInTitle: "Betaal met creditcard of bankpas",
  allPaymentMethods: "alle betaalmethoden",
  submit: "voorleggen",
  useAddressOnFile: "Gebruik het adres in het bestand",
  customerName: "klantnaam",
  country: "land",
  province: "Provincie",
  city: "stad",
  postalCode: "Postcode",
  street: "Straat",
  proceed: "Doorgaan",
  cardNumber: "Kaartnummer",
  cardHolder: "Kaarthouder",
  cardHolderName: "Naam pashouder",
  cardExpires: "Kaart verloopt",
  cvv: "CVV",
  firstName: "Voornaam",
  lastName: "Achternaam",
  zipCode: "Postcode",
  address: "Adres",
  state: "Staat",
  email: "E-mail",
  phone: "Telefoon",
  dateOfBirth: "Geboortedatum",
  transactionStatus: "Transactiestatus",
  trace: "Spoor",
  amount: "Bedrag",
  min: "min",
  max: "max",
  overrideLimits: "Grenzen overschrijven",
  transaction: "Transactie",
  processed: "verwerkte",
  transactionRejected: "Transactie geweigerd",
  transactionApproved: "Transactie goedgekeurd",
  yourTransactionIsPending: "Uw transactie is in behandeling",
  transactionAuthorized: "Transactie geautoriseerd",
  requestAccepted: "Verzoek geaccepteerd",
  status2:
    "Uw opnameverzoek is geaccepteerd. Houd er rekening mee dat verdere autorisatie en verwerking wordt uitgevoerd tijdens de openingstijden van werkdagen. Gedurende die tijd kunt u door het uitvoerend personeel gecontacteerd worden voor identiteitsbevestiging.",
  unexpectedErrorOccurred: "Er is een onverwachte fout opgetreden",
  status3:
    "Onze excuses, er is iets misgegaan tijdens het verwerken van uw transactie. Hieronder vindt u de technische details: ",
  tryAgain: "Probeer het opnieuw",
  somethingWentWrong: "Er is iets fout gegaan...",
  newPayoutRequest: "Nieuw betalingsverzoek",
  makeAnotherPayment: "Voer nog een betaling uit",
  profile: "Profiel",
  justOneMoreStep: "Nog maar een stap...",
  "profile-auth":
    "Vul uw profiel in om door te gaan met de betaling met {{title}}",
  payWith: "Betaal met",
  ProceedWith: "Doorgaan met",
  vtTitle: "Virtuele terminal: ",
  pay: "Betalen",
  deposit: "Storting",
  withdraw: "terugtrekken",
  withdrawal: "het terugtrekken",
  hello: "Hallo,",
  currency: "Valuta",
  currentIp: "Huidig IP: ",
  UseCurrentIp: "Gebruik huidige IP: ",
  UseCustomerIp: "Gebruik klant-IP: ",
  copyright: "auteursrechten",
  cannotBeBlank: "Kan niet leeg zijn.",
  noDepositFree: "Geen aanbetaling. geen opnamekosten.",
  next: "De volgende",
  backToSite: "Terug naar de site",
  maximumLength: "Maximale lengte {{length}} tekens",
  minimumLength: "Minimale lengte {{length}} tekens",
  emailIsNotValid: "E-mail is geen geldig e-mailadres.",
  phoneIsNotValid: "Telefoon is niet geldig",
  cardHolderInvalid:
    "Kaarthouder is ongeldig. Kaarthouder moet de voornaam en de achternaam bevatten.",
  pleaseEnterPayout:
    "Voer een uitbetalingsbedrag in dat kleiner is dan of gelijk is aan",
  invalidAmount: "Ongeldig bedrag",
  transactionWillAppear: "Uw transactie verschijnt op uw afschrift als",
  "Customer does not exist": "Klant bestaat niet",
  "Sorry no available gateways found.":
    "Sorry geen beschikbare gateways gevonden.",
  "Incorrect currency": "Onjuiste valuta",
  "Sorry, not supported payment type": "Sorry, niet ondersteund betalingstype",
  "Payment method has no payment type":
    "Betaalmethode heeft geen betalingswijze",
  "Incorrect payment type in payment method":
    "Onjuist betalingstype in betalingsmethode",
  "Gateway limit exceeded": "Gatewaylimiet overschreden",
  "Not supported transaction type": "Niet ondersteund transactietype",
  "Sorry. Payout with credit card gateway not supported yet":
    "Sorry. Uitbetaling met creditcardgateway wordt nog niet ondersteund",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Onze excuses, deze betalingsmethode is niet van toepassing op de vermelde betalingsgegevens. Laad de pagina opnieuw en probeer een ander bedrag, andere valuta of betalingsmethode.",
  "Back to site": "Terug naar de site",
  "Your transaction has been requested. Please wait for approval.":
    "Uw transactie is aangevraagd. Wacht op goedkeuring.",
  "Token expired": "Token is verlopen",
  back: "terug",
  "Transaction not found.": "Transactie niet gevonden.",
  "Continue in the New Window": "Ga verder in het nieuwe venster",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Sluit dit venster alstublieft niet. Na het afronden van de transactie wordt u teruggeleid in een nieuw venster. Ziet u geen nieuw venster dat een beveiligde verbinding opent? We zijn hier om u te helpen uw transactie te voltooien.",
  "Click to continue": "Klik om verder te gaan",
  "Just One More Step...": "Nog maar een stap...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "De betaalmethode die u heeft geselecteerd, wordt op een nieuwe pagina geopend. Klik op de knop 'DOORGAAN' hieronder om naar de laatste stap te navigeren.",
  Proceed: "Doorgaan",
  "Opening a Secure Connection...": "Een beveiligde verbinding openen ...",
  "You will be redirected to complete your transaction in just a moment":
    "U wordt doorgestuurd om uw transactie in een oogwenk af te ronden",
  "Close window": "Venster sluiten",
  "Partial approval confirmation": "Gedeeltelijke goedkeuringsbevestiging",
  PartiallyApprovedAmount:
    "Uw kaartsaldo heeft onvoldoende saldo om het volledige bedrag te crediteren. De transactie werd gedeeltelijk goedgekeurd voor {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Klik op 'OK' om het gedeeltelijke aanbetalingsbedrag te bevestigen of op 'ANNULEREN' om de transactie te annuleren. De aanbetaling wordt automatisch na 5 minuten bevestigd als er geen actie wordt ondernomen.",
  fxRateSpreadText:
    "De aangegeven wisselkoers is inclusief alle vergoedingen en kosten.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Laden even geduld aub",
};
