export const transformSyntheticEvent = (e) => {
    if (e === undefined) return {};
    if (!e.target) return e;
    const {name, value} = e.target;
    return {[name]: value,}
};

export const parsingSynthetics = (e) => {
    if (e === undefined) return {};
    if (!e.target) return e;

    const {name, value} = e.target;
    return {[name]: value}
};