import { base64ToArrayBuffer } from "./utils-encrypt/base64-to-array-buffer";
import { encryptDataWithPublicKey } from "./utils-encrypt/encrypt-data-with-public-key";
import { arrayBufferToString } from "./utils-encrypt/array-buffer-to-string";
import { config } from "../../config/config";

export const encrypt_rsa_oaep = (data) => {

  const publicKey = config.publicKeyForEncrypt;

  return window.crypto.subtle
    .importKey(
      "spki", //can be "jwk" (public or private), "spki" (public only), or "pkcs8" (private only)
      base64ToArrayBuffer(publicKey),
      {
        //these are the algorithm options
        name: "RSA-OAEP",
        hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
      },
      false, //whether the key is extractable (i.e. can be used in exportKey)
      ["encrypt"] //"encrypt" or "wrapKey" for public key import or
      //"decrypt" or "unwrapKey" for private key imports
    )
    .then((publicKey) => {
      return encryptDataWithPublicKey(data, publicKey).then((result) => {
        return btoa(arrayBufferToString(result));
      });
    })
    .catch((err) => {
      console.error(err);
      return err;
    });
};
