import {config} from "../config/config";
import {getTokenFromUrl} from "../utils/helper-functions/getTokenFromUrl";
const defaultLoader = 'https://assets.praxispay.com/images/Cashier_cubes_preloader.gif';
const isDevelopment = process.env.NODE_ENV === 'development';
const getHost = () => window.location?.host;

const getDomain = () => getHost().split('.').filter((value,index ) => index !== 0).join('.');

export const host = getHost();

export const getUrl = (type) => {
    if(!config.hasOwnProperty(type)){
        if(type === 'assetsLink') return defaultLoader;
        return '';
    }

    const domain = getDomain();
    const {defaultDomain, defaultPciDomain} = config;
    
    if(type === 'api') {
        return config?.useConfigApiForMock ? config['mockApi'] : config[type].replace('{{domain}}', host);
    }
    
    if(type === 'pciLink' && domain === defaultDomain){
        return config[type].replace('{{domain}}', defaultPciDomain);
    }

    return config[type].replace('{{domain}}', isDevelopment || config?.useConfigApiForMock ? defaultDomain : domain);
};

export const isTest = process.env.NODE_ENV === 'development' && (window.location.pathname.indexOf("test-data") !== -1 || (window.location.host.includes("localhost") && !getTokenFromUrl()));
