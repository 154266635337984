const changePayData = (state, action) => {
  if (state === undefined) {
    return {};
  }
  switch (action.type) {
    case "CREATE_CURRENT_GATEWAY":
      return {};
    case "INIT_METHOD_STATE":
      return action.payload.fields;
    case "CHANGE-PAY-DATA":
      return {
        ...state.payData,
        ...action.payload,
      };
    case "UPDATE-PAY-DATA":
      return {
        ...state.payData,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state.payData;
  }
};

export { changePayData };
