export const zh_CHS = {
  "Loading... Please Wait": "加载请稍候",
  yourTransactionIsPendingForPayOut: "您的提款请求正在等待中",
  status1:
    "请注意，处理银行或付款服务提供商正在审核您的交易。 如果您想了解有关交易状态的更多信息，请联系支持部门以请求有关跟踪ID的帮助：{{trace_id}}",
  Status: "状态",
  "Deposit Review For Account:": "帐户的存款审查：",
  "New Transaction": "新交易n",
  Update: "更新资料",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "您选择的付款方式将在单独的页面中打开。 请单击“继续”以导航到新的浏览器窗口，然后继续付款过程。",
  dod_error_text: "您必须阅读并接受《存款声明》才能继续",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "您必须接受条款和条件才能进行存款",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "我确认已阅读并接受条款和条件",
  dod_url: "#",
  dod_url_text: "条款和条件",
  dod_text: "我确认已阅读并接受{{dod_url}}",
  transactionSuccessfullyCanceled: "交易{{id}}已成功取消",
  "Fee should not exceed 20% of the amount": "费用不得超过金额的20％",
  "My Info": "我的资料",
  "Billing info": "计费信息",
  "Withdrawal Requests": "提款要求",
  "No Withdrawal Requests found": "找不到提款请求",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "取消",
  Amount: "量",
  "Last Update": "最后更新",
  "Payment Method": "付款方法",
  TID: "时间",
  Action: "行动",
  "Confirmation required": "需要确认",
  "Are you sure you want to cancel transaction": "您确定要取消交易吗",
  Yes: "是",
  No: "没有",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "最小-最大金额",
  "Fee (included)": "费用（含）",
  "- Amount between": "-之间的金额",
  "I have the Promocode!": "我有促销代码！",
  "Please enter the Promocode": "请输入促销代码",
  Promocode: "促销代码",
  noSavedTemplates: "没有存储卡",
  noOptions: "没有选择",
  "Please confirm your billing details": "请确认您的帐单明细",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "手续费",
  Fee: "费用",
  Total: "总",
  customer_support_link_text: "客户支持",
  terms_conditions_link_text: "条款和条件",
  "Edit Billing Info": "修改帐单信息",
  chooseAnAccount: "选择一个帐号",
  "Requested to Receive": "要求接收",
  "Receive With": "接收方式",
  "Unknown session": "未知会话",
  transactionId: "交易编号",
  traceId: "跟踪ID",
  save: "救",
  fillTheBillingInformation: "请填写下面的帐单信息",
  correctionsRequired: "需要更正",
  billingInfo: "计费信息",
  "Cancelled by client": "被客户取消",
  "Your Cards": "您的卡",
  Done: "完成了",
  redirect: "重新导向",
  redirectingTo: "重定向到",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "交易未成功。很抱歉，提供的PayPal账户出现在我们记录中的另一个客户的交易账户下。客户只能在我们的网站上使用一个有效的交易账户。若要允许此PayPal账户与您的登录信息一起使用，或者如果您需要任何帮助，请与客服联系。",
  "Not supported yet": "还不支持",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "抱歉，您似乎没有注册任何可用于提款请求的付款明细",
  "Not available gateways found": "找不到可用的网关",
  "Token is invalid": "令牌无效",
  "Payment method not available. Please contact your site administrator.":
    "无法使用付款方式。 请与您的站点管理员联系。",
  cardIsNotValid: "卡无效",
  invalidInputFormat: "输入格式无效。 至少{{length}}个字符。",
  invalidDateFormat: "日期格式无效。",
  maximumValueIs: "最大值是",
  chooseAmount: "选择数量",
  minimumValueIs: "最小值为",
  enterPayoutAmount: "请输入小于或等于您的可用余额的付款金额。",
  maxLength2: "最大长度为2个字符。",
  footer1:
    "某些发卡银行已限制使用信用卡进行在线娱乐。请随时使用上述第三种替代付款方式之一，或与我们的{{customer_support_url}}代表免费拨打N / A资金\n请注意：根据您的发卡银行的不同，信用卡交易可能会被视为现金垫款。{{application_key}}对于此交易中涉及的任何现金垫款费用不承担任何责任，因为这是由信用卡来确定的卡的发卡行。请咨询您的银行以获取更多详细信息。",
  footer2:
    "如果需要协助或确认尝试进行的交易，请联系{{customer_support_url}}。\n请注意：根据您的银行的不同，该交易可能被视为预付款。{{application_key}}将不承担任何责任。该交易涉及的任何现金垫款费用。除非您的交易收据上另有说明，否则该交易将在您的信用卡对帐单上标识为信用卡描述符。",
  footer3:
    "所有付款请求均受我们的{{terms_conditions_url}}约束。如果您需要帮助或更多信息，请联系{{customer_support_url}}。提交请求后，该信息将转发给我们的收银部门进行处理。您将在收到付款后收到一封确认电子邮件。在我们审核付款后，将从您的帐户中扣除付款金额和手续费（如果适用）。",
  gatewsTitle: "您要如何付款？",
  gatewaysTitleDeposit: "请选择存款方式",
  gatewaysTitleWithdrawal: "请选择提款方式",
  payInTitle: "用信用卡或借记卡付款",
  allPaymentMethods: "所有付款方式",
  submit: "提交",
  useAddressOnFile: "使用文件上的地址",
  customerName: "顾客姓名",
  country: "国家",
  province: "省",
  city: "市",
  postalCode: "邮政编码",
  street: "街",
  proceed: "继续",
  cardNumber: "卡号",
  cardHolder: "持卡人",
  cardHolderName: "持卡人姓名",
  cardExpires: "卡过期",
  cvv: "CVV",
  firstName: "名字",
  lastName: "姓",
  zipCode: "邮政编码",
  address: "地址",
  state: "州",
  email: "电子邮件",
  phone: "电话",
  dateOfBirth: "出生日期",
  transactionStatus: "交易状态",
  trace: "跟踪",
  amount: "量",
  min: "分",
  max: "最高",
  overrideLimits: "超越限制",
  transaction: "交易",
  processed: "处理",
  transactionRejected: "交易被拒绝",
  transactionApproved: "交易批准",
  yourTransactionIsPending: "您的交易待处理",
  transactionAuthorized: "交易授权",
  requestAccepted: "接受请求",
  status2:
    "您的提款要求已被接受. 请注意，将在工作日的营业时间内执行进一步的授权和处理. 在此期间，执行人员可能会与您联系以进行身份确认.",
  unexpectedErrorOccurred: "发生意外错误",
  status3: "很抱歉，处理您的交易时出了点问题。 请在下面找到技术细节：",
  tryAgain: "再试一次",
  somethingWentWrong: "有些不对劲...",
  newPayoutRequest: "新的付款请求",
  makeAnotherPayment: "再次付款",
  profile: "轮廓",
  justOneMoreStep: "再走一步...",
  "profile-auth": "请完成您的个人资料，以便使用进行付款 {{title}}",
  payWith: "使用。。。支付",
  ProceedWith: "与..一起处理",
  vtTitle: "虚拟终端：",
  pay: "工资",
  deposit: "存款",
  withdraw: "退出",
  withdrawal: "退出",
  hello: "你好，",
  currency: "货币",
  currentIp: "当前IP：",
  UseCurrentIp: "使用当前IP：",
  UseCustomerIp: "使用客户IP：",
  copyright: "版权",
  cannotBeBlank: "不能是空白的",
  noDepositFree: "沒有存款費。沒有提款費。",
  next: "下一個",
  backToSite: "回到網站",
  maximumLength: "最大長度{{length}}個字符",
  minimumLength: "最小长度{{length}}个字符",
  emailIsNotValid: "電子郵件不是有效的電子郵件地址。",
  phoneIsNotValid: "電話無效",
  cardHolderInvalid: "持卡人無效。 持卡人應包含名字和姓氏。",
  pleaseEnterPayout: "請輸入小於或等於的付款金額",
  invalidAmount: "无效金额",
  transactionWillAppear: "您的交易将在您的对帐单上显示为",
  "Customer does not exist": "客户不存在",
  "Sorry no available gateways found.": "抱歉，找不到可用的网关。",
  "Incorrect currency": "货币不正确",
  "Sorry, not supported payment type": "抱歉，不支持的付款方式",
  "Payment method has no payment type": "付款方式无付款方式",
  "Incorrect payment type in payment method": "付款方式中的付款类型不正确",
  "Gateway limit exceeded": "超出网关限制",
  "Not supported transaction type": "不支持的交易类型",
  "Sorry. Payout with credit card gateway not supported yet":
    "抱歉。 尚不支持使用信用卡网关付款",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "抱歉，此付款方式不适用于指定的付款明细。 请重新加载页面，然后尝试使用其他金额，货币或付款方式。",
  "Back to site": "回到网站",
  "Your transaction has been requested. Please wait for approval.":
    "您的交易已被请求。 请等待批准。",
  "Token expired": "令牌已過期",
  back: "背部",
  "Transaction not found.": "超出门限",
  "Continue in the New Window": "在新窗口继续",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "请不要关闭此窗口。 在新窗口中完成交易后，您将被重定向回。 没有看到打开安全连接的新窗口？ 我们在这里帮助您完成交易。",
  "Click to continue": "点击继续",
  "Just One More Step...": "再走一步...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "您选择的付款方式将在新页面中打开。 请单击下面的“继续”按钮以导航到最后一步",
  Proceed: "继续",
  "Opening a Secure Connection...": "打开安全连接...",
  "You will be redirected to complete your transaction in just a moment":
    "您将被重定向以在短时间内完成交易",
  "Close window": "关闭窗口",
  "Partial approval confirmation": "部分批准确认",
  PartiallyApprovedAmount:
    "您的卡余额不足，无法贷记全额。该交易部分批准为 {{amount}} {{currency}}。",
  ConfirmPartialDeposit:
    "请点击“确定”确认部分存款金额或点击“取消”取消交易。如果不采取任何行动，存款将在 5 分钟后自动确认。",
  fxRateSpreadText: "所示汇率包括所有费用和收费。",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "加载请稍候",
};
