import React from 'react';

const IconSunLoader = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none">
            <path d="M11.5 2.75V5.5" stroke="var(--main_white_const)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.3348 5.16504L15.3926 7.10723" stroke="var(--main_white_const)" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M19.75 11H17" stroke="var(--main_white_const)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.3348 16.8353L15.3926 14.8931" stroke="var(--main_white_const)" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M11.5 19.25V16.5" stroke="var(--main_white_const)" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M5.66504 16.8353L7.60723 14.8931" stroke="var(--main_white_const)" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path d="M3.25 11H6" stroke="var(--main_white_const)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M5.66504 5.16504L7.60723 7.10723" stroke="var(--main_white_const)" strokeWidth="1.5" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </svg>
    );
};

export default IconSunLoader;