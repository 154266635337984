import React from 'react';

const {
    Provider: PraxisI18NProvider,
    Consumer: PraxisI18NConsumer
} = React.createContext();

export {
    PraxisI18NConsumer,
    PraxisI18NProvider
}