import {sha384} from "js-sha512";
import {config} from "../../config/config";

export const createSignature = (data) => {
    let hashString = "";
    const array = Object.keys(data);

    array.sort().map((el) => {
        let value = data[el];
        
        if([null, undefined, false].includes(value)) return;
        if(["array", "object"].includes(typeof value)) value = JSON.stringify(value)
        if(typeof value === "boolean") value = +value
        hashString += (value);
    });
    hashString += config.signatureSecretKey;
    const signature = sha384(hashString);
    return signature;
};