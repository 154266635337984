import React from 'react'
import {DeleteButtonSVG} from "../../../../@cashier-project/assets/images/delete";
import compose from "../../../../utils/helper-functions/compose";
import {withI18N} from "../../../hoc-components";
import {withAdr} from "../../../hoc-components/with-adr";

export const TransactionsList = (props) => {
    const {transactions, deleteTransaction, tableClass="", langBack, s} = props;

    if(!transactions) return "";
    return (
        <table className={tableClass}>
            <thead>
            <tr>
                <th>{langBack('Amount')}</th>
                <th>{langBack('Last Update')}</th>
                <th>{langBack('Payment Method')}</th>
                <th>{langBack('TID')}</th>
                <th>{langBack('Action')}</th>
            </tr>
            </thead>
            <tbody>
                {
                    transactions.map((el) => {
                        return (
                            <tr key={el.trace_id}>
                                <td>{el.amount} {el.currency}</td>
                                <td>{el.date}</td>
                                <td>{el.gateway}</td>
                                <td>{el.trace_id}</td>
                                <td>
                                    <div
                                        onClick={() => deleteTransaction(el.trace_id)}
                                        className={`${s('button')} ${s('deleteTransactionBtn')}`}>
                                        <DeleteButtonSVG/>
                                        {langBack('Cancel')}
                                    </div>
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    )
};

export default compose(withI18N, withAdr,
)(TransactionsList);
