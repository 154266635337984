const updateProfile = (state, action) => {
    if (state === undefined) {
        return false
    }
    switch (action.type) {
        case 'UPDATE_PROFILE':
            const data = {...action.payload};
            return data;
        default:
            return state.profileInfo;
    }
};

export {updateProfile}