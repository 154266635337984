const pendingTransactions = (state, action) => {
    if (state === undefined) {
        return {
            transactionList: undefined
        }
    }
    switch (action.type) {
        case 'SET_PENDING_TRANSACTIONS':
            return {
                transactionList: action.payload,
            };
        default:
            return state.pendingTransactions;
    }
};

export {pendingTransactions}
