export const fr = {
  "Loading... Please Wait": "Chargement, veuillez patienter",
  yourTransactionIsPendingForPayOut: "Votre demande de retrait est en attente",
  status1:
    "Veuillez noter que votre transaction est en cours d'examen par la banque de traitement ou le fournisseur de services de paiement. Si vous souhaitez plus d'informations sur l'état de votre transaction, veuillez contacter l'assistance pour demander de l'aide sur Trace ID: {{trace_id}}",
  Status: "Statut",
  "Deposit Review For Account:": "Examen du dépôt pour le compte:",
  "New Transaction": "Nouvelle transaction",
  Update: "Mettre à jour",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Le mode de paiement que vous avez sélectionné s'ouvre sur une page distincte. Veuillez cliquer sur 'CONTINUER' pour accéder à une nouvelle fenêtre de navigateur et poursuivre le processus de paiement.",
  dod_error_text:
    "Vous devez lire et accepter la déclaration de dépôt pour continuer",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Vous devez accepter les conditions générales afin de procéder à votre dépôt",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Je reconnais avoir lu et j'accepte par la présente les termes et conditions",
  dod_url: "#",
  dod_url_text: "Termes et conditions",
  dod_text:
    "Je reconnais avoir lu et j'accepte par la présente les {{dod_url}}",
  transactionSuccessfullyCanceled:
    "La transaction {{id}} a été annulée avec succès",
  "Fee should not exceed 20% of the amount":
    "Les frais ne doivent pas dépasser 20% du montant",
  "My Info": "Mes infos",
  "Billing info": "informations de facturation",
  "Withdrawal Requests": "Demandes de retrait",
  "No Withdrawal Requests found": "Aucune demande de retrait trouvée",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Annuler",
  Amount: "Montante",
  "Last Update": "Dernière mise à jour",
  "Payment Method": "Mode de paiement",
  TID: "TEMPS",
  Action: "Action",
  "Confirmation required": "Confirmation requise",
  "Are you sure you want to cancel transaction":
    "Êtes-vous sûr de vouloir annuler la transaction",
  Yes: "Oui",
  No: "Non",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Montants min-max",
  "Fee (included)": "Frais (inclus)",
  "- Amount between": "- Montant entre",
  "I have the Promocode!": "J'ai le Promocode!",
  "Please enter the Promocode": "Veuillez saisir le code promotionnel",
  Promocode: "Code promo",
  noSavedTemplates: "Aucune carte enregistrée",
  noOptions: "Aucune option",
  "Please confirm your billing details":
    "Veuillez confirmer vos informations de facturation",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Frais de traitement",
  Fee: "Frais",
  Total: "Total",
  customer_support_link_text: "Service client",
  terms_conditions_link_text: "Termes et conditions",
  "Edit Billing Info": "Modifier les informations de facturation",
  chooseAnAccount: "Choisissez un compte",
  "Requested to Receive": "Demandé pour recevoir",
  "Receive With": "Recevoir avec",
  "Unknown session": "Session inconnue",
  transactionId: "ID de transaction",
  traceId: "ID de trace",
  save: "sauver",
  fillTheBillingInformation:
    "Veuillez renseigner les informations de facturation ci-dessous",
  correctionsRequired: "corrections nécessaires",
  billingInfo: "informations de facturation",
  "Cancelled by client": "Annulé par le client",
  "Your Cards": "Vos cartes",
  Done: "Terminé",
  redirect: "Réorienter",
  redirectingTo: "Redirection vers",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "La transaction a échoué. Nous sommes désolés, mais le compte PayPal fourni apparaît sous le compte de trading d'un autre client. Les clients sont limités à un compte de trading actif sur notre site. Pour que ce compte PayPal soit autorisé avec vos identifiants de connexion, ou si vous avez besoin d'aide, veuillez contacter le service clients",
  "Not supported yet": "Pas encore pris en charge",
  "Not available gateways found": "Passerelles non disponibles trouvées",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Toutes mes excuses, il semble que vous n'ayez pas enregistré de détails de paiement à utiliser pour la demande de retrait",
  "Token is invalid": "Le jeton n'est pas valide",
  chooseAmount: "Choix du montant",
  "Payment method not available. Please contact your site administrator.":
    "Mode de paiement non disponible. Merci de contacter l'administrateur de votre site.",
  cardIsNotValid: "La carte n'est pas valide",
  invalidInputFormat:
    "Format d'entrée non valide. Au moins {{length}} caractères.",
  invalidDateFormat: "Format de date non valide.",
  maximumValueIs: "La valeur maximale est",
  minimumValueIs: "La valeur minimale est",
  enterPayoutAmount:
    "Veuillez saisir un montant de paiement inférieur ou égal à votre solde disponible.",
  maxLength2: "Longueur maximale 2 caractères.",
  footer1:
    "Certaines banques émettrices ont limité l'utilisation des cartes de crédit pour les divertissements en ligne. N'hésitez pas à utiliser l'une des méthodes de paiement tierces ci-dessus ou à contacter nos {{customer_support_url}} représentants sans frais au numéro sans frais pour financer votre compte. \n Remarque: en fonction de votre banque émettrice, les transactions par carte de crédit peuvent être traitées comme une avance de fonds. {{application_key}} ne peut être tenu responsable des frais d'avance de fonds impliqués dans cette transaction, car cela est établi par le crédit la banque émettrice de la carte. Consultez votre banque pour plus de détails. ",
  footer2:
    "Pour obtenir de l'aide ou pour confirmer une tentative de transaction, veuillez contacter {{customer_support_url}}. \n Remarque: selon votre banque, cette transaction peut être traitée comme une avance de fonds. {{application_key}} ne sera pas responsable de les frais d'avance de fonds impliqués dans cette transaction. La transaction sera identifiée sur votre relevé de carte de crédit en tant que descripteur de carte de crédit, sauf indication contraire sur votre reçu de transaction. ",
  footer3:
    "Toutes les demandes de paiement sont soumises à nos {{terms_conditions_url}}. Si vous avez besoin d'aide ou d'informations complémentaires, veuillez contacter {{customer_support_url}}. Une fois votre demande soumise, les informations sont transmises à notre service de caisse pour traitement . Vous recevrez un e-mail de confirmation lorsque votre paiement sera traité. Le montant du paiement et les frais de traitement (le cas échéant) seront débités de votre compte après notre examen des paiements. ",
  gatewaysTitle: "Comment voulez-vous payer?",
  gatewaysTitleDeposit: "Veuillez choisir la méthode de dépôt",
  gatewaysTitleWithdrawal: "Veuillez choisir la méthode de retrait",
  payInTitle: "Payer par carte de crédit ou de débit",
  allPaymentMethods: "tous les modes de paiement",
  submit: "Soumettre",
  useAddressOnFile: "Utiliser l'adresse dans le dossier",
  customerName: "Nom du client",
  country: "Pays",
  province: "Province",
  city: "Ville",
  postalCode: "Code postal",
  street: "rue",
  proceed: "Procéder",
  cardNumber: "Numéro de carte",
  cardHolder: "Titulaire de la carte",
  cardHolderName: "Nom du titulaire",
  cardExpires: "Expiration de la carte",
  cvv: "CVV",
  firstName: "Prénom",
  lastName: "Nom de famille",
  zipCode: "Code postal",
  address: "Adresse",
  state: "Etat",
  email: "Email",
  phone: "Téléphone",
  dateOfBirth: "Date de naissance",
  transactionStatus: "Statut de la transaction",
  trace: "Trace",
  amount: "Montant",
  min: "min",
  max: "max",
  overrideLimits: "Remplacer les limites",
  transaction: "Transaction",
  processed: "Traité",
  transactionRejected: "Transaction refusée",
  transactionApproved: "Transaction approuvée",
  yourTransactionIsPending: "Votre transaction est en attente",
  transactionAuthorized: "Transaction autorisée",
  requestAccepted: "Requête acceptée",
  status2:
    "Votre demande de retrait a été acceptée. Veuillez noter que d'autres autorisations et traitements seront exécutés pendant les heures d'ouverture des jours ouvrables. Pendant ce temps, le personnel exécutif peut vous contacter pour une confirmation d'identité.",
  unexpectedErrorOccurred: "Une erreur inattendue s'est produite",
  status3:
    "Nous nous excusons, un problème est survenu lors du traitement de votre transaction. Veuillez trouver les détails techniques ci-dessous:",
  tryAgain: "Réessayer",
  somethingWentWrong: "Quelque chose a mal tourné ...",
  newPayoutRequest: "Nouvelle demande de paiement",
  makeAnotherPayment: "Effectuer un autre paiement",
  profile: "Profil",
  justOneMoreStep: "Juste un pas de plus...",
  "profile-auth":
    "Veuillez compléter votre profil afin de procéder au paiement en utilisant {{title}}",
  payWith: "Payer avec",
  ProceedWith: "Procéder à",
  vtTitle: "Terminal virtuel:",
  pay: "Payer",
  deposit: "Dépôt",
  withdraw: "Se désister",
  withdrawal: "Retrait",
  hello: "Bonjour,",
  currency: "Devise",
  currentIp: "IP actuelle:",
  UseCurrentIp: "Utiliser l'IP actuelle:",
  UseCustomerIp: "Utiliser l'IP du client:",
  copyright: "droits d'auteur",
  cannotBeBlank: "Ne peut être vide.",
  noDepositFree: "Pas de frais de dépôt. aucun frais de retrait.",
  next: "prochain",
  backToSite: "retour au site",
  maximumLength: "Longueur maximale {{length}} caractères",
  minimumLength: "Longueur minimale {{length}} caractères",
  emailIsNotValid: "L'e-mail n'est pas une adresse e-mail valide.",
  phoneIsNotValid: "Le téléphone n'est pas valide",
  cardHolderInvalid:
    "Le titulaire de la carte n'est pas valide. Le titulaire de la carte doit contenir le prénom et le nom de famille.",
  pleaseEnterPayout: "Veuillez saisir un montant de paiement inférieur ou égal",
  invalidAmount: "Montant invalide",
  transactionWillAppear: "Votre transaction apparaîtra sur votre relevé comme",
  "Customer does not exist": "Le client n'existe pas",
  "Sorry no available gateways found.":
    "Désolé, aucune passerelle disponible n'a été trouvée.",
  "Incorrect currency": "Devise incorrecte",
  "Sorry, not supported payment type":
    "Désolé, le type de paiement n'est pas pris en charge",
  "Payment method has no payment type":
    "Le mode de paiement n'a pas de type de paiement",
  "Incorrect payment type in payment method":
    "Type de paiement incorrect dans le mode de paiement",
  "Gateway limit exceeded": "Limite de passerelle dépassée",
  "Not supported transaction type": "Type de transaction non pris en charge",
  "Sorry. Payout with credit card gateway not supported yet":
    "Désolé. Paiement avec passerelle de carte de crédit pas encore pris en charge",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Nous nous excusons, ce mode de paiement n'est pas applicable pour les détails de paiement spécifiés. Veuillez recharger la page et essayer un montant, une devise ou un mode de paiement différent.",
  "Back to site": "Retour au site",
  "Your transaction has been requested. Please wait for approval.":
    "Votre transaction a été demandée. Veuillez attendre l'approbation.",
  "Token expired": "Le jeton a expiré",
  back: "retour",
  "Transaction not found.": "Transaction introuvable.",
  "Continue in the New Window": "Continuer dans la nouvelle fenêtre",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Veuillez ne pas fermer cette fenêtre. Vous serez redirigé à la fin de la transaction dans une nouvelle fenêtre. Vous ne voyez pas une nouvelle fenêtre ouvrant une connexion sécurisée ? Nous sommes là pour vous aider à finaliser votre transaction.",
  "Click to continue": "Cliquez pour continuer",
  "Just One More Step...": "Juste un pas de plus...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Le mode de paiement que vous avez sélectionné sera ouvert dans une nouvelle page. Veuillez cliquer sur le bouton 'PROCÉDER' ci-dessous pour accéder à la dernière étape.",
  Proceed: "Procéder",
  "Opening a Secure Connection...": "Ouverture d'une connexion sécurisée ...",
  "You will be redirected to complete your transaction in just a moment":
    "Vous serez redirigé pour terminer votre transaction dans un instant",
  "Close window": "Fermer la fenêtre",
  "Partial approval confirmation": "Confirmation d'approbation partielle",
  PartiallyApprovedAmount:
    "Le solde de votre carte est insuffisant pour créditer le montant total. La transaction a été partiellement approuvée pour {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Veuillez cliquer sur « OK » pour confirmer le montant du dépôt partiel ou sur « ANNULER » pour annuler la transaction. Le dépôt sera confirmé automatiquement après 5 minutes si aucune mesure n'est prise.",
  fxRateSpreadText:
    "Le taux de change indiqué comprend tous les frais et charges.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Chargement, veuillez patienter",
};
