import {determineMethodType} from "../../utils/helper-functions/determine-method-type";

const createMethod = (state, action) => {
    if (state === undefined) {
        return ""
    }

    switch (action.type) {
        case 'CREATE_CURRENT_GATEWAY':
            return determineMethodType(action.payload);
        case 'CREATE_CURRENT_GATEWAY_FROM_AMOUNT_PAGE':
            return determineMethodType(action.payload);
        default:
            return state.method;
    }
};

export { createMethod }