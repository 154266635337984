export const ta = {
  "Loading... Please Wait": "Loading... Please Wait",
  yourTransactionIsPendingForPayOut: "Your Withdrawal Request is Pending",
  status1:
    "Please be aware that your transaction is being reviewed by the processing bank or payment service provider. If you would like more information about the status of your transaction, please contact support to request assistance on Trace ID: {{trace_id}}",
  Status: "Status",
  "Deposit Review For Account:": "Deposit Review For Account:",
  "New Transaction": "New Transaction",
  Update: "Update",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.",
  dod_error_text:
    "You must read and accept Declaration of Deposit in order to proceed",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "You must accept Terms and Conditions in order to proceed with your deposit",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "I acknowledge that I have read, and do hereby accept the Terms and Conditions",
  dod_url: "#",
  dod_url_text: "Terms and Conditions",
  dod_text:
    "I acknowledge that I have read, and do hereby accept the {{dod_url}}",
  transactionSuccessfullyCanceled:
    "The transaction {{id}} was successfully canceled",
  "Fee should not exceed 20% of the amount":
    "Fee should not exceed 20% of the amount",
  "My Info": "My Info",
  "Billing info": "Billing info",
  "Withdrawal Requests": "Withdrawal Requests",
  "No Withdrawal Requests found": "No Withdrawal Requests found",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Cancel",
  Amount: "Amount",
  "Last Update": "Last Update",
  "Payment Method": "Payment Method",
  TID: "TID",
  Action: "Action",
  "Confirmation required": "Confirmation required",
  "Are you sure you want to cancel transaction":
    "Are you sure you want to cancel transaction",
  Yes: "Yes",
  No: "No",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Min-Max Amounts",
  "Fee (included)": "Fee (included)",
  "- Amount between": "- Amount between",
  "I have the Promocode!": "I have the Promo code!",
  "Please enter the Promocode": "Please enter the Promo code",
  Promocode: "Promo code",
  noSavedTemplates: "No Cards Saved",
  noOptions: "No options",
  "Please confirm your billing details": "Please confirm your billing details",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Processing fee",
  Fee: "Fee",
  Total: "Total",
  customer_support_link_text: "Customer Support",
  terms_conditions_link_text: "Terms and Conditions",
  "Edit Billing Info": "Edit Billing Info",
  chooseAnAccount: "Choose an account",
  "Requested to Receive": "Requested to Receive",
  "Receive With": "Receive With",
  "Unknown session": "Unknown session",
  transactionId: "Transaction ID",
  traceId: "Trace ID",
  save: "Save",
  fillTheBillingInformation: "Please fill in the billing information below",
  correctionsRequired: "corrections required",
  billingInfo: "Billing info",
  "Cancelled by client": "Cancelled by client",
  "Your Cards": "Your Cards",
  Done: "Done",
  redirect: "Redirect",
  redirectingTo: "Redirecting to",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account, if you require any assistance with your account, please contact customer service.",
  "Not supported yet": "Not supported yet",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Apologies, it appears that you don't have any payment details registered to use for withdrawal request",
  "Not available gateways found": "Not available gateways found",
  "Token is invalid": "Token is invalid",
  "Payment method not available. Please contact your site administrator.":
    "Payment method not available. Please contact your site administrator.",
  cardIsNotValid: "Card is not valid",
  invalidInputFormat: "Invalid input format. At least {{length}} characters.",
  invalidDateFormat: "Invalid date format: MM/YY",
  maximumValueIs: "Maximum value is",
  minimumValueIs: "Minimum value is",
  enterPayoutAmount:
    "Please enter a payout Amount less than or equal to your Available Balance.",
  maxLength2: "Maximum length 2 characters.",
  footer1:
    "Some issuing banks have restricted the use of credit cards for online entertainment. Please feel free to use one of the alternative 3rd party payment methods above or contact our {{customer_support_url}} Representatives toll free at N/A to fund your account. \n Please Note: Depending on your issuing bank, credit card transactions could be treated as a cash advance. {{application_key}} cannot be held responsible for any cash advance fees involved in this transaction as this is established by the credit card's issuing bank. Consult with your bank for further details.",
  footer2:
    "For assistance or confirmation of an attempted transaction please contact {{customer_support_url}}.\n Please Note: Depending on your bank this transaction could be treated as a cash advance. {{application_key}} will not be responsible for any cash advance fees involved in this transaction. The transaction will be identified on your credit card statement as Credit Card Descriptor unless stated otherwise on your transaction receipt.",
  footer3:
    "All payout requests are subject to our {{terms_conditions_url}}. If you require assistance or further information, please contact {{customer_support_url}}. Once your request is submitted, the information is forwarded to our cashier department for processing. You will receive an email as confirmation when your payout is processed.The payout amount and processing fee (if applicable) will be debited from your account following our payout review.",
  gatewaysTitle: "How would you like to pay?",
  gatewaysTitleDeposit: "Please choose deposit method",
  gatewaysTitleWithdrawal: "Please choose withdrawal method",
  payInTitle: "Pay with Credit or Debit Card",
  allPaymentMethods: "all payment methods",
  submit: "Submit",
  useAddressOnFile: "Use address on file",
  customerName: "Customer Name",
  country: "Country",
  province: "Province",
  city: "City",
  postalCode: "Postal code",
  street: "Street",
  proceed: "Proceed",
  cardNumber: "Card Number",
  cardHolder: "Card Holder",
  cardHolderName: "Card Holder Name (Latin only)",
  cardExpires: "Card Expires",
  cvv: "CVV",
  firstName: "First Name",
  lastName: "Last Name",
  zipCode: "Zip Code",
  address: "Address",
  state: "State",
  email: "Email",
  phone: "Phone",
  dateOfBirth: "Date Of Birth",
  transactionStatus: "Transaction Status",
  trace: "Trace",
  amount: "Amount",
  min: "min",
  max: "max",
  overrideLimits: "Override limits",
  transaction: "Transaction",
  processed: "Processed By",
  transactionRejected: "Transaction Rejected",
  transactionApproved: "Transaction Approved",
  yourTransactionIsPending: "Your Transaction is Pending",
  transactionAuthorized: "Transaction Authorized",
  requestAccepted: "Withdrawal request accepted",
  status2:
    "Your withdrawal request has been accepted. Please be aware that further authorization and processing will be executed during the operational hours of business days. During that time, you may be contacted by executive staff for identity confirmation.",
  unexpectedErrorOccurred: "Unexpected Error Occurred",
  status3:
    "We apologize, something went wrong while processing your transaction. Please find the technical details below: ",
  tryAgain: "Try Again",
  somethingWentWrong: "Something went wrong...",
  newPayoutRequest: "New Payout Request",
  makeAnotherPayment: "Make Another Payment",
  profile: "Profile",
  justOneMoreStep: "Just one more step...",
  "profile-auth":
    "Please complete your profile in order to proceed with the payment using {{title}}",
  payWith: "Pay With",
  ProceedWith: "Proceed with",
  vtTitle: "Virtual Terminal: ",
  pay: "Pay",
  deposit: "Deposit",
  withdraw: "Withdraw",
  withdrawal: "Withdrawal",
  hello: "Hello,",
  currency: "Currency",
  currentIp: "Current IP: ",
  UseCurrentIp: "Use current IP: ",
  UseCustomerIp: "Use customer IP: ",
  copyright: "Copyright",
  chooseAmount: "Choose amount",
  cannotBeBlank: "Cannot be blank.",
  noDepositFree: "No deposit fee. no withdrawal fee.",
  next: "Next",
  backToSite: "back to site",
  maximumLength: "Maximum length {{length}} characters",
  minimumLength: "Minimum length {{length}} characters",
  emailIsNotValid: "Email is not a valid email address.",
  phoneIsNotValid: "Phone is not valid",
  cardHolderInvalid:
    "Card holder is invalid. Card holder should contain the first name and the last name.",
  pleaseEnterPayout: "Please enter a payout Amount less than or equal",
  invalidAmount: "Invalid amount",
  transactionWillAppear: "Your transaction will appear on your statement as",
  "Customer does not exist": "Customer does not exist",
  "Sorry no available gateways found.": "Sorry no available gateways found.",
  "Incorrect currency": "Incorrect currency",
  "Sorry, not supported payment type": "Sorry, not supported payment type",
  "Payment method has no payment type": "Payment method has no payment type",
  "Incorrect payment type in payment method":
    "Incorrect payment type in payment method",
  "Gateway limit exceeded": "Gateway limit exceeded",
  "Not supported transaction type": "Not supported transaction type",
  "Sorry. Payout with credit card gateway not supported yet":
    "Sorry. Payout with credit card gateway not supported yet",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.",
  "Back to site": "Back to site",
  "Your transaction has been requested. Please wait for approval.":
    "Your transaction has been requested. Please wait for approval.",
  "Token expired": "Token expired",
  back: "BACK",
  "Transaction not found.": "Transaction not found",
  "Continue in the New Window": "Continue in the New Window",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.",
  "Click to continue": "Click to continue",
  "Just One More Step...": "Just One More Step...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step",
  Proceed: "Proceed",
  "Opening a Secure Connection...": "Opening a Secure Connection...",
  "You will be redirected to complete your transaction in just a moment":
    "You will be redirected to complete your transaction in just a moment",
  "Close window": "Close window",
  "Partial approval confirmation": "Partial approval confirmation",
  PartiallyApprovedAmount:
    "Your card balance has insufficient funds to credit the full amount. The transaction was partially approved for {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Please click 'OK' to confirm the partial deposit amount or 'CANCEL' to cancel the transaction. The deposit will be confirmed automatically after 5 minutes if no action is taken.",
  fxRateSpreadText:
    "The indicated exchange rate includes all fees and charges.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newWithdrawChoosePaymentMethod: "Withdraw your funds",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newCCDetails: "Credit Card Details",
  newCCProceed: "Proceed with Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newWithdrawAmount: "Withdraw Amount",
  newTotalCharge: "Total Charge",
  newChooseCurrency: "Choose Currency",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}}{{min}} - {{currency}}{{max}}. Over {{max}} requires multiple deposits",
  newWithdrawAmountMustBe:
    "Withdraw amount must be between {{currency}}{{min}} - {{currency}}{{max}}. Over {{max}} requires multiple withdraws",
  newMaxAmount: "Max amount must be {{base_currency}}{{base_currency_max}} / {{currency}}{{max}}",
  newMinAmount: "Min amount must be {{base_currency}}{{base_currency_min}} / {{currency}}{{min}}",
  newFee: "Fee",
  newExchangeRate: "Exchange Rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration Date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newWithdraw: "Withdraw",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  new3DDeclineMessage: "Your transaction was failed due to missing/incorrect 3DV code, please complete 3DV code and try again or contact customer service.",
  newRegularDeclineMessage: "We could not process your deposit. Please try another deposit method or contact customer service.",
  newEmail: "Email",
  newPhone: "Phone",
  newPhoneValue: "+380123456789",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newUploadLink: "/test-data/",
  newMyAccount: "My Account",
  newMyAccountLink: "/test-data/",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. \n An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. \n Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. \n Your account will be immediately updated once confirmation is received.",
  newInvalidExpireData: "Card is expired",
  newSelectCurrency: "Select your currency",
  newUseBillingAddress: "Use Current Billing Address",
  newWithdrawalCompleted: "Thank you for your Withdrawal",
  newWithdrawalCompletedMessage: "Your withdrawal request has been accepted. Please be acknowledged that further authorization and processing will be executed during the operational hours of business days.\n        During that time, you may be contacted by executive staff for identity confirmation.",
  newBackToTrading: "Back to Trading",
  initialLoadingText: "பதிவேறுகிறது, காத்திருக்கவும்",
};
