export const ru = {
  "Loading... Please Wait": "Загрузка... пожалуйста подождите",
  yourTransactionIsPendingForPayOut:
    "Ваш запрос на вывод средств находится на рассмотрении",
  status1:
    "Имейте в виду, что ваша транзакция проверяется банком-обработчиком или поставщиком платежных услуг. Если вам нужна дополнительная информация о статусе транзакции, обратитесь в службу поддержки, чтобы запросить помощь по Trace ID: {{trace_id}}",
  Status: "Статус",
  "Deposit Review For Account:": "Обзор депозита для учетной записи:",
  "New Transaction": "Новая транзакция",
  Update: "Обновление",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Выбранный способ оплаты открывается на отдельной странице. Нажмите 'ПРОДОЛЖИТЬ', чтобы перейти в новое окно браузера и продолжить процесс оплаты.",
  dod_error_text:
    "Вы должны прочитать и принять декларацию о депозите, чтобы продолжить",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Вы должны принять условия, чтобы продолжить внесение депозита.",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Я подтверждаю, что прочитал(а) и принимаю Условия использования",
  dod_url: "#",
  dod_url_text: "Условия использования",
  dod_text: "Я подтверждаю, что прочитал(а) и принимаю {{dod_url}}",
  transactionSuccessfullyCanceled: "Транзакция {{id}} была успешно отменена",
  "Fee should not exceed 20% of the amount":
    "Комиссия не должна превышать 20% от суммы",
  "My Info": "Моя информация",
  "Billing info": "Платежная информация",
  "Withdrawal Requests": "Запросы на вывод средств",
  "No Withdrawal Requests found": "Запросы на вывод средств не найдены",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Отмена",
  Amount: "Сумма",
  "Last Update": "Последнее обновление",
  "Payment Method": "Способ оплаты",
  TID: "Время",
  Action: "Действие",
  "Confirmation required": "Требуется подтверждение",
  "Are you sure you want to cancel transaction":
    "Вы уверены, что хотите отменить транзакцию",
  Yes: "Да",
  No: "Нет",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Мин-Макс сумма",
  "Fee (included)": "Комиссия (включена)",
  "- Amount between": "- Сумма между",
  "I have the Promocode!": "У меня есть промо код!",
  "Please enter the Promocode": "Пожалуйста, введите промо код",
  Promocode: "Промо код",
  noSavedTemplates: "Нет сохраненных карт",
  noOptions: "Нет вариантов",
  "Please confirm your billing details":
    "Пожалуйста, подтвердите свои платежные данные",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Разовая комиссия",
  Fee: "Комиссия",
  Total: "Всего",
  customer_support_link_text: "Служба поддержки",
  terms_conditions_link_text: "Условия и положения",
  "Edit Billing Info": "Изменить платежную информацию",
  chooseAnAccount: "Выберите аккаунт",
  "Requested to Receive": "Запрошено на получение",
  "Receive With": "Получить с",
  "Unknown session": "Неизвестная сессия",
  transactionId: "ID Транзакции",
  traceId: "ID Номера отслеживания",
  save: "Сохранить",
  fillTheBillingInformation: "Пожалуйста, заполните платежную информацию ниже",
  correctionsRequired: "требуются исправления",
  billingInfo: "Платежная информация",
  "Cancelled by client": "Отменено клиентом",
  "Your Cards": "Ваши карты",
  Done: "Готово",
  redirect: "Перенаправление",
  redirectingTo: "Перенаправление на",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Операция не выполнена. Согласно нашим данным, указанный счет PayPal привязан к другому торговому счету. Клиентам разрешается иметь только один торговый счет на нашем сайте. Для привязки счета PayPal к текущему логину и для получения помощи обратитесь в службу поддержки.",
  "Not supported yet": "Пока что не поддерживается",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Извините, похоже, что у вас нет зарегистрированных платежных реквизитов для использования в запросе на снятие средств.",
  "Not available gateways found": "Нет доступных вариантов оплаты",
  "Token is invalid": "Токен недействителен",
  "Payment method not available. Please contact your site administrator.":
    "Способ оплаты недоступен. Пожалуйста, свяжитесь с администратором сайта.",
  cardIsNotValid: "Карта не действительна",
  invalidInputFormat: "Неверный формат ввода. Минимум {{length}} символа.",
  invalidDateFormat: "Недопустимый формат даты.",
  maximumValueIs: "Максимальное значение",
  minimumValueIs: "Минимальное значение",
  chooseAmount: "Выберите сумму",
  enterPayoutAmount:
    "Пожалуйста, введите сумму, которая меньше или равна вашему доступному балансу.",
  maxLength2: "Максимальная длина 2 символа.",
  footer1:
    "Некоторые банки-эмитенты ограничили использование кредитных карт для онлайн-развлечений. Воспользуйтесь одним из альтернативных способов оплаты третьей стороной, указанным выше, или свяжитесь с нашими {{customer_support_url}} представителями по бесплатному телефону для финансирования ваш аккаунт. \n Обратите внимание: в зависимости от банка-эмитента транзакции по кредитной карте могут рассматриваться как аванс наличными. {{application_key}} не может нести ответственность за какие-либо сборы за аванс наличными, связанные с этой транзакцией, поскольку это установлено кредитом банк-эмитент карты. Для получения дополнительных сведений обратитесь в свой банк. ",
  footer2:
    "Для получения помощи или подтверждения попытки транзакции свяжитесь с {{customer_support_url}}. \n Обратите внимание: в зависимости от вашего банка эта транзакция может рассматриваться как денежный аванс. {{application_key}} не несет ответственности за любые комиссии за аванс наличными, связанные с этой транзакцией. В выписке по кредитной карте транзакция будет обозначена как Дескриптор кредитной карты, если иное не указано в квитанции о транзакции. ",
  footer3:
    "Все запросы на выплату регулируются нашими {{terms_conditions_url}}. Если вам требуется помощь или дополнительная информация, свяжитесь с {{customer_support_url}}. После отправки вашего запроса информация будет отправлена ​​в нашу кассу для обработки. . Вы получите электронное письмо с подтверждением, когда ваша выплата будет обработана. Сумма выплаты и комиссия за обработку (если применимо) будут списаны с вашего счета после нашего обзора выплат. ",
  gatewaysTitle: "Выберите способ оплаты",
  gatewaysTitleDeposit: "Пожалуйста, выберите способ депозита",
  gatewaysTitleWithdrawal: "Пожалуйста, выберите способ вывода средств",
  payInTitle: "Оплата с кредитной или дебетовой карты",
  allPaymentMethods: "Все способы оплаты",
  submit: "Подтвердить",
  useAddressOnFile: "Использовать персональные данные для платежа",
  customerName: "Имя покупателя",
  country: "Страна",
  province: "Область",
  city: "Город",
  postalCode: "Почтовый Код",
  street: "Улица",
  proceed: "Продолжить",
  cardNumber: "Номер карты",
  cardHolder: "Имя на карте",
  cardHolderName: "Имя владельца карты",
  cardExpires: "Срок действия карты",
  cvv: "CVV",
  firstName: "Имя",
  lastName: "Фамилия",
  zipCode: "Почтовый Индекс",
  address: "Адрес",
  state: "Состояние",
  email: "Эл. адрес",
  phone: "Телефон",
  dateOfBirth: "Дата рождения",
  transactionStatus: "Статус транзакции",
  trace: "Номер отслеживания",
  amount: "Сумма",
  min: "мин",
  max: "макс",
  overrideLimits: "Переопределить лимит",
  transaction: "Транзакция",
  processed: "Обработано",
  transactionRejected: "Транзакция отклонена",
  transactionApproved: "Транзакция подтверждена",
  yourTransactionIsPending: "Ваша транзакция обрабатывается",
  transactionAuthorized: "Транзакция авторизована",
  requestAccepted: "Запрос принят",
  status2:
    "Ваш запрос на снятие был принят. Обращаем ваше внимание, что дальнейшая авторизация и обработка будут выполняться в рабочее время. В течение этого времени с вами может связаться исполнительный персонал для подтверждения личности.",
  unexpectedErrorOccurred: "Произошла непредвиденная ошибка",
  status3:
    "Приносим свои извинения, что-то пошло не так при обработке вашей транзакции. Технические детали: ",
  tryAgain: "Попробуйте еще раз",
  somethingWentWrong: "Что-то пошло не так...",
  newPayoutRequest: "Новый запрос на выплату",
  makeAnotherPayment: "Сделать еще один платеж",
  profile: "Профиль",
  justOneMoreStep: "Еще один шаг...",
  "profile-auth":
    "Пожалуйста, заполните свой профиль, чтобы продолжить оплату, используя {{title}}",
  payWith: "Оплата с",
  ProceedWith: "Вывод средств с",
  vtTitle: "Виртуальный терминал: ",
  pay: "Оплатить",
  deposit: "Депозит",
  withdraw: "Снятие средств",
  withdrawal: "Вывод средств",
  hello: "Здравствуйте,",
  currency: "Валюта",
  currentIp: "Текущий IP: ",
  UseCurrentIp: "Использовать текущий IP: ",
  UseCustomerIp: "Использовать IP клиента: ",
  copyright: "авторское право",
  cannotBeBlank: "Не может быть пустым.",
  noDepositFree: "Без депозита. Без комиссии",
  next: "Дальше",
  backToSite: "Вернуться на сайт",
  maximumLength: "Максимальная длина {{length}} символов",
  minimumLength: "Минимальная длина {{length}} символов",
  emailIsNotValid: "E-mail не является валидным адресом электронной почты.",
  phoneIsNotValid: "Телефон недействителен",
  cardHolderInvalid:
    "Владелец карты недействителен. Владелец карты должен содержать имя и фамилию.",
  pleaseEnterPayout: "Пожалуйста, введите сумму выплаты, меньшую или равную",
  invalidAmount: "Неверная сумма",
  transactionWillAppear: "Ваша транзакция появится в вашем запросе как",
  "Customer does not exist": "Клиент не существует",
  "Sorry no available gateways found.":
    "Извините, доступные методы оплаты не найдены.",
  "Incorrect currency": "Неверная валюта",
  "Sorry, not supported payment type": "Извините, не поддерживается тип оплаты",
  "Payment method has no payment type": "Способ оплаты не имеет типа оплаты",
  "Incorrect payment type in payment method":
    "Неправильный способ оплаты в методе",
  "Gateway limit exceeded": "Превышен лимит метода оплаты",
  "Not supported transaction type": "Не поддерживается тип транзакции",
  "Sorry. Payout with credit card gateway not supported yet":
    "К сожалению, выплата с помощью кредитной карты пока что не поддерживается",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Приносим свои извинения, этот способ оплаты не применим для указанных реквизитов. Пожалуйста, перезагрузите страницу и попробуйте другую сумму, валюту или способ оплаты.",
  "Back to site": "Вернуться на сайт",
  "Your transaction has been requested. Please wait for approval.":
    "Транзакция была запрошена. Пожалуйста, дождитесь подтверждения.",
  "Token expired": "Токен недействителен",
  back: "Назад",
  "Transaction not found.": "Транзакция не найдена.",
  "Continue in the New Window": "Продолжить в новом окне",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Пожалуйста, не закрывайте это окно. Вы будете перенаправлены обратно после завершения транзакции в новом окне. Не видите новое окно, открывающее безопасное соединение? Мы здесь, чтобы помочь вам завершить транзакцию",
  "Click to continue": "Нажмите для продолжения",
  "Just One More Step...": "Еще один шаг ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Выбранный вами способ оплаты будет открыт на новой странице. Нажмите кнопку 'ПРОДОЛЖИТЬ' ниже, чтобы перейти к последнему шагу.",
  Proceed: "Продолжить",
  "Opening a Secure Connection...": "Открытие безопасного соединения ...",
  "You will be redirected to complete your transaction in just a moment":
    "Вы будете перенаправлены на завершение транзакции в считанные секунды.",
  "Close window": "Закрыть окно",
  "Partial approval confirmation": "Подтверждение частичного подтверждения",
  PartiallyApprovedAmount:
    "На балансе вашей карты недостаточно средств для зачисления полной суммы. Транзакция частично одобрена на сумму {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Нажмите «ОК», чтобы подтвердить частичную сумму депозита, или «ОТМЕНА», чтобы отменить транзакцию. Депозит будет подтвержден автоматически через 5 минут, если не будет предпринято никаких действий.",
  fxRateSpreadText: "Указанный курс обмена включает все комиссии и сборы.",
  newNext: "Далее",
  newChoosePaymentMethod: "Выберите платежный инструмент",
  newQuickDeposit: "Быстрый депозит",
  newViaCreditCard: "Через Платежную Карту",
  newMin: "Mин",
  newMax: "Mакс",
  newDepositAmount: "Сумма Депозита",
  newTotalCharge: "Суммарный платеж",
  newEnterAmount: "Введите Сумму",
  newDepositAmountMustBe:
    "Сумма депозита должна составлять от {{currency}}{{min}} до {{currency}}{{max}}. Для внесения более {{max}} необходимо несколько депозитов.",
  newFee: "Сбор",
  newExchangeRate: "Обменный курс",
  newFeeIncludes: "* В суммарный платеж входят сборы",
  newPaymentSecure:
    "Ваш платеж безопасен. Данные вашей карты не будут никому передаваться.",
  newCardNumber: "Номер карты",
  newExpireData: "Дата истечения срока",
  newCardHolderName: "Имя держателя карты",
  newCvv: "CVV",
  newDeposit: "Внести средства",
  newTermsOf: "Условия и положения",
  newChooseCreditCard: "Выберите кредитную карту",
  newAddPayMethod: "Добавить способ оплаты",
  newPlus: "+",
  newAdd: "Добавить",
  newWhatIsCvv: "Что такое CVV?",
  newWhatIsCvvDescription:
    "CVV — это код из 3 или 4 цифр на обратной стороне вашей карты, который обеспечивает дополнительную защиту.",
  newMissingInformation: "Недостающая информация",
  newMissingDetails:
    "Нам недостает сведений. Введите данные и нажмите «Продолжить», чтобы внести средства",
  newContinue: "Продолжить",
  newTransactionId: "ID Транзакции",
  newDepositFailed: "Депозит не прошел",
  newFundsDeposit:
    "У вас недостаточно средств для депозита. Пожалуйста, введите меньшую сумму, чтобы начать торговать с нами.",
  newContactOur:
    "Пожалуйста, свяжитесь с нашей службой поддержки клиентов через:",
  newEmail: "Почта",
  newPhone: "Телефон",
  newChat: "Чат",
  newOr: "Или",
  newChangeAmount: "Изменить Сумму",
  newTryAgain: "Повторить попытку",
  newClickHere: "Кликните сюда",
  newMyAccount: "Мой счет",
  newAnyBonuses:
    "Любые бонусы будут добавлены на ваш счет только после проверки ваших документов.",
  newToUpload: "чтобы загрузить свои документы. Желаем вам успешной торговли.",
  newDepositCompleted: "Депозит выполнен",
  newThankYou: "Спасибо за внесение средств",
  newTradingImmediately:
    "Можно приступать к торговле. На ваш зарегистрированный электронный адрес отправлено письмо с данными депозита.",
  newTradeNow: "Приступить к торговле",
  newTransactionFailed: "Транзакция не выполнена",
  newWeCouldNotDeposit:
    "Мы не смогли обработать вашу транзакцию. Попробуйте воспользоваться другим способом внесения средств или обратитесь в службу поддержки.",
  newTransactionPending: "Транзакция на рассмотрении",
  newClosed: "Закрыть",
  newThankYouPending:
    "Спасибо. Сейчас ваша транзакция находится на рассмотрении и ожидает подтверждения. Сразу после подтверждения ваш счет будет обновлен.",
  newInvalidExpireData: "Срок действия карты истек",
  initialLoadingText: "Загрузка, пожалуйста подождите",
};
