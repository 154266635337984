import React, {useEffect} from 'react'
import {withI18N} from "../../../hoc-components";
import "../modals.sass"
import InputWrapper from "../../../helper-components/input-wrapper/input-wrapper";
import {CloseButtonSVG} from "../../../../@cashier-project/assets/images/close-button";
import {withComputeCallbacks} from "../../../hoc-components/hoc-context/with-compute-callbacks";
import compose from "../../../../utils/helper-functions/compose";
import {withAdr} from "../../../hoc-components/with-adr";
import {shallowEqual, useSelector} from "react-redux";
import {clientClickEvent} from "../../../../utils/custom-cashier-events/client-click-event";

const ProfileModal = ({close, lang, s}) => {
    
    const {customer, waste} = useSelector(({profileInfo:{customer={}}, payInfo:{waste}}) => ({customer, waste}), shallowEqual);

    useEffect(()=>{
        clientClickEvent("profile-modal_open")
        return ()=> clientClickEvent("profile-modal_close")
    },[])
    
    return(
        <div onClick={close} className={`${s('modal-container')} ${s('profile-modal')}`}>
            <div
                className={`${s('modal')}`}
                onClick={(e) => e.stopPropagation()}>
                <div className={`${s('modal-header')}`}>
                    <div className={`${s('modal-title')}`}>{lang("profile")}</div>
                    <div className={`${s('close')}`} onClick={close}>
                        <CloseButtonSVG />
                    </div>
                </div>
                {!waste.includes('full_name') && customer['full_name'] || (customer['first_name'] && customer['last_name']) ?
                    <InputWrapper
                        title = {lang("customerName")}>
                        <input
                            value = {customer.full_name ? `${customer.full_name}` : `${customer.first_name} ${customer.last_name}`}
                            type="text"
                            className={`${s('input')}`}
                            disabled={true}
                        />
                    </InputWrapper> : ""
                }
                {!waste.includes('country') && customer['country'] &&
                    <InputWrapper
                        title = {lang("country")}>
                        <input
                            value = {customer.country}
                            type="text"
                            className={`${s('input')}`}
                            disabled={true}
                        />
                    </InputWrapper>
                }
                {!waste.includes('state') && customer['state'] &&
                    <InputWrapper
                        title = {lang("province")}>
                        <input
                            value = {customer.state}
                            type="text"
                            className={`${s('input')}`}
                            disabled={true}
                        />
                    </InputWrapper>
                }
                {!waste.includes('city') && customer['city'] &&
                    <InputWrapper
                        title = {lang("city")}>
                        <input
                            value = {customer.city}
                            type="text"
                            className={`${s('input')}`}
                            disabled={true}
                        />
                    </InputWrapper>
                }
                {!waste.includes('zip') && customer['zip'] &&
                    <InputWrapper
                        title = {lang("postalCode")}>
                        <input
                            value = {customer.zip}
                            type="text"
                            maxLength={12}
                            className={`${s('input')}`}
                            disabled={true}
                        />
                    </InputWrapper>
                }
                {!waste.includes('address') && customer['address'] &&
                    <InputWrapper
                        title = {lang("street")}>
                        <input
                            title = {lang("street")}
                            value = {customer.address}
                            type="text"
                            className={`${s('input')}`}
                            disabled={true}
                        />
                    </InputWrapper>
                }
            </div>
        </div>
    )
};

export default compose(
    withComputeCallbacks(7), withI18N, withAdr
)(ProfileModal)
