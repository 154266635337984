import {useEffect, useMemo} from 'react'
import {withResizeDetector} from 'react-resize-detector';
import compose from "../../utils/helper-functions/compose";
import {postMessage} from "../../utils/helper-functions/post-message";
import {bindActionCreators} from "redux";
import {changeInitTheme} from "../../state/actions";
import {connect, shallowEqual, useSelector} from "react-redux";
import {getUrlHost} from "../../utils/helper-functions/get-url-host";

const ConnectParentDomain = ({height, children, initTheme, changeInitTheme, currentGateway, resultOfPay}) => {
    const {title} = currentGateway;
    let {transaction} = resultOfPay;
    const {currentDomain, valid_domains = []} = initTheme;

    const profileInfo = useSelector(({profileInfo}) => profileInfo, shallowEqual);
    const {cid, order_id} = useMemo(() => {
        const cid = (profileInfo?.customer?.pin || initTheme?.cid) ?? ""
        const order_id = (profileInfo?.order_id || initTheme?.order_id) ?? ""
        return {cid, order_id}
    }, [profileInfo, initTheme])
    
    useEffect(() => {
        if (!currentDomain) return;
        const sendAdvancedCashierEvent = (event) => {
            const {detail} = event
            postMessage({...detail, cid, order_id}, currentDomain)
        }
        window.addEventListener('trigger_advance_cashier_event', sendAdvancedCashierEvent)
        return () => {
            window.removeEventListener('trigger_advance_cashier_event', sendAdvancedCashierEvent)
        }
        
    }, [currentDomain])
    
    useEffect(() => {
            if (!initTheme.validate_domain) return;
            
            const receiveMessage = (event) => {
                //if currentDomain is set no need to do it again.
                //if event origin is the same with current website one should not set the currentDomain to it.
                //If event origin does not exist in valid domains array then don't set it.
                if (currentDomain || event.origin === window.origin || !valid_domains?.includes(getUrlHost(event.origin))) return;
                changeInitTheme({...initTheme, currentDomain: event.origin})
            };
            
            window.addEventListener("message", receiveMessage);
            postMessage({init: true});
            
            return () => {
                window.removeEventListener("message", receiveMessage);
            }
        }, []
    );
    
    //resize event
    useEffect(() => {
        if (!height || !currentDomain) return;
        
        postMessage({
            event_type: "resize",
            height,
            cid,
            order_id
        }, currentDomain)
    }, [height, currentDomain]);
    
    //transaction attempted event
    useEffect(() => {
        if (!transaction || !currentDomain) return;
        postMessage({
            event_type: 'transaction',
            ...transaction,
            cid,
            order_id
        }, currentDomain)
    }, [transaction, currentDomain]);
    
    //payment method selected event
    useEffect(() => {
        if (typeof title !== 'string' || title.length === 0 || !currentDomain) return;
        
        postMessage({
            event_type: "payment_method_selected",
            payment_method: title,
            cid,
            order_id
        }, currentDomain)
        
    }, [title, currentDomain]);
    
    return children
};

const mapStateToProps = ({currentGateway, initTheme, resultOfPay}) => {
    return {currentGateway, initTheme, resultOfPay}
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        changeInitTheme
    }, dispatch)
};

export default compose(
    withResizeDetector,
    connect(mapStateToProps, mapDispatchToProps),
)(ConnectParentDomain);
