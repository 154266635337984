export const jp = {
  "Loading... Please Wait": "読み込み中。。。待って下さい",
  yourTransactionIsPendingForPayOut: "引き出しリクエストは保留中です",
  status1:
    "取引は処理銀行または決済サービスプロバイダーによって審査されていることに注意してください。 トランザクションのステータスの詳細については、サポートに連絡してトレースIDに関するサポートをリクエストしてください：{{trace_id}}",
  Status: "状態",
  "Deposit Review For Account:": "アカウントのデポジットレビュー：",
  "New Transaction": "新しいトランザクション",
  Update: "更新",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "選択したお支払い方法が別のページに表示されます。 [続行]をクリックして新しいブラウザウィンドウに移動し、支払いプロセスを続行してください。",
  dod_error_text: "続行するには、預金宣言を読んで同意する必要があります",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "入金を続行するには、利用規約に同意する必要があります",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "私はこれを読んだことを認め、これにより利用規約に同意します",
  dod_url: "#",
  dod_url_text: "規約と条件",
  dod_text: "私は読んだことを認め、これにより{{dod_url}}を受け入れます",
  transactionSuccessfullyCanceled:
    "トランザクション{{id}}は正常にキャンセルされました",
  "Fee should not exceed 20% of the amount":
    "料金は金額の20％を超えてはなりません",
  "My Info": "私の情報",
  "Billing info": "請求情報",
  "Withdrawal Requests": "引き出しリクエスト",
  "No Withdrawal Requests found": "引き出しリクエストが見つかりません",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "キャンセル",
  Amount: "量",
  "Last Update": "最後の更新",
  "Payment Method": "支払方法",
  TID: "時間",
  Action: "アクション",
  "Confirmation required": "確認が必要です",
  "Are you sure you want to cancel transaction":
    "取引をキャンセルしてもよろしいですか",
  Yes: "はい",
  No: "番号",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "最小-最大量",
  "Fee (included)": "料金（含まれています）",
  "- Amount between": "-間の金額",
  "I have the Promocode!": "私はプロモコードを持っています！",
  "Please enter the Promocode": "プロモーションコードを入力してください",
  Promocode: "プロモーションコード",
  noSavedTemplates: "カードは保存されません",
  noOptions: "オプションなし",
  "Please confirm your billing details": "お支払いの詳細をご確認ください",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "手数料",
  Fee: "手数料",
  Total: "合計",
  customer_support_link_text: "顧客サポート",
  terms_conditions_link_text: "規約と条件",
  "Edit Billing Info": "請求情報の編集",
  chooseAnAccount: "アカウントを選択してください",
  "Requested to Receive": "受け取りをリクエスト",
  "Receive With": "で受信",
  "Unknown session": "不明なセッション",
  transactionId: "トランザクションID",
  traceId: "トレースID",
  save: "セーブ",
  fillTheBillingInformation: "以下のお支払い情報を入力してください",
  correctionsRequired: "必要な修正",
  billingInfo: "お支払い情報",
  "Cancelled by client": "クライアントによりキャンセルされました",
  "Your Cards": "あなたのカード",
  Done: "できた",
  redirect: "リダイレクト",
  redirectingTo: "にリダイレクト",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "処理ができませんでした。恐れ入りますが、提供されたそのPayPal口座は当社の記録では別のお客様のトレーディング口座に表示されています。お客様は当社のサイトにおいて1つの有効なお客様のトレーディング口座に制限されています。このPayPal口座をご利用のログインIDで利用できるようにするためには、または、ご自分の口座について支援が必要な場合は、カスタマーサービスまでご連絡ください。",
  "Not supported yet": "まだサポートされていません",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "申し訳ありませんが、出金リクエストに使用するために登録されたお支払いの詳細がないようです",
  "Not available gateways found": "利用できないゲートウェイが見つかりました",
  "Token is invalid": "トークンが無効です",
  chooseAmount: "金額を選択",
  "Payment method not available. Please contact your site administrator.":
    "支払い方法は利用できません。 サイト管理者に連絡してください。",
  cardIsNotValid: "カードが無効です",
  invalidInputFormat: "入力形式が無効です。 少なくとも{{length}}文字。",
  invalidDateFormat: "無効な日付形式。",
  maximumValueIs: "最大値は",
  minimumValueIs: "最小値は",
  enterPayoutAmount: "利用可能残高以下の支払い額を入力してください。",
  maxLength2: "最大長は2文字です。",
  footer1:
    "一部の発行銀行は、オンラインエンターテイメントでのクレジットカードの使用を制限しています。上記の代替のサードパーティ支払い方法のいずれかを使用するか、{{customer_support_url}}担当者にフリーダイヤルで資金を提供してください。アカウント。\n注意：発行銀行によっては、クレジットカード取引がキャッシュアドバンスとして扱われる場合があります。{{application_key}}は、クレジットによって確立されるため、このトランザクションに関連するキャッシュアドバンス料金について責任を負いません。カードの発行銀行。詳細については、銀行にご相談ください",
  footer2:
    "試行された取引の支援または確認については、{{customer_support_url}}までご連絡ください。\n注意：銀行によっては、この取引はキャッシングとして扱われる場合があります。{{application_key}}は責任を負いませんこの取引に関連するキャッシング料金。取引は、取引の受領書に別段の記載がない限り、クレジットカードの明細書にクレジットカード記述子として識別されます",
  footer3:
    "すべての支払いリクエストは{{terms_conditions_url}}の対象となります。サポートや詳細情報が必要な場合は、{{customer_support_url}}までご連絡ください。リクエストが送信されると、情報は処理のためにキャッシャー部門に転送されます。 。支払いが処理されると、確認としてメールが届きます。支払い額と処理手数料（該当する場合）は、支払いの確認後にアカウントから引き落とされます",
  gatewaysTitle: "どのように支払いますか？",
  gatewaysTitleDeposit: "入金方法を選択してください",
  gatewaysTitleWithdrawal: "出金方法をお選びください",
  payInTitle: "クレジットカードまたはデビットカードで支払います",
  allPaymentMethods: "すべての支払い方法",
  submit: "参加する",
  useAddressOnFile: "ファイルのアドレスを使用",
  customerName: "顧客名",
  country: "国",
  province: "州",
  city: "市",
  postalCode: "郵便番号",
  street: "通り",
  proceed: "進む",
  cardNumber: "カード番号",
  cardHolder: "カードホルダー",
  cardHolderName: "クレジットカード名義人氏名",
  cardExpires: "カードの有効期限",
  cvv: "CVV",
  firstName: "ファーストネーム",
  lastName: "苗字",
  zipCode: "郵便番号",
  address: "住所",
  state: "状態",
  email: "Eメール",
  phone: "電話",
  dateOfBirth: "生年月日",
  transactionStatus: "取引状況",
  trace: "痕跡",
  amount: "量",
  min: "分",
  max: "最大",
  overrideLimits: "制限を上書き",
  transaction: "トランザクション",
  processed: "処理済み",
  transactionRejected: "拒否されたトランザクション",
  transactionApproved: "承認された取引",
  yourTransactionIsPending: "取引は保留中です",
  transactionAuthorized: "承認されたトランザクション",
  requestAccepted: "要求が受け入れられました",
  status2:
    "出金リクエストが受け入れられました. 営業日の営業時間内に追加の承認と処理が実行されることをご了承ください. その間、身元確認のためにエグゼクティブスタッフから連絡を受ける場合があります。",
  unexpectedErrorOccurred: "予期しないエラーが発生しました",
  status3:
    "申し訳ありませんが、トランザクションの処理中に問題が発生しました. 以下の技術詳細をご覧ください。",
  tryAgain: "再試行",
  somethingWentWrong: "何かがうまくいきませんでした...",
  newPayoutRequest: "新しい支払いリクエスト",
  makeAnotherPayment: "別のお支払いを行う",
  profile: "プロフィール",
  justOneMoreStep: "あと1ステップ...",
  "profile-auth":
    "を使用して支払いを続行するには、プロフィールを完成してください {{title}}",
  payWith: "で支払う",
  ProceedWith: "を進めます",
  vtTitle: "仮想端末：",
  pay: "支払う",
  deposit: "保証金",
  withdraw: "撤回",
  withdrawal: "撤退",
  hello: "こんにちは、",
  currency: "通貨",
  currentIp: "現在のIP：",
  UseCurrentIp: "現在のIPを使用：",
  UseCustomerIp: "顧客IPを使用：",
  copyright: "著作権",
  cannotBeBlank: "空白にすることはできません.",
  noDepositFree: "入金手数料なし。出金手数料なし。",
  next: "次",
  backToSite: "サイトに戻る",
  maximumLength: "最大長{{length}}文字",
  minimumLength: "最小の長さ{{length}}文字",
  emailIsNotValid: "メールは有効なメールアドレスではありません。",
  phoneIsNotValid: "電話が無効です",
  cardHolderInvalid:
    "カードホルダーが無効です。カードホルダーには姓名を含める必要があります。",
  pleaseEnterPayout: "以下の支払い金額を入力してください",
  invalidAmount: "無効な金額",
  transactionWillAppear: "取引は明細書に次のように表示されます",
  "Customer does not exist": "顧客は存在しません",
  "Sorry no available gateways found.":
    "利用可能なゲートウェイが見つかりません。",
  "Incorrect currency": "通貨が間違っている",
  "Sorry, not supported payment type": "サポートされていない支払いタイプです",
  "Payment method has no payment type":
    "お支払い方法に支払いタイプがありません",
  "Incorrect payment type in payment method":
    "お支払い方法の支払いタイプが正しくありません",
  "Gateway limit exceeded": "ゲートウェイ制限を超えました",
  "Not supported transaction type":
    "サポートされていないトランザクションタイプ",
  "Sorry. Payout with credit card gateway not supported yet":
    "ごめんなさい。 クレジットカードゲートウェイでの支払いはまだサポートされていません",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "このお支払い方法は、指定されたお支払いの詳細には適用されません。 ページをリロードして、別の金額、通貨、または支払い方法を試してください。",
  "Back to site": "サイトに戻る",
  "Your transaction has been requested. Please wait for approval.":
    "取引がリクエストされました。 承認をお待ちください。",
  "Token expired": "トークンが期限切れです",
  back: "バック",
  "Transaction not found.": "ゲート制限を超えました",
  "Continue in the New Window": "新しいウィンドウで続行",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "このウィンドウを閉じないでください。 新しいウィンドウでトランザクションが完了すると、リダイレクトされます。 安全な接続を開く新しいウィンドウが表示されませんか？ 私たちはあなたがあなたの取引を完了するのを手伝うためにここにいます。",
  "Click to continue": "続けるにはクリック",
  "Just One More Step...": "あと1ステップ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "選択したお支払い方法が新しいページで開きます。 最後のステップに進むには、下の[続行]ボタンをクリックしてください",
  Proceed: "続行します",
  "Opening a Secure Connection...": "安全な接続を開く...",
  "You will be redirected to complete your transaction in just a moment":
    "すぐにトランザクションを完了するようにリダイレクトされます",
  "Close window": "ウィンドウを閉じる",
  "Partial approval confirmation": "部分承認確認",
  PartiallyApprovedAmount:
    "カードの残高に、全額を入金するのに十分な資金がありません。取引は{{amount}}{{currency}}で部分的に承認されました。",
  ConfirmPartialDeposit:
    "「OK」をクリックして部分預金額を確認するか、「キャンセル」をクリックして取引をキャンセルしてください。何もしなければ、5分後にデポジットが自動的に確認されます。",
  fxRateSpreadText:
    "表示されている為替レートには、すべての手数料が含まれています。",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "読み込み中です。しばらくお待ちください",
};
