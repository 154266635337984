export const de = {
  "Loading... Please Wait": "Laden, bitte warten",
  yourTransactionIsPendingForPayOut:
    "Ihre Auszahlungsanforderung steht noch aus",
  status1:
    "Bitte beachten Sie, dass Ihre Transaktion von der verarbeitenden Bank oder dem Zahlungsdienstleister überprüft wird. Wenn Sie weitere Informationen zum Status Ihrer Transaktion wünschen, wenden Sie sich an den Support, um Unterstützung bei der Trace-ID anzufordern: {{trace_id}}",
  Status: "Status",
  "Deposit Review For Account:": "Einzahlungsüberprüfung für Konto:",
  "New Transaction": "Neue Transaktion",
  Update: "Aktualisieren",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Die von Ihnen ausgewählte Zahlungsmethode wird auf einer separaten Seite geöffnet. Klicken Sie auf 'VERFAHREN', um zu einem neuen Browserfenster zu navigieren und den Zahlungsvorgang fortzusetzen.",
  dod_error_text:
    "Sie müssen die Einzahlungserklärung lesen und akzeptieren, um fortfahren zu können",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Sie müssen die Allgemeinen Geschäftsbedingungen akzeptieren, um mit Ihrer Einzahlung fortfahren zu können",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Ich bestätige, dass ich die Allgemeinen Geschäftsbedingungen gelesen habe und akzeptiere sie hiermit",
  dod_url: "#",
  dod_url_text: "Geschäftsbedingungen",
  dod_text:
    "Ich bestätige, dass ich die Allgemeinen {{dod_url}} gelesen habe und akzeptiere sie hiermit",
  transactionSuccessfullyCanceled:
    "Die Transaktion {{id}} wurde erfolgreich abgebrochen",
  "Fee should not exceed 20% of the amount":
    "Die Gebühr sollte 20% des Betrags nicht überschreiten",
  "My Info": "Meine Information",
  "Billing info": "Rechnungsinformation",
  "Withdrawal Requests": "Auszahlungsanträge",
  "No Withdrawal Requests found": "Keine Auszahlungsanträge gefunden",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Stornieren",
  Amount: "Betrag",
  "Last Update": "Letztes Update",
  "Payment Method": "Zahlungsmethode",
  TID: "TID",
  Action: "Aktion",
  "Confirmation required": "Bestätigung erforderlich",
  "Are you sure you want to cancel transaction":
    "Sind Sie sicher, dass Sie die Transaktion abbrechen möchten",
  Yes: "Ja",
  No: "Nein",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Min-Max-Beträge",
  "Fee (included)": "Gebühr (inbegriffen)",
  "- Amount between": "- Betrag zwischen",
  "I have the Promocode!": "Ich habe den Promocode!",
  "Please enter the Promocode": "Bitte geben Sie den Promo-Code ein",
  Promocode: "Werbe-Code",
  noSavedTemplates: "Keine Karten gespeichert",
  noOptions: "Keine Optionen",
  "Please confirm your billing details":
    "Bitte bestätigen Sie Ihre Rechnungsdaten",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Bearbeitungsgebühr",
  Fee: "Gebühr",
  Total: "Gesamt",
  customer_support_link_text: "Kundendienst",
  terms_conditions_link_text: "Geschäftsbedingungen",
  "Edit Billing Info": "Abrechnungsinformationen bearbeiten",
  chooseAnAccount: "Wähle einen Account",
  "Requested to Receive": "Zum Empfang angefordert",
  "Receive With": "Erhalten",
  "Unknown session": "Unbekannte Sitzung",
  transactionId: "Transaktion ID",
  traceId: "Trace ID",
  save: "speichern",
  fillTheBillingInformation:
    "Bitte geben Sie die folgenden Zahlungsinformationen ein",
  correctionsRequired: "Korrekturen erforderlich",
  billingInfo: "Rechnungsinformation",
  "Cancelled by client": "Vom Kunden storniert",
  "Your Cards": "Deine Karten",
  Done: "Erledigt",
  redirect: "umleiten",
  redirectingTo: "Weiterleiten an",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Die Transaktion konnte nicht erfolgreich abgewickelt werden. Es tut uns leid, aber das von Ihnen angegebene PayPal-Konto erscheint in unseren Unterlagen unter dem Handelskonto eines anderen Kunden. Kunden können lediglich ein aktives Tradingkonto auf unserer Webseite unterhalten. Damit dieses PayPal-Konto mit Ihren Anmeldedaten verwendet werden kann bzw. falls Sie Unterstützung bei Ihrem Konto benötigen, setzen Sie sich bitte mit dem Kundenservice in Kontakt.",
  "Not supported yet": "Noch nicht unterstützt",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Entschuldigung, es scheint, dass Sie keine Zahlungsdetails registriert haben, die Sie für eine Auszahlungsanforderung verwenden könnten",
  "Not available gateways found": "Nicht verfügbare Gateways gefunden",
  "Token is invalid": "Token ist ungültig",
  "Payment method not available. Please contact your site administrator.":
    "Zahlungsmethode nicht verfügbar. Bitte wenden Sie sich an Ihren Site-Administrator.",
  cardIsNotValid: "Karte ist nicht gültig",
  chooseAmount: "Wählen Sie die Anzahl",
  invalidInputFormat:
    "Ungültiges Eingabeformat. Mindestens {{length}} Zeichen.",
  invalidDateFormat: "Ungültiges Datumsformat.",
  maximumValueIs: "Maximalwert ist",
  minimumValueIs: "Mindestwert ist",
  enterPayoutAmount:
    "Bitte geben Sie einen Auszahlungsbetrag ein, der kleiner oder gleich Ihrem verfügbaren Guthaben ist.",
  maxLength2: "Maximale Länge 2 Zeichen",
  footer1:
    "Einige ausstellende Banken haben die Verwendung von Kreditkarten für Online-Unterhaltung eingeschränkt. Sie können auch eine der oben genannten alternativen Zahlungsmethoden von Drittanbietern verwenden oder sich an unsere {{customer_support_url}} Vertreter wenden, die gebührenfrei bei N / A zahlen Ihr Konto. \n Bitte beachten Sie: Abhängig von Ihrer ausstellenden Bank können Kreditkartentransaktionen als Vorauszahlung behandelt werden. {{application_key}} kann nicht für Vorauszahlungen verantwortlich gemacht werden, die mit dieser Transaktion verbunden sind, da dies durch die Gutschrift festgelegt wird Ausstellende Bank der Karte. Wenden Sie sich an Ihre Bank, um weitere Informationen zu erhalten. ",
  footer2:
    "Für Unterstützung oder Bestätigung einer versuchten Transaktion wenden Sie sich bitte an {{customer_support_url}}. \n Hinweis: Abhängig von Ihrer Bank kann diese Transaktion als Vorauszahlung behandelt werden. {{application_key}} ist nicht verantwortlich für Alle mit dieser Transaktion verbundenen Vorauszahlungsgebühren. Die Transaktion wird auf Ihrer Kreditkartenabrechnung als Kreditkartenbeschreibung angegeben, sofern auf Ihrem Transaktionsbeleg nichts anderes angegeben ist. ",
  footer3:
    "Alle Auszahlungsanfragen unterliegen unserem {{terms_conditions_url}}. Wenn Sie Hilfe oder weitere Informationen benötigen, wenden Sie sich bitte an {{customer_support_url}}. Sobald Ihre Anfrage gesendet wurde, werden die Informationen zur Bearbeitung an unsere Kasse weitergeleitet Sie erhalten eine E-Mail als Bestätigung, wenn Ihre Auszahlung verarbeitet wird. Der Auszahlungsbetrag und die Bearbeitungsgebühr (falls zutreffend) werden nach unserer Auszahlungsprüfung von Ihrem Konto abgebucht. ",
  gatewaysTitle: "Wie möchten Sie bezahlen?",
  gatewaysTitleDeposit: "Bitte wählen Sie die Einzahlungsmethode",
  gatewaysTitleWithdrawal: "Bitte wählen Sie die Auszahlungsmethode",
  payInTitle: "Einzahlen mit Kredit & EC-Karte",
  allPaymentMethods: "alle zahlungsmethoden",
  submit: "Einreichen",
  useAddressOnFile: "Adresse in Datei verwenden",
  customerName: "Kundenname",
  country: "Land",
  province: "Provinz",
  city: "Stadt",
  postalCode: "Postleitzahl",
  street: "Straße",
  proceed: "Vorgehen",
  cardNumber: "Kartennummer",
  cardHolder: "Kartenhalter",
  cardHolderName: "Name des Karteninhabers",
  cardExpires: "Ablaufdatum",
  cvv: "CVV",
  firstName: "Vorname",
  lastName: "Nachname",
  zipCode: "Postleitzahl",
  address: "Adresse",
  state: "Zustand",
  email: "Email",
  phone: "Telefon",
  dateOfBirth: "Geburtsdatum",
  transactionStatus: "Transaktionsstatus",
  trace: "Spur",
  amount: "betrag",
  min: "min",
  max: "max",
  overrideLimits: "Grenzen überschreiben",
  transaction: "Transaktion",
  processed: "Verarbeitet",
  transactionRejected: "Transaktion abgelehnt",
  transactionApproved: "Transaktion genehmigt",
  yourTransactionIsPending: "Ihre Transaktion steht noch aus",
  transactionAuthorized: "Transaktion genehmigt",
  requestAccepted: "Anfrage akzeptiert",
  status2:
    "Ihre Auszahlungsanforderung wurde akzeptiert. Bitte haben Sie Verständnis dafür, dass die weitere Autorisierung und Bearbeitung während der Geschäftszeiten der Geschäftstage durchgeführt wird. Während dieser Zeit werden Sie möglicherweise von Führungskräften zur Bestätigung der Identität kontaktiert.",
  unexpectedErrorOccurred: "Ein unerwarteter Fehler ist aufgetreten",
  status3:
    "Wir entschuldigen uns, bei der Bearbeitung Ihrer Transaktion ist ein Fehler aufgetreten. Nachfolgend finden Sie die technischen Details:",
  tryAgain: "Versuch es noch einmal",
  somethingWentWrong: "Etwas ist schief gelaufen...",
  newPayoutRequest: "Neue Auszahlungsanforderung",
  makeAnotherPayment: "Erneut bezahlen",
  profile: "Profil",
  justOneMoreStep: "Nur noch ein Schritt ...",
  "profile-auth":
    "Bitte vervollständigen Sie Ihr Profil, um mit der Zahlung über fortzufahren {{title}}",
  payWith: "Bezahlen mit",
  ProceedWith: "Weitermachen mit",
  vtTitle: "Virtuelles Terminal:",
  pay: "Zahlen",
  deposit: "Einzahlung",
  withdraw: "Abheben",
  withdrawal: "Rückzug",
  hello: "Hallo,",
  currency: "Währung",
  currentIp: "Aktuelle IP IP:",
  UseCurrentIp: "Aktuelle IP verwenden",
  UseCustomerIp: "Verwenden Sie die Kunden-IP:",
  copyright: "Urheberrechte",
  cannotBeBlank: "Darf nicht leer sein.",
  noDepositFree: "Keine Einzahlungsgebühr. Keine Abhebungsgebühr.",
  next: "Nächster",
  backToSite: "zurück zur Seite",
  maximumLength: "Maximale Länge {{length}} Zeichen",
  minimumLength: "Mindestlänge {{length}} Zeichen",
  emailIsNotValid: "E-Mail ist keine gültige E-Mail-Adresse.",
  phoneIsNotValid: "Telefon ist ungültig",
  cardHolderInvalid:
    "Karteninhaber ist ungültig. Karteninhaber sollte den Vor- und Nachnamen enthalten.",
  PleaseEnterPayout:
    "Bitte geben Sie einen Auszahlungsbetrag ein, der kleiner oder gleich ist",
  invalidAmount: "Ungültiger Betrag",
  transactionWillAppear:
    "Ihre Transaktion wird auf Ihrer Abrechnung als angezeigt",
  "Customer does not exist": "Kunde existiert nicht",
  "Sorry no available gateways found.":
    "Leider wurden keine verfügbaren Gateways gefunden.",
  "Incorrect currency": "Falsche Währung",
  "Sorry, not supported payment type":
    "Entschuldigung, nicht unterstützte Zahlungsart",
  "Payment method has no payment type": "Zahlungsmethode hat keine Zahlungsart",
  "Incorrect payment type in payment method":
    "Falsche Zahlungsart in Zahlungsmethode",
  "Gateway limit exceeded": "Gateway-Limit überschritten",
  "Not supported transaction type": "Nicht unterstützter Transaktionstyp",
  "Sorry. Payout with credit card gateway not supported yet":
    "Entschuldigung. Auszahlung mit Kreditkarten-Gateway wird noch nicht unterstützt",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Wir entschuldigen uns, diese Zahlungsmethode gilt nicht für die angegebenen Zahlungsdetails. Bitte laden Sie die Seite neu und probieren Sie einen anderen Betrag, eine andere Währung oder eine andere Zahlungsmethode aus.",
  "Back to site": "Zurück zur Seite",
  "Your transaction has been requested. Please wait for approval.":
    "Ihre Transaktion wurde angefordert. Bitte warten Sie auf die Genehmigung.",
  "Token expired": "Token abgelaufen",
  back: "zurück",
  "Transaction not found.": "Transaktion nicht gefunden.",
  "Continue in the New Window": "Fahren Sie im neuen Fenster fort",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Bitte schließen Sie dieses Fenster nicht. Sie werden nach Abschluss der Transaktion in einem neuen Fenster zurückgeleitet. Sie sehen kein neues Fenster, das eine sichere Verbindung öffnet? Wir sind hier, um Ihnen zu helfen, Ihre Transaktion abzuschließen.",
  "Click to continue": "Klicke um Fortzufahren",
  "Just One More Step...": "Nur noch ein Schritt ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Die von Ihnen ausgewählte Zahlungsmethode wird auf einer neuen Seite geöffnet. Bitte klicken Sie unten auf die Schaltfläche 'VERFAHREN', um zum letzten Schritt zu navigieren.",
  Proceed: "Vorgehen",
  "Opening a Secure Connection...": "Öffnen einer sicheren Verbindung ...",
  "You will be redirected to complete your transaction in just a moment":
    "Sie werden weitergeleitet, um Ihre Transaktion in einem Moment abzuschließen",
  "Close window": "Fenster schließen",
  "Partial approval confirmation": "Teilgenehmigungsbestätigung",
  PartiallyApprovedAmount:
    "Ihr Kartenguthaben reicht nicht aus, um den vollen Betrag gutzuschreiben. Die Transaktion wurde teilweise für {{amount}} {{currency}} genehmigt.",
  ConfirmPartialDeposit:
    "Bitte klicken Sie auf „OK“, um den Teilbetrag der Einzahlung zu bestätigen oder auf „ABBRECHEN“, um die Transaktion abzubrechen. Die Einzahlung wird nach 5 Minuten automatisch bestätigt, wenn nichts unternommen wird.",
  fxRateSpreadText:
    "Der angegebene Wechselkurs beinhaltet alle Gebühren und Abgaben.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Laden, bitte warten",
};
