import React from 'react';
import { PraxisI18NConsumer } from '../../../context-components/context/internalization-context/internalization-context'

export const withTemplate = (Wrapped) => {
    return (props) => {
        return(
            <PraxisI18NConsumer>
                {
                    (praxisInternalization) => {
                        const template = praxisInternalization.getTemplate();
                        return (
                            <Wrapped
                                {...props}
                                theme={template.theme}
                                template={template.components}
                                templateRouter = {template.router} />
                    )
                    }
                }
            </PraxisI18NConsumer>
        )
    }
};