import React, {useCallback, useEffect, useRef, useState} from 'react'
import './input-wrapper.sass'
import {withTemplate} from "../../hoc-components/hoc-context/with-template";
import {bindActionCreators, compose} from "redux";
import {connect, shallowEqual, useSelector} from "react-redux";
import {withAdr} from "../../hoc-components/with-adr";
import {
    cashierValidationFailedEvent,
    cashierValidationSuccessEvent
} from "../../../utils/custom-cashier-events/cashier-validation-event";
import {debounce} from "../../../utils/helper-functions/debounce";

const InputWrapper = (props) => {
    
    let {
        children: input, error, title, icon = false, initShowError = false,
        className = "", template, name, focus, show_all_errors, option_id, option_type, s
    } = props;
    
    const payData = useSelector((state) => state.payData, shallowEqual);
    const [changed, setChanged] = useState(initShowError);
    const ref = useRef();
    const debounceCashierValidationEvent = useCallback(debounce((name, inputValue, error) => {
            if (error) {
                cashierValidationFailedEvent({field: name, value: inputValue, message: error})
            } else {
                cashierValidationSuccessEvent({
                    field: name, value: inputValue, message: 'Validation Success'
                })
            }
        }
        , 700), []);
    
    useEffect(() => {
        if (focus === name && !changed) setChanged(true);
    }, [focus]);
    
    useEffect(() => {
        if (!changed) return;
        debounceCashierValidationEvent(name, payData[name], error)
    }, [error, changed, payData[name]])
    
    useEffect(() => {
        setChanged(false)
    }, [option_id, option_type]);
    
    if (typeof error === "array") error = String(error);
    
    if (!input) console.error("InputWrapper must have an input as children props");
    
    let hasError = (changed || show_all_errors || initShowError) && error;
    if (focus === name) hasError = false;
    
    if (template.error === "top") {
        return (
            <div ref={ref}
                 className={`${s('input-item')} ${className} ${focus === name ? s('focus') : ""} ${icon ? s('has-icon') : ""} ${hasError ? s('has-error') : ""}`}>
                <div className={`${s('title')}`}>
                    {hasError ? error : title}
                </div>
                <div className={`${s('input-wrap')}`}>
                    {input}
                    {icon ? <div className={`${s('input-icon')}`}>{icon}</div> : ""}
                </div>
            </div>
        )
    }
    
    return (
        <div ref={ref}
             className={`${s('input-item')} ${className} ${focus === name ? s('focus') : ""} ${hasError ? s('has-error') : ""}`}>
            <div className={`${s('title')} ${title ? '' : s('title-none')}`}>
                {title}
            </div>
            <div className={`${s('input-wrap')}`}>
                {input}
                {icon ? <div className={`${s('input-icon')}`}>{icon}</div> : ""}
            </div>
            
            <div className={`${s('error')} ${hasError ? s('show') : s('hide')}`}>{error}</div>
        </div>
    )
};

const mapStateToProps = ({errors: {show_all_errors}, currentGateway: {option_id, option_type}, focus}) => {
    return {show_all_errors, option_id, option_type, focus}
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({}, dispatch)
};

export default compose(
    withTemplate, withAdr, connect(mapStateToProps, mapDispatchToProps),
)(InputWrapper);


