export const pl = {
  "Loading... Please Wait": "Ładowanie proszę czekać",
  yourTransactionIsPendingForPayOut: "Twoja prośba o wypłatę oczekuje",
  status1:
    "Należy pamiętać, że transakcja jest sprawdzana przez bank przetwarzający lub dostawcę usług płatniczych. Jeśli chcesz uzyskać więcej informacji o statusie transakcji, skontaktuj się z pomocą techniczną, aby poprosić o pomoc w sprawie identyfikatora śledzenia: {{trace_id}}",
  Status: "Status",
  "Deposit Review For Account:": "Przegląd depozytu dla konta:",
  "New Transaction": "Nowa transakcja",
  Update: "Aktualizacja",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Wybrana metoda płatności zostanie otwarta na osobnej stronie. Kliknij 'DALEJ', aby przejść do nowego okna przeglądarki i kontynuować proces płatności.",
  dod_error_text:
    "Aby kontynuować, musisz przeczytać i zaakceptować Deklarację wpłaty",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Musisz zaakceptować Warunki, aby dokonać wpłaty",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Potwierdzam, że przeczytałem i akceptuję Regulamin",
  dod_url: "#",
  dod_url_text: "Regulamin",
  dod_text: "Potwierdzam, że przeczytałem i akceptuję {{dod_url}}",
  transactionSuccessfullyCanceled:
    "Transakcja {{id}} została pomyślnie anulowana",
  "Fee should not exceed 20% of the amount":
    "Opłata nie powinna przekraczać 20% kwoty",
  "My Info": "Moje informacje",
  "Billing info": "Informacja rachunkowa",
  "Withdrawal Requests": "Wnioski o wypłatę",
  "No Withdrawal Requests found": "Nie znaleziono żądań wypłaty",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "anulować",
  Amount: "Ilość",
  "Last Update": "Ostatnia aktualizacja",
  "Payment Method": "Metoda płatności",
  TID: "CZAS",
  Action: "Akcja",
  "Confirmation required": "Wymagane Potwierdzenie",
  "Are you sure you want to cancel transaction":
    "Czy na pewno chcesz anulować transakcję",
  Yes: "tak",
  No: "Nie",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Kwoty min-maks",
  "Fee (included)": "Opłata (wliczona)",
  "- Amount between": "- Kwota między",
  "I have the Promocode!": "Mam Promocode!",
  "Please enter the Promocode": "Wprowadź kod promocyjny",
  Promocode: "Kod promocyjny",
  noSavedTemplates: "Brak zapisanych kart",
  noOptions: "Brak opcji",
  "Please confirm your billing details": "Potwierdź swoje dane rozliczeniowe",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Opłata manipulacyjna",
  Fee: "Opłata",
  Total: "Całkowity",
  customer_support_link_text: "Obsługa klienta",
  terms_conditions_link_text: "Regulamin",
  "Edit Billing Info": "Edytuj informacje rozliczeniowe",
  chooseAnAccount: "Wybierz konto",
  "Requested to Receive": "Wezwany do odbioru",
  "Receive With": "Odbierz z",
  "Unknown session": "Nieznana sesja",
  transactionId: "ID transakcji",
  traceId: "ID śledzenia",
  save: "Zapisać",
  fillTheBillingInformation: "Podaj informacje rozliczeniowe poniżej",
  correctionsRequired: "wymagane poprawki",
  billingInfo: "Informacja rachunkowa",
  "Cancelled by client": "Anulowane przez klienta",
  "Your Cards": "Twoje karty",
  Done: "Gotowy",
  redirect: "Przeadresować",
  redirectingTo: "Przekierowuję do",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Transakcja nie powiodła się. Przykro nam, ale wskazany rachunek PayPal widnieje w naszym rejestrze jako powiązany z rachunkiem transakcyjnym innego klienta. Klienci mogą posiadać tylko jeden aktywny rachunek transakcyjny na naszej stronie. Jeśli chcesz umożliwić wykorzystywanie tego rachunku PayPal wraz z Twoim loginem lub jeśli potrzebujesz pomocy w związku z Twoim rachunkiem, skontaktuj się z działem obsługi klienta.",
  "Not supported yet": "Jeszcze nie obsługiwane",
  "Not available gateways found": "Nie znaleziono bram",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Przepraszamy, wygląda na to, że nie masz zarejestrowanych żadnych szczegółów płatności, które można by wykorzystać do żądania wypłaty",
  "Token is invalid": "Token jest nieprawidłowy",
  "Payment method not available. Please contact your site administrator.":
    "Metoda płatności niedostępna. Skontaktuj się z administratorem witryny.",
  cardIsNotValid: "Karta nie jest ważna",
  invalidInputFormat:
    "Niepoprawny format wejściowy. Co najmniej {{length}} znaki.",
  invalidDateFormat: "Zły format daty.",
  chooseAmount: "Wybierz ilość",
  maximumValueIs: "Maksymalna wartość to",
  minimumValueIs: "Minimalna wartość to",
  enterPayoutAmount:
    "Wprowadź kwotę wypłaty mniejszą lub równą dostępnemu saldowi.",
  maxLength2: "Maksymalna długość 2 znaki.",
  footer1:
    "Niektóre banki wydające ograniczyły używanie kart kredytowych do rozrywki online. Skorzystaj z jednej z powyższych alternatywnych metod płatności innych firm lub skontaktuj się z naszymi {{customer_support_url}} pod bezpłatnym numerem telefonu N / A, aby sfinansować Twoje konto. \n Uwaga: w zależności od banku, który wydał, transakcje kartą kredytową mogą być traktowane jako zaliczka gotówkowa. {{application_key}} nie może być pociągnięty do odpowiedzialności za jakiekolwiek opłaty z tytułu zaliczek gotówkowych związane z tą transakcją, ponieważ jest to ustalone na podstawie kredytu bank, który wydał kartę. Aby uzyskać więcej informacji, skontaktuj się ze swoim bankiem. ",
  footer2:
    "Aby uzyskać pomoc lub potwierdzenie próby transakcji, skontaktuj się z {{customer_support_url}}. \n Uwaga: w zależności od banku ta transakcja może zostać potraktowana jako zaliczka gotówkowa. {{application_key}} nie będzie odpowiedzialny za wszelkie opłaty z tytułu zaliczki gotówkowej związane z tą transakcją. Transakcja zostanie określona na wyciągu z karty kredytowej jako Deskryptor karty kredytowej, chyba że na potwierdzeniu transakcji określono inaczej. ",
  footer3:
    "Wszystkie żądania wypłaty podlegają naszym {{terms_conditions_url}}. Jeśli potrzebujesz pomocy lub dodatkowych informacji, skontaktuj się z {{customer_support_url}}. Po przesłaniu żądania informacje są przekazywane do naszego działu kasjerów w celu przetworzenia . Otrzymasz wiadomość e-mail z potwierdzeniem, kiedy Twoja wypłata zostanie przetworzona. Kwota wypłaty i opłata manipulacyjna (jeśli dotyczy) zostaną pobrane z Twojego konta po sprawdzeniu wypłaty. ",
  gatewaysTitle: "Jak chciałbyś zapłacić?",
  gatewaysTitleDeposit: "Wybierz metodę wpłaty",
  gatewaysTitleWithdrawal: "Wybierz metodę wypłaty",
  payInTitle: "Zapłać kartą kredytową lub debetową",
  allPaymentMethods: "wszystkie metody płatności",
  submit: "Zatwierdź",
  useAddressOnFile: "Użyj adresu w pliku",
  customerName: "Nazwa klienta",
  country: "Kraj",
  province: "Województwo",
  city: "Miasto",
  postalCode: "Kod pocztowy",
  street: "Ulica",
  proceed: "Kontynuować",
  cardNumber: "Numer karty",
  cardHolder: "Posiadacz karty",
  cardHolderName: "Imię właściciela karty",
  cardExpires: "Karta wygasa",
  cvv: "CVV",
  firstName: "Imię",
  lastName: "Nazwisko",
  zipCode: "Kod pocztowy",
  address: "Adres",
  state: "Stan",
  email: "E-mail",
  phone: "Telefon",
  dateOfBirth: "Data urodzenia",
  transactionStatus: "Status transakcji",
  trace: "Ślad",
  amount: "Ilość",
  min: "min",
  max: "max",
  overrideLimits: "Zastąp limity",
  transaction: "Transakcja",
  processed: "Obrobiony",
  transactionRejected: "Transakcja odrzucona",
  transactionApproved: "Transakcja zatwierdzona",
  yourTransactionIsPending: "Twoja transakcja jest w toku",
  transactionAuthorized: "Transakcja autoryzowana",
  requestAccepted: "Prośba zaakceptowana",
  status2:
    "Twoja prośba o wycofanie została zaakceptowana. Informujemy, że dalsza autoryzacja i przetwarzanie zostanie wykonane w godzinach operacyjnych dni roboczych. W tym czasie personel wykonawczy może skontaktować się z Tobą w celu potwierdzenia tożsamości.",
  unexpectedErrorOccurred: "Pojawił się nieoczekiwany błąd",
  status3:
    "Przepraszamy, wystąpił błąd podczas przetwarzania transakcji. Szczegóły techniczne poniżej: ",
  tryAgain: "Spróbuj ponownie",
  somethingWentWrong: "Coś poszło nie tak...",
  newPayoutRequest: "Nowa prośba o zapłatę",
  makeAnotherPayment: "Dokonaj kolejnej płatności",
  profile: "Profil",
  justOneMoreStep: "Jeszcze tylko jeden krok...",
  "profile-auth":
    "Uzupełnij swój profil, aby kontynuować płatność za pomocą {{title}}",
  payWith: "Zapłacić",
  ProceedWith: "Kontynuować",
  vtTitle: "Terminal wirtualny: ",
  pay: "Zapłacić",
  deposit: "Kaucja",
  withdraw: "Wycofać",
  withdrawal: "Wycofanie",
  hello: "Witaj,",
  currency: "Waluta",
  currentIp: "Aktualny adres IP: ",
  UseCurrentIp: "Użyj aktualnego adresu IP: ",
  UseCustomerIp: "Użyj adresu IP klienta: ",
  copyright: "prawa autorskie",
  cannotBeBlank: "Nie może być puste.",
  noDepositFree: "Brak opłaty depozytowej. brak opłaty za wypłatę.",
  next: "Kolejny",
  backToSite: "Powrót do strony",
  maximumLength: "Maksymalna długość {{length}} znaków",
  minimumLength: "Minimalna długość {{length}} znaków",
  emailIsNotValid: "Adres e-mail nie jest prawidłowym adresem e-mail.",
  phoneIsNotValid: "Telefon jest nieprawidłowy",
  cardHolderInvalid:
    "Posiadacz karty jest nieprawidłowy. Posiadacz karty powinien zawierać imię i nazwisko.",
  pleaseEnterPayout: "Wprowadź kwotę wypłaty mniejszą lub równą",
  invalidAmount: "nieprawidłowa kwota",
  transactionWillAppear: "Twoja transakcja pojawi się na wyciągu jako",
  "Customer does not exist": "Klient nie istnieje",
  "Sorry no available gateways found.":
    "Niestety nie znaleziono dostępnych bram.",
  "Incorrect currency": "Niepoprawna waluta",
  "Sorry, not supported payment type":
    "Przepraszamy, nieobsługiwany typ płatności",
  "Payment method has no payment type":
    "Metoda płatności nie ma typu płatności",
  "Incorrect payment type in payment method":
    "Niepoprawny typ płatności w metodzie płatności",
  "Gateway limit exceeded": "Przekroczono limit bramy",
  "Not supported transaction type": "Nieobsługiwany typ transakcji",
  "Sorry. Payout with credit card gateway not supported yet":
    "Przepraszam. Wypłata z bramą karty kredytowej nie jest jeszcze obsługiwana",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Przepraszamy, ta metoda płatności nie ma zastosowania do określonych szczegółów płatności. Załaduj stronę ponownie i wypróbuj inną kwotę, walutę lub metodę płatności.",
  "Back to site": "Powrót do strony",
  "Your transaction has been requested. Please wait for approval.":
    "Twoja transakcja została zamówiona. Poczekaj na zatwierdzenie.",
  "Token expired": "Token wygasł",
  back: "plecy",
  "Transaction not found.": "Nie znaleziono transakcji.",
  "Continue in the New Window": "Kontynuuj w nowym oknie",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Proszę nie zamykać tego okna. Zostaniesz przekierowany z powrotem po zakończeniu transakcji w nowym oknie. Nie widzisz nowego okna otwierającego bezpieczne połączenie? Jesteśmy tutaj, aby pomóc Ci sfinalizować transakcję.",
  "Click to continue": "Kliknij, aby kontynuować",
  "Just One More Step...": "Jeszcze tylko jeden krok...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Wybrana metoda płatności zostanie otwarta na nowej stronie. Kliknij przycisk 'DALEJ' poniżej, aby przejść do ostatniego kroku.",
  Proceed: "Kontynuować",
  "Opening a Secure Connection...": "Otwieranie bezpiecznego połączenia ...",
  "You will be redirected to complete your transaction in just a moment":
    "Za chwilę zostaniesz przekierowany do sfinalizowania transakcji",
  "Close window": "Zamknij okno",
  "Partial approval confirmation": "Częściowe potwierdzenie zatwierdzenia",
  PartiallyApprovedAmount:
    "Saldo Twojej karty nie ma wystarczających środków, aby zasilić pełną kwotę. Transakcja została częściowo zatwierdzona na {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Proszę kliknąć „OK”, aby potwierdzić wpłatę częściową lub „ANULUJ”, aby anulować transakcję. Wpłata zostanie potwierdzona automatycznie po 5 minutach, jeśli nie zostaną podjęte żadne działania.",
  fxRateSpreadText: "Podany kurs wymiany zawiera wszystkie opłaty i prowizje.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Trwa ładowanie, proszę czekać",
};
