import React, {Component} from 'react';
import PanoErrorPage from "@pano-project/pages/error-page/error-page";
import {withPraxisService} from "../hoc-components";
import compose from "../../utils/helper-functions/compose";
import {connect} from "react-redux";
import ErrorPageContainer from "../container-components/error-page-container/error-page-container";
import {isPanoramicTheme} from "../../@pano-project/variables/is-panoramic-2-0-theme";

class ErrorBoundry extends Component {

    state = {
        hasError: false
    };

    componentDidCatch(error, errorInfo) {
        console.error(error);
        this.setState({hasError: true});
        const {praxisService:{postErrors}} = this.props;
        error = JSON.stringify(error, Object.getOwnPropertyNames(error));
        postErrors({error});
    }

    render() {
        if(this.state.hasError) {
            return (
                <ErrorPageContainer>
                    {isPanoramicTheme && <PanoErrorPage wrapper={true}/>}
                </ErrorPageContainer>
            )
        }
        return this.props.children;
    }
}

const mapStateToProps = (store) => {
    return {store}
};

const mapDispatchToProps = () => {
    return {}
};

export default compose(
    withPraxisService(), connect(mapStateToProps, mapDispatchToProps)
)(ErrorBoundry);