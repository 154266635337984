import React from 'react'
import "./confirm-window.sass"
import compose from "../../../utils/helper-functions/compose";
import {withI18N} from "../../hoc-components";
import Button from "../button/button";
import {withAdr} from "../../hoc-components/with-adr";

const ConfirmWindow = (props) => {
    const {confirm, reject, text="", rejectButton="No", confirmButton="Yes", langBack, s} = props;

    return(
        <div className={`${s('confirm-container')}`}>
            <div className={`${s('confirm-window')}`}>
                <div className={`${s('confirmation-required')}`}>{langBack('Confirmation required')}</div>
                <div className={`${s('confirm-title')}`}>{text}</div>
                <div className={`${s('confirm-btn-wrapper')}`}>
                    <Button
                        className={`${s('button')}`}
                        onClick={confirm}
                        content={langBack(confirmButton)}/>
                    <Button
                        className={`${s('button')}`}
                        onClick={reject}
                        content={langBack(rejectButton)}/>
                </div>
            </div>
        </div>
    )
};

export default compose(
    withI18N, withAdr
)(ConfirmWindow);
