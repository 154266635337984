const limitError = (state, action) => {
  if (state === undefined) {
    return false;
  }

  switch (action.type) {
    case "SET_LIMIT_ERROR":
      return action.payload;
    default:
      return state.limitError;
  }
};

export { limitError };
