import {payList} from "../../variables/pay-list";

const checkNames = (gatewaysList) => {
    if(!gatewaysList.encrypted_input_names){
        return {...gatewaysList, encrypted_input_names: payList}
    }
    return gatewaysList;
}

const updateGateways = (state, action) => {
    
    if (state === undefined) {
        return false
    }
    switch (action.type) {
        case 'UPDATE_GATEWAYS':
            return checkNames(action.payload)
        default:
            return state.gatewaysList;
    }
};

export {updateGateways}
