import React from 'react';
import {compose} from "redux";
import {connect} from "react-redux";
import store from "../../state/store"


export const dec = (num, names) => {
    if(!names) names = store.getState().gatewaysList.encrypted_input_names ?? [];
    return names[num]
}

export const withDecodePayTitle = (Wrapped) => {
    const Hoc = (props) => {
        const {names=[], payData, ...other} = props;
    
        return (
            <Wrapped
                {...other}
                res0={payData[names[0]]}
                res1={payData[names[1]]}
                res2={payData[names[2]]}
                res3={payData[names[3]]}
                res4={payData[names[4]]}
                res5={payData[names[5]]}
                dec={(num) => names[num]}/>
        )
    };

    const mapStateToProps = ({gatewaysList:{encrypted_input_names:names}, payData}) => {
        return {names, payData}
    };


    return compose(
        connect(mapStateToProps),
    )(Hoc);

};
