import {createValidationPayError} from "./pay-data-actions";
import {
    createFatalError,
    createValidationProfileError,
} from "./errors-actions";
import {transformSyntheticEvent} from "../../utils/helper-functions/transform-syntetic-event";
import PraxisService from "../../context-components/service";
import {triggerAdvanceCashierEvent} from "../../utils/custom-cashier-events/trigger-advance-cashier-event";
import {
    sanitizeNonLatinCharacters
} from "../../utils/helper-functions/sanitize-non-latin-characters";

const service = new PraxisService();
const {
    postPayGateway,
    getUser,
    saveData,
    getGateways,
    getGatewayDetails,
    partialApprovedResult,
    manageRetryRequest,
    postErrors,
    getAvailableGateways
} = service;

export const tokenInit = (payload) => {
    return {
        type: "TOKEN_INIT",
        payload,
    };
};

export const createVirtualSettings = (payload) => {
    return {
        type: "VIRTUAL_SETTINGS",
        payload,
    };
};

export const changeInitTheme = (payload) => {
    return {
        type: "INIT_THEME",
        payload,
    };
};

export const updateGateways = (payload) => {
    return {
        type: "UPDATE_GATEWAYS",
        payload,
    };
};

export const updateProcessingGateways = (payload) => {
    return {
        type: "UPDATE_PROCESSING_GATEWAYS",
        payload,
    };
};

export const setPendingTransactions = (payload) => {
    return {
        type: "SET_PENDING_TRANSACTIONS",
        payload,
    };
};

export const createResultOfPay = (payload) => {
    return {
        type: "CREATE_RESULT_OF_PAY",
        payload,
    };
};

export const changeProfile = (payload) => {
    //Sanitize the customer fields based in the restrict_non_latin_characters flag
    payload = sanitizeNonLatinCharacters(payload, ['customer']);
    return {
        type: "UPDATE_PROFILE",
        payload,
    };
};

export const createMainFocus = (payload) => {
    payload = transformSyntheticEvent(payload);
    return {
        type: "MAIN_FOCUS",
        payload,
    };
};

export const updateGatewayDetails = (payload) => {
    //Sanitize the gateway templates based in the restrict_non_latin_characters flag
    payload = sanitizeNonLatinCharacters(payload,  ['templates']);
    return {
        type: "CREATE_CURRENT_GATEWAY",
        payload,
    };
};

export const updateGatewayInfoFromAmountPage = (payload) => {
    return {
        type: 'CREATE_CURRENT_GATEWAY_FROM_AMOUNT_PAGE',
        payload
    }
}

export const createCurrentGateway = (data) => (dispatch) => {
    return new Promise((resolve) => {
        const card_type_name = data.card_type_name ?? null
        getGatewayDetails(data)
            .then(({data}) => {
                if (data.status === 0) { dispatch(updateGatewayDetails({
                    ...data,
                    card_type_name
                }))
                    dispatch(setLimitSubmit(false));
                }
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === -1) dispatch(createFatalError(data.error_details));
                resolve(data);
            })
            .catch(({response = {}}) => {
                const {data = {}} = response;
                if (data.status === 0) {
                    dispatch(updateGatewayDetails(data))
                    dispatch(setLimitSubmit(false));
                }
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === -1) dispatch(createFatalError(data.error_details));
                if (!data.status) dispatch(createFatalError());
                resolve(data);
            });
    });
};

export const saveLocation = (payload) => {
    return {
        type: "SAVE-LOCATION",
        payload,
    };
};

export const changeBaseAmount = (payload) => {
    return {
        type: "CHANGE_BASE_AMOUNT",
        payload,
    };
};

export const changeObSettings = (payload) => {
    return {
        type: "CHANGE_OB_SETTINGS",
        payload,
    };
};

const cashierGatewayValidator = (data, dispatch) => {
    if (window.cashierGatewayValidator) {
        return window.cashierGatewayValidator(data).then((el) => {
            if (el.status === 0) data.gateways = el.gateways;
            dispatch(updateGateways(data));
            return {data};
        });
    }
    dispatch(updateGateways(data));
    return {data};
};

export const fetchGateways = (ob_decline_recovery = 0) => (dispatch, getState) => {
    const {
        profileInfo: {transaction_type},
    } = getState();
    return getGateways(transaction_type, ob_decline_recovery)
        .then(({data}) => {
            if (data.status === 0) return cashierGatewayValidator(data, dispatch);
            if (data.status === 2) dispatch(createResultOfPay(data));
            if (data.status === -1) dispatch(createFatalError(data.error_details));
        })
        .catch(({response = {}}) => {
            const {data = {}} = response;
            if (data.status === 0) return cashierGatewayValidator(data, dispatch);
            if (data.status === 2) dispatch(createResultOfPay(data));
            if (data.status === -1) dispatch(createFatalError(data.error_details));
            if (!data.status) dispatch(createFatalError());
        });
};

export const fetchProcessingGateways = (data) => (dispatch, getState) => {
    return new Promise((resolve) => {
        return getAvailableGateways({...data})
            .then(({data} = {}) => {
                const {status} = data;
                if (status === 0) {
                    dispatch(updateProcessingGateways(data));
                } else {
                    if (status === 2) dispatch(createResultOfPay(data));
                    if (status === -1) dispatch(createFatalError(data.error_details));
                }
                resolve(data);
            })
            .catch(({response = {}}) => {
                const {data = {}, data: {status} = {}} = response;
                if (status === 0) {
                    dispatch(updateProcessingGateways(data));
                } else {
                    if (status === 2) dispatch(createResultOfPay(data));
                    if (status === -1) dispatch(createFatalError(data.error_details));
                    if (!status) {
                        postErrors({error: {stack: 'getAvailableGateways catch', message: JSON.stringify(response)}});
                        dispatch(createFatalError());
                    }
                }
                resolve(data);
            });
    });
}

export const handleResultResponse = (data, dispatch) => {
    const {status} = data;
    switch(status) {
        case 0:
            dispatch(createResultOfPay(data));
            break;
        case 2:
            dispatch(createResultOfPay(data));
            break;
        case -1:
            dispatch(createFatalError(data.error_details));
            break;
        case -3:
            dispatch(createValidationPayError(data.error_details));
            break;
        default:
            dispatch(createFatalError());
    }
}

const delayPayWithGateway = () => {
    return new Promise(resolve => {
        const interval = setInterval(() => {
            getUser(1).then(({data}) => {
                const {processing_in_progress} = data;
                if(!processing_in_progress) {
                    clearInterval(interval);
                    resolve({data})
                }
            })
        }, 10000)
    })
}

export const payWithGateway = (data, isQuickDeposit = false) => (dispatch, getState) => {
    const {
        currentGateway: {option_id, option_type}, gatewaysList: {is_encrypted_input_names = 0},
        virtualSettings, profileInfo: {remove_customer_data}
    } = getState();
    
    const params = {
        option_id,
        option_type,
        is_encrypted_input_names,
        remove_customer_data, ...virtualSettings, ...data
    };
    
    //tell the BE that this transaction is quick deposit
    if (isQuickDeposit) {
        params['is_quick_deposit'] = 1
    }
    
    return new Promise((resolve) => {
        return postPayGateway(params)
            .then(({data} = {}) => {
                const {status, processing_in_progress} = data;
                
                if (status === 0) {
                    const { transaction } = data;
                    triggerAdvanceCashierEvent({
                        event_type: "transaction_attempted",
                        transaction: {
                            amount: transaction?.amount,
                            currency: transaction?.currency,
                            charge_amount: transaction?.charge_amount,
                            charge_currency: transaction?.charge_currency,
                            payment_method: transaction?.payment_method,
                            payment_processor: transaction?.payment_processor,
                            trace_id: transaction?.trace_id,
                            card_number: transaction?.card_number,
                        }
                    });
                }
                
                if ([0, 2].includes(status) && processing_in_progress) {
                    return delayPayWithGateway().then(({data}) => {
                        handleResultResponse(data, dispatch);
                        resolve(data);
                    })
                }
                handleResultResponse(data, dispatch);
                resolve(data);
            })
            .catch(({response = {}}) => {
                const {data = {}, data: {status, processing_in_progress} = {}} = response;
                if([0,2].includes(status) && processing_in_progress) {
                    return delayPayWithGateway().then(({data}) => {
                        handleResultResponse(data, dispatch);
                        resolve(data);
                    })
                }
                handleResultResponse(data, dispatch);
                resolve(data);
            });
    });
};

export const payWithPartiallyApproved = (data) => (dispatch) => {
    return new Promise((resolve) => {
        return partialApprovedResult(data)
            .then(({data} = {}) => {
                handleResultResponse(data, dispatch);
                resolve(data);
            })
            .catch(({response = {}}) => {
                const {data = {}} = response;
                handleResultResponse(data, dispatch);
                resolve(data);
            });
    });
};

export const payWithManageRetryRequest = (data) => (dispatch, getState) => {
    const {payData} = getState();
    const retryData = {...data, ...payData};
    return new Promise((resolve) => {
        return manageRetryRequest(retryData)
            .then(({data} = {}) => {
                handleResultResponse(data, dispatch);
                resolve(data);
            })
            .catch(({response = {}}) => {
                const {data = {}} = response;
                handleResultResponse(data, dispatch);
                resolve(data);
            });
    });
};

export const updateProfile = (callback = 0) => (dispatch) => {
    return new Promise((resolve) => {
        return getUser(callback)
            .then(({data}) => {
                if(callback && data.redirect_url) data.redirect_url = null;
                if (data.status === 0) {
                    if (window.cashierUpdateCustomer)
                        try {
                            window.cashierUpdateCustomer(data.customer);
                        } catch {
                        }
                    dispatch(changeProfile(data));
                }
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === -3)
                    dispatch(createValidationProfileError(data.error_details));
                if (data.status === -1) dispatch(createFatalError(data.error_details));
                if (data.status === -2) dispatch(changeProfile(data));
                resolve(data);
            })
            .catch(({response = {}}) => {
                const {data = {}} = response;
                if (data.status === 0) {
                    if (window.cashierUpdateCustomer)
                        try {
                            window.cashierUpdateCustomer(data.customer);
                        } catch {
                        }
                    dispatch(changeProfile(data));
                }
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === -3)
                    dispatch(createValidationProfileError(data.error_details));
                if (data.status === -1) dispatch(createFatalError(data.error_details));
                if (data.status === -2) dispatch(changeProfile(data));
                if (!data.status) dispatch(createFatalError());
                resolve(data);
            });
    });
};

export const saveDataInfo = (amount) => (dispatch) => {
    return new Promise((resolve) => {
        saveData({...amount})
            .then(({data}) => {
                if (data.status === -3)
                    dispatch(
                        createValidationPayError(data?.error_details?.payment_information)
                    );
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === 0) resolve(data);
                resolve(data);
            })
            .catch(({response}) => {
                const {data = {}} = response;
                if (data.status === -3)
                    dispatch(
                        createValidationPayError(data?.error_details?.payment_information)
                    );
                if (data.status === 2) dispatch(createResultOfPay(data));
                if (data.status === 0) resolve(data);
                if (!data.status) dispatch(createFatalError());
                resolve(data);
            });
    });
};

export const setLimitSubmit = (payload) => {
    return {
        type: "SET_LIMIT_SUBMIT",
        payload,
    };
};

export const setLimitError = (payload) => {
    return {
        type: "SET_LIMIT_ERROR",
        payload,
    };
};

