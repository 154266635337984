import React, {useEffect, useRef, useState} from 'react'
import '../header.sass'
import compose from "../../../../utils/helper-functions/compose";
import {withI18N} from "../../../hoc-components";
import {ProfileSVG} from "../../../../@cashier-project/assets/images/profile";
import {shallowEqual, useSelector} from "react-redux";
import {withAdr} from "../../../hoc-components/with-adr";

const MyInfo = ({langBack, setBillingModal, setTransactionsModal, s}) => {

    const store = useSelector(({profileInfo, resultOfPay}) => ({
        profileInfo, resultOfPay}), shallowEqual);
    const {profileInfo, resultOfPay} = store;
    const {transaction_type:type=resultOfPay.transaction_type} = profileInfo;

    useEffect(() => {
        document.addEventListener('click', onClickOuterModal, false);
        return () => document.removeEventListener('click', onClickOuterModal, false);
    });

    const onClickOuterModal = (event) => {
        if (toggleRef.current && !toggleRef.current.contains(event.target) && profileToggle) {
            setProfileToggle(false);
        }
    };

    const  [profileToggle, setProfileToggle] = useState(false);
    const toggleRef = useRef(null);

    return(
        <div className={`${s('header-profile-container')}`}>
            <div
                onClick={() => type==="pay-in"? setProfileToggle(true)
                    : setProfileToggle(!profileToggle)}
                className={`${s('header-profile-toggle')}`}>
                <ProfileSVG/>
                {langBack('My Info')}
            </div>
            {
                !profileToggle? "" :
                    <div className={`${s('header-profile-menu')}`} ref={toggleRef}>
                        <div
                            onClick={() => {setProfileToggle(false); setBillingModal(true)}}
                            className={`${s('header-profile-menu-item')} ${s('item-info')}`}>
                            {langBack('Billing info')}
                        </div>
                        <div
                            onClick={() => {setProfileToggle(false); setTransactionsModal(true)}}
                            className={`${s('header-profile-menu-item')} ${s('item-info')}`}>
                            {langBack('Withdrawal Requests')}
                        </div>
                    </div>
            }
        </div>
    )
}

export default compose(
    withI18N, withAdr
)(MyInfo)
