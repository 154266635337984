import CreditCardDefaultIcon from "../../@cashier-project/assets/images/credit-card-default.png";
import CCWithoutBCIcon from "../../@cashier-project/assets/images/cc-without-bc.png";
import CreditAndDebitCardIcon from "../../@cashier-project/assets/images/credit-and-debit-card.png";
import AddFieldsIcon from "../../@cashier-project/assets/images/with-add-fields.png";
import AddFieldsDefaultValueIcon from "../../@cashier-project/assets/images/with-add-fields-default-value.png";
import AddFieldsSameAccountIcon from "../../@cashier-project/assets/images/with-add-fields-same-account.png";
import PayPalIcon from "../../@cashier-project/assets/images/paypal-new.png";
import CustomProcessorIcon from "../../@cashier-project/assets/images/custom-processor.png";
import newPayPalIcon from "../../@nova-project/assets/images/payPal.png";
import newCreditCardIcon from "../../@nova-project/assets/images/creditCard.png";
import newNeteller from "../../@nova-project/assets/images/neteller.png";
import newSkrillIcon from "../../@nova-project/assets/images/skrill.png";
import newWireTransferIcon from "../../@nova-project/assets/images/wireTransfer.png";
import newManualBankWireIcon from '../../images/blank-bank.svg'
import newTrueLayerIcon from "../../images/trueLayer.png";
import newVoltIcon from "../../images/volt.png";
import newHelp2PayIcon from "../../images/help2Pay.png";
import newEcommpayIcon from "../../images/ecommpay.png";

// export const creditCardWithoutBaseCurrency = {
//   image: CCWithoutBCIcon,
//   title: "Credit Card Without Base Currency",
//   option_id: 10,
//   option_type: "payment_method",
// };

// export const defaultCreditCardWithoutTemplates = {
//   image: CreditCardDefaultIcon,
//   title: "Default Credit Card Without Templates",
//   option_id: 70,
//   option_type: "gateway",
// };

export const defaultCreditCardWithTemplates = {
  image: newCreditCardIcon,
  title: "Credit Card",
  option_id: 10,
  option_type: "payment_method",
  payment_information: {
    base_currency: "USD",
    currency_list: {
      EUR: {
        rate: 0.9019572472,
      },
      BTC: {
        rate: 0.000018,
        min_amount: "0.00000001",
        crypto: 1,
        max_amount: "1",
      },
      USD: {
        rate: 1,
        min_amount: "1000.5",
        max_amount: "13000",
      },
      IQD: {
        rate: 5.6,
        min_amount: "1",
        max_amount: "14000",
      },
      BIF: {
        rate: 1941.307125,
        min_amount: "1941",
        max_amount: "388261425",
      },
    },
  },
  processor_type: "pp",
  additional_image: newCreditCardIcon,
  payment_method_type: 'cc',
  quick_deposit: {
    amount: 22,
    cc_template: "444444*******4444",
    currency: "USD",
  },
  card_types: [
    "Mir",
    "VISA",
    "JCB",
    "AMEX",
    "Dankort",
    "DinersClub",
    "Discover",
    "Electron",
    "Forbrugsforeningen",
    "Maestro",
    "MasterCard",
    "Unionpay"
  ]
};

// export const defaultAddFieldsAllTypesAndHTMLVariables = {
//   image: AddFieldsIcon,
//   title: "Add Fields All Types And HTML Variables",
//   option_id: 90,
//   option_type: "gateway",
// };

// export const addFieldsWithDefaultValue = {
//   image: AddFieldsDefaultValueIcon,
//   title: "Add Fields With Default Value",
//   option_id: 100,
//   option_type: "gateway",
// };

// export const addFieldsWithTemplates = {
//   image: AddFieldsSameAccountIcon,
//   title: "Add Fields With Same Account",
//   option_id: 110,
//   option_type: "gateway",
// };

export const PayPal = {
  image: 'https://cdn-qa.cashier-dev.com/payment_method_/payment_method_649ad657ab737.svg',
  //additional_image_dark_mode: 'https://cdn-qa.cashier-dev.com/payment_method_/payment_method_64887069a5711.svg',
  option_id: 2091,
  option_type: "gateway",
  title: "PayPal",
  payment_information: {
    base_currency: "EUR",
    currency_list: {
      EUR: {
        max_amount: 10000,
        min_amount: 100,
        rate: 1,
      },
      USD: {
        rate: 1,
        min_amount: 10,
        max_amount: 10000,
      },
    },
  },
  additional_image: "",
  processor_type: "pp",
};

export const Skrill = {
  image: 'https://cdn-qa.cashier-dev.com/payment_method_/payment_method_649ad7283d317.svg',
  option_id: 1944,
  option_type: "gateway",
  title: "Skrill",
  payment_information: {
    base_currency: "USD",
    currency_list: {
      EUR: { rate: 0.885979, min_amount: 0.89, max_amount: 8859.79 },
      USD: { rate: 1, min_amount: 1, max_amount: 10000 },
    },
  },
  additional_image: "",
  processor_type: "pp",
};

export const WireTransfer = {
  image: newWireTransferIcon,
  option_id: 1502,
  option_type: "gateway",
  title: "Wire Transfer",
  payment_information: {
    base_currency: "USD",
    currency_list: {
      EUR: { rate: 0.885979, min_amount: 0.89, max_amount: 8859.79 },
      USD: { rate: 1, min_amount: 1, max_amount: 10000 },
    },
  },
  processor_type: "custom",
  additional_image: "",
};

export const ManualBankWire = {
  image: newManualBankWireIcon,
  option_id: 1510,
  option_type: "gateway",
  title: "Manual Bank Wire",
  payment_information: {
    base_currency: "USD",
    currency_list: {
      EUR: { rate: 0.885979, min_amount: 0.89, max_amount: 8859.79 },
      USD: { rate: 1, min_amount: 1, max_amount: 10000 },
    },
  },
  processor_type: "manual",
  additional_image: "",
};

export const customProcessor = {
  image: CustomProcessorIcon,
  title: "Custom Processor",
  option_id: 120,
  option_type: "gateway",
  processor_type: "pp",
  additional_image: "",
};

export const docCreditCard = {
  image:
    "https://cdn-chinagw.s3.amazonaws.com/payment_method_/payment_method_5e394a652e7b0.png",
  title: "Credit Card",
  option_id: 80,
  option_type: "payment_method",
  processor_type: "pp",
  additional_image: "",
};

export const docEwalet = {
  image: "",
  title: "Ewalet",
  option_id: 110,
  option_type: "gateway",
  processor_type: "pp",
};

export const neteller = {
  title: "Neteller",
  image: 'https://cdn-qa.cashier-dev.com/gateway_/gateway_658e8bb20d449.jpg',
  option_id: 644,
  option_type: "gateway",
  payment_information: {
    base_currency: "USD",
    currency_list: {
      EUR: { rate: 0.885979, min_amount: 0.89, max_amount: 8859.79 },
      USD: { rate: 1, min_amount: 1, max_amount: 10000 },
    },
  },
  processor_type: "pp",
  additional_image: "",
};

export const TrueLayer = {
  title: "TrueLayer",
  image: newTrueLayerIcon,
  option_id: 529,
  option_type: "gateway",
  payment_information: {
    base_currency: "USD",
    currency_list: {
      EUR: { rate: 0.885979, min_amount: 0.89, max_amount: 8859.79 },
      USD: { rate: 1, min_amount: 1, max_amount: 10000 },
    },
  },
  processor_type: "pp",
  additional_image: "",
}

export const Volt = {
  title: "Volt",
  image: newVoltIcon,
  option_id: 421,
  option_type: "gateway",
  payment_information: {
    base_currency: "USD",
    currency_list: {
      EUR: { rate: 0.885979, min_amount: 0.89, max_amount: 8859.79 },
      USD: { rate: 1, min_amount: 1, max_amount: 10000 },
    },
  },
  processor_type: "pp",
  additional_image: "",
}
export const Help2Pay = {
  title: "Help2Pay",
  image: newHelp2PayIcon,
  option_id: 22,
  option_type: "gateway",
  payment_information: {
    base_currency: "USD",
    currency_list: {
      EUR: { rate: 0.885979, min_amount: 0.89, max_amount: 8859.79 },
      USD: { rate: 1, min_amount: 1, max_amount: 10000 },
    },
  },
  processor_type: "pp",
  additional_image: "",
}

export const Ecommpay = {
  title: "Ecommpay",
  image: newEcommpayIcon,
  option_id: 108,
  option_type: "gateway",
  payment_information: {
    base_currency: "USD",
    currency_list: {
      EUR: { rate: 0.885979, min_amount: 0.89, max_amount: 8859.79 },
      USD: { rate: 1, min_amount: 1, max_amount: 10000 },
    },
  },
  processor_type: "pp",
  additional_image: "",
}
