export const clearNumber = (value = "") => {
    if(!value || typeof value !== "string") return value;
    return value.replace(/\D+/g, "");
};

export const clearSpace = (value = "") => {
    if(typeof value !== "string") return value;
    else {
        value = value.replace(/\s/g, '');
        return value
    }
};