import {Amplify} from 'aws-amplify';
import {generateClient} from 'aws-amplify/api';
import {getTokenFromUrl} from "../utils/helper-functions/getTokenFromUrl";
import * as subscriptions from './subscriptions.js'
import * as queries from './queries.js'
import amplifyConfig from "./amplify-config";

const createAmplifyClient = () => {
    Amplify.configure(amplifyConfig);
    return generateClient();
}

const auth = {
    Authorization: getTokenFromUrl('graphql')
}

const getAmplifyQuery = async(query = '', client) => {
    try {
        const {data = {}} = await client.graphql({query: queries?.[query]}, auth);
        if(data) {
            return data;
        } else {
            console.error(`Can't get ${query} query as its undefined`);
            return {};
        }
    } catch(err) {
        console.error(`Can't get ${query} query:`, err);
    }
}
const setSubscription = async (client, query = '', callbackSuccess, callbackError) => {
    return client.graphql({query: subscriptions[query]}, auth).subscribe({
        next: ({data}) => {
            if (typeof callbackSuccess === 'function') {
                callbackSuccess(data);
            }
        },
        error: (error) => {
            console.error('Error to set subscription', {query, SubscriptionStateChangedError: error})
            if (typeof callbackError === 'function') {
                callbackError(error);
            }
        }
    });
}

const unsubscribeAllFromQraphqlAPI = (subscriptions = {}) => {
    Object.keys(subscriptions).forEach(async (subscription_key) => {
        subscriptions[subscription_key]?.unsubscribe();
    })
}

export {
    createAmplifyClient,
    setSubscription,
    getAmplifyQuery,
    unsubscribeAllFromQraphqlAPI
}