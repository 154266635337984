import {
  parsingSynthetics,
  transformSyntheticEvent,
} from "../../utils/helper-functions/transform-syntetic-event";
import { checkFormat } from "../../utils/validations-functions/change-format-methods";

export const changePayData = (payload) => {
  payload = checkFormat(parsingSynthetics(payload));
  return {
    type: "CHANGE-PAY-DATA",
    payload,
  };
};

export const updatePayData = (payload) => {
  return {
    type: "UPDATE-PAY-DATA",
    payload,
  };
};

export const identifyCardType = (payload) => {
  return {
    type: "IDENTIFY_CARD_TYPE",
    payload,
  };
};
export const createValidationPayError = (payload) => {
  return {
    type: "VALIDATION_ERROR_PAY",
    payload,
  };
};

export const applyTemplate = (payload) => {
  return {
    type: "APPLY_TEMPLATE",
    payload,
  };
};

export const setRequired = (payload) => {
  return {
    type: "SET_REQUIRED",
    payload,
  };
};

export const setWaste = (payload) => {
  return {
    type: "SET_WASTE",
    payload,
  };
};

export const setAddFieldsInfo = (payload) => {
  return {
    type: "SET_ADD_FIELDS_INFO",
    payload,
  };
};

export const setCheckbox = (payload) => {
  payload = transformSyntheticEvent(payload);
  return {
    type: "SET_CHECKBOX",
    payload,
  };
};

export const initMethodAction = (payload) => {
  return {
    type: "INIT_METHOD_STATE",
    payload,
  };
};

export const setInputEditorCacheValue = (payload) => {
  return {
    type: 'SET_INPUT_EDITOR_CACHE_VALUE',
    payload
  }
};

export const setBdccModal = (payload) => {
  payload = transformSyntheticEvent(payload);
  return {
    type: "SET_BDCC_MODAL",
    payload,
  };
};

export const setCardValidationModal = (payload) => {
  payload = transformSyntheticEvent(payload);
  return {
    type: "SET_CARD_VALIDATION_MODAL",
    payload,
  };
};

export const setPciPraxisGate = (payload) => {
  return {
    type: "SET_PCI_PRAXIS_GATE",
    payload,
  };
};