
export const  getCardName = (value="") => {
    // JCB
    let jcb_regex = new RegExp("^35(2[89]|[3-8][0-9])"); //2131, 1800, 35 (3528-3589)
    // American Express
    let amex_regex = new RegExp("^3[47]"); //34, 37
    // Diners Club
    let diners_regex = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}$'); //300-305, 309, 36, 38-39
    // Visa
    let visa_regex = new RegExp("^4"); //4
    // Visa Electron
    let visa_electron =new RegExp("^(4026|417500|4508|4844|491(3|7))");
    // MasterCard
    let mastercard_regex = new RegExp(/^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/); //2221-2720, 51-55
    // Maestro
    let maestro_regex = new RegExp('^(5[06789]|6)[0-9]{0,}$');
    //Discover
    let discover_regex = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)"); ////6011, 622126-622925, 644-649, 65
    //Mir
    let mir_regex = new RegExp(/^(?:220[0-4])\d+$/);
    // Forbrugsforeningen
    let forbrugsforeningen_regex = new RegExp(/^600/);
    // UnionPay
    let unionpay_regex = new RegExp(/^(62[0-9]{14,17})$/); // 622126 and 622925 are starts for Discovery
    // Dankort
    let dankort_regex = new RegExp(/5019(?:[-\ ]?[0-9]{4}){3}/);

    // get rid of anything but numbers
    let cur_val = value.replace(/\D/g, '');

    let sel_brand = "unknown";
    if (cur_val.match(jcb_regex)) {
        sel_brand = "JCB";
    } else if (cur_val.match(amex_regex)) {
        sel_brand = "AMEX";
    } else if (cur_val.match(diners_regex)) {
        sel_brand = "DinersClub";
    } else if (cur_val.match(visa_regex)) {
        if(cur_val.match(visa_electron)) {
            sel_brand = "Electron";
        } else {
            sel_brand = "VISA";
        }
    } else if (cur_val.match(mastercard_regex)) {
        sel_brand = "MasterCard";
    } else if (cur_val.match(discover_regex)) {
        sel_brand = "Discover";
    } else if (cur_val.match(maestro_regex)) {
        if(cur_val.match(unionpay_regex)) {
            sel_brand = "UnionPay";
        } else if(cur_val.match(dankort_regex)) {
            sel_brand = "Dankort";
        } else {
            sel_brand = "Maestro"
        }
    } else if(cur_val.match(mir_regex)) {
        sel_brand = "Mir";
    } else if(cur_val.match(forbrugsforeningen_regex)) {
        sel_brand = "Forbrugsforeningen";
    }

    return sel_brand
};
