import React from 'react';
const FooterLinks = ({policies_data = {}, langBack}) => {
    
    return (
        <div className={'custom-footer-links'}>
            {policies_data.privacy_policy_url &&
                <a href={policies_data.privacy_policy_url} target={'_blank'} rel="noreferrer">{langBack('Privacy Policy')}</a>}
            {policies_data.terms_of_service_url &&
                <a href={policies_data.terms_of_service_url} target={'_blank'} rel="noreferrer">{langBack('Terms of Service')}</a>}
            {policies_data.support_website && <a href={policies_data.support_website} target={'_blank'} rel="noreferrer">{langBack('Help')}</a>}
            {policies_data.support_email && <a href={`mailto:${policies_data.support_email}`}
                                                  target={'_blank'} rel="noreferrer">{policies_data.support_email}</a>}
            {policies_data.support_phone && <a href={`tel:${policies_data.support_phone}`}
                                                  target={'_blank'} rel="noreferrer">{policies_data.support_phone}</a>}
        </div>
    );
};

export default FooterLinks;