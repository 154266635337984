export const zh_CHT = {
  "Loading... Please Wait": "加載請稍候",
  yourTransactionIsPendingForPayOut: "您的提款請求正在等待中",
  status1:
    "請注意，處理銀行或付款服務提供商正在審核您的交易。 如果您想要有關交易狀態的更多信息，請聯繫支持部門以請求有關跟踪ID的幫助：{{trace_id}}",
  Status: "狀態",
  "Deposit Review For Account:": "帳戶的存款審查：",
  "New Transaction": "新交易",
  Update: "更新資料",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "您選擇的付款方式將在單獨的頁面中打開。 請點擊“繼續”以瀏覽到新的瀏覽器窗口，然後繼續付款過程。",
  dod_error_text: "您必須閱讀並接受《存款聲明》才能繼續",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "您必須接受條款和條件才能進行存款",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "我確認已閱讀並接受條款和條件",
  dod_url: "#",
  dod_url_text: "條款和條件",
  dod_text: "我確認已閱讀並接受{{dod_url}}",
  transactionSuccessfullyCanceled: "交易{{id}}已成功取消",
  "Fee should not exceed 20% of the amount": "費用不得超過金額的20％",
  "My Info": "我的資料",
  "Billing info": "計費信息",
  "Withdrawal Requests": "提款要求",
  "No Withdrawal Requests found": "找不到提款請求",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "取消",
  Amount: "量",
  "Last Update": "最後更新",
  "Payment Method": "付款方法",
  TID: "時間",
  Action: "行動",
  "Confirmation required": "需要確認",
  "Are you sure you want to cancel transaction": "您確定要取消交易嗎",
  Yes: "是",
  No: "沒有",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "最小-最大金額",
  "Fee (included)": "費用（含）",
  "- Amount between": "-之間的金額",
  "I have the Promocode!": "我有促銷代碼！",
  "Please enter the Promocode": "請輸入促銷代碼",
  Promocode: "促銷代碼",
  noSavedTemplates: "沒有存儲卡",
  noOptions: "沒有選擇",
  "Please confirm your billing details": "請確認您的帳單明細",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "手續費",
  Fee: "費用",
  Total: "總",
  customer_support_link_text: "客戶支持",
  terms_conditions_link_text: "條款和條件",
  "Edit Billing Info": "修改帳單信息",
  chooseAnAccount: "選擇一個帳號",
  "Requested to Receive": "要求接收",
  "Receive With": "接收方式",
  "Unknown session": "未知會話",
  transactionId: "交易編號",
  traceId: "跟踪ID",
  save: "救",
  fillTheBillingInformation: "請在下面填寫帳單信息",
  correctionsRequired: "需要更正",
  billingInfo: "計費信息",
  "Cancelled by client": "被客戶取消",
  "Your Cards": "您的卡",
  Done: "完成",
  redirect: "重新導向",
  redirectingTo: "重定向到",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "交易未成功。很抱歉，提供的PayPal賬戶出現在我們記錄中的另一個客戶的交易賬戶下。客戶只能在我們的網站上使用一個有效的交易賬戶。若要允許此PayPal賬戶與您的登錄資訊一起使用，或者如果您需要任何説明，請與客服聯繫。",
  "Not supported yet": "還不支持",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "抱歉，您似乎沒有註冊任何可用於提款請求的付款明細",
  "Not available gateways found": "找不到可用的網關",
  "Token is invalid": "令牌無效",
  "Payment method not available. Please contact your site administrator.":
    "無法使用付款方式。 請與您的站點管理員聯繫。",
  cardIsNotValid: "卡無效",
  invalidInputFormat: "輸入格式無效。 至少{{length}}個字符。",
  invalidDateFormat: "日期格式無效。",
  maximumValueIs: "最大值是",
  minimumValueIs: "最小值為",
  chooseAmount: "選擇數量",
  enterPayoutAmount: "請輸入小於或等於您的可用餘額的付款金額。",
  maxLength2: "最大長度為2個字符。",
  footer1:
    "某些發卡銀行已限制使用信用卡進行在線娛樂。請隨時使用上述第三種替代付款方式之一，或與我們的{{customer_support_url}}代表免費撥打N / A資金\n請注意：根據您的發卡銀行的不同，信用卡交易可能會被視為現金墊款。{{application_key}}對於此交易中涉及的任何現金墊款費用不承擔任何責任，因為這是由信用卡來確定的卡的發卡行。請諮詢您的銀行以獲取更多詳細信息。",
  footer2:
    "如果需要協助或確認嘗試進行的交易，請聯繫{{customer_support_url}}。\n請注意：根據您的銀行的不同，該交易可能被視為預付款。{{application_key}}將不承擔任何責任。該交易涉及的任何現金墊款費用。除非您的交易收據上另有說明，否則該交易將在您的信用卡對帳單上標識為信用卡描述符。",
  footer3:
    "所有付款請求均受我們的{{terms_conditions_url}}約束。如果您需要幫助或更多信息，請聯繫{{customer_support_url}}。提交請求後，該信息將轉發給我們的收銀部門進行處理。您將在收到付款後收到一封確認電子郵件。在我們審核付款後，將從您的帳戶中扣除付款金額和手續費（如果適用）。",
  gatewaysTitle: "您要如何付款?",
  gatewaysTitleDeposit: "請選擇存款方式",
  gatewaysTitleWithdrawal: "請選擇提款方式",
  payInTitle: "用信用卡或借記卡付款",
  allPaymentMethods: "所有付款方式",
  submit: "提交",
  useAddressOnFile: "使用文件上的地址",
  customerName: "顧客姓名",
  country: "國家",
  province: "省",
  city: "市",
  postalCode: "郵政編碼",
  street: "街",
  proceed: "繼續",
  cardNumber: "卡號",
  cardHolder: "持卡人",
  cardHolderName: "持卡人姓名",
  cardExpires: "卡過期",
  cvv: "CVV",
  firstName: "名字",
  lastName: "姓",
  zipCode: "郵政編碼",
  address: "地址",
  state: "州",
  email: "電子郵件",
  phone: "電話",
  dateOfBirth: "出生日期",
  transactionStatus: "交易狀態",
  trace: "跟踪",
  amount: "量",
  min: "分",
  max: "最大值",
  overrideLimits: "超越限制",
  transaction: "交易",
  processed: "處理",
  transactionRejected: "交易被拒絕",
  transactionApproved: "交易批准",
  yourTransactionIsPending: "您的交易有待處理",
  transactionAuthorized: "交易授權",
  requestAccepted: "接受請求",
  status2:
    "您的提款要求已被接受. 請注意，將在工作日的營業時間內執行進一步的授權和處理. 在此期間，執行人員可能會與您聯繫以進行身份確認.",
  unexpectedErrorOccurred: "發生意外錯誤",
  status3: "很抱歉，處理您的交易時出了點問題。 請在下面找到技術細節：",
  tryAgain: "再試一次",
  somethingWentWrong: "有些不對勁...",
  newPayoutRequest: "新的付款請求",
  makeAnotherPayment: "再次付款",
  profile: "輪廓",
  justOneMoreStep: "再走一步...",
  "profile-auth": "請完成您的個人資料，以便使用進行付款 {{title}}",
  payWith: "使用。。。支付",
  ProceedWith: "與..一起處理",
  vtTitle: "虛擬終端：",
  pay: "工資",
  deposit: "存款",
  withdraw: "退出",
  withdrawal: "退出",
  hello: "你好，",
  currency: "貨幣",
  currentIp: "當前IP：",
  UseCurrentIp: "使用當前IP：",
  UseCustomerIp: "使用客戶IP：",
  copyright: "版權",
  cannotBeBlank: "不能是空白的。",
  noDepositFree: "没有存款费。没有提款费。",
  next: "下一个",
  backToSite: "回到网站",
  maximumLength: "最大长度{{length}}个字符",
  minimumLength: "最小長度{{length}}個字符",
  emailIsNotValid: "电子邮件不是有效的电子邮件地址。",
  phoneIsNotValid: "电话无效",
  cardHolderInvalid: "持卡人无效。 持卡人应包含名字和姓氏。",
  pleaseEnterPayout: "请输入小于或等于的付款金额",
  invalidAmount: "无效金额",
  transactionWillAppear: "您的交易將在您的對帳單上顯示為",
  "Customer does not exist": "客戶不存在",
  "Sorry no available gateways found.": "抱歉，找不到可用的網關。",
  "Incorrect currency": "貨幣不正確",
  "Sorry, not supported payment type": "抱歉，不支持的付款方式",
  "Payment method has no payment type": "付款方式無付款方式",
  "Incorrect payment type in payment method": "付款方式中的付款類型不正確",
  "Gateway limit exceeded": "超出網關限制",
  "Not supported transaction type": "不支持的交易類型",
  "Sorry. Payout with credit card gateway not supported yet":
    "抱歉。 尚不支持使用信用卡網關付款",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "抱歉，此付款方式不適用於指定的付款明細。 請重新加載頁面，然後嘗試使用其他金額，貨幣或付款方式。",
  "Back to site": "回到網站",
  "Your transaction has been requested. Please wait for approval.":
    "您的交易已被請求。 請等待批准。",
  "Token expired": "令牌已過期",
  back: "背部",
  "Transaction not found.": "超出門限",
  "Continue in the New Window": "在新視窗繼續",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "請不要關閉此窗口。 在新窗口中完成交易後，您將被重定向回。 沒有看到打開安全連接的新窗口？ 我們在這裡幫助您完成交易。",
  "Click to continue": "點擊繼續",
  "Just One More Step...": "再走一步...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "您選擇的付款方式將在新頁面中打開。 請單擊下面的“繼續”按鈕以導航到最後一步",
  Proceed: "繼續",
  "Opening a Secure Connection...": "打開安全連接...",
  "You will be redirected to complete your transaction in just a moment":
    "您將被重定向以在短時間內完成交易",
  "Close window": "關閉窗口",
  "Partial approval confirmation": "部分批准確認",
  PartiallyApprovedAmount:
    "您的卡餘額不足，無法貸記全額。該交易部分批准用於 {{amount}} {{currency}}。",
  ConfirmPartialDeposit:
    "請點擊“確定”確認部分存款金額或點擊“取消”取消交易。如果不採取任何行動，存款將在 5 分鐘後自動確認。",
  fxRateSpreadText: "所示匯率包括所有費用和收費。",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "加载请稍候",
};
