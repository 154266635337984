export const hi = {
  "Loading... Please Wait": "लोड हो रहा है कृपया प्रतीक्षा करें",
  yourTransactionIsPendingForPayOut: "आपका निकासी अनुरोध लंबित है",
  status1:
    "कृपया ध्यान रखें कि आपके लेनदेन की समीक्षा प्रसंस्करण बैंक या भुगतान सेवा प्रदाता द्वारा की जा रही है। यदि आप अपने लेन-देन की स्थिति के बारे में अधिक जानकारी चाहते हैं, तो कृपया ट्रेस आईडी पर सहायता के लिए समर्थन से संपर्क करें: {{trace_id}",
  Status: "स्थिति",
  "Deposit Review For Account:": "खाते के लिए जमा की समीक्षा:",
  "New Transaction": "नया लेन-देन",
  Update: "अपडेट करें",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "आपके द्वारा चुनी गई भुगतान विधि एक अलग पृष्ठ में खुलती है। कृपया नई ब्राउज़र विंडो में नेविगेट करने और भुगतान प्रक्रिया जारी रखने के लिए 'बढ़ना' पर क्लिक करें।",
  dod_error_text:
    "आगे बढ़ने के लिए आपको जमा की घोषणा को पढ़ना और स्वीकार करना होगा",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "अपनी जमा राशि के साथ आगे बढ़ने के लिए आपको नियम और शर्तें स्वीकार करनी चाहिए",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "मैं स्वीकार करता हूं कि मैंने पढ़ा है, और इसके साथ नियम और शर्तें स्वीकार करते हैं",
  dod_url: "#",
  dod_url_text: "नियम और शर्तें",
  dod_text:
    "मैंने स्वीकार किया है कि मैंने पढ़ा है, और इसके अलावा {{dod_url}} स्वीकार करते हैं",
  transactionSuccessfullyCanceled:
    "लेनदेन {{id}} सफलतापूर्वक रद्द कर दिया गया था",
  "Fee should not exceed 20% of the amount":
    "शुल्क राशि का 20% से अधिक नहीं होना चाहिए",
  "My Info": "मेरी जानकारी",
  "Billing info": "बिलिंग जानकारी",
  "Withdrawal Requests": "निकासी अनुरोध",
  "No Withdrawal Requests found": "कोई निकासी अनुरोध नहीं मिला",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "रद्द करना",
  Amount: "रकम",
  "Last Update": "आखिरी अपडेट",
  "Payment Method": "भुगतान का तरीका",
  TID: "समय",
  Action: "कार्य",
  "Confirmation required": "पुष्टिकरण की आवश्यकता है",
  "Are you sure you want to cancel transaction":
    "क्या आप वाकई लेन-देन रद्द करना चाहते हैं",
  Yes: "हाँ",
  No: "नहीं",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "मिन-मैक्स की गिनती",
  "Fee (included)": "शुल्क (शामिल)",
  "- Amount between": "- के बीच की राशि",
  "I have the Promocode!": "मेरे पास प्रोमो कोड है!",
  "Please enter the Promocode": "कृपया प्रोमो कोड दर्ज करें",
  Promocode: "प्रचार कोड",
  noSavedTemplates: "कोई कार्ड सहेजा नहीं गया",
  noOptions: "कोई विकल्प नहीं",
  "Please confirm your billing details":
    "कृपया अपने बिलिंग विवरण की पुष्टि करें",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "प्रक्रमण संसाधन शुल्क",
  Fee: "शुल्क",
  Total: "संपूर्ण",
  customer_support_link_text: "ग्राहक सहेयता",
  terms_conditions_link_text: "नियम और शर्तें",
  "Edit Billing Info": "बिलिंग जानकारी संपादित करें",
  chooseAnAccount: "एक खाता चुनें",
  "Requested to Receive": "प्राप्त करने के लिए अनुरोध किया",
  "Receive With": "के साथ प्राप्त करें",
  "Unknown session": "अज्ञात सत्र",
  transactionId: "लेनदेन आईडी",
  traceId: "ट्रेस आईडी",
  save: "सहेजें",
  fillTheBillingInformation: "कृपया नीचे बिलिंग जानकारी भरें",
  correctionsRequired: "सुधार की आवश्यकता है",
  billingInfo: "बिलिंग जानकारी",
  "Cancelled by client": "ग्राहक द्वारा रद्द किया गया",
  "Your Cards": "आपके कार्ड",
  Done: "किया हुआ",
  redirect: "पुनर्निर्देशन",
  redirectingTo: "के लिए पुनर्निर्देशन",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "लेन-देन सफल नहीं था। हमें खेद है, लेकिन प्रदान किया गया पेपाल खाता किसी अन्य ग्राहक के ट्रेडिंग खाते के अंतर्गत हमारे रिकॉर्ड में दिखाई देता है, यदि आपको अपने खाते से किसी भी सहायता की आवश्यकता है, तो कृपया ग्राहक सेवा से संपर्क करें।",
  "Not supported yet": "अभी तक समर्थित नहीं है",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "माफी, यह प्रतीत होता है कि आपके पास निकासी अनुरोध के लिए उपयोग करने के लिए कोई भुगतान विवरण पंजीकृत नहीं है",
  "Not available gateways found": "उपलब्ध गेटवे नहीं मिले",
  "Token is invalid": "टोकन अमान्य है",
  "Payment method not available. Please contact your site administrator.":
    "भुगतान विधि उपलब्ध नहीं है। कृपया अपने साइट व्यवस्थापक से संपर्क करें।",
  cardIsNotValid: "कार्ड मान्य नहीं है",
  invalidInputFormat: "अमान्य इनपुट प्रारूप। कम से कम {{length}} अक्षर।",
  invalidDateFormat: "अमान्य तिथि प्रारूप।",
  maximumValueIs: "अधिकतम मूल्य है",
  minimumValueIs: "न्यूनतम मान है",
  enterPayoutAmount:
    "कृपया अपने उपलब्ध शेष राशि से कम या उसके बराबर भुगतान राशि दर्ज करें।",
  maxLength2: "अधिकतम लंबाई 2 वर्ण।",
  footer1:
    "कुछ जारी करने वाले बैंकों ने ऑनलाइन मनोरंजन के लिए क्रेडिट कार्ड के उपयोग को प्रतिबंधित कर दिया है। कृपया उपरोक्त तृतीय पक्ष भुगतान विधियों में से किसी एक का उपयोग करने के लिए स्वतंत्र महसूस करें या अपने खाते को निधि देने के लिए हमारे {{customer_support_url}} प्रतिनिधियों से संपर्क करें।\n" +
    "  कृपया ध्यान दें: आपके जारीकर्ता बैंक के आधार पर, क्रेडिट कार्ड लेनदेन को नकद अग्रिम के रूप में माना जा सकता है। {{application_key}} को इस लेनदेन में शामिल किसी भी नकद अग्रिम शुल्क के लिए जिम्मेदार नहीं ठहराया जा सकता है क्योंकि यह क्रेडिट कार्ड के जारीकर्ता बैंक द्वारा स्थापित किया गया है। अधिक जानकारी के लिए अपने बैंक से परामर्श करें।",
  footer2:
    "प्रयास किए गए लेन-देन की सहायता या पुष्टि के लिए कृपया {{customer_support_url}} से संपर्क करें। कृपया ध्यान दें: आपके बैंक के आधार पर इस लेनदेन को नकद अग्रिम माना जा सकता है। {{application_key}} किसी भी नकद भुगतान शुल्क के लिए जिम्मेदार नहीं होगा। इस लेन-देन में शामिल है। लेन-देन की पहचान आपके क्रेडिट कार्ड विवरणी के रूप में की जाएगी जब तक कि आपके लेनदेन की रसीद पर अन्यथा नहीं कहा जाता है। ",
  footer3:
    "सभी भुगतान अनुरोध हमारे {{terms_conditions_url}} के अधीन हैं। यदि आपको सहायता या आगे की जानकारी की आवश्यकता है, तो कृपया {{customer_support_url}} से संपर्क करें। आपका अनुरोध सबमिट होने के बाद, सूचना प्रसंस्करण के लिए हमारे कैशियर विभाग को भेज दी जाएगी। आपको प्राप्त होगा। आपके भुगतान की प्रक्रिया होने पर पुष्टि के रूप में एक ईमेल। हमारी भुगतान समीक्षा के बाद भुगतान राशि और प्रसंस्करण शुल्क (यदि लागू हो) आपके खाते से डेबिट कर दिया जाएगा। ",
  gatewaysTitle: "आप कैसे पैसे देना पंसद करेंगे?",
  gatewaysTitleDeposit: "कृपया जमा विधि चुनें",
  gatewaysTitleWithdrawal: "कृपया वापसी विधि चुनें",
  payInTitle: "क्रेडिट या डेबिट कार्ड से भुगतान करें",
  allPaymentMethods: "सभी भुगतान विधियां",
  submit: "प्रस्तुत",
  useAddressOnFile: "फ़ाइल पर पते का उपयोग करें",
  customerName: "ग्राहक का नाम",
  country: "देश",
  province: "प्रांत",
  city: "सिटी",
  postalCode: "डाक कोड",
  street: "सड़क",
  proceed: "बढ़ना",
  cardNumber: "कार्ड नंबर",
  cardHolder: "कार्ड धारक",
  cardHolderName: "कार्ड धारक का नाम (केवल लैटिन)",
  cardExpires: "कार्ड की समय सीमा समाप्त",
  cvv: "सीवीवी",
  firstName: "पहला नाम",
  lastName: "उपनाम",
  zipCode: "पिन कोड",
  address: "पता",
  state: "राज्य",
  email: "ईमेल",
  phone: "फ़ोन",
  dateOfBirth: "जन्म की तारीख",
  transactionStatus: "लेन-देन की स्थिति",
  trace: "निशान",
  amount: "रकम",
  min: "मिनट",
  max: "अधिकतम",
  overrideLimits: "ओवरराइड की सीमा",
  transaction: "लेन-देन",
  processed: "द्वारा संसाधित",
  transactionRejected: "लेन-देन अस्वीकृत",
  transactionApproved: "लेन-देन स्वीकृत",
  yourTransactionIsPending: "आपका लेन-देन लंबित है",
  transactionAuthorized: "लेन-देन अधिकृत",
  requestAccepted: "अनुरोध स्वीकार",
  status2:
    "आपका निकासी अनुरोध स्वीकार कर लिया गया है। कृपया स्वीकार किया जाए कि व्यावसायिक दिनों के परिचालन घंटों के दौरान आगे प्राधिकरण और प्रसंस्करण निष्पादित किया जाएगा। उस समय के दौरान, आपको पहचान की पुष्टि के लिए कार्यकारी कर्मचारियों से संपर्क किया जा सकता है।",
  unexpectedErrorOccurred: "अनपेक्षित त्रुटि",
  status3:
    "हम क्षमा चाहते हैं, आपके लेनदेन को संसाधित करते समय कुछ गलत हुआ। कृपया नीचे तकनीकी विवरण देखें:",
  tryAgain: "पुनः प्रयास करें",
  somethingWentWrong: "कुछ गलत हो गया...",
  newPayoutRequest: "नया भुगतान अनुरोध",
  makeAnotherPayment: "एक और भुगतान करें",
  profile: "प्रोफ़ाइल",
  justOneMoreStep: "बस एक और कदम...",
  "profile-auth":
    "{{शीर्षक}} का उपयोग करके भुगतान के साथ आगे बढ़ने के लिए कृपया अपना प्रोफ़ाइल पूरा करें",
  payWith: "के साथ भुगतान करें",
  ProceedWith: "साथ आगे बढ़ना",
  vtTitle: "वर्चुअल टर्मिनल:",
  pay: "वेतन",
  deposit: "जमा",
  withdraw: "निकालना",
  withdrawal: "निकासी",
  hello: "नमस्कार,",
  currency: "मुद्रा",
  currentIp: "वर्तमान आईपी:",
  UseCurrentIp: "वर्तमान आईपी का उपयोग करें:",
  UseCustomerIp: "ग्राहक आईपी का उपयोग करें:",
  copyright: "कॉपीराइट",
  chooseAmount: "राशि चुनें",
  cannotBeBlank: "खाली नहीं किया जा सकता।",
  noDepositFree: "कोई जमा शुल्क नहीं। कोई निकासी शुल्क नहीं।",
  next: "आगे",
  backToSite: "वापस साइट पर",
  maximumLength: "अधिकतम लंबाई {{length}} अक्षर",
  minimumLength: "न्यूनतम लंबाई {{length}} वर्ण",
  emailIsNotValid: "ईमेल एक मान्य ईमेल पता नहीं है।",
  phoneIsNotValid: "फोन वैध नहीं है",
  cardHolderInvalid:
    "कार्ड धारक अमान्य है। कार्ड धारक में पहला नाम और अंतिम नाम होना चाहिए।",
  pleaseEnterPayout: "कृपया एक भुगतान राशि दर्ज करें जो इससे कम या बराबर है",
  invalidAmount: "अवैध राशि",
  transactionWillAppear: "आपका लेन-देन आपके बयान पर दिखाई देगा",
  "Customer does not exist": "ग्राहक मौजूद नहीं है",
  "Sorry no available gateways found.": "क्षमा करें उपलब्ध गेटवे नहीं मिले।",
  "Incorrect currency": "गलत मुद्रा",
  "Sorry, not supported payment type":
    "क्षमा करें, समर्थित भुगतान प्रकार नहीं है",
  "Payment method has no payment type":
    "भुगतान विधि का कोई भुगतान प्रकार नहीं है",
  "Incorrect payment type in payment method":
    "भुगतान पद्धति में गलत भुगतान प्रकार",
  "Gateway limit exceeded": "गेटवे की सीमा पार हो गई",
  "Not supported transaction type": "समर्थित लेनदेन प्रकार नहीं",
  "Sorry. Payout with credit card gateway not supported yet":
    "माफ़ करना। क्रेडिट कार्ड गेटवे के साथ भुगतान अभी तक समर्थित नहीं है",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "हम क्षमा चाहते हैं, यह भुगतान विधि निर्दिष्ट भुगतान विवरण के लिए लागू नहीं है। कृपया पृष्ठ पुनः लोड करें और एक अलग राशि, मुद्रा या भुगतान विधि आज़माएँ।",
  "Back to site": "वापस साइट पर",
  "Your transaction has been requested. Please wait for approval.":
    "आपके लेन-देन का अनुरोध किया गया है। कृपया अनुमोदन की प्रतीक्षा करें।",
  "Token expired": "टोकन समाप्त हो गया",
  back: "वापस",
  "Transaction not found.": "लेन-देन नहीं मिला",
  "Continue in the New Window": "नई विंडो में जारी रखें",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "कृपया इस विंडो को बंद न करें। एक नई विंडो में लेन-देन पूरा करने पर आपको वापस रीडायरेक्ट कर दिया जाएगा। सुरक्षित कनेक्शन खोलने वाली नई विंडो नहीं दिख रही है? हम यहां आपके लेन-देन को पूरा करने में आपकी सहायता करने के लिए हैं।",
  "Click to continue": "जारी रखने के लिए चटकाएं",
  "Just One More Step...": "बस एक और कदम...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "आपके द्वारा चुनी गई भुगतान विधि एक नए पृष्ठ में खुली होगी। कृपया अंतिम चरण पर जाने के लिए नीचे 'प्रोसीड' बटन पर क्लिक करें",
  Proceed: "बढ़ना",
  "Opening a Secure Connection...": "एक सुरक्षित कनेक्शन खोलना ...",
  "You will be redirected to complete your transaction in just a moment":
    "आप अपने लेन-देन को कुछ ही समय में पूरा करने के लिए पुनर्निर्देशित हो जाएंगे",
  "Close window": "विंडो बंद करें",
  "Partial approval confirmation": "आंशिक स्वीकृति पुष्टि",
  PartiallyApprovedAmount:
    "आपके कार्ड की शेष राशि में पूरी राशि जमा करने के लिए अपर्याप्त धनराशि है। लेन-देन आंशिक रूप से {{amount}} {{currency}} के लिए स्वीकृत किया गया था।",
  ConfirmPartialDeposit:
    "कृपया आंशिक जमा राशि की पुष्टि करने के लिए 'ओके' पर क्लिक करें या लेनदेन को रद्द करने के लिए 'रद्द करें' पर क्लिक करें। यदि कोई कार्रवाई नहीं की जाती है तो 5 मिनट के बाद जमा की पुष्टि स्वचालित रूप से की जाएगी।",
  fxRateSpreadText: "संकेतित विनिमय दर में सभी शुल्क और शुल्क शामिल हैं।",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "लोड हो रहा है कृपया प्रतीक्षा करें",
};
