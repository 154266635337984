import {getUrl} from "../variables/host";

const amplifyConfig =  {
    API: {
        GraphQL: {
            endpoint: `${getUrl('graphqlDomain')}`,
            region: 'eu-west-1',
            defaultAuthMode: 'lambda',
        }
    }
};

export default amplifyConfig;