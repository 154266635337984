import {determineMethodType} from "../../utils/helper-functions/determine-method-type";
import {filterObject} from "../../utils/helper-functions/filter-object";

const analyzeAdditionalFields = (additional_fields) => {
    let visibleFields = 0;
    let notRequiredFields = [];
    let isDynamicFields = false;
    if (!additional_fields) additional_fields = [];
    
    additional_fields.map(({name, type, required: req, data}) => {
        if (["text", "password", "select", "dynamic"].includes(type)) {
            if (req === 0) notRequiredFields = [...notRequiredFields, name]
            visibleFields++;
        }
        if (type === "html") {
            notRequiredFields = [...notRequiredFields, name]
            if (data) visibleFields++
        }
        if (type === "dynamic") {
            isDynamicFields = true
        }
    });
    return {visibleFields, notRequiredFields, isDynamicFields}
}

const analyzeManualBankWire = (additional_fields = [], currency_list={}, base_currency='', base_amount= '') => {
    let baseInList = false;
    let hasJson = false;
    let manualWireCurrencyList = {};
    let manualWireFieldName = '';
    let manualWireResultMarkdown = '';
    
    if(!additional_fields || additional_fields?.length === 0) {
        console.error("type 'manual_bank_wire' must have an additional_field")
        return {}
    }
    
    additional_fields?.forEach( ({type, name, data}) => {
        if(type === 'json') {
            hasJson = true;
            try {
                const jsonData = JSON.parse(data);
                const jsonDataFiltered = filterObject({obj: jsonData, objName: `${name} additional field`});
                if(Object.keys(jsonDataFiltered || {})?.length > 0) {
                    if(base_amount) {
                        Object.entries(jsonDataFiltered).forEach(([key, val]) => {
                            const rate = currency_list?.[key];
                            if(+base_amount > rate?.min_amount && +base_amount < rate.max_amount) {
                                manualWireCurrencyList[key] = val
                            }
                        })
                    } else {
                        manualWireCurrencyList = jsonDataFiltered;
                    }
                    manualWireFieldName = name;
                    baseInList = !!jsonDataFiltered?.[base_currency]
                } else {
                    console.error(`${name} additional field json not valid or empty`);
                    return {}
                }
            } catch(error) {
                console.error(`${name} additional field json not provided or not valid. Details: ${error}`);
                return {}
            }
        }
        
        if(type === 'html') {
            manualWireResultMarkdown = data;
        }
    });
    
    if(!hasJson) {
        console.error("type 'manual_bank_wire' must have an additional_field with type 'json'")
        return {}
    }
    
    return {manualWireCurrencyList, manualWireFieldName, baseInList, manualWireResultMarkdown}
}

const analyzeCurrentGateway = (profileInfo, payload) => {
    let {additional_fields, payment_information = {}} = payload;
    const {currency_list = {}} = payment_information;
    const {base_currency, base_amount, transaction_type, intent} = profileInfo;
    const baseCurrencyInfo = {
        ...(payment_information?.hasOwnProperty('base_currency') && {currency: payment_information?.base_currency}),
        ...(payment_information?.hasOwnProperty('min_amount') && {min_amount: payment_information?.min_amount}),
        ...(payment_information?.hasOwnProperty('max_amount') && {max_amount: payment_information?.max_amount}),
        ...(payment_information?.hasOwnProperty('crypto') && {crypto: payment_information?.crypto}),
    }
    const array_currency = Object.keys(currency_list);
    const currencyLength = array_currency.length;
    const cryptoList = array_currency.filter((el) => String(currency_list[el]?.crypto) === "1");
    const method = determineMethodType(payload);
    
    return {
        baseInList: !!currency_list?.[base_currency],
        is_auth: method === 'card-method' && transaction_type === "pay-in" && intent === "authorization" && String(base_amount) === "0",
        method, currency_list, currencyLength, cryptoList, baseCurrencyInfo,
        ...analyzeAdditionalFields(additional_fields),
        ...(method === 'mbw-method' && analyzeManualBankWire(additional_fields, currency_list, base_currency, base_amount))
    }
}

export const createGatewayInfo = (state, action) => {
    if (state === undefined) {
        return {
            baseInList: null,
            method: null,
            currency_list: {},
            cryptoList: [],
            currencyLength: null,
            wasteLength: null,
            visibleFields: null,
            notRequiredFields: []
        }
    }
    switch (action.type) {
        case  'CREATE_CURRENT_GATEWAY':
            return analyzeCurrentGateway(state.profileInfo, action.payload);
        case  'CREATE_CURRENT_GATEWAY_FROM_AMOUNT_PAGE':
            return analyzeCurrentGateway(state.profileInfo, action.payload);
        default:
            return state.gatewayInfo;
    }
}
