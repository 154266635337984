import React from 'react';
import Skeleton from '../../../../shared-components/helper-components/Skeleton/Skeleton';
import SkeletonItem from '../../../../shared-components/helper-components/Skeleton/SkeletonItem';
import SkeletonContainer from '../../../../shared-components/helper-components/Skeleton/SkeletonContainer';

const PaymentMethodSkeleton = ({hideSlider = false}) => {
    return (
        <Skeleton>
            {!hideSlider && (
                <>
                    <SkeletonItem width="50%" height="20px"/>
                    <SkeletonContainer flex={true} gap="10px">
                        <SkeletonItem width="100px" height="100px" repeat={6}/>
                    </SkeletonContainer>
                </>
            )}
            <div className={'skeleton-container'}>
                <SkeletonContainer flex={true} width="50%">
                    <SkeletonItem width="100%" height="20px"/>
                    <SkeletonItem width="100%" height="40px" repeat={4}/>
                    <SkeletonItem width="100%" height="20px"/>
                </SkeletonContainer>
                <SkeletonContainer width="50%">
                    <SkeletonItem width="100%" height="20px"/>
                    <SkeletonItem width="100%" height="40px" repeat={2}/>
                    <SkeletonItem width="100%" height="20px"/>
                </SkeletonContainer>
            </div>
            <SkeletonItem width="100%" height="20px"/>
        </Skeleton>
    );
};

export default PaymentMethodSkeleton;
