export const he = {
  "Loading... Please Wait": "בטעינה אנא המתן",
  yourTransactionIsPendingForPayOut: "בקשת המשיכה שלך ממתינה",
  status1:
    "לידיעתך, הבנק המעבד או ספק שירותי התשלום שלך נבדק על העסקה שלך. אם ברצונך לקבל מידע נוסף אודות סטטוס העסקה שלך, פנה לתמיכה לבקשת סיוע במזהה המעקב: {{trace_id}}",
  Status: "סטָטוּס",
  "Deposit Review For Account:": "סקירת הפקדה לחשבון:",
  "New Transaction": "עסקה חדשה",
  Update: "עדכון",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "אמצעי התשלום שבחרת נפתח בעמוד נפרד. לחץ על 'להמשיך' כדי לנווט לחלון דפדפן חדש ולהמשיך בתהליך התשלום.",
  dod_error_text: "עליך לקרוא ולקבל את הצהרת ההפקדה כדי להמשיך",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "עליך לקבל תנאים והגבלות כדי להמשיך בהפקדה שלך",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "אני מאשר שקראתי, ומקבל בזאת את התנאים וההגבלות",
  dod_url: "#",
  dod_url_text: "תנאים",
  dod_text: "אני מאשר שקראתי, ומקבל בזאת את {{dod_url}}",
  transactionSuccessfullyCanceled: "העסקה {{id}} בוטלה בהצלחה",
  "Fee should not exceed 20% of the amount": "האגרה לא תעלה על 20% מהסכום",
  "My Info": "המידע שלי",
  "Billing info": "מידע על חיוב",
  "Withdrawal Requests": "בקשות משיכה",
  "No Withdrawal Requests found": "לא נמצאו בקשות משיכה",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "לְבַטֵל",
  Amount: "כמות",
  "Last Update": "העדכון אחרון",
  "Payment Method": "אמצעי תשלום",
  TID: "זְמַן",
  Action: "פעולה",
  "Confirmation required": "אישור נדרש",
  "Are you sure you want to cancel transaction":
    "האם אתה בטוח שברצונך לבטל את העסקה",
  Yes: "כן",
  No: "לא",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "מינימום מקסימום סכומים",
  "Fee (included)": "תשלום (כלול)",
  "- Amount between": "הסכום בין",
  "I have the Promocode!": "יש לי את הפרומוקוד!",
  "Please enter the Promocode": "אנא הזן את קוד הקידום",
  Promocode: "קוד קופון",
  noSavedTemplates: "לא נשמר קלפים",
  noOptions: "אין אפשרויות",
  "Please confirm your billing details": "אנא אשר את פרטי החיוב שלך",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "עמלת עיבוד",
  Fee: "תַשְׁלוּם",
  Total: 'סה"כ',
  customer_support_link_text: "שירות לקוחות",
  terms_conditions_link_text: "תנאים",
  "Edit Billing Info": "ערוך את פרטי החיוב",
  chooseAnAccount: "בחר חשבון",
  "Requested to Receive": "מבוקש לקבל",
  "Receive With": "קבל עם",
  "Unknown session": "הפעלה לא ידועה",
  transactionId: "מזהה עסקה",
  traceId: "זיהוי עקבות",
  save: "להציל",
  fillTheBillingInformation: "אנא מלא את פרטי החיוב שלהלן",
  correctionsRequired: "חובה לבצע תיקונים",
  billingInfo: "מידע על חיוב",
  "Cancelled by client": "בוטל על ידי הלקוח",
  "Your Cards": "הקלפים שלך",
  Done: "בוצע",
  redirect: "הפניה מחדש",
  redirectingTo: "מפנה מחדש ל",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "העסקה לא הצליחה. אנו מצטערים אך חשבון ה- PayPal שסופק מופיע ברשומות שלנו תחת חשבון המסחר של לקוח אחר. הלקוחות מוגבלים לחשבון פעיל אחד למסחר לקוחות באתר שלנו. כדי לאפשר שימוש בחשבון PayPal זה עם הכניסה שלך, או אם אתה זקוק לעזרה בחשבונך, אנא צור קשר עם שירות הלקוחות.",
  "Not supported yet": "עדיין לא נתמך",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "מתנצל, נראה כי אין לך פרטי תשלום רשומים לשימוש לבקשת משיכה",
  "Not available gateways found": "לא נמצאו שערים זמינים",
  "Token is invalid": "האסימון אינו חוקי",
  "Payment method not available. Please contact your site administrator.":
    "אמצעי תשלום אינו זמין. אנא צור קשר עם מנהל האתר שלך.",
  cardIsNotValid: "הכרטיס אינו תקף",
  chooseAmount: "בחר כמות",
  invalidInputFormat: "פורמט קלט לא חוקי. לפחות {{length}} תווים.",
  invalidDateFormat: "פורמט תאריך לא נכון.",
  maximumValueIs: "הערך המרבי הוא",
  minimumValueIs: "ערך מינימלי הוא",
  enterPayoutAmount: "אנא הכנס סכום תשלום פחות או שווה ליתרה הזמינה שלך.",
  maxLength2: "אורך מרבי 2 תווים.",
  footer1:
    "חלק מהבנקים המנפיקים הגבילו את השימוש בכרטיסי אשראי לבידור מקוון. אל תהסס להשתמש באחת משיטות התשלום החלופיות של צד שלישי לעיל, או צרו קשר עם נציגי {{customer_support_url}} ללא תשלום בכניסה ללא תשלום למימון חשבונך. \n שים לב: בהתאם לבנק המנפיק שלך, ניתן להתייחס לעסקאות בכרטיסי אשראי כאל מקדמה במזומן. {{application_key}} לא יכולה להיות אחראית לעמלות מקדמות מזומניות הכרוכות בעסקה זו מכיוון שזו נקבעת על ידי האשראי. הבנק המנפיק כרטיס. התייעץ עם הבנק שלך לקבלת פרטים נוספים. ",
  footer2:
    "לקבלת סיוע או אישור של ניסיון עסקה אנא פנה אל {{customer_support_url}}. \n שים לב: בהתאם לבנק שלך ניתן יהיה להתייחס לעסקה זו כאל מקדמה במזומן. {{application_key}} לא תהיה אחראית כל עמלות מקדמה במזומן הכרוכות בעסקה זו. העסקה תזוהה בהצהרת כרטיס האשראי שלך כמתאר כרטיסי אשראי אלא אם כן צוין אחרת בקבלת העסקה שלך. ",
  footer3:
    "כל בקשות התשלום כפופות ל {{terms_conditions_url}} שלנו. אם אתה זקוק לסיוע או למידע נוסף, אנא צור קשר עם {{customer_support_url}}. לאחר הגשת בקשתך, המידע יועבר למחלקת הקופאיות שלנו לעיבוד . תקבל אימייל לאישור בעת עיבוד התשלום שלך. סכום התשלום ודמי העיבוד (אם רלוונטיים) יחויבו מחשבונך לאחר בדיקת התשלום שלנו. ",
  gatewaysTitle: "איך תרצה לשלם?",
  gatewaysTitleDeposit: "אנא בחר בשיטת ההפקדה",
  gatewaysTitleWithdrawal: "בחר שיטת נסיגה",
  payInTitle: "שלם באמצעות כרטיס אשראי או כרטיס חיוב",
  allPaymentMethods: "כל אמצעי התשלום",
  submit: "שלח",
  useAddressOnFile: "השתמש בכתובת בקובץ",
  customerName: "שם לקוח",
  country: "מדינה",
  province: "מחוז",
  city: "עיר",
  postalCode: "מיקוד",
  street: "רחוב",
  proceed: "להמשיך",
  cardNumber: "מספר כרטיס",
  cardHolder: "מחזיק כרטיס",
  cardHolderName: "שם מחזיק כרטיס",
  cardExpires: "התוקף פג",
  cvv: "CVV",
  firstName: "שם פרטי",
  lastName: "שם משפחה",
  zipCode: "מיקוד",
  address: "כתובת",
  state: "מדינה",
  email: "אימייל",
  phone: "מכשיר טלפון",
  dateOfBirth: "תאריך לידה",
  transactionStatus: "סטטוס עסקה",
  trace: "עקבות",
  amount: "כמות",
  min: "דקות",
  max: "מקסימום",
  overrideLimits: "עקף גבולות",
  transaction: "עסקה",
  processed: "מעובד",
  transactionRejected: "העסקה נדחתה",
  transactionApproved: "אושרה עסקה",
  yourTransactionIsPending: "העסקה שלך ממתינה",
  transactionAuthorized: "עסקאות מורשות",
  requestAccepted: "בקשה התקבלה",
  status2:
    "בקשת הנסיגה שלך התקבלה. אנא שים לב כי הרשאה ועיבוד נוספים יבוצעו בשעות הפעילות של ימי עסקים. במהלך תקופה זו יתכן שתפנה אליך על ידי הצוות המנהל לצורך אישור זהות.",
  unexpectedErrorOccurred: "אירעה שגיאה לא צפויה",
  status3:
    "אנו מתנצלים, משהו השתבש במהלך עיבוד העסקה שלך. אנא מצא את הפרטים הטכניים להלן:",
  tryAgain: "נסה שוב",
  somethingWentWrong: "משהו השתבש...",
  newPayoutRequest: "בקשת תשלום חדשה",
  makeAnotherPayment: "בצע תשלום נוסף",
  profile: "פרופיל",
  justOneMoreStep: "רק עוד צעד אחד...",
  "profile-auth": "מלא את הפרופיל שלך בכדי להמשיך בתשלום באמצעות {{title}}",
  payWith: "לשלם עם",
  ProceedWith: "המשך ב",
  vtTitle: "מסוף וירטואלי:",
  pay: "שלם",
  deposit: "הפקדה",
  withdraw: "למשוך",
  withdrawal: "נסיגה",
  hello: "שלום,",
  currency: "מטבע",
  currentIp: "IP נוכחי:",
  UseCurrentIp: "השתמש ב- IP הנוכחי:",
  UseCustomerIp: "השתמש ב- IP של לקוחות:",
  copyright: "זכויות יוצרים",
  cannotBeBlank: "לא יכול להיות ריק.",
  noDepositFree: "ללא דמי פיקדון. ללא דמי משיכה.",
  next: "הבא",
  backToSite: "חזרה לאתר",
  maximumLength: "אורך מקסימלי {{length}} תווים",
  minimumLength: "אורך מינימלי {{length}} תווים",
  emailIsNotValid: 'אימייל אינו כתובת דוא"ל חוקית.',
  phoneIsNotValid: "הטלפון אינו תקף",
  cardHolderInvalid:
    "בעל הכרטיס אינו חוקי. על בעל הכרטיס להכיל את השם הפרטי ואת שם המשפחה.",
  pleaseEnterPayout: "אנא הכנס סכום תשלום פחות או שווה",
  invalidAmount: "סכום לא חוקי",
  transactionWillAppear: "העסקה שלך תופיע בהצהרה שלך",
  "Customer does not exist": "הלקוח אינו קיים",
  "Sorry no available gateways found.": "מצטערים, לא נמצאו שערים זמינים.",
  "Incorrect currency": "מטבע שגוי",
  "Sorry, not supported payment type": "מצטערים, לא נתמך בסוג התשלום",
  "Payment method has no payment type": "לשיטת התשלום אין סוג תשלום",
  "Incorrect payment type in payment method": "סוג תשלום שגוי באמצעי התשלום",
  "Gateway limit exceeded": "חריגה ממגבלת השער",
  "Not supported transaction type": "לא תומך בסוג העסקה",
  "Sorry. Payout with credit card gateway not supported yet":
    "מצטער. התשלום עם שער כרטיס האשראי עדיין לא נתמך",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "אנו מתנצלים, אמצעי תשלום זה אינו חל על פרטי התשלום שצוינו. טען מחדש את הדף ונסה סכום אחר, מטבע או אמצעי תשלום.",
  "Back to site": "חזרה לאתר",
  "Your transaction has been requested. Please wait for approval.":
    "העסקה שלך התבקשה. אנא המתן לאישור.",
  "Token expired": "פג תוקף האסימון",
  back: "חזור",
  "Transaction not found.": "העסקה לא נמצאה.",
  "Continue in the New Window": "המשך בחלון החדש",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "אנא אל תסגור חלון זה. תנותב בחזרה עם סיום העסקה בחלון חדש. אינך רואה חלון חדש הפותח חיבור מאובטח? אנו כאן כדי לעזור לך להשלים את העסקה שלך.",
  "Click to continue": "לחץ כדי להמשיך",
  "Just One More Step...": "רק עוד צעד אחד...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    ".אמצעי התשלום שבחרת יהיה פתוח בדף חדש. אנא לחץ על כפתור 'להמשיך' למטה כדי לעבור לשלב הסופי",
  Proceed: "להמשיך",
  "Opening a Secure Connection...": "פותח חיבור מאובטח ...",
  "You will be redirected to complete your transaction in just a moment":
    "תנותב להשלמת העסקה שלך תוך רגע בלבד",
  "Close window": "חלון סגור",
  "Partial approval confirmation": "אישור חלקי לאישור",
  PartiallyApprovedAmount:
    "ליתרת הכרטיס שלך אין מספיק כסף לזיכוי הסכום המלא. העסקה אושרה חלקית תמורת {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "אנא לחץ על 'אישור' כדי לאשר את סכום ההפקדה החלקית או 'ביטול' לביטול העסקה. הפיקדון יאושש באופן אוטומטי לאחר 5 דקות אם לא תתבצע כל פעולה.",
  fxRateSpreadText: "שער החליפין המצוין כולל את כל העמלות והחיובים.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "בטעינה אנא המתן",
};
