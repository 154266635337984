const changeBaseAmount = (state, action) => {
    if (state === undefined) {
        return {}
    }
    switch (action.type) {
        case  'CHANGE_BASE_AMOUNT':
            return {...state.changedAmount, ...action.payload};
        default:
            return state.changedAmount;
    }
}

export { changeBaseAmount }