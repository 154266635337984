import React from 'react'
import compose from "utils/helper-functions/compose";
import { withI18N } from "shared-components/hoc-components";
import './error-page.sass'
import { withRouter } from "react-router";
import Button from '@pano-project/components/Button/Button';
import { clientClickEvent } from "utils/custom-cashier-events/client-click-event";
import IconError from '@pano-project/assets/icons/IconError';
import IconArrow from '@pano-project/assets/icons/IconArrow';
import MainLayout from "@pano-project/components/Layout/MainLayout/MainLayout";

const ErrorPage = (props) => {
    const {wrapper = false, errorsInfo, langBack, history} = props;

    return (
        <>
            {
                wrapper ?
                    <MainLayout>
                        <ErrorPageContent errorsInfo={errorsInfo} langBack={langBack} history={history}/>
                    </MainLayout> :
                    <ErrorPageContent errorsInfo={errorsInfo} langBack={langBack} history={history}/>
            }
        </>
    )
};

const ErrorPageContent = ({errorsInfo, langBack, history}) => {
    const {
        errorType, back = false,
        submit_action = '',
        submit_name = '',
        generateErrorMessage,
    } = errorsInfo;

    return (
        <div className={'page-container error-container'}>
            <div className={'error-back-button'}>
                {
                    errorType && back ?
                        <div
                            className={'back'}
                            onClick={() => {
                                clientClickEvent("back_button");
                                history.push("gateways");
                            }}>
                            <IconArrow /> {langBack('All Payment Methods')}
                        </div>
                        : ""
                }
            </div>
            <div className={`error-page ${errorType ?'error-' + errorType : ''}`}>
                <div className={'error-icon'}><IconError /></div>
                <div className={'title'}>{generateErrorMessage(errorsTitle, errorType)}</div>
                <div className={'description'}>
                    { errors[errorType]?.description &&
                        <p>{langBack(errors[errorType]?.description)}</p>
                    }
                    {langBack('If you require assistance or technical support, please contact customer support.')}
                </div>
                {
                    !submit_action ? "" :
                        <a href={submit_action} onClick={() => clientClickEvent('back_to_website_button')}><Button
                            variant="fourfold" className={'button'}>{submit_name}</Button></a>
                }
            </div>
        </div>

    )
}


const errors = {
    1: {
        title: 'Payment method not available.',
        description: 'Please contact your site administrator.',
        message: 'Payment method not available. Please contact your site administrator.'
    },
    2: {
        title: 'Token is invalid',
        description: '',
        message: 'Token is invalid'
    },
    3: {
        title: 'Not available gateways found',
        description: 'Not available gateways found',
        message: 'Not available gateways found',
    },
    4: {
        title: 'Not supported yet',
        description: 'Not supported yet',
        message: 'Not supported yet',
    },
    5: {
        title: "Something went wrong.",
        description: "Apologies, it appears that you don't have any payment details registered to use for withdrawal request",
        message: "Apologies, it appears that you don't have any payment details registered to use for withdrawal request",
    },
    6: {
        title: 'Unknown session',
        description: '',
        message: 'Unknown session',
    },
    7: {
        title: 'Session expired',
        description: '',
        message: 'Session expired',
    },
    8: {
        title: 'Something went wrong.',
        description: 'Please contact your system administrator (Code: 101)',
        message: 'Something went wrong. Please contact your system administrator (Code: 101)'
    },
    9: {
        title: 'Something went wrong.',
        description: 'For the combination of card number, amount and currency there are no available payment gateways. Please try another credit card or select an alternative payment method.',
        message: 'For the combination of card number, amount and currency there are no available payment gateways. Please try another credit card or select an alternative payment method.',
    },
    10: {
        title: 'Can\'t get session information',
        description: '',
        message: 'Can\'t get session information'
    }
};

const errorsTitle = Object.keys(errors)?.map((errorType) => {
    return {[errorType]: errors[errorType]?.title}
})

export default compose(
    withRouter, withI18N
)(ErrorPage)
