const mediaQueryObj = window.matchMedia('(prefers-color-scheme: dark)');

export const isDarkSystemAppearance = mediaQueryObj.matches;

export const themeAppearanceMode = (appearance, cashier_theme) => {
    return cashier_theme === 'dark-theme' || appearance === 'dark' || (appearance === 'system' && isDarkSystemAppearance) ? 'dark-mode' : 'light-mode'
}

export const themePreviewMode = (mode) => {
    return mode === 'theme-preview'
}
