export const transactions = [
        {
            amount: 12,
            currency: "USD",
            date: "22/10/2020 11:13:05 AM",
            gateway: "Credit Card",
            trace_id: 1,
        },
        {
            amount: 123,
            currency: "USD",
            date: "22/10/2020 11:13:05 AM",
            gateway: "PayPal",
            trace_id: 2,
        },
    {
        amount: 123,
        currency: "USD",
        date: "22/10/2020 11:13:05 AM",
        gateway: "PayPal",
        trace_id: 3,
    },
    {
        amount: 123,
        currency: "USD",
        date: "22/10/2020 11:13:05 AM",
        gateway: "PayPal",
        trace_id: 4,
    },
    {
        amount: 123,
        currency: "USD",
        date: "22/10/2020 11:13:05 AM",
        gateway: "PayPal",
        trace_id: 5,
    },
    {
        amount: 123,
        currency: "USD",
        date: "22/10/2020 11:13:05 AM",
        gateway: "PayPal",
        trace_id: 6,
    },
    {
        amount: 123,
        currency: "USD",
        date: "22/10/2020 11:13:05 AM",
        gateway: "PayPal",
        trace_id: 7,
    },
    {
        amount: 123,
        currency: "USD",
        date: "22/10/2020 11:13:05 AM",
        gateway: "PayPal",
        trace_id: 8,
    },
    {
        amount: 123,
        currency: "USD",
        date: "22/10/2020 11:13:05 AM",
        gateway: "PayPal",
        trace_id: 9,
    }
    ]

export const removeTransactions = {
    status:0
}
