export const pt = {
  "Loading... Please Wait": "Carregando, por favor espere",
  yourTransactionIsPendingForPayOut: "Seu pedido de retirada está pendente",
  status1:
    "Esteja ciente de que sua transação está sendo analisada pelo banco de processamento ou provedor de serviços de pagamento. Se desejar obter mais informações sobre o status de sua transação, entre em contato com o suporte para solicitar assistência no Trace ID: {{trace_id}}",
  Status: "Status",
  "Deposit Review For Account:": "Revisão de depósito para conta:",
  "New Transaction": "Nova Transação",
  Update: "Atualizar",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "O método de pagamento que você selecionou é aberto em uma página separada. Clique em 'CONTINUAR' para navegar para uma nova janela do navegador e continuar o processo de pagamento.",
  dod_error_text:
    "Você deve ler e aceitar a Declaração de Depósito para prosseguir",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Você deve aceitar os Termos e Condições para prosseguir com seu depósito",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Eu reconheço que li e aceito os Termos e Condições",
  dod_url: "#",
  dod_url_text: "Termos e Condições",
  dod_text: "Eu reconheço que li e aceito os {{dod_url}}",
  transactionSuccessfullyCanceled:
    "A transação {{id}} foi cancelada com sucesso",
  "Fee should not exceed 20% of the amount":
    "A taxa não deve exceder 20% do valor",
  "My Info": "Minha informação",
  "Billing info": "Informação de pagamento",
  "Withdrawal Requests": "Pedidos de retirada",
  "No Withdrawal Requests found": "Nenhum pedido de retirada encontrado",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Cancelar",
  Amount: "Montante",
  "Last Update": "Última atualização",
  "Payment Method": "Forma de pagamento",
  TID: "TEMPO",
  Action: "Açao",
  "Confirmation required": "Necessária confirmação",
  "Are you sure you want to cancel transaction":
    "Tem certeza que deseja cancelar a transação",
  Yes: "sim",
  No: "Não",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Quantidades mín. Máx.",
  "Fee (included)": "Taxa (incluída)",
  "- Amount between": "- Quantidade entre",
  "I have the Promocode!": "Eu tenho o Promocode!",
  "Please enter the Promocode": "Insira o código promocional",
  Promocode: "Código promocional",
  noSavedTemplates: "Nenhum cartão salvo",
  noOptions: "Sem opções",
  "Please confirm your billing details":
    "Por favor, confirme seus detalhes de faturamento",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Taxa de processamento",
  Fee: "Taxa",
  Total: "Total",
  customer_support_link_text: "Suporte ao cliente",
  terms_conditions_link_text: "Termos e Condições",
  "Edit Billing Info": "Escolha uma conta",
  chooseAnAccount: "Escolha uma conta",
  "Requested to Receive": "Solicitado para receber",
  "Receive With": "Receba com",
  "Unknown session": "Sessão desconhecida",
  transactionId: "ID da transação",
  traceId: "ID de rastreio",
  save: "Salve",
  fillTheBillingInformation: "Preencha as informações de cobrança abaixo",
  correctionsRequired: "correções necessárias",
  billingInfo: "Informação de pagamento",
  "Cancelled by client": "Cancelado pelo cliente",
  "Your Cards": "Seus Cartões",
  Done: "Feito",
  redirect: "Redirecionar",
  redirectingTo: "Redirecionando para",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "A transação não foi bem-sucedida. Infelizmente a conta do PayPal fornecida parece estar ligada à conta de investimentos de outro cliente nos nossos registros. Os clientes estão limitados a uma única conta de investimento no nosso site. Para permitir que esta conta do PayPal seja usada com o seu login ou se precisar de qualquer assistência, entre em contato com o atendimento ao consumidor.",
  "Not supported yet": "Ainda não suportado",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Desculpas, parece que você não possui detalhes de pagamento registrados para usar na solicitação de retirada",
  "Not available gateways found": "Gateways não disponíveis encontrados",
  "Token is invalid": "O token é inválido",
  "Payment method not available. Please contact your site administrator.":
    "Forma de pagamento não disponível. Entre em contato com o administrador do site.",
  cardIsNotValid: "O cartão não é válido",
  invalidInputFormat:
    "Formato de entrada inválido. Pelo menos {{length}} caracteres.",
  invalidDateFormat: "Formato de data inválido.",
  maximumValueIs: "O valor máximo é",
  minimumValueIs: "O valor mínimo é",
  chooseAmount: "Escolha quantidade",
  enterPayoutAmount:
    "Insira um valor de pagamento menor ou igual ao seu saldo disponível.",
  maxLength2: "Comprimento máximo de 2 caracteres.",
  footer1:
    "Alguns bancos emissores restringiram o uso de cartões de crédito para entretenimento online. Sinta-se à vontade para usar um dos métodos alternativos de pagamento de terceiros acima ou entre em contato com nossos representantes de {{customer_support_url}} gratuitamente em N / A para financiar sua conta. \n Observação: dependendo do banco emissor, as transações de cartão de crédito podem ser tratadas como um adiantamento em dinheiro. {{application_key}} não pode ser responsabilizado por quaisquer taxas de adiantamento em dinheiro envolvidas nesta transação, pois isso é estabelecido pelo crédito banco emissor do cartão. Consulte seu banco para obter mais detalhes. ",
  footer2:
    "Para obter assistência ou confirmação de uma tentativa de transação, entre em contato com {{customer_support_url}}. \n Observação: Dependendo do seu banco, esta transação pode ser tratada como um adiantamento em dinheiro. {{application_key}} não será responsável por quaisquer taxas de adiantamento de dinheiro envolvidas nesta transação. A transação será identificada no extrato do seu cartão de crédito como Descritor do Cartão de Crédito, salvo indicação em contrário no recibo da transação. ",
  footer3:
    "Todos os pedidos de pagamento estão sujeitos aos nossos {{terms_conditions_url}}. Se necessitar de assistência ou mais informações, contacte {{customer_support_url}}. Assim que o seu pedido for enviado, as informações são encaminhadas para o nosso departamento de caixa para processamento . Você receberá um e-mail de confirmação quando seu pagamento for processado. O valor do pagamento e a taxa de processamento (se aplicável) serão debitados de sua conta após nossa análise de pagamento. ",
  gatewaysTitle: "Como você gostaria de pagar?",
  gatewaysTitleDeposit: "Por favor, escolha o método de depósito",
  gatewaysTitleWithdrawal: "Por favor, escolha o método de retirada",
  payInTitle: "Pagar com cartão de crédito ou débito",
  allPaymentMethods: "todos os métodos de pagamento",
  submit: "Enviar",
  useAddressOnFile: "Usar endereço no arquivo",
  customerName: "nome do cliente",
  country: "País",
  province: "Província",
  city: "Cidade",
  postalCode: "Código postal",
  street: "Rua",
  proceed: "Continuar",
  cardNumber: "Número do cartão",
  cardHolder: "Titular do cartão",
  cardHolderName: "Nome do Titular",
  cardExpires: "O cartão expira",
  cvv: "CVV",
  firstName: "Primeiro nome",
  lastName: "Último nome",
  zipCode: "Código postal",
  address: "Endereço",
  state: "Estado",
  email: "O email",
  phone: "telefone",
  dateOfBirth: "Data de nascimento",
  transactionStatus: "Status da transação",
  trace: "Vestígio",
  amount: "Montante",
  min: "min",
  max: "max",
  overrideLimits: "Substituir limites",
  transaction: "Transação",
  processed: "Processado",
  transactionRejected: "Transação rejeitada",
  transactionApproved: "Transação aprovada",
  yourTransactionIsPending: "Sua transação está pendente",
  transactionAuthorized: "Transação autorizada",
  requestAccepted: "Pedido aceito",
  status2:
    "Sua solicitação de retirada foi aceita. Tenha em atenção que mais autorização e processamento serão executados durante o horário de funcionamento dos dias úteis. Durante esse período, você pode ser contatado pela equipe executiva para confirmação da identidade.",
  unexpectedErrorOccurred: "Ocorreu um erro inesperado",
  status3:
    "Pedimos desculpas. Ocorreu um erro ao processar sua transação. Encontre os detalhes técnicos abaixo: ",
  tryAgain: "Tente novamente",
  somethingWentWrong: "Algo deu errado ...",
  newPayoutRequest: "Nova solicitação de pagamento",
  makeAnotherPayment: "Efetuar outro pagamento",
  profile: "Perfil",
  justOneMoreStep: "Só mais um passo...",
  "profile-auth":
    "Por favor, complete seu perfil para prosseguir com o pagamento usando {{title}}",
  payWith: "Pagar com",
  ProceedWith: "Proceder com",
  vtTitle: "Terminal virtual: ",
  pay: "Pagar",
  deposit: "Depósito",
  withdraw: "Retirar",
  withdrawal: "Retirado",
  hello: "Olá,",
  currency: "Moeda",
  currentIp: "IP atual: ",
  UseCurrentIp: "Use o IP atual: ",
  UseCustomerIp: "Use o IP do cliente: ",
  copyright: "direito autoral",
  cannotBeBlank: "Nao pode estar em branco.",
  noDepositFree: "Sem taxa de depósito. nenhuma taxa de retirada.",
  next: "próximo",
  backToSite: "Voltar ao site",
  maximumLength: "Comprimento máximo de {{length}} caracteres",
  minimumLength: "Comprimento mínimo de {{length}} caracteres",
  emailIsNotValid:
    "El correo electrónico no es una dirección de correo electrónico válida",
  phoneIsNotValid: "El teléfono no es válido",
  cardHolderInvalid:
    "El titular de la tarjeta no es válido. El titular de la tarjeta debe contener el nombre y el apellido",
  pleaseEnterPayout: "Ingrese un monto de pago menor o igual que",
  invalidAmount: "Cantidad no válida",
  transactionWillAppear: "transação aparecerá",
  "Customer does not exist": "O cliente não existe",
  "Sorry no available gateways found.":
    "Desculpe, não foram encontrados gateways disponíveis.",
  "Incorrect currency": "Moeda incorreta",
  "Sorry, not supported payment type":
    "Desculpe, tipo de pagamento não suportado",
  "Payment method has no payment type":
    "A forma de pagamento não possui tipo de pagamento",
  "Incorrect payment type in payment method":
    "Tipo de pagamento incorreto na forma de pagamento",
  "Gateway limit exceeded": "Limite do gateway excedido",
  "Not supported transaction type": "Tipo de transação não suportado",
  "Sorry. Payout with credit card gateway not supported yet":
    "Desculpa. Pagamento com gateway de cartão de crédito ainda não suportado",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Lamentamos, mas este método de pagamento não é aplicável aos detalhes de pagamento especificados. Atualize a página e tente um valor, moeda ou método de pagamento diferente.",
  "Back to site": "Voltar ao site",
  "Your transaction has been requested. Please wait for approval.":
    "Sua transação foi solicitada. Aguarde aprovação.",
  "Token expired": "Token expirado",
  back: "de volta",
  "Transaction not found.": "Transação não encontrada.",
  "Continue in the New Window": "Continue na nova janela",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Por favor, não feche esta janela. Você será redirecionado de volta ao concluir a transação em uma nova janela. Não vê uma nova janela abrindo uma conexão segura? Estamos aqui para ajudá-lo a concluir sua transação.",
  "Click to continue": "Clique para continuar",
  "Just One More Step...": "Só mais um passo...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "O método de pagamento que você selecionou será aberto em uma nova página. Clique no botão 'CONTINUAR' abaixo para navegar para a etapa final.",
  Proceed: "Continuar",
  "Opening a Secure Connection...": "Abrindo uma conexão segura ...",
  "You will be redirected to complete your transaction in just a moment":
    "Você será redirecionado para concluir sua transação em apenas um momento",
  "Close window": "Janela fechada",
  "Partial approval confirmation": "Confirmação de aprovação parcial",
  PartiallyApprovedAmount:
    "O saldo do cartão não tem fundos suficientes para creditar o valor total. A transação foi parcialmente aprovada por {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Clique em 'OK' para confirmar o valor do depósito parcial ou em 'CANCELAR' para cancelar a transação. O depósito será confirmado automaticamente após 5 minutos se nenhuma ação for tomada.",
  fxRateSpreadText:
    "A taxa de câmbio indicada inclui todas as taxas e encargos.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Carregando, por favor espere",
};
