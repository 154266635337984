import {
    hexToHsla, hslaToRgba
} from 'colour-codes';
export const createStyles = (styles, id = '', position = '') => {
    const element = document.createElement("style");
    if (id) element.setAttribute('data-id', id);
    const first_style = document.head.querySelector('style');
    if (position === 'top' && first_style) {
        document.head.insertBefore(element, first_style);
    } else {
        document.head.appendChild(element);
    }
    element.innerHTML = styles
}

export const setVariablesStyle = (variables, variablesSelector, stylesId) => {
    const styleId = `${stylesId ?? 'variables'}`;
    const styles = document.head.querySelector(`[data-id='${styleId}']`);
    const variablesStyles = `${variablesSelector ?? ':root'} {
                                        ${variables}
                               }`
    
    if (styles) {
        styles.remove();
        createStyles(variablesStyles, styleId, 'top');
    } else {
        createStyles(variablesStyles, styleId, 'top');
    }
}

export const createColorPaletteVariables = (baseColor = [], paletteObj = {}) => {
    const [baseColorKey, baseColorValue] = baseColor;
    const baseHslColor = hexToHSL(baseColorValue);
    let colorPalette = `--${baseColorKey}:${baseColorValue};`
    Object.keys(paletteObj).forEach((variableName) => {
        const colorHslaObj = {...baseHslColor, ...paletteObj[variableName]};
        const variable = createColorVariable(variableName, colorHslaObj)
        colorPalette += variable;
        if(baseColorKey === 'primary_active') {
            if(variableName === 'primary_hover') {
               const textVariable = createTextColorVariable('primary_hover_text', colorHslaObj);
                colorPalette += textVariable;
            }
            if(variableName === 'primary_tapped') {
                const textVariable = createTextColorVariable('primary_tapped_text', colorHslaObj);
                colorPalette += textVariable;
            }
        }
    })
    return colorPalette;
}

const createColorVariable = (variableName = '', hslObj = {}) => {
    const colorHsla = convertHslArrayToString(hslObj);
    return `--${variableName}:${colorHsla};`;
}
const convertHslArrayToString = (hslArray = {}) => {
    const {h, s, l, a} = hslArray;
    return `hsla(${h}${h > 0 ? '' : 'deg'}, ${s}%, ${l}%, ${a})`;
}

const hexToHSL = (color) => {
    const {hue, saturation, lightness, alpha} = hexToHsla(color);
    return {h: hue.toFixed(0), s: saturation.toFixed(0), l: lightness.toFixed(0), a: alpha.toFixed(0)};
}

export const createTextColorVariable = (variableName = '', bgColor) => {
    const hsla = convertHslArrayToString(bgColor);
    const rgba = hslaToRgba(hsla);
    const {red, green, blue} = rgba;
    const perceivedLightness =  (red * 0.2126 +  green *  0.7152 + blue *  0.0722) / 255;
    return `--${variableName}: ${perceivedLightness >= 0.5 ? '#121212' : '#F2F2F2'};`;
}
export const getThemeEditorSettingsVariables = (themeEditorObj = {}) => {
    let styleString = '';
    Object.keys(themeEditorObj).forEach((variableName) => {
        styleString += `--${variableName}:${themeEditorObj[variableName]};`;
    })
    return styleString
}

export const defineFontFamily = (fontName = '') => {
    const serifFonts = ['Bitter', 'Hahmlet', 'Lora', 'Noto Serif', 'Pridi', 'PT Serif', 'Roboto Slab', 'Zen Maru Gothic'];
    const monospaceFonts = ['Inconsolata', 'M PLUS 1 Code', 'Ubuntu Mono'];
    const fontType = serifFonts.includes(fontName) ? 'serif' : monospaceFonts.includes(fontName) ? 'monospace' : 'sans-serif';
    return !fontName || fontName === 'default' ? 'Inter, sans-serif' : `${fontName}, ${fontType}`;
}

export const defineBorderStyle = (style) => {
    switch (style) {
        case 'rounded':
            return '8px'
        case 'sharp':
            return '0'
        case 'pill':
            return '20px'
        default:
            return '8px'
    }
}
