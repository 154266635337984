export const ms = {
  "Loading... Please Wait": "Muat turun sila tunggu",
  yourTransactionIsPendingForPayOut: "Permintaan Pengeluaran Anda Tertunda",
  status1:
    "Perlu diketahui bahawa transaksi anda sedang disemak oleh bank pemprosesan atau penyedia perkhidmatan pembayaran. Sekiranya anda menginginkan lebih banyak maklumat mengenai status transaksi anda, hubungi sokongan untuk meminta bantuan pada Trace ID: {{trace_id}}",
  Status: "Status",
  "Deposit Review For Account:": "Semakan Deposit Untuk Akaun:",
  "New Transaction": "Transaksi Baru",
  Update: "Kemas kini",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Kaedah pembayaran yang anda pilih akan dibuka di halaman yang berasingan. Klik 'Teruskan' untuk menavigasi ke tetingkap penyemak imbas baru dan teruskan proses pembayaran.",
  dod_error_text:
    "Anda mesti membaca dan menerima Deklarasi Deposit untuk meneruskan",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Anda mesti menerima Terma dan Syarat untuk meneruskan deposit anda",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Saya mengakui bahawa saya telah membaca, dan dengan ini menerima Terma dan Syarat",
  dod_url: "#",
  dod_url_text: "Terma dan syarat",
  dod_text:
    "Saya mengakui bahawa saya telah membaca, dan dengan ini menerima {{dod_url}}",
  transactionSuccessfullyCanceled: "Transaksi {{id}} berjaya dibatalkan",
  "Fee should not exceed 20% of the amount":
    "Bayaran tidak boleh melebihi 20% dari jumlahnya",
  "My Info": "Maklumat Saya",
  "Billing info": "Maklumat pengebilan",
  "Withdrawal Requests": "Permintaan Pengeluaran",
  "No Withdrawal Requests found": "Permintaan Pengeluaran tidak dijumpai",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Batal",
  Amount: "Jumlah",
  "Last Update": "Kemaskini terakhir",
  "Payment Method": "Kaedah Pembayaran",
  TID: "MASA",
  Action: "Tindakan",
  "Confirmation required": "Pengesahan diperlukan",
  "Are you sure you want to cancel transaction":
    "Adakah anda pasti mahu membatalkan transaksi",
  Yes: "Ya",
  No: "Tidak",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Jumlah Min-Max",
  "Fee (included)": "Yuran (termasuk)",
  "- Amount between": "- Jumlah antara",
  "I have the Promocode!": "Saya ada kod Promosi!",
  "Please enter the Promocode": "Sila masukkan kod Promosi",
  Promocode: "Kod promosi",
  noSavedTemplates: "Tiada Kad Disimpan",
  noOptions: "Tiada pilihan",
  "Please confirm your billing details": "Sila sahkan butiran pengebilan anda",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Bayaran pemprosesan",
  Fee: "Bayaran",
  Total: "Jumlah",
  customer_support_link_text: "Sokongan pengguna",
  terms_conditions_link_text: "Terma dan syarat",
  "Edit Billing Info": "Edit Maklumat Pengebilan",
  chooseAnAccount: "Pilih akaun",
  "Requested to Receive": "Diminta untuk Menerima",
  "Receive With": "Terima Dengan",
  "Unknown session": "Sesi tidak diketahui",
  transactionId: "ID Transaksi",
  traceId: "Jejak ID",
  save: "Jimat",
  fillTheBillingInformation: "Sila isi maklumat pengebilan di bawah",
  correctionsRequired: "pembetulan diperlukan",
  billingInfo: "Maklumat pengebilan",
  "Cancelled by client": "Dibatalkan oleh pelanggan",
  "Your Cards": "Kad Anda",
  Done: "Selesai",
  redirect: "Ubah hala",
  redirectingTo: "Mengalihkan ke",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Urus niaga tidak berjaya. Kami minta maaf tetapi akaun PayPal yang disediakan muncul dalam rekod kami di bawah akaun perdagangan pelanggan lain.",
  "Not supported yet": "Belum disokong",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Maaf, nampaknya anda tidak mempunyai butiran pembayaran yang didaftarkan untuk digunakan untuk permintaan penarikan",
  "Not available gateways found": "Gerbang tidak dijumpai",
  "Token is invalid": "Token tidak sah",
  "Payment method not available. Please contact your site administrator.":
    "Kaedah pembayaran tidak tersedia. Sila hubungi pentadbir laman web anda.",
  cardIsNotValid: "Kad tidak sah",
  invalidInputFormat:
    "Format input tidak sah. Sekurang-kurangnya {{length}} aksara.",
  invalidDateFormat: "Format tarikh tidak sah.",
  maximumValueIs: "Nilai maksimum adalah",
  minimumValueIs: "Nilai minimum ialah",
  enterPayoutAmount:
    "Sila masukkan Jumlah pembayaran kurang dari atau sama dengan Baki yang Ada.",
  maxLength2: "Panjang maksimum 2 aksara.",
  footer1:
    "Beberapa bank penerbit telah melarang penggunaan kad kredit untuk hiburan dalam talian. Jangan ragu untuk menggunakan salah satu kaedah pembayaran pihak ketiga alternatif di atas atau hubungi {{customer_support_url}} Perwakilan kami bebas tol di N / A untuk membiayai akaun anda.\n" +
    " Harap Perhatikan: Bergantung pada bank penerbit anda, transaksi kad kredit dapat dianggap sebagai pendahuluan tunai. {{application_key}} tidak boleh bertanggung jawab atas sebarang bayaran pendahuluan tunai yang terlibat dalam transaksi ini kerana ini ditentukan oleh bank penerbit kad kredit. Rujuk dengan bank anda untuk maklumat lebih lanjut.",
  footer2:
    "Untuk bantuan atau pengesahan percubaan transaksi, sila hubungi {{customer_support_url}}.\n" +
    " Perhatian: Bergantung pada bank anda, transaksi ini dapat dianggap sebagai pendahuluan tunai. {{application_key}} tidak akan bertanggungjawab untuk sebarang bayaran pendahuluan tunai yang terlibat dalam transaksi ini. Transaksi akan dikenal pasti pada penyata kad kredit anda sebagai Penerangan Kad Kredit kecuali dinyatakan sebaliknya pada resit transaksi anda.",
  footer3:
    "Semua permintaan pembayaran tertakluk kepada {{terms_conditions_url}} kami. Sekiranya anda memerlukan bantuan atau maklumat lebih lanjut, hubungi {{customer_support_url}}. Setelah permintaan anda dihantar, maklumat tersebut akan dihantar ke juruwang kami untuk diproses. Anda akan menerima e-mel sebagai pengesahan semasa pembayaran anda diproses. Jumlah pembayaran dan kos pemprosesan (jika berkenaan) akan didebitkan dari akaun anda setelah kajian pembayaran kami.",
  gatewaysTitle: "bagaimana awak ingin membayarnya?",
  gatewaysTitleDeposit: "Sila pilih kaedah deposit",
  gatewaysTitleWithdrawal: "Sila pilih kaedah pengeluaran",
  payInTitle: "Bayar dengan Kad Kredit atau Debit",
  allPaymentMethods: "semua kaedah pembayaran",
  submit: "Hantar",
  useAddressOnFile: "Gunakan alamat pada fail",
  customerName: "Nama Pelanggan",
  country: "Negara",
  province: "Wilayah",
  city: "Bandar",
  postalCode: "Poskod",
  street: "Jalan",
  proceed: "Teruskan",
  cardNumber: "Nombor kad",
  cardHolder: "Pemegang Kad",
  cardHolderName: "Nama Pemegang Kad (Bahasa Latin sahaja)",
  cardExpires: "Kad Tamat",
  cvv: "CVV",
  firstName: "Nama pertama",
  lastName: "Nama terakhir",
  zipCode: "Poskod",
  address: "Alamat",
  state: "Nyatakan",
  email: "E-mel",
  phone: "Telefon",
  dateOfBirth: "Tarikh lahir",
  transactionStatus: "Status Transaksi",
  trace: "Jejak",
  amount: "Jumlah",
  min: "min",
  max: "maks",
  overrideLimits: "Mengatasi had",
  transaction: "Transaksi",
  processed: "Diproses Oleh",
  transactionRejected: "Transaksi Ditolak",
  transactionApproved: "Transaksi Diluluskan",
  yourTransactionIsPending: "Urus Niaga Anda Tertunda",
  transactionAuthorized: "Transaksi Dibolehkan",
  requestAccepted: "Permintaan Diterima",
  status2:
    "Permintaan pengeluaran anda telah diterima. Harap diakui bahawa kebenaran dan pemprosesan selanjutnya akan dilaksanakan pada waktu operasi hari kerja. Selama waktu itu, anda mungkin dihubungi oleh kakitangan eksekutif untuk pengesahan identiti.",
  unexpectedErrorOccurred: "Berlaku Kesalahan Tidak dijangka",
  status3:
    "Kami mohon maaf, ada yang tidak kena semasa memproses transaksi anda. Sila dapatkan maklumat teknikal di bawah:",
  tryAgain: "Cuba lagi",
  somethingWentWrong: "Ada yang tidak kena ...",
  newPayoutRequest: "Permintaan Pembayaran Baru",
  makeAnotherPayment: "Buat Bayaran Lain",
  profile: "Profil",
  justOneMoreStep: "Hanya satu langkah lagi ...",
  "profile-auth":
    "Lengkapkan profil anda untuk meneruskan pembayaran menggunakan {{title}}",
  payWith: "Bayar Dengan",
  ProceedWith: "Diteruskan dengan",
  vtTitle: "Terminal Maya:",
  pay: "Bayar",
  deposit: "Deposit",
  withdraw: "Tarik balik",
  withdrawal: "Mengeluarkan",
  hello: "Helo,",
  currency: "Mata Wang",
  currentIp: "IP Semasa:",
  UseCurrentIp: "Gunakan IP semasa:",
  UseCustomerIp: "Gunakan IP semasa:",
  copyright: "hak cipta",
  chooseAmount: "Pilih jumlah",
  cannotBeBlank: "Tidak boleh kosong.",
  noDepositFree: "Tanpa bayaran deposit. tiada bayaran pengeluaran.",
  next: "Seterusnya",
  backToSite: "kembali ke laman web",
  maximumLength: "Panjang maksimum {{length}} aksara",
  minimumLength: "Panjang minimum {{length}} aksara",
  emailIsNotValid: "E-mel bukan alamat e-mel yang sah.",
  phoneIsNotValid: "Telefon tidak sah",
  cardHolderInvalid:
    "Pemegang kad tidak sah. Pemegang kad hendaklah mengandungi nama depan dan nama belakang.",
  pleaseEnterPayout: "Sila masukkan Jumlah pembayaran kurang dari atau sama",
  invalidAmount: "Jumlah tidak sah",
  transactionWillAppear: "Urus niaga anda akan tertera di penyata anda sebagai",
  "Customer does not exist": "Pelanggan tidak wujud",
  "Sorry no available gateways found.":
    "Maaf tidak ada pintu masuk yang dijumpai.",
  "Incorrect currency": "Mata wang yang tidak betul",
  "Sorry, not supported payment type": "Maaf, jenis pembayaran tidak disokong",
  "Payment method has no payment type":
    "Kaedah pembayaran tidak mempunyai jenis pembayaran",
  "Incorrect payment type in payment method":
    "Jenis pembayaran yang tidak betul dalam kaedah pembayaran",
  "Gateway limit exceeded": "Had gerbang melebihi",
  "Not supported transaction type": "Jenis transaksi tidak disokong",
  "Sorry. Payout with credit card gateway not supported yet":
    "Maaf. Pembayaran dengan pintu masuk kad kredit belum disokong\n" +
    "Kami mohon maaf, kaedah pembayaran ini tidak berlaku untuk perincian pembayaran yang ditentukan. Muat semula halaman dan cuba jumlah, mata wang atau kaedah pembayaran yang lain.",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "",
  "Back to site": "Kembali ke laman web",
  "Your transaction has been requested. Please wait for approval.":
    "Urus niaga anda telah diminta. Tunggu kelulusan.",
  "Token expired": "Token tamat",
  back: "KEMBALI",
  "Transaction not found.": "Transaksi tidak dijumpai",
  "Continue in the New Window": "Terus di Tetingkap Baru",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Jangan tutup tetingkap ini. Anda akan diarahkan kembali setelah menyelesaikan transaksi di tetingkap baru. Tidak melihat tetingkap baru yang membuka sambungan selamat? Kami sedia membantu anda menyelesaikan transaksi anda.",
  "Click to continue": "Klik untuk meneruskan",
  "Just One More Step...": "Satu Langkah Lagi ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Kaedah pembayaran yang anda pilih akan dibuka di halaman baru. Sila klik butang 'DIPERCAYAI' di bawah untuk menuju ke langkah terakhir.",
  Proceed: "Teruskan",
  "Opening a Secure Connection...": "Membuka Sambungan Selamat ...",
  "You will be redirected to complete your transaction in just a moment":
    "Anda akan diarahkan untuk menyelesaikan transaksi anda dalam beberapa saat",
  "Close window": "Tutup tingkap",
  "Partial approval confirmation": "Pengesahan separa",
  PartiallyApprovedAmount:
    "Baki kad anda tidak mencukupi untuk mengkreditkan jumlah keseluruhan. Transaksi tersebut diluluskan sebahagiannya untuk {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Klik 'OK' untuk mengesahkan jumlah deposit sebahagian atau 'BATAL' untuk membatalkan transaksi. Deposit akan disahkan secara automatik setelah 5 minit sekiranya tidak diambil tindakan.",
  fxRateSpreadText:
    "Nilai tukar yang dinyatakan merangkumi semua yuran dan caj.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Muat turun sila tunggu",
};
