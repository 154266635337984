import React from 'react';

const SkeletonItem = ({ width, height, repeat }) => {
    const style = { width, height };
    
    if (typeof repeat === 'number' && repeat > 0) {
        let items = [];
        for (let i = 0; i < repeat; i++) {
            items.push(<div key={i} className="skeleton-item" style={style} />);
        }
        return <>{items}</>;
    }
    
    return <div className="skeleton-item" style={style} />;
};

export default SkeletonItem;
