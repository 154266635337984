import React from "react";
import "./button.sass";

const Button = ({
  onClick,
  content,
  name,
  className,
  id,
  disabled,
  type,
  target,
  style,
  autoFocus
}) => {
  return (
    <button
      style={style}
      target={target}
      type={type}
      className={className}
      name={name}
      id={id}
      disabled={disabled}
      onClick={onClick}
      autoFocus={autoFocus}
    >
      {content}
    </button>
  );
};

export default Button;
