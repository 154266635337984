import React from 'react';
import IconSunLoader from "@pano-project/assets/icons/IconSunLoader";

const Button = React.forwardRef(
    (
        {
            variant = 'primary', // 'primary', 'secondary', 'tertiary', 'fourfold'
            sizeType = 'auto', // 'auto', small', 'medium', 'large'
            icon,
            loading,
            disabled,
            iconPos, // 'right', 'left'
            children,
            className,
            ...props
        },
        ref
    ) => {
        
        const buttonClass = `button ${variant} ${sizeType} ${icon && iconPos === 'left' ? 'icon-left' : ''} ${icon && iconPos === 'right' ? 'icon-right' : ''} ${loading ? 'loading' : ''} ${disabled ? 'disabled' : ''}`;
        
        return (
            <button className={`${className ? className : ''} ${buttonClass}`} disabled={disabled} ref={ref} {...props}>
                {loading ?
                    <div className={'loading-icon'}><IconSunLoader/></div> :
                    <>
                        {icon ? icon : ''}
                        {children}
                    </>
                }
            </button>
        );
    }
);

Button.displayName = 'Button';

export default Button;
