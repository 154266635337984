import React, {useEffect, useState} from 'react'
import {withI18N, withPraxisService} from "../hoc-components";
import compose from "../../utils/helper-functions/compose";
import Loader from "../helper-components/loader/loader";
import {withComputeCallbacks} from "../hoc-components/hoc-context/with-compute-callbacks";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {changeInitTheme, handleResultResponse} from "state/actions";
import {withAdr} from "../hoc-components/with-adr";
import InternalizationService from "context-components/internalization";
import {perfBreak} from "utils/performance-functions/perf-break";
import {isPanoramicTheme} from "@pano-project/variables/is-panoramic-2-0-theme";
import {themeAppearanceMode, themePreviewMode} from "../../@pano-project/variables/theme-mode";
import {
    createAmplifyClient, getAmplifyQuery,
    setSubscription,
    unsubscribeAllFromQraphqlAPI
} from "../../graphql-aws-amplify/amplify";
import {createStyles} from "../../utils/helper-functions/create-styles";
import {withRouter} from "react-router";
import {withCurrentPage} from "../hoc-components/with-current-page";
import {previewResultPage} from "../../@pano-project/variables/preview-result-page";
import {isTestData} from "../../utils/helper-functions/getTokenFromUrl";
import PanoErrorPage from "@pano-project/pages/error-page/error-page";
import ErrorPageContainer from "../container-components/error-page-container/error-page-container";

const i18n = new InternalizationService();

const ProcessingComputeData = (props) => {

    const {
        children,
        getCallbacks,
        computeCallback,
        sMinStyles,
        praxisService: {timeInfoUpdate, getCompute},
        langBack,
        history,
        currentPage
    } = props;
    const [loader, setLoader] = useState(true);
    const [error, setError] = useState(false);
    const [qraphqlError, setQraphqlError] = useState(false);
    const [errorGraphQL, setErrorGraphQL] = useState(false);
    const dispatch = useDispatch()
    const store = useSelector(({token, initTheme:{cashier_preview_mode, preview_page_state, theme_editor_settings}}) => ({token, cashier_preview_mode, preview_page_state, theme_editor_settings}), shallowEqual);
    const {token, cashier_preview_mode = false, preview_page_state = '', theme_editor_settings = {}} = store;
    const {initTemplate, initDictionary, initCustomStyles} = i18n;
    const mockGraphQLRequest = isTestData;


    useEffect(() => {
        const start = perfBreak();
        let subscriptions = {};
        if (isPanoramicTheme) {
            const client = createAmplifyClient();
            getGraphQLData(client).then(async (data) => {
                if (data) {
                    const {cashier_preview_mode} = data;
                    if (cashier_preview_mode) {
                        subscriptions['previewThemeChanged'] = await setSubscription(client, 'previewThemeChanged', onPreviewThemeChangedSuccess);
                        subscriptions['previewPageStateChanged'] = await setSubscription(client, 'previewPageStateChanged', onPreviewPageStateChangedSuccess);
                    }
                    handleInitRequest(data, start)
                }
            }).catch((err) => {
                setErrorGraphQL(true);
                console.error(`Can't establish graphql connection: ${err}`)
            })
        } else {
            getCompute(token)
                .then(({data}) => handleInitRequest(data, start))
                .catch(error => {
                    console.error('Failed to receive compute data:', error);
                    setError(true);
                });
        }
        return () => {
            if (Object.keys(subscriptions)?.length) {
                unsubscribeAllFromQraphqlAPI(subscriptions);
            }
        }
    }, [token]);

    useEffect(() => {
        if(isPanoramicTheme && cashier_preview_mode) {
            if(preview_page_state?.activePage === 'TRANSACTION_STATUS') {
                handleResultResponse(previewResultPage(preview_page_state?.transactionStatusPage?.type), dispatch);
                history.push('result-of-pay');
            } else if(currentPage === 'resultPage')  {
                window.history.replaceState(null, "New Page Title", `/${token}?cashier_theme=panoramic-2-0-theme`);
                window?.location.reload();
            }
        }
    }, [cashier_preview_mode, preview_page_state]);

    const getGraphQLData = async (client) => {
        try {
            const data = await getCashierUIPreset(client);
            if (data === null) {
                setErrorGraphQL(true);
                return {};
            }
            return mapGraphQLDataToAppData(data)
        } catch (err) {
            console.error('Cant get cashierPreset', err);
            setQraphqlError(true);
            if(mockGraphQLRequest) {
                console.warn('Check if graphqlTestAuthToken in config is provided');

                //uncomment for mock qraphql response
                // setQraphqlError(false);
                // const testGraphData = await graphqlMockRequest();
                // return mapGraphQLDataToAppData(testGraphData);
            }
        }
    };

    const onPreviewThemeChangedSuccess = (data) => {
        const {previewThemeChanged = {}} = data;
        const mappedThemeEditorChanges = mappedUiPresetData(previewThemeChanged);
        if(theme_editor_settings?.cashier_css !== previewThemeChanged?.cashier_css) {
            const theme_editor_styles = document?.head?.querySelector('[data-id="theme_editor_styles"]');
            if(theme_editor_styles) {
                theme_editor_styles.remove();
                createStyles(previewThemeChanged?.cashier_css, 'theme_editor_styles');
            } else {
                createStyles(previewThemeChanged?.cashier_css, 'theme_editor_styles');
            }
        }
        dispatch(changeInitTheme({...mappedThemeEditorChanges}));
    }

    const onPreviewPageStateChangedSuccess = (data) => {
        const {previewPageStateChanged = {}} = data;
        dispatch(changeInitTheme({preview_page_state: {...previewPageStateChanged}}));
    }

    function mapGraphQLDataToAppData(graphQLData) {
        const {session_information, ui_presets, application_configurations, translations} = graphQLData;
        const {variable1 = '', ...session_details} = session_information;
        const {theme = '', customization_settings} = ui_presets;

        return {
            ...application_configurations,
            ...session_details,
            translation: JSON.parse(translations),
            cashier_theme: theme,
            cashier_preview_mode: themePreviewMode(variable1),
            cashier_appearance_mode: themeAppearanceMode(customization_settings?.appearance, theme),
            ...mappedUiPresetData(ui_presets)
        }
    }

    const getCashierUIPreset = async (client) => {
        const {session_information} = await getAmplifyQuery('session_information', client);
        const {application_configuration} = await getAmplifyQuery('application_configuration', client);
        const {ui_presets} = await getAmplifyQuery('ui_presets', client);
        const {translations} = await getAmplifyQuery('translations', client);

        return {
            session_information: session_information,
            application_configuration: application_configuration,
            ui_presets: ui_presets,
            translations: translations
        }
    }

    const mappedUiPresetData = (uiPresetData = {}) => {
        const {theme = '', cashier_js = '', cashier_css = '', customization_settings = {}, billing_info_settings = {}} = uiPresetData;
        return {
            cashier_theme: theme,
            cashier_js,
            cashier_css,
            theme_editor_settings: {
                ...theme_editor_settings,
                customization_settings,
                billing_info_settings: mappedBillingInfo(billing_info_settings)
            }
        };
    }

    // cashier used old format of customer data, so need to mapped it to compatibility
    const mappedBillingInfo = (billingInfo) => {
        return {
            address: billingInfo?.address,
            city: billingInfo?.city,
            full_name: billingInfo?.customer_name,
            country: billingInfo?.country,
            email_customer: billingInfo?.email,
            phone_customer: billingInfo?.phone,
            state: billingInfo?.state,
            zip: billingInfo?.zip
        };
    }

    const handleInitRequest = async (data, start) => {
        await processingData(data);
        const getHermesAssets = (perfBreak() - start).toFixed(3);
        timeInfoUpdate({getHermesAssets})
        setLoader(false)
        computeCallback(9, data);
    }

    const processingData = async (data = {}) => {
        if (errorGraphQL || data.status === 1) return setError(true);
        let {
            cashier_theme,
            locale,
            valid_domains,
            validate_domain,
            cashier_css,
            cashier_js,
            communication_token = null,
            session_duration = null,
            session_theme = null,
            default_translation,
            translation,
            encrypt_cashier_css_class_names: encryptSelectors = false,
            lang,
            restrict_non_latin_characters,
            powerslide_autoredirect_time = 3000,
            currency_sign_amount = false,
            new_window_size,
            cid,
            order_id,
            cashier_preview_mode,
            cashier_appearance_mode,
            theme_editor_settings = {}
        } = data;

        const showLoaderIcon = true

        await executeCashierJs(cashier_js, encryptSelectors)
        const {dictionary} = getCallbacks();
        setAttrHtml(lang)
        initDictionary(locale, default_translation, translation, dictionary)
        initTemplate(cashier_theme)
        initCustomStyles(cashier_css)

        dispatch(changeInitTheme({
            cashier_theme,
            locale,
            valid_domains,
            validate_domain,
            communication_token,
            session_duration,
            session_theme,
            encryptSelectors,
            cashier_css,
            restrict_non_latin_characters,
            powerslide_autoredirect_time,
            currency_sign_amount,
            showLoaderIcon,
            new_window_size,
            cid,
            order_id,
            cashier_preview_mode,
            cashier_appearance_mode,
            theme_editor_settings,
            preview_page_state: {
                activePage: 'PAYMENT_FORM',
                paymentFormPage: {
                    _: true
                },
                transactionStatusPage: {
                    type: 'approved',
                    renderTypImage: true
                }
            }
        }));
    };

    const setAttrHtml = (lang) => {
        if (lang) document.getElementsByTagName('html')[0].setAttribute('lang', lang)
    }

    const executeCashierJs = async (cashier_js, encryptSelectors) => {
        if (cashier_js) {
            if (encryptSelectors) cashier_js = sMinStyles(cashier_js, encryptSelectors)
            try {
                await eval(cashier_js)
            } catch (error) {
                console.warn("cashier_js return error:", error)
            }
        }
    }

    if(error || qraphqlError) return (
        <ErrorPageContainer errorType={qraphqlError ? 10 : 2} back={false}>
            {isPanoramicTheme && <PanoErrorPage wrapper={true}/>}
        </ErrorPageContainer>
    )

    if (loader) return <Loader text={langBack('initialLoadingText', false, true)} showLoaderIcon={false}
                               className={'initial-loading-text'}/>

    return children
};

export default compose(
    withI18N, withComputeCallbacks(), withAdr, withPraxisService(), withRouter, withCurrentPage
)(ProcessingComputeData)
