export const config = {
    configName: "config4",
    title: "Cashier",
    logo: null,
    api: "https://hermes.cashier-dev.com/api/",
    apiInitTheme: "https://auth.cashier-dev.com/validate/",
    apiTheme: "https://compute.cashier-dev.com/hermes-assets/",
    origin: "https://helios.cashier-dev.com",
    withdrawRequests: true,
    activityScript: false,
    apiServer: "https://atlas.cashier-dev.com/",
    pciLink: `https://pci-api-dev.{{domain}}/PraxisGate.js?t=${new Date().getTime()}`,
    socketUrl: 'wss://prometheus.cashier-dev.com/',
    publicKeyForEncrypt: "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAsJqf6qf3buGQrXBQpv+82Sn/Y9igi4br/+L4UydCCJHcZm0eECzI91Kw0HZBJvCFlsnCNQFdylYoI/ogtpYk3TyPJZw11zI/n+ouurqEBs7l346LLSCcrm4X1DjMM1TLLWkV8HwRKmHZclQaE1ZDs2F6gs775imCItHh2PR1yGIoIJ8+IGgIFwjQX4iMxKiD74QA+XuWYOPtVcOVIaUgupz9cKj66ia158iDTtib9rUVw0wsgw26js15I4poWyhejRisf0mnoOY41t5zMfUO56lKKcOjoHXaP1p2AX5AF4WlwM1IisHPf8D/BAhywp7c7XfdiyzBxN9nnaBqSSOI0wIDAQAB",
    signatureSecretKey: "SvX6meGq9o"
};
