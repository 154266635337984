import { isChineseDomain } from "./is-chinese-domain";

const loaderLink = `${isChineseDomain ?
    'https://alt-cdn.prx-pay.com/images/Cashier_cubes_preloader.gif' :
    'https://assets.praxispay.com/images/Cashier_cubes_preloader.gif'
    }`

const styles = `
.loader-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px;
}
.loader{
   width: 50px;
   height: 50px;
   background-image: url(${loaderLink});
   background-size: contain;
   z-index: 1;
}
.timer-text {
   font-size: 16px;
   color: #333333;
   padding-top: 10px;
   font-family: 'Open Sans', sans-serif;
   display: none;
}
.pano-timer-text {
   font-size: 14px;
   color: #16181D;
   padding-top: 4px;
   font-family: Inter, sans-serif;
   display: none;
}
.loader-text{
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
    font-size: 22px;
    text-align: center;
    color: #4980aa;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 50px;
}
.loader-delay-text {
   display: none;
   font-size: 16px;
   color: #333333;
   padding-top: 10px;
   font-family: 'Open Sans', sans-serif;
   text-align: center;
}
.pano-loader-delay-text {
   display: none;
   font-size: 14px;
   color: #16181D;
   padding-top: 12px;
   font-family: Inter, sans-serif;
   text-align: center;
}
.pano-loader-text {
    font-size: 14px;
    font-weight: 400;
    color: #16181D;
    font-family: Inter, sans-serif;
    padding-top: 12px;
}
.spinner {
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}
    
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes preloader{
   to{
       transform: rotate(360deg);
   }
}
`

export const redirectWindowDom = (langBack, cashier_theme) =>
    `<html>
    <head>
        <title>Redirect url...</title>
        <style>
            ${styles}
        </style>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>
    </head>
    <body>
    <div class="popupWrapper">
        <div class="loader-container">
            ${cashier_theme === "panoramic-2-0-theme" ? `
                <div class="spinner">
                    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M40 16C26.7452 16 16 26.7452 16 40C16 53.2548 26.7452 64 40 64C53.2548 64 64 53.2548 64 40C64 36.2345 63.1328 32.6716 61.5873 29.5" stroke="#16181D" stroke-width="1.5" stroke-linecap="round" />
                    </svg>
                </div>
            ` : `
                <div class="loader"></div>
            `}

            <div class="${cashier_theme === "panoramic-2-0-theme" ? 'pano-loader-delay-text' : 'loader-delay-text'}">
                ${langBack('delayLoaderText')}
            </div>
            <div class="${cashier_theme === "panoramic-2-0-theme" ? 'pano-timer-text' : 'timer-text'}"></div>

            ${cashier_theme === "panoramic-2-0-theme" ? `
                <div class="pano-loader-text">
                    ${langBack('Please do not close this window.')}
                </div>
            ` : `
                <div class="loader-text">
                    ${langBack('Loading... Please Wait')}
                </div>
            `}
        </div>
    </div>
    <script>
        const newDate = new Date(0, 0, 0, 0, 5, 0)
        const timerFormat = new Intl.DateTimeFormat('gb', {minute: "numeric", second: "numeric"})
        const timer = document.querySelector('.timer-text')
        const delayLoaderText = document.querySelector('.loader-delay-text');
    
        if(delayLoaderText) {
            setTimeout(() => {
                delayLoaderText.style.display = 'block';
            }, 15000)
        }
        
        setTimeout(() => {
            timer.style.display = 'block'
        }, 60000)

        const interval = setInterval(() => {
            newDate.setSeconds(newDate.getSeconds() - 1)
            timer.innerText = timerFormat.format(newDate)
            if (newDate.getMinutes() === 0 && newDate.getSeconds() === 0) {
                clearInterval(interval)
            }
        }, 1000)
        window.onclose = () => {
            clearInterval(interval)
        }
    </script>
    </body>
    </html>`

