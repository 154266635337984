export const getQueryStringParams = (query = '') => {
    let vars = {};
    const parseString = query ? query : window.location.href;
    parseString.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m,key,value) => {
        vars[key] = value;
    });
    return vars;
};

export const getHashStringParams = (hash) => {
    let vars = {};
    const parseString = hash ? hash : window.location.href;
    parseString.replace(/([^#=&]+)=([^&]+)/g, (m,key,value) => {
        vars[key] = value;
    });
    return vars;
}

export const locationCashierTheme = getQueryStringParams(window.location?.search)?.cashier_theme ?? '';
