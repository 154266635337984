import React, {useState} from 'react'
import './header.sass'
import ProfileModal from "../modals/profile-modal/profile-modal.js";
import TransactionsModal from "../modals/transactions-modal/transactions-modal";
import logoVirtual from './img/logo.svg'
import {config} from "../../../config/config";
import {withRouter} from "react-router";
import compose from "../../../utils/helper-functions/compose";
import {withTemplate} from "../../hoc-components/hoc-context/with-template";
import {withI18N} from "../../hoc-components";
import {isMobile} from "../../../utils/detect-devices/is-mobile";
import MyInfo from "./my-info/my-info";
import Greeting from "./greeting/greeting";
import {withAdr} from "../../hoc-components/with-adr";
import {shallowEqual, useSelector} from "react-redux";
import {withCurrentPage} from "../../hoc-components/with-current-page";
import {ArrowDown} from "../../../@cashier-project/assets/images/arrow-down";
import {eNumber} from "../../../utils/round-functions/e-number";
import {clientClickEvent} from "../../../utils/custom-cashier-events/client-click-event";
import {isPanoramicTheme} from "../../../@pano-project/variables/is-panoramic-2-0-theme";


const logo = config.logo;

const Header = ({history, template, lang, s, currentPage}) => {

    const store = useSelector(({resultOfPay, profileInfo:{transaction_type:type, session_type, base_amount, base_currency }}) =>
        ({resultOfPay, type, session_type, base_amount, base_currency}), shallowEqual);
    const {resultOfPay} = store;
    const {type=resultOfPay?.transaction_type, session_type=resultOfPay?.session_type, base_amount, base_currency,
    } = store;
    

    const  [billingModal, setBillingModal] = useState(false);
    const  [transactionsModal, setTransactionsModal] = useState(false);
    let pageTitle;

    switch (type) {
        case("pay-in"):
            pageTitle = base_amount?  `${lang('pay')} ${eNumber(base_amount)} ${base_currency}` : `${lang('deposit')}`;
            break;
        case("pay-out"):
            pageTitle = base_amount?  `${lang('withdraw')} ${eNumber(base_amount)} ${base_currency}` : `${lang('withdrawal')}`;
            break;
        default:
            pageTitle = "";
    }

    const headerLogo = (
        <div className={`${s('header-logo')}`}>
            {
                !isPanoramicTheme && currentPage === 'paymentPage' && !(["slider", "beside"].includes(template.sliderMethods) && !isMobile)?
                    <div
                        className={`${s('back')}`}
                        onClick={() => {
                            clientClickEvent("back_button");
                            history.goBack();
                        }}>
                        <span className={`${s('arrow-wrapper')}`}>
                            <ArrowDown/>
                        </span>
                        <span>{lang('back')}</span>
                    </div> :
                    <div className={`${s('logo-container')}`}>
                        {logo? <img alt={""} src={session_type === 'vt' ? logoVirtual : logo}/> : ""}
                        <div className={`${s('main-method')}`}>{pageTitle}</div>
                    </div>
            }
        </div>
    )

    if(resultOfPay && currentPage !== 'errorPage' && !isPanoramicTheme) {
        return (
            <div className={`${s('main-header')} ${session_type === 'vt' ? s('header-virtual_terminal') : ""}`}>
                {headerLogo}
            </div>
        )
    }

    return(
        <>
            {
                billingModal? <ProfileModal close={() => {setBillingModal(false)}}/> : ""
            }

            {
                transactionsModal? <TransactionsModal close={() => {setTransactionsModal(false)}}/> : ""
            }
            {currentPage !== 'errorPage' ?
            <header className={`${s('main-header')} ${session_type === 'vt' ? s('header-virtual_terminal') : ""}`}>
                {headerLogo}
                {
                    type === "pay-out" && session_type !== "vt" && config.withdrawRequests?
                        <MyInfo
                            setBillingModal={setBillingModal}
                            setTransactionsModal={setTransactionsModal}/>
                        :
                        <Greeting
                            setBillingModal={setBillingModal}/>
                }
            </header> : ''}
        </>
    )
};

export default compose(
    withRouter, withTemplate, withI18N, withAdr, withCurrentPage
)(Header)

