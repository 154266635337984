import React, {useEffect, useState} from 'react';
import {themeAppearanceMode} from "../../../variables/theme-mode";
import {changeInitTheme} from "../../../../state/actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {withCurrentPage} from "../../../../shared-components/hoc-components/with-current-page";
import {compose} from "redux";
import './MainLayout.sass'
import Footer from "../../Footer/Footer";
import Header from "../../../../shared-components/dependent-components/header/header";
import {isIframe} from "../../../../utils/helper-functions/is-iframe";

const MainLayout = ({children, currentPage}) => {
    const dispatch = useDispatch()
    const store = useSelector(({initTheme:{cashier_appearance_mode, cashier_theme, theme_editor_settings:{customization_settings:{appearance} = {}} = {}}, resultOfPay:{transaction_type} ={},
                                   profileInfo:{transaction_type:type, session_type}={} }) => ({cashier_appearance_mode, transaction_type, type, session_type, appearance, cashier_theme}), shallowEqual)
    const { cashier_appearance_mode, transaction_type, type, session_type, appearance, cashier_theme} = store;
    const [cashierMode, setCashierMode] = useState(cashier_appearance_mode ?? '');
    const [showFooter, setShowFooter] = useState(true);
    const [showHeader, setShowHeader] = useState(false);
    const mainPages = ['gatewaysPage', 'amountPage', 'paymentPage'];

    useEffect(() => {
        const cashier_mode = themeAppearanceMode(appearance, cashier_theme);
        setCashierMode(cashier_mode);
        dispatch(changeInitTheme({cashier_appearance_mode: cashierMode}))
    }, [appearance, cashier_theme]);

    useEffect(() => {
        setShowHeader(mainPages.includes(currentPage))
        setShowFooter(!['redirectPage', 'errorPage'].includes(currentPage))
    }, [currentPage]);

    return (
        <div
            transaction_type={type? type : transaction_type}
            className = {
                `wrapper
             panoramic-2-0-theme
            ${cashierMode ?? ''}
            ${session_type === 'vt'? 'virtual_terminal' : ''}
            ${currentPage ? currentPage : ''} ${isIframe() ? 'iframe-container' : ''}`
            }>
            <div className={'wrap'}>
                <main className={'main-container'}>
                    {showHeader && <Header/>}
                    {children}
                    {showFooter && <Footer/>}
                </main>
            </div>
        </div>
    );
};

export default compose(withCurrentPage)(MainLayout);