const updateProcessingGatewaysList = (state, action) => {
    
    if (state === undefined) {
        return false
    }
    switch (action.type) {
        case 'UPDATE_PROCESSING_GATEWAYS':
            return action.payload;
        default:
            return state.processingGatewaysList;
    }
};

export {updateProcessingGatewaysList}
