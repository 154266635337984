const limitSubmit = (state, action) => {
    if (state === undefined) {
        return false
    }

    switch (action.type) {
        case 'SET_LIMIT_SUBMIT':
            return action.payload;
        default:
            return state.limitSubmit;
    }
};

export { limitSubmit }