export const mainPalleteDay = {
    main_high_contrast_0: {l:"7"},
    main_white_const: {}
}

export const mainPalleteNight = {
    main_high_contrast_0: {l:"95"},
    main_white_const: {l:"95"}
}

export const primaryPalleteDay = {
    primary_hover: {l:"20"},
    primary_tapped: {l:"15"},
    primary_1: {l:"96"},
    primary_2: {l:"92"},
    primary_3: {l:"88"},
    primary_4: {l:"84"},
    primary_5: {l:"80"},
    primary_6: {l:"70"},
    primary_7: {l:"60"},
    primary_8: {l:"50"},
    primary_9: {l:"40"},
    primary_10: {l:"30"},
    primary_11: {l:"20"},
    primary_12: {l:"15"},
}
export const primaryPalleteNight = {
    primary_hover: {l:"40"},
    primary_tapped: {l:"45"},
    primary_1: {l:"4"},
    primary_2: {l:"8"},
    primary_3: {l:"12"},
    primary_4: {l:"16"},
    primary_5: {l:"20"},
    primary_6: {l:"30"},
    primary_7: {l:"40"},
    primary_8: {l:"50"},
    primary_9: {l:"60"},
    primary_10: {l:"70"},
    primary_11: {l:"80"},
    primary_12: {l:"85"},
}
export const neutralPalleteDay = {
    neutral_hover: {l:"50"},
    neutral_tapped: {l:"40"},
    neutral_1: {l:"96"},
    neutral_2: {l:"92"},
    neutral_3: {l:"88"},
    neutral_4: {l:"84"},
    neutral_5: {l:"80"},
    neutral_6: {l:"70"},
    neutral_7: {l:"60"},
    neutral_8: {l:"50"},
    neutral_9: {l:"40"},
    neutral_10: {l:"30"},
    neutral_11: {l:"20"},
    neutral_12: {l:"10"},
}
export const neutralPalleteNight = {
    neutral_hover: {l:"70"},
    neutral_tapped: {l:"75"},
    neutral_1: {l:"12"},
    neutral_2: {l:"16"},
    neutral_3: {l:"20"},
    neutral_4: {l:"24"},
    neutral_5: {l:"28"},
    neutral_6: {l:"32"},
    neutral_7: {l:"40"},
    neutral_8: {l:"50"},
    neutral_9: {l:"61"},
    neutral_10: {l:"70"},
    neutral_11: {l:"80"},
    neutral_12: {l:"90"},
}
export const accentNegativePalleteDay = {
    accent_negative_light: {l:"95"},
    accent_negative_hover: {l:"50"},
    
}
export const accentNegativePalleteNight = {
    accent_negative_light: {l:"10"},
    accent_negative_hover: {l:"72"},
    
}
export const accentPositivePalleteDay = {
    accent_positive_light: {l:"93"},
    accent_positive_hover: {l:"40"},
    
}
export const accentPositivePalleteNight = {
    accent_positive_light: {l:"10"},
    accent_positive_hover: {l:"62"},
    
}
export const accentWarningPalleteDay = {
    accent_warning_light: {l:"93"},
    accent_warning_hover: {l:"55"},
    
}
export const accentWarningPalleteNight = {
    accent_warning_light: {l:"10"},
    accent_warning_hover: {l:"75"},
    
}