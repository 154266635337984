export const fi = {
  "Loading... Please Wait": "Ladataan, odota",
  yourTransactionIsPendingForPayOut: "Nostopyyntösi on vireillä",
  status1:
    "Huomaa, että käsittelypankki tai maksupalveluntarjoaja tarkistaa tapahtumasi. Jos haluat lisätietoja tapahtumasi tilasta, ota yhteyttä tukeen ja pyydä apua Trace ID: lle: {{trace_id}}",
  Status: "Tila",
  "Deposit Review For Account:": "Talletuksen tarkistus tilille:",
  "New Transaction": "Uusi tapahtuma",
  Update: "Päivittää",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Valitsemasi maksutapa avautuu erilliselle sivulle. Napsauta 'Edetä' siirtyäksesi uuteen selainikkunaan ja jatkaaksesi maksuprosessia.",
  dod_error_text:
    "Sinun on luettava ja hyväksyttävä talletusilmoitus jatkaaksesi",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Sinun on hyväksyttävä käyttöehdot talletuksesi jatkamiseksi",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Tunnustan lukeneeni ja hyväksyn täten ehdot ja ehdot",
  dod_url: "#",
  dod_url_text: "Käyttöehdot",
  dod_text: "Tunnustan lukeneeni ja hyväksyn täten {{dod_url}}",
  transactionSuccessfullyCanceled: "Tapahtuma {{id}} peruutettiin",
  "Fee should not exceed 20% of the amount":
    "Maksun ei tulisi ylittää 20% summasta",
  "My Info": "Omat tietoni",
  "Billing info": "Laskutustiedot",
  "Withdrawal Requests": "Nostopyynnöt",
  "No Withdrawal Requests found": "Nostopyyntöä ei löytynyt",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Peruuttaa",
  Amount: "Määrä",
  "Last Update": "Viimeisin päivitys",
  "Payment Method": "Maksutapa",
  TID: "AIKA",
  Action: "Toiminta",
  "Confirmation required": "Vahvistus vaaditaan",
  "Are you sure you want to cancel transaction":
    "Haluatko varmasti peruuttaa tapahtuman",
  Yes: "Joo",
  No: "Ei",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Min-Max-määrät",
  "Fee (included)": "Maksu (sisältyy)",
  "- Amount between": "- Määrä välillä",
  "I have the Promocode!": "Minulla on Promocode!",
  "Please enter the Promocode": "Anna tarjouskoodi",
  Promocode: "Tarjouskoodi",
  noSavedTemplates: "Ei kortteja tallennettu",
  noOptions: "Ei vaihtoehtoja",
  "Please confirm your billing details": "Vahvista laskutustietosi",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Käsittelymaksu",
  Fee: "Maksu",
  Total: "kaikki yhteensä",
  customer_support_link_text: "Asiakaspalvelu",
  terms_conditions_link_text: "Käyttöehdot",
  "Edit Billing Info": "Muokkaa laskutustietoja",
  chooseAnAccount: "Choose an account",
  "Requested to Receive": "Pyydetään vastaanottamaan",
  "Receive With": "Valitse tili",
  "Unknown session": "Tuntematon istunto",
  transactionId: "tapahtuman tunnus",
  traceId: "jäljitetunnus",
  save: "Tallentaa",
  fillTheBillingInformation: "Täytä alla olevat laskutustiedot",
  correctionsRequired: "tarvittavat korjaukset",
  billingInfo: "Laskutustiedot",
  "Cancelled by client": "Asiakas peruutti",
  "Your Cards": "Korttisi",
  Done: "Tehty",
  redirect: "uudelleenohjaus",
  redirectingTo: "Uudelleenohjaus kohteeseen",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Kauppa ei onnistunut. Valitettavasti annettu PayPal-tili näkyy tiedoissamme toisen asiakkaan kaupankäyntitilillä. Asiakkailla voi olla sivustollamme vain yksi aktiivinen kaupankäyntitili. Ota yhteyttä asiakaspalveluun käyttääksesi tätä PayPal-tiliä sisäänkirjautumisen yhteydessä tai jos tarvitset apua tilisi kanssa.",
  "Not supported yet": "Ei vielä tuettu",
  "Not available gateways found": "Ei saatavissa yhdyskäytäviä",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Pahoittelut, näyttää siltä, että sinulla ei ole rekisteröidyjä maksutietoja käytettäväksi nostopyynnössä",
  "Token is invalid": "Tunnus on virheellinen",
  "Payment method not available. Please contact your site administrator.":
    "Maksutapa ei ole käytettävissä. Ota yhteyttä sivustosi järjestelmänvalvojaan.",
  cardIsNotValid: "Kortti ei kelpaa",
  chooseAmount: "Valitse määrä",
  invalidInputFormat: "Virheellinen syöttömuoto. Ainakin {{length}} merkkiä.",
  invalidDateFormat: "Virheellinen päivämäärämuoto.",
  maximumValueIs: "Enimmäisarvo on",
  minimumValueIs: "Vähimmäisarvo on",
  enterPayoutAmount:
    "Anna maksusuoritus, joka on pienempi tai yhtä suuri kuin käytettävissä oleva saldo.",
  maxLength2: "Pituus máxima 2 karakteria",
  footer1:
    "Jotkin liikkeeseen laskevat pankit ovat rajoittaneet luottokorttien käyttöä verkkoviihdettä varten. Käytä vapaasti jotakin yllä olevista vaihtoehtoisista kolmansien osapuolten maksutavoista tai ota yhteyttä {{customer_support_url}} edustajamme maksuttomaan numeroon N / A rahoittamiseksi tilisi. \n Huomaa: Liikkeeseen laskevasta pankistasi riippuen luottokorttitapahtumia voidaan käsitellä käteisennakkoina. {{application_key}} ei ole vastuussa kaikista tapahtumaan liittyvistä käteisennakkopalkkioista, koska luotto on todennut tämän. kortin myöntänyt pankki. Kysy lisätietoja pankistasi. ",
  footer2:
    "Jos tarvitset apua tai vahvistus yritetystä tapahtumasta, ota yhteyttä osoitteeseen {{customer_support_url}}. \n Huomaa: Pankistasi riippuen tätä tapahtumaa voidaan pitää käteisenä. {{application_key}} ei ole vastuussa kaikki tähän tapahtumaan liittyvät käteisennakkopalkkiot. Tapahtuma tunnistetaan luottokorttitiliotteessasi luottokorttitunnukseksi, ellei tapahtumakuitissasi toisin mainita. ",
  footer3:
    "Kaikkiin maksupyyntöihin sovelletaan {{terms_conditions_url}}. Jos tarvitset apua tai lisätietoja, ota yhteyttä {{customer_support_url}}. Kun pyyntösi on lähetetty, tiedot toimitetaan kassamme käsittelyyn . Saat sähköpostitse vahvistuksen, kun maksusi on käsitelty. Maksusumma ja käsittelymaksu (jos sovellettavissa) veloitetaan tililtäsi maksumme tarkistuksen jälkeen. ",
  gatewaysTitle: "Miten haluaisit maksaa?",
  gatewaysTitleDeposit: "Valitse talletustapa",
  gatewaysTitleWithdrawal: "Valitse peruuttamismenetelmä",
  payInTitle: "Maksa luotto- tai pankkikortilla",
  allPaymentMethods: "kaikki maksutavat",
  submit: "Lähetä",
  useAddressOnFile: "Käytä tiedostossa olevaa osoitetta",
  customerName: "Asiakkaan nimi",
  country: "Maa",
  province: "maakunta",
  city: "Kaupunki",
  postalCode: "Postinumero",
  street: "katu",
  proceed: "Edetä",
  cardNumber: "Kortin numero",
  cardHolder: "Kortin haltija",
  cardHolderName: "Kortinhaltijan nimi",
  cardExpires: "Kortti vanhenee",
  cvv: "CVV",
  firstName: "Etunimi",
  lastName: "Sukunimi",
  zipCode: "Postinumero",
  address: "Osoite",
  state: "Osavaltio",
  email: "Sähköposti",
  phone: "Puhelin",
  dateOfBirth: "Syntymäaika",
  transactionStatus: "Tapahtuman tila",
  trace: "Jäljittää",
  amount: "Määrä",
  min: "min",
  max: "max",
  overrideLimits: "Ohita rajat",
  transaction: "liiketoimi",
  processed: "jalostettu",
  transactionRejected: "Tapahtuma hylättiin",
  transactionApproved: "Kauppa hyväksytty",
  yourTransactionIsPending: "Tapahtumasi odottaa",
  transactionAuthorized: "Kauppa valtuutettu",
  requestAccepted: "Pyyntö hyväksytty",
  status2:
    "Peruuttamispyyntösi on hyväksytty. Huomaa, että lisävaltuutus ja käsittely suoritetaan työpäivien aukioloaikoina. Tänä aikana toimeenpaneva henkilökunta voi ottaa sinuun yhteyttä henkilöllisyyden vahvistamiseksi.",
  unexpectedErrorOccurred: "Tapahtui odottamaton virhe",
  status3:
    "Pahoittelemme, jotain meni pieleen käsittelyn aikana. Katso alla olevat tekniset yksityiskohdat:",
  tryAgain: "Yritä uudelleen",
  somethingWentWrong: "Jotain meni pieleen...",
  newPayoutRequest: "Uusi maksupyyntö",
  makeAnotherPayment: "Suorita uusi maksu",
  profile: "Profiili",
  justOneMoreStep: "Vielä yksi askel ...",
  "profile-auth": "Täytä profiili ja jatka maksua käyttämällä {{title}}",
  payWith: "Maksaa",
  ProceedWith: "Jatka",
  vtTitle: "Virtuaalinen pääte:",
  pay: "Maksaa",
  deposit: "Tallettaa",
  withdraw: "Peruuttaa",
  withdrawal: "vetäytyminen",
  hello: "Hei,",
  currency: "valuutta",
  currentIp: "Nykyinen IP:",
  UseCurrentIp: "Käytä nykyistä IP: tä :",
  UseCustomerIp: "Käytä asiakkaan IP: tä :",
  copyright: "tekijänoikeus",
  cannotBeBlank: "Ei voi olla tyhjä.",
  noDepositFree: "Ei talletusmaksua. ei nostomaksua.",
  next: "Seuraava",
  backToSite: "Takaisin sivulle",
  maximumLength: "Enimmäispituus {{length}} merkkiä",
  minimumLength: "Vähimmäispituus {{length}} merkkiä",
  emailIsNotValid: "Sähköposti ei ole kelvollinen sähköpostiosoite.",
  phoneIsNotValid: "Puhelin ei kelpaa",
  cardHolderInvalid:
    "Kortin haltija on virheellinen. Kortin haltijan tulee sisältää etunimi ja sukunimi.",
  pleaseEnterPayout:
    "Anna maksusuorituksen summa, joka on pienempi tai yhtä suuri",
  invalidAmount: "virheellinen summa",
  transactionWillAppear: "Tapahtumasi näkyy tiliotteessa nimellä",
  "Customer does not exist": "Asiakasta ei ole",
  "Sorry no available gateways found.":
    "Valitettavasti käytettävissä olevia yhdyskäytäviä ei löytynyt.",
  "Incorrect currency": "Väärä valuutta",
  "Sorry, not supported payment type": "Valitettavasti ei tueta maksutyyppiä",
  "Payment method has no payment type": "Maksutavalla ei ole maksutyyppiä",
  "Incorrect payment type in payment method": "Väärä maksutyyppi maksutavassa",
  "Gateway limit exceeded": "Yhdyskäytävän raja ylitetty",
  "Not supported transaction type": "Ei tuettu tapahtumatyyppi",
  "Sorry. Payout with credit card gateway not supported yet":
    "Anteeksi. Maksua luottokorttiyhdyskäytävällä ei tueta vielä",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Pahoittelemme, tätä maksutapaa ei voida soveltaa määritettyihin maksutietoihin. Lataa sivu uudelleen ja kokeile toista määrää, valuuttaa tai maksutapaa.",
  "Back to site": "Takaisin sivulle",
  "Your transaction has been requested. Please wait for approval.":
    "Tapahtumaa on pyydetty. Odota hyväksyntää.",
  "Token expired": "Tunnus vanhentunut",
  back: "takaisin",
  "Transaction not found.": "Tapahtumaa ei löytynyt.",
  "Continue in the New Window": "Jatka uudessa ikkunassa",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Älä sulje tätä ikkunaa. Sinut ohjataan takaisin, kun olet suorittanut tapahtuman uudessa ikkunassa. Etkö näe uutta ikkunaa avaamassa suojattua yhteyttä? Autamme sinua suorittamaan tapahtumasi.",
  "Click to continue": "Klikkaa jatkaaksesi",
  "Just One More Step...": "Vain yksi askel ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Valitsemasi maksutapa on auki uudella sivulla. Napsauta alla olevaa EDETÄ-painiketta siirtyäksesi viimeiseen vaiheeseen.",
  Proceed: "Edetä",
  "Opening a Secure Connection...": "Suojatun yhteyden avaaminen ...",
  "You will be redirected to complete your transaction in just a moment":
    "Sinut ohjataan suorittamaan tapahtuma hetkessä",
  "Close window": "Sulje ikkuna",
  "Partial approval confirmation": "Osittainen hyväksynnän vahvistus",
  PartiallyApprovedAmount:
    "Korttisi saldossa ei ole riittävästi varoja koko summan hyvittämiseen. Kauppa hyväksyttiin osittain {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Napsauta 'OK' vahvistaaksesi osittaisen talletuksen summan tai peruuta tapahtuma valitsemalla 'PERUUTA'. Talletus vahvistetaan automaattisesti 5 minuutin kuluttua, jos mitään ei tehdä.",
  fxRateSpreadText: "Ilmoitettu valuuttakurssi sisältää kaikki maksut.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Ladataan, odota",
};
