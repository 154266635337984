import React from 'react';
import {shallowEqual, useSelector} from "react-redux";
import {withCurrentPage} from "../../../../shared-components/hoc-components/with-current-page";
import {compose} from "redux";
import {withI18N} from "../../../../shared-components/hoc-components";
import './SpreadAmountText.sass'

const SpreadAmountText = ({withNoteText = false, langBack, currentPage}) => {
    const {is_spread_amount } = useSelector(({currentGateway:{is_spread_amount}}) => ({
        is_spread_amount
    }), shallowEqual);

    return (
        <>
            {!!(currentPage === 'paymentPage' && is_spread_amount) ? <p className={'spread-text'}>{`${withNoteText ? langBack('Please Note:') + ' ' : ''}${langBack('panoFxRateSpreadText')}`}</p> : ""}
        </>
    );
};

export default compose(withCurrentPage, withI18N)(SpreadAmountText);