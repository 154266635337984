import {adler32EncryptString} from "../../utils/encrypt-functions/adler32-encrypt-string";

const createAdrToken = (state, action) => {
    if (state === undefined) {
        return false
    }
    switch (action.type) {
        case 'TOKEN_INIT':
            return adler32EncryptString(action.payload);
        default:
            return state.adrToken;
    }
}

export {createAdrToken}