import React from 'react'

export default class Callbacks extends  React.Component {
    
    readCallbacks = () => actionCallback;

    getCallbacks = () => {
        const {actionCallback:callbacks={}} = window
        actionCallback = callbacks;
        return callbacks? callbacks : {};
    }

    callbacksUpdate = (newCallbacks = {}) => {
        actionCallback = {...actionCallback, ...newCallbacks}
    }
}

let actionCallback = {}


