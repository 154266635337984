import {defineDecimalPlaces} from "../round-functions/get-decimal-places";
import {eNumber} from "../round-functions/e-number";

const addCommas = (nStr) => {
    nStr += '';
    let x = nStr.split('.'),
        x1 = x[0],
        x2 = x.length > 1 ? '.' + x[1] : '',
        rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
}

export const numberFormat = (value, currency, withDecimals = false, withThousandSeparator = false) => {
    let decimalPlaces = defineDecimalPlaces(currency)
    value = eNumber(value);
    value = value
        .replace(/[^\d,.]*/g, '')
        .replace(/([.,])[.,]+/g, '$1')
        .replace(",", '.')
        .replace(/^[^\d]*(\d+([.]\d{0,10})?).*$/g, '$1');

    if (value.indexOf(".") != '-1') {
        value = value.substring(0, value.indexOf(".") + (+decimalPlaces+1));
    }
    if(withDecimals && value.indexOf(".") == '-1') {
        value = value + '.';
        for (let i=0; i < decimalPlaces; i++) {
            value += '0';
        }
    }
    if(value.indexOf(".") != '-1' && decimalPlaces === 0) {
        value = value.slice(0, value.indexOf("."))
    }

    if(withThousandSeparator) {
        value = addCommas(value);
    }
    return value;
};
