export const lt = {
  "Loading... Please Wait": "Attendere il caricamento prego",
  yourTransactionIsPendingForPayOut: "Laukiama jūsų atsiėmimo prašymo",
  status1:
    "Atminkite, kad jūsų operaciją peržiūri apdorojantis bankas arba mokėjimo paslaugų teikėjas. Jei norite gauti daugiau informacijos apie operacijos būseną, susisiekite su palaikymo komanda ir paprašykite pagalbos dėl „Trace ID“: {{trace_id}}",
  Status: "Statusas",
  "Deposit Review For Account:": "Indėlių peržiūra sąskaitai:",
  "New Transaction": "Nauja operacija",
  Update: "Atnaujinti",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Pasirinktas mokėjimo būdas atsidaro atskirame puslapyje. Spustelėkite 'VYKSTA', jei norite pereiti į naują naršyklės langą ir tęsti mokėjimo procesą.",
  dod_error_text:
    "Norėdami tęsti, turite perskaityti ir priimti indėlio deklaraciją",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Norėdami tęsti indėlį, turite sutikti su taisyklėmis ir nuostatomis",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Pripažįstu, kad perskaičiau ir sutinku su taisyklėmis ir nuostatomis",
  dod_url: "#",
  dod_url_text: "Terminai ir sąlygos",
  dod_text: "Patvirtinu, kad perskaičiau ir sutinku su {{dod_url}}",
  transactionSuccessfullyCanceled: "Operacija {{id}} sėkmingai atšaukta",
  "Fee should not exceed 20% of the amount":
    "Mokestis neturėtų viršyti 20% sumos",
  "My Info": "Mano informacija",
  "Billing info": "Informacija apie atsiskaitymą",
  "Withdrawal Requests": "Prašymai atsiimti",
  "No Withdrawal Requests found": "Nerasta prašymų atsiimti",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Atšaukti",
  Amount: "Suma",
  "Last Update": "Paskutiniai pakeitimai",
  "Payment Method": "Mokėjimo būdas",
  TID: "LAIKAS",
  Action: "Veiksmas",
  "Confirmation required": "Reikalingas patvirtinimas",
  "Are you sure you want to cancel transaction":
    "Ar tikrai norite atšaukti operaciją",
  Yes: "Taip",
  No: "Ne",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Min-Max sumos",
  "Fee (included)": "Mokestis (įskaičiuota)",
  "- Amount between": "- Suma tarp",
  "I have the Promocode!": "Aš turiu Promo kodą!",
  "Please enter the Promocode": "Įveskite reklamos kredito kodą",
  Promocode: "Reklamos kodas",
  noSavedTemplates: "Nėra išsaugotų kortelių",
  noOptions: "Nėra galimybių",
  "Please confirm your billing details":
    "Patvirtinkite atsiskaitymo informaciją",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Apdorojimo mokestis",
  Fee: "Rinkliava",
  Total: "Iš viso",
  customer_support_link_text: "Pagalba klientams",
  terms_conditions_link_text: "Terminai ir sąlygos",
  "Edit Billing Info": "Redaguoti atsiskaitymo informaciją",
  chooseAnAccount: "Pasirinkite paskyrą",
  "Requested to Receive": "Prašoma gauti",
  "Receive With": "Gauti su",
  "Unknown session": "Nežinomas seansas",
  transactionId: "Operacijos ID",
  traceId: "Pėdsako ID",
  save: "Sutaupyti",
  fillTheBillingInformation: "Įveskite žemiau esančią atsiskaitymo informaciją",
  correctionsRequired: "reikalingi pataisymai",
  billingInfo: "Informacija apie atsiskaitymą",
  "Cancelled by client": "Atšaukė klientas",
  "Your Cards": "Jūsų kortelės",
  Done: "padaryta",
  redirect: "Peradresuoti",
  redirectingTo: "Nukreipiama į",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Sandoris nebuvo sėkmingas. Apgailestaujame, bet pateikta „PayPal“ sąskaita yra įraše, esančiame kito kliento prekybos sąskaitoje. Jei jums reikia pagalbos dėl savo paskyros, susisiekite su klientų aptarnavimo tarnyba.",
  "Not supported yet": "Kol kas nepalaikoma",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Apgailestaujame, atrodo, kad neturite jokios mokėjimo informacijos, kurią galėtumėte naudoti norėdami atsiimti",
  "Not available gateways found": "Nerasta jokių šliuzų",
  "Token is invalid": "Žetonas neteisingas",
  "Payment method not available. Please contact your site administrator.":
    "Mokėjimo būdas nepasiekiamas. Susisiekite su savo svetainės administratoriumi.",
  cardIsNotValid: "Kortelė negalioja",
  invalidInputFormat:
    "Netinkamas įvesties formatas. Bent {{length}} simboliai.",
  invalidDateFormat: "Netinkamas datos formatas.",
  maximumValueIs: "Didžiausia vertė yra",
  minimumValueIs: "Minimali vertė yra",
  enterPayoutAmount:
    "Įveskite išmokėjimo sumą, mažesnę arba lygią jūsų turimam likučiui.",
  maxLength2: "Didžiausias ilgis - 2 simboliai.",
  footer1:
    "Kai kurie bankai, išleidę banką, apribojo kreditinių kortelių naudojimą pramogoms internete. Jei norite finansuoti savo sąskaitą, nedvejodami naudokitės vienu iš alternatyvių aukščiau nurodytų trečiosios šalies mokėjimo būdų arba susisiekite su mūsų {{customer_support_url}} atstovais nemokamai.\n" +
    "  Atkreipkite dėmesį: atsižvelgiant į jūsų banką, kredito kortelės operacijos gali būti laikomos avansu grynaisiais. „{{application_key}}“ negali būti laikoma atsakinga už avansus grynaisiais, susijusius su šia operacija, nes tai nustato kredito kortelę išduodantis bankas. Dėl išsamesnės informacijos kreipkitės į savo banką.",
  footer2:
    "Jei reikia pagalbos ar bandymo atlikti operaciją patvirtinimo, susisiekite su {{customer_support_url}}.\n" +
    "  Atkreipkite dėmesį: atsižvelgiant į jūsų banką, ši operacija gali būti traktuojama kaip avansas grynaisiais. „{{application_key}}“ nebus atsakinga už jokius avansus grynaisiais, susijusius su šia operacija. Operacija bus nurodyta jūsų kreditinės kortelės ataskaitoje kaip kreditinės kortelės aprašas, nebent operacijos kvite nurodyta kitaip.",
  footer3:
    "Visoms išmokėjimo užklausoms taikoma {{terms_conditions_url}}. Jei jums reikia pagalbos ar papildomos informacijos, susisiekite su {{customer_support_url}}. Pateikus jūsų užklausą, informacija persiunčiama mūsų kasos skyriui apdoroti. Kai patvirtinsite išmokėjimą, gausite el. Laišką kaip patvirtinimą. Išmokėjimo suma ir apdorojimo mokestis (jei taikoma) bus nurašyti iš jūsų sąskaitos po mūsų išmokėjimo peržiūros.",
  gatewaysTitle: "Kaip norėtumėte mokėti?",
  gatewaysTitleDeposit: "Pasirinkite indėlio būdą",
  gatewaysTitleWithdrawal: "Pasirinkite atsiėmimo būdą",
  payInTitle: "Mokėkite kredito ar debeto kortele",
  allPaymentMethods: "visi mokėjimo būdai",
  submit: "Pateikti",
  useAddressOnFile: "Naudokite faile esantį adresą",
  customerName: "Kliento vardas",
  country: "Šalis",
  province: "Provincija",
  city: "Miestas",
  postalCode: "Pašto kodas",
  street: "Gatvė",
  proceed: "Tęskite",
  cardNumber: "Kortelės numeris",
  cardHolder: "Kortelės turėtojas",
  cardHolderName: "Kortelės turėtojo vardas (tik lotynų kalba)",
  cardExpires: "Kortelės galiojimo laikas baigiasi",
  cvv: "CVV",
  firstName: "Pirmas vardas",
  lastName: "Pavardė",
  zipCode: "Pašto kodas",
  address: "Adresas",
  state: "Valstija",
  email: "El",
  phone: "Telefonas",
  dateOfBirth: "Gimimo data",
  transactionStatus: "Operacijos būsena",
  trace: "Pėdsakas",
  amount: "Suma",
  min: "min",
  max: "maks",
  overrideLimits: "Nepaisyti ribų",
  transaction: "Sandoris",
  processed: "Apdorojo",
  transactionRejected: "Operacija atmesta",
  transactionApproved: "Operacija patvirtinta",
  yourTransactionIsPending: "Laukiama jūsų operacijos",
  transactionAuthorized: "Sandoris patvirtintas",
  requestAccepted: "Užklausa priimta",
  status2:
    "Jūsų prašymas atsiimti buvo priimtas. Prašome pripažinti, kad tolesnis įgaliojimas ir apdorojimas bus vykdomi darbo dienų darbo valandomis. Per tą laiką vadovai gali susisiekti su jumis dėl asmens tapatybės patvirtinimo.",
  unexpectedErrorOccurred: "Įvyko netikėta klaida",
  status3:
    "Atsiprašome, apdorojant operaciją įvyko klaida. Žemiau rasite techninę informaciją:",
  tryAgain: "Bandyk iš naujo",
  somethingWentWrong: "Kažkas nepavyko...",
  newPayoutRequest: "Naujas išmokėjimo prašymas",
  makeAnotherPayment: "Atlikite dar vieną mokėjimą",
  profile: "Profilis",
  justOneMoreStep: "Tik dar vienas žingsnis ...",
  "profile-auth":
    "Užpildykite savo profilį, kad galėtumėte mokėti naudodami {{title}}",
  payWith: "Mokėti su",
  ProceedWith: "Tęskite",
  vtTitle: "Virtualus terminalas: ",
  pay: "Mokėk",
  deposit: "Indėlis",
  withdraw: "Pasitrauk",
  withdrawal: "Pasitraukimas",
  hello: "Sveiki,",
  currency: "Valiuta",
  currentIp: "Dabartinis IP:",
  UseCurrentIp: "Naudoti dabartinį IP:",
  UseCustomerIp: "Naudoti kliento IP:",
  copyright: "Autorių teisės",
  chooseAmount: "Pasirinkite sumą",
  cannotBeBlank: "Negali būti tuščias.",
  noDepositFree: "Nr indėlio mokesčio. Jokio atsiėmimo mokesčio.",
  next: "Kitas",
  backToSite: "atgal į svetainę",
  maximumLength: "Didžiausias simbolių ilgis: {{length}}",
  minimumLength: "Minimalus simbolių ilgis: {{length}}",
  emailIsNotValid: "El. Paštas nėra galiojantis el. Pašto adresas.",
  phoneIsNotValid: "Telefonas neteisingas",
  cardHolderInvalid:
    "Kortelės savininkas neteisingas. Kortelės turėtojas turi nurodyti vardą ir pavardę.",
  pleaseEnterPayout: "Įveskite mažesnę arba lygią išmokėjimo sumą",
  invalidAmount: "Neteisinga suma",
  transactionWillAppear: "Jūsų operacija ataskaitoje bus rodoma kaip",
  "Customer does not exist": "Kliento nėra",
  "Sorry no available gateways found.": "Deja, nerasta jokių galimų šliuzų.",
  "Incorrect currency": "Neteisinga valiuta",
  "Sorry, not supported payment type": "Deja, nepalaikomas mokėjimo tipas",
  "Payment method has no payment type": "Mokėjimo būdas neturi mokėjimo tipo",
  "Incorrect payment type in payment method":
    "Neteisingas mokėjimo būdas mokėjimo metodu",
  "Gateway limit exceeded": "Vartų riba viršyta",
  "Not supported transaction type": "Nepalaikomas operacijos tipas",
  "Sorry. Payout with credit card gateway not supported yet":
    "Atsiprašau. Išmokėjimas su kredito kortelės šliuzais dar nepalaikomas",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Atsiprašome, šis mokėjimo metodas netaikomas nurodant išsamią mokėjimo informaciją. Įkelkite puslapį iš naujo ir išbandykite kitą sumą, valiutą ar mokėjimo metodą.",
  "Back to site": "Grįžti į svetainę",
  "Your transaction has been requested. Please wait for approval.":
    "Buvo paprašyta jūsų operacijos. Palaukite patvirtinimo.",
  "Token expired": "Žetono galiojimo laikas baigėsi",
  back: "GRĮŽTI",
  "Transaction not found.": "Operacija nerasta",
  "Continue in the New Window": "Tęskite naujame lange",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Neuždarykite šio lango. Baigę operaciją naujame lange, būsite nukreipti atgal. Nematote naujo lango, atveriančio saugų ryšį? Mes norime padėti jums užbaigti operaciją.",
  "Click to continue": "Spustelėkite norėdami tęsti",
  "Just One More Step...": "Tik dar vienas žingsnis ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Pasirinktas mokėjimo metodas bus atidarytas naujame puslapyje. Spustelėkite žemiau esantį mygtuką 'PROCEED', kad pereitumėte prie paskutinio veiksmo",
  Proceed: "Tęskite",
  "Opening a Secure Connection...": "Atidaromas saugus ryšys ...",
  "You will be redirected to complete your transaction in just a moment":
    "Būsite nukreipti įvykdyti operaciją tik per akimirką",
  "Close window": "Chiudi la finestra",
  "Partial approval confirmation": "Dalinio patvirtinimo patvirtinimas",
  PartiallyApprovedAmount:
    "Jūsų kortelės balanse nepakanka lėšų visai sumai įskaityti. Sandoris buvo iš dalies patvirtintas už {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Spustelėkite „Gerai“, kad patvirtintumėte dalinę indėlio sumą, arba „ATŠAUKTI“, jei norite atšaukti operaciją. Jei nesiimsite jokių veiksmų, indėlis bus automatiškai patvirtintas po 5 minučių.",
  fxRateSpreadText:
    "Į nurodytą valiutos kursą įeina visi mokesčiai ir rinkliavos.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Kraunasi... Prašome palaukti",
};
