import React, {useState, useEffect} from 'react'

const Timer = ({minutes = 5, timerVisibleAt = 4}) => {
    const newDate = new Date(0, 0, 0, 0, minutes, 0)
    useEffect(() => {
        const countdownInterval = setInterval(() => {
            setTimeoutTimer(() => {
                newDate.setSeconds(newDate.getSeconds() - 1)
                return counterFormatter.format(newDate)
            })
            
            if (newDate.getMinutes() <= timerVisibleAt - 1) {
                setShowTimer(true)
            }
            
            if (newDate.getMinutes() === 0 && newDate.getSeconds() === 0) {
                clearInterval(countdownInterval)
            }
        }, 1000)
        
        return () => {
            clearInterval(countdownInterval)
        }
    }, [])
    
    const [timeoutTimer, setTimeoutTimer] = useState('')
    const [showTimer, setShowTimer] = useState(false)
    const counterFormatter = new Intl.DateTimeFormat('gb', {minute: "numeric", second: "numeric"})
    
    return (showTimer && <div className={'loader-delay-text'}>{timeoutTimer}</div>)
};

export default Timer;