/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const previewThemeChanged = /* GraphQL */ `
  subscription PreviewThemeChanged {
    previewThemeChanged {
      session
      theme
      cashier_js
      cashier_css
      customization_settings {
        appearance
        button_style
        brand_colors_dark
        brand_colors_light
        brand_text_colors_dark
        brand_text_colors_light
        font
        submit_button_type
        submit_button_text_pay_in
        submit_button_text_pay_out
        layout
        payment_methods_view
        render_typ_image
        policies_data {
          render_policies_data
          support_website
          support_email
          support_phone
          privacy_policy_url
          terms_of_service_url
          __typename
        }
        promocode
        __typename
      }
      billing_info_settings {
        country
        customer_name
        email
        phone
        zip
        state
        city
        address
        __typename
      }
      __typename
    }
  }
`;
export const previewPageStateChanged = /* GraphQL */ `
  subscription PreviewPageStateChanged {
    previewPageStateChanged {
      session
      activePage
      paymentFormPage {
        _
        __typename
      }
      transactionStatusPage {
        type
        renderTypImage
        __typename
      }
      __typename
    }
  }
`;
