import { mathCeilDecimal } from "../round-functions/math-ceil-decimal";
import { numberFormat } from "../validations-functions/number-format";
import { round } from "../round-functions/round";

export const calculateFee = (fees, amount, currency, type) => {
  let total = amount,
    fee = null,
    feeObj = null;
  if (!fees || !fees[currency] || !amount || !currency) return { total, fee };
  feeObj = findCurrentFee(fees[currency], total);
  fee = changeFormatFee(feeObj, total, currency);
  total = findTotal(fee, total, currency, type);
  return { total, fee };
};

const changeFormatFee = (fee, total, currency) => {
  if (!fee) return fee;
  const { flat_fee_amount, percentage_fee, is_included } = fee;
  if (flat_fee_amount) fee = +flat_fee_amount;
  if (percentage_fee)
    fee = mathCeilDecimal((+total / 100) * +percentage_fee, currency);
  const fee_value = numberFormat(fee, currency);

  return { fee_value, is_included, percentage_fee };
};

export const findCurrentFee = (feeArray, amount) => {
  let currentFee = null;
  feeArray.some((el, index) => {
      if (amount >= +el.min_transaction_amount &&
          amount <= +el.max_transaction_amount) {
          const isLastFee = index + 1 !== feeArray.length
          if (isLastFee) {
              const isNextFeeInBounds = +feeArray[index + 1].min_transaction_amount === amount
              if(isNextFeeInBounds)
                return false
          }
          return (currentFee = el);
      }
  });
  return currentFee;
};

const findTotal = (feeObj, total, currency, type) => {
  if (!feeObj) return total;
  const { is_included, fee_value } = feeObj;
  if (is_included && type === "pay-out")
    total = numberFormat(round(+total - +fee_value, currency), currency);
  if (!is_included)
    total = numberFormat(round(+total + +fee_value, currency), currency);

  return +total;
};

export const calculateAmountWithPercent = (fees, totalCharge, currency, rate) => {
  const fee = getCurrentFeeFromTotalCharge(fees, totalCharge, currency);
  let accurateFee;
  let amount;
  if (fee && !fee?.is_included) {
    amount = fee.percentage_fee ?
      totalCharge / ((100 + +fee.percentage_fee) / 100) : totalCharge - +fee.flat_fee_amount;
    accurateFee = getCurrentFeeFromTotalCharge(fees, totalCharge, currency);
    if (accurateFee) amount = amount / rate;
  } else amount = totalCharge / rate;
  if (!fee || !accurateFee) amount = totalCharge / rate;
  return amount
}

const getCurrentFeeFromTotalCharge = (fees, totalCharge, currency) => {
  let currentFee = null;
  fees[currency].some(i => {
    if (i.percentage_fee) {
      const percentage = (100 + +i.percentage_fee) / 100;
      if (+i.min_transaction_amount * percentage <= totalCharge
        && totalCharge < +i.max_transaction_amount * percentage) {
        return currentFee = i
      }
    } else if (i.flat_fee_amount) {
      if (+i.min_transaction_amount + +i.flat_fee_amount <= totalCharge
        && totalCharge < +i.max_transaction_amount + +i.flat_fee_amount) {
        return currentFee = i
      }
    }
  })
  return currentFee;
}
