const initTheme = (state, action) => {
    if (state === undefined) {
        return false
    }
    switch (action.type) {
        case 'INIT_THEME':
            return {...state.initTheme, ...action.payload};
        default:
            return state.initTheme;
    }
};

export {initTheme}