import {triggerAdvanceCashierEvent} from "./trigger-advance-cashier-event";

export const cashierValidationSuccessEvent = (data) => {
    triggerAdvanceCashierEvent({
        event_type: 'validation_success',
        ...data
    })
}

export const cashierValidationFailedEvent = (data) => {
    triggerAdvanceCashierEvent({
        event_type: 'validation_failed',
        ...data
    })
}