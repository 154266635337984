export const payWithManageRetryRequest = {
    status: 0,
    is_iframe: 0,
    new_attempt: 1,
    error_message: '[TEST] Transaction status: declined. Reason: Insufficient funds',
    transaction: {
        status: 'approved',
        trace_id: 112617,
        amount: "98.34",
        charge_amount: 111,
        charge_currency: "USD",
        currency: "EUR",
        transaction_id: '352461',
        payment_processor: 'Test Card Processor',
        error_message: '[TEST] Transaction status: declined. Reason: Insufficient funds'
    },
    // PRX-4622(4541) Retry for Insufficient funds flow uncomment "retry_settings" in case want mock several attempts START
    // retry_settings: {
    //     show_amount_field: 1,
    //     predefined_options: [
    //         {
    //             "amount": 150.5,
    //             "currency": "USD"
    //         },
    //         {
    //             "amount": 100.75,
    //             "currency": "USD"
    //         },
    //         {
    //             "amount": 50.8,
    //             "currency": "USD"
    //         },
    //     ],
    //     payment_information: {
    //         base_currency: "USD",
    //         min_amount: "1",
    //         max_amount: "252",
    //         rate: 1,
    //         crypto: 0,
    //         currency_list: {
    //             USD: {
    //                 min_amount: "1",
    //                 max_amount: "300",
    //                 rate: 1,
    //                 crypto: 0,
    //             },
    //             EUR: {
    //                 min_amount: "0.94",
    //                 max_amount: "282",
    //                 rate: 0.93827,
    //                 crypto: 0,
    //             },
    //         },
    //     }
    // },
    // PRX-4622(4541) Retry for Insufficient funds flow uncomment "retry_settings" in case want mock several attempts END
    submit_action: 'https://',
    submit_name: 'Back to site',
    transaction_type: "pay-in",
    redirect_url: "",
    title: 'Your deposit attempt failed [TEST] Transaction status: declined. Reason: Insufficient funds'
}
