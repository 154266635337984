const createResultOfPay = (state, action) => {
    if (state === undefined) {
        return false
    }
    switch (action.type) {
        case 'CREATE_RESULT_OF_PAY':
            return action.payload;
        default:
            return state.resultOfPay;
    }
};

export { createResultOfPay }