import { getDictionary } from "../mock-localization";

const testJS = `
    window.actionCallback = {
        // createPaymentPage: ({payment_method_key}) => {
        //     console.log("createPaymentPage")
        // },
        // createResultPage: (data) => {
        //     console.log("createResultPage")
        // },
        // createGatewaysPage: (data) => {
        //     console.log("createGatewaysPage")
        // },
        // initApp: (data) => {
        //     console.log("initApp")
        // },
        // initAmountSlider: (data) => {
        //     console.log("initAmountSlider")
        // },
        // initBillingInfo: ({createSettings, transaction_type, payment_method_key, ...data}) => {
        //         const methodSettings = {
        //             0: {
        //                 disabled: true,
        //                 hidden: false,
        //             },
        //             4: {
        //                 disabled: true,
        //                 hidden: false,
        //             },
        //             6: {
        //                 disabled: false,
        //                 hidden: false,
        //             }
        //         }
        //         createSettings(methodSettings)
        // },
        // initHeaderProfile: (data) => {
        //     console.log("initHeaderProfile")
        // },
        // initTransactionModal: (data) => {
        //     console.log("initTransactionModal")
        // },
        // customerSettings: {
        //     zipRequired: false,
        //     notFillAddFields: false,
        //     nameDisabled: false,
        //     slidesToShow: 5,
        //     disableAutoSubmit: false,
        //     autoReturn: false,
        //     enableAddWrappers: false,
        // },
        // fieldsValidation: {
        //     phone: {min:4, max:8},
        //     email: {min:4, max:8},
        //     country: {min:4, max:8},
        //     city: {min:4, max:8},
        //     zip: {min:4, max:8},
        //     address: {min:4, max:8},
        //     full_name: {min:4, max:8},
        //     state: {min:4, max:8},
        // },
        dictionary: {
            "en-GB": {
                "key": "Text",
                "deposit": "Deposit JS"
            }
        }
}`;

const locale = "en-GB";
export const theme = {
  // cashier_theme: "default-theme",
  cashier_theme: "default-theme",
  encrypt_cashier_css_class_names: false,
  cashier_js: testJS,
  cashier_css: "",
  locale: locale,
  lang: "ru",
  default_translation: getDictionary(locale),
  translation: {
    deposit: "Deposit customer",
  },
  valid_domains: [],
  session_duration: "test-duration",
  session_theme: "test-theme",
  validate_domain: false,
  communication_token: "bf5d0967a7125bf5d0967a7125098f6b",
  powerslide_autoredirect_time: 0,
  currency_sign_amount:1
};
