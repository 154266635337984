import {dec} from "../../shared-components/hoc-components/with-decode-pay-title";

const analyzeErrors = (errors, required, gatewaysList={}, method) => {
    const {encrypted_input_names:names=[]} = gatewaysList;
    const data = {};
    const profile = method === "card-method"?
        ['full_name','country', 'state', 'city', 'zip', 'address', dec(4, names), dec(5, names)] :
        [dec(4, names), dec(5, names)]

    data.profileHasErrors = profile.some(el => required.includes(el) && errors[el] !== null);

    data.canSubmit = !required.some((el) => errors[el] !== null);

    return data
};

const localeChangeErrors = (state, fields, required) => {
    const errors = {...state.errors.payment_errors, ...fields};
    const {gatewaysList, gatewayInfo:{method}={}} = state;
    return {
        payment_errors: errors,
        errorsInfo: analyzeErrors(errors, required, gatewaysList, method)
    };
}

const createError = (state, action) => {
    if (state === undefined) {
        return {
            app_fatal_error: false,
            payment_errors: {},
            show_all_errors: false,
            errorsInfo: {}
        }
    }

    switch (action.type) {
        case 'VALIDATION_ERROR_PAY':
            return {
                ...state.errors,
                ...localeChangeErrors(state, action.payload, state.payInfo.required)
            }
        case 'INIT_METHOD_STATE':
            return {
                ...state.errors,
                ...localeChangeErrors(state, action.payload.errors, action.payload.required)
            }
        case 'CREATE_FATAL_ERROR':
            return {...state.errors, app_fatal_error: action.payload};
        case 'SHOW_ALL_ERRORS':
            return {...state.errors, show_all_errors: true};

        case 'CREATE_CURRENT_GATEWAY':
            return {
                app_fatal_error: false,
                payment_errors: {},
                show_all_errors: false,
                errorsInfo: {}
            };
        case 'CREATE_CURRENT_GATEWAY_FROM_AMOUNT_PAGE':
            return {
                app_fatal_error: false,
                payment_errors: {},
                show_all_errors: false,
                errorsInfo: {}
            };
        default:
            return state.errors;
    }
};

export {createError}
