import CCWithoutBCIcon from "../../@cashier-project/assets/images/cc-without-bc.png";
import CreditCardDefaultIcon from "../../@cashier-project/assets/images/credit-card-default.png";
import CreditAndDebitCardIcon from "../../@cashier-project/assets/images/credit-and-debit-card.png";
import AddFieldsIcon from "../../@cashier-project/assets/images/with-add-fields.png";
import AddFieldsDefaultValueIcon from "../../@cashier-project/assets/images/with-add-fields-default-value.png";
import AddFieldsSameAccountIcon from "../../@cashier-project/assets/images/with-add-fields-same-account.png";
import PayPalIcon from "../../@cashier-project/assets/images/paypal-new.png";
import CustomProcessorIcon from "../../@cashier-project/assets/images/custom-processor.png";
import newSkrillIcon from "../../@nova-project/assets/images/skrill.png";
import newWireTransferIcon from "../../@nova-project/assets/images/wireTransfer.png";
import newTrueLayerIcon from "../../images/trueLayer.png";
import newVoltIcon from "../../images/volt.png";
import newEcommpayIcon from "../../images/ecommpay.png";
export const getCurrentGateway = {
  10: {
    "image": "https://cdn.cashier-dev.com/payment_method_/payment_method_603398e98e40c.svg",
    "title": "Credit Card",
    "image_dark_mode": "https://cdn-qa.cashier-dev.com/payment_method_/payment_method_65cdedd6a5884.gif",
    "additional_image": null,
    "additional_image_dark_mode": null,
    "option_id": 10,
    "option_type": "payment_method",
    "payout_condition": null,
    "additional_fields": null,
    "templates": {
      "411111******1111": {
        "card_number": "411111******1111",
        "card_exp": "12/2033",
        "card_holder": "Admin Account",
        "template_token": "34fe90784a6af2497ee803128d00fa48"
      },
      "400002******0000": {
        "card_number": "400002******0000",
        "card_exp": "12/2035",
        "card_holder": "VALID CARD",
        "template_token": "343f64cc4bcae5ffada1253f8d07b431"
      },
      "491761******0000": {
        "card_number": "491761******0000",
        "card_exp": "10/2028",
        "card_holder": "test account",
        "template_token": "f364631bf72df358fca800e3841d34ef"
      },
      "542022******0947": {
        "card_number": "542022******0947",
        "card_exp": "10/2029",
        "card_holder": "Digity Drago",
        "template_token": "d2e13f21c2b2fef4a2962c7611fa9d3b"
      }
    },
    "payment_information": {
      "base_currency": "USD",
      "currency_list": {
        "AUD": {
          "min_amount": 135.46,
          "max_amount": 15051.5,
          "rate": "1.50515",
          "crypto": 0
        },
        "BHD": {
          "min_amount": 0.377,
          "max_amount": 37659.3,
          "rate": "0.376593",
          "crypto": 0
        },
        "BIF": {
          "min_amount": 3856,
          "max_amount": 385606330,
          "rate": 3856.063298,
          "crypto": 0
        },
        "BMD": {
          "min_amount": 1,
          "max_amount": 100000,
          "rate": "1",
          "crypto": 0
        },
        "BTC": {
          "min_amount": "0.00001442",
          "max_amount": "1.44200000",
          "rate": "1.442E-5",
          "crypto": 1
        },
        "CNY": {
          "min_amount": 646.71,
          "max_amount": 71857,
          "rate": "7.1857",
          "crypto": 0
        },
        "DOGE": {
          "min_amount": "5.83800000",
          "max_amount": "583800.00000000",
          "rate": "5.838",
          "crypto": 1
        },
        "ETH": {
          "min_amount": "0.00025660",
          "max_amount": "25.66000000",
          "rate": "0.0002566",
          "crypto": 1
        },
        "EUR": {
          "min_amount": 0.93,
          "max_amount": 93205.97,
          "rate": 0.9320596800000001,
          "crypto": 0
        },
        "GBP": {
          "min_amount": 0.78,
          "max_amount": 77772.6,
          "rate": "0.777726",
          "crypto": 0
        },
        "INR": {
          "min_amount": 82.75,
          "max_amount": 8275234.6,
          "rate": "82.752346",
          "crypto": 0
        },
        "IQD": {
          "min_amount": 1330,
          "max_amount": 133000000,
          "rate": 1330,
          "crypto": 0
        },
        "JPY": {
          "min_amount": 13236,
          "max_amount": 1470650,
          "rate": "147.06498746",
          "crypto": 0
        },
        "LTC": {
          "min_amount": "0.01139000",
          "max_amount": "1139.00000000",
          "rate": "0.01139",
          "crypto": 1
        },
        "PLN": {
          "min_amount": 803.83,
          "max_amount": 89314.25,
          "rate": 8.931425,
          "crypto": 0
        },
        "UAH": {
          "min_amount": 42.05,
          "max_amount": 4205441.46,
          "rate": 42.054414599999994,
          "crypto": 0
        },
        "USD": {
          "min_amount": 1,
          "max_amount": 100000,
          "rate": 1,
          "crypto": 0
        },
        "USDC": {
          "min_amount": "1.00000000",
          "max_amount": "100000.00000000",
          "rate": "1",
          "crypto": 1
        },
        "USDT": {
          "min_amount": "0.99860000",
          "max_amount": "99860.00000000",
          "rate": "0.9986",
          "crypto": 1
        },
        "ZAR": {
          "min_amount": 18.68,
          "max_amount": 1867732.5,
          "rate": "18.677325",
          "crypto": 0
        }
      },
      "min_amount": 90,
      "max_amount": 10000,
      "crypto": 0
    },
    "processor_type": "pp",
    "payment_method_type": "cc",
    "payment_method_key": "Credit Card",
    "card_types": [
      "Visa",
      "Mastercard",
      "UnionPay",
      "Amex",
      "JCB",
      "DinersClub",
      "Discover",
      "Dankort",
      "Electron",
      "Forbrugsforeningen",
      "Maestro",
      "Mir"
    ],
    "allow_new_card": true,
    "fees": {
      "AUD": [
        {
          "flat_fee_amount": null,
          "percentage_fee": "5.50",
          "is_included": 0,
          "min_transaction_amount": "1",
          "max_transaction_amount": "100000"
        }
      ],
      "BTC": [
        {
          "flat_fee_amount": null,
          "percentage_fee": "1.50",
          "is_included": 0,
          "min_transaction_amount": "0.00000001",
          "max_transaction_amount": "10"
        }
      ]
    },
    "is_new_window": 1,
    "show_amount": 0,
    "window_size_width": null,
    "window_size_height": null,
    "is_spread_amount": true,
    "remove_customer_data": "1",
    "time_sample_duration_of_ewallet_templates": 0,
    "time_sample_duration_of_credit_card_templates": 0.010261058807373047,
    "status": 0
  },
  70: {
    status: 0,
    image: CreditCardDefaultIcon,
    title: "Default Credit Card Without Templates",
    description: "Credit Card",
    option_id: 70,
    is_new_window: 1,
    option_type: "gateway",
    skip_customer_details: 0,
    fees: {
      USD: [
        {
          flat_fee_amount: "250.556",
          percentage_fee: null,
          is_included: 0,
          min_transaction_amount: 1000,
          max_transaction_amount: 13000,
        },
        // {
        //     "flat_fee_amount": null,
        //     "percentage_fee": 10,
        //     "is_included": 1,
        //     "min_transaction_amount": 60,
        //     "max_transaction_amount": 100
        // }
      ],
      EUR: [
        {
          flat_fee_amount: 5,
          percentage_fee: null,
          is_included: 1,
          min_transaction_amount: 10,
          max_transaction_amount: 100,
        },
      ],
      BTC: [
        {
          flat_fee_amount: "0.00000009",
          is_included: 0,
          max_transaction_amount: "1",
          min_transaction_amount: "0",
          percentage_fee: null,
        },
      ],
    },
    additional_fields: null,
    templates: {
      "491658*********3338": {
        card_number: "491658*********3338",
        card_exp: "04/2023",
        card_holder: "Test Test",
        type: "card_number",
        template_token: "J-jJxxK1oiqKFmvB",
      },
      "541612******2351": {
        card_number: "541612******2351",
        card_exp: "02/2033",
        card_holder: "Mvm",
        type: "card_number",
        template_token: "OGpD-QvGl4Gn5i_c",
      },
    },
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {
          rate: 0.9019572472,
        },
        BTC: {
          rate: 0.000018,
          min_amount: "0.00000001",
          crypto: 1,
          max_amount: "1",
        },
        USD: {
          rate: 1,
          min_amount: "1000.5",
          max_amount: "13000",
        },
        IQD: {
          rate: 5.6,
          min_amount: "1",
          max_amount: "14000",
        },
        BIF: {
          rate: 1941.307125,
          min_amount: "1941",
          max_amount: "388261425",
        },
      },
    },
    payment_method_type: "cc",
    processor_type: "pp",
    payment_method_key: "Credit Card",
  },
  80: {
    status: 0,
    image: CreditAndDebitCardIcon,
    title: "Credit Card",
    description: "Credit Card",
    option_id: 80,
    allow_new_card: false,
    option_type: "payment_method",
    skip_customer_details: 1,
    fees: {
      USD: [
        {
          flat_fee_amount: "100.556",
          percentage_fee: null,
          is_included: 0,
          min_transaction_amount: 1000,
          max_transaction_amount: 13000,
        },
        // {
        //     "flat_fee_amount": null,
        //     "percentage_fee": 10,
        //     "is_included": 1,
        //     "min_transaction_amount": 60,
        //     "max_transaction_amount": 100
        // }
      ],
      EUR: [
        {
          flat_fee_amount: 5,
          percentage_fee: null,
          is_included: 1,
          min_transaction_amount: 10,
          max_transaction_amount: 100,
        },
      ],
      BTC: [
        {
          flat_fee_amount: "0.00000009",
          is_included: 0,
          max_transaction_amount: "1",
          min_transaction_amount: "0",
          percentage_fee: null,
        },
      ],
    },
    additional_fields: null,
    templates: {
      "491658*********3338": {
        card_number: "491658*********3338",
        card_exp: "04/2022",
        card_holder: "Test Test2222",
        type: "card_number",
        template_token: "J-jJxxK1oiqKFmvB",
      },
      "541612******2351": {
        card_number: "541612******2351",
        card_exp: "02/2033",
        card_holder: "Mvm",
        type: "card_number",
        template_token: "OGpD-QvGl4Gn5i_c",
      },
    },
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {
          rate: 0.9019572472,
        },
        BTC: {
          rate: 0.000018,
          min_amount: "0.00000001",
          crypto: 1,
          max_amount: "1",
        },
        USD: {
          rate: 1,
          min_amount: "1000.5",
          max_amount: "13000",
        },
        IQD: {
          rate: 5.6,
          min_amount: "1",
          max_amount: "14000",
        },
        BIF: {
          rate: 1941.307125,
          min_amount: "1941",
          max_amount: "388261425",
        },
      },
    },
    payment_method_type: "cc",
    processor_type: "pp",
    payment_method_key: "Credit Card",
  },
  90: {
    status: 0,
    image: AddFieldsIcon,
    title: "Add Fields All Types And HTML Variables",
    description: "TestPerfectM",
    option_id: 90,
    payout_condition: null,
    option_type: "gateway",
    card_types: [
      "Electron",
      "Maestro",
      "Forbrugsforeningen",
      "Dankort",
      "MasterCard",
      "AMEX",
      "DinersClub",
      "Discover",
      "Unionpay",
      "JCB",
      "MIR",
    ],
    additional_fields: [
      {
        name: "not_required",
        label: "NOT REQUIRED field",
        default_value: "1111",
        type: "text",
        required: 0,
      },
      {
        name: "email",
        label: "Email",
        default_value: "",
        type: "text",
        required: 1,
      },
      {
        name: "dynamic_1",
        label: "Dynamic field 1",
        default_value: "",
        type: "dynamic",
        required: 1,
      },
      {
        name: "dynamic_2",
        label: "Dynamic field 2",
        default_value: "",
        type: "dynamic",
        required: 0,
      },
      {
        name: "123",
        required: 0,
        default_value: "some value",
        data:
            "<h1>Privatbank IBAN 39675867399test</h1> " +
            "<div>Variable1: {{variable1}};</div> " +
            "<div>Variable2: {{variable2}};</div> " +
            "<div>Frontend_name: {{frontend_name}};</div>" +
            "<div>Variable3: {{variable3}};</div>",
        type: "html",
      },
    ],
    templates: [],
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {
          rate: 0.9519572472,
          min_amount: 10,
          max_amount: 100000,
        },
        GBP: {
          rate: 0.7786416524,
          min_amount: 1,
          max_amount: 10000,
        },
        USD: {
          rate: 1,
          min_amount: 10,
          max_amount: 10000,
        },
      },
    },
    payment_method_type: "ewallet",
    processor_type: "apm",
    payment_method_key: "Default APM",
  },
  100: {
    status: 0,
    image: AddFieldsDefaultValueIcon,
    title: "Add Fields With Default Value",
    description: "TestPerfectM",
    payout_condition: null,
    option_id: 100,
    skip_customer_details: 1,
    option_type: "gateway",
    transactions: [
      {
        amount: "100.00",
        card_number: "111111******2233",
        currency: "USD",
        date: "12/10/2020 11:22:11",
        continue_url: "https://google.com",
        status: "pending",
        trace_id: 111111111,
      },
      {
        amount: "100.00",
        card_number: "111111******2233",
        currency: "USD",
        date: "12/10/2020 11:22:11",
        continue_url: "https://google.com",
        status: "pending",
        trace_id: 111111111,
      },
    ],
    card_types: [
      "Electron",
      "Maestro",
      "Forbrugsforeningen",
      "Dankort",
      "MasterCard",
      "AMEX",
      "DinersClub",
      "Discover",
      "Unionpay",
      "JCB",
      "MIR",
    ],
    additional_fields: [
      {
        name: "account_number",
        label: "Account Number",
        default_value: "some hidden value",
        type: "text",
      },
      {
        name: "some_input",
        label: "Some Input",
        default_value: "some value",
        type: "text",
      },
      {
        name: "select_country",
        label: "list of countriesList",
        default_value: "Option 3",
        data: '{"Option 1":"Ukraine","Option 2":"Poland","Option 3":"Cyprus"}',
        type: "select",
      },
      {
        name: "hidden_input",
        default_value: "some value of hidden",
        type: "hidden",
      },
      {
        name: "123",
        required: 0,
        default_value: "some value",
        data: "<h1>Privatbank IBAN 39675867399test</h1>",
        type: "html",
      },
    ],
    templates: [],
    payment_information: {
      base_currency: "USD",
      currency_list: {
        USD: {
          rate: 1,
          min_amount: 1,
          max_amount: 10000,
        },
      },
    },
    payment_method_type: "ewallet",
    processor_type: "apm",
    payment_method_key: "Default APM",
  },
  110: {
    status: 0,
    image: AddFieldsSameAccountIcon,
    title: "Add Fields With Same Account",
    description: "TestPerfectM",
    option_id: 110,
    payout_condition: "same_account",
    option_type: "gateway",
    card_types: [
      "Electron",
      "Maestro",
      "Forbrugsforeningen",
      "Dankort",
      "MasterCard",
      "AMEX",
      "DinersClub",
      "Discover",
      "Unionpay",
      "JCB",
      "MIR",
    ],
    additional_fields: [
      {
        name: "account_number",
        label: "Account Number",
        default_value: "",
        type: "text",
        required: 1,
        is_account_identifier: 0,
      },
      {
        name: "some_input",
        label: "Some Input",
        default_value: "",
        type: "text",
        required: 1,
        is_account_identifier: 1,
      },
    ],
    templates: {
      template1: {
        some_input: "Option 3",
        account_number: "Option 2",
        template_token: "2mdhd832l3oej",
      },
      template2: {
        some_input: "some value template",
        select_country: "Option 3",
        template_token: "1wji2hhe8sdf2",
      },
    },
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {
          rate: 0.9519572472,
          min_amount: 10,
          max_amount: 100000,
        },
        GBP: {
          rate: 0.7786416524,
          min_amount: 1,
          max_amount: 10000,
        },
        USD: {
          rate: 1,
          min_amount: 10,
          max_amount: 10000,
        },
      },
    },
    payment_method_type: "ewallet",
    processor_type: "apm",
    payment_method_key: "Default APM",
  },
  2091: {
    status: 0,
    additional_fields: [
      {
        default_value: "",
        is_account_identifier: 1,
        label: null,
        name: "paypal_id",
        required: 0,
        sort_order: 0,
        type: "hidden",
      },
      {
        default_value: "test@test.com",
        is_account_identifier: 0,
        label: "PayPal Email",
        name: "paypal_email",
        required: 1,
        sort_order: 1,
        type: "text",
      },
      {
        name: "dynamic_1",
        label: "Dynamic field 1",
        default_value: "",
        type: "dynamic",
        required: 0,
      },
      {
        name: "email",
        label: "Email",
        default_value: "",
        type: "text",
        required: 0,
      },
      {
        name: "phone",
        label: "Phone",
        default_value: "",
        type: "text",
        required: 0,
      },
    ],
    transactions: null,
    card_types: [],
    description: "PayPal",
    image: PayPalIcon,
    option_id: 2091,
    option_type: "gateway",
    payout_condition: "same_accountt",
    payment_information: {
      base_currency: "EUR",
      currency_list: {
        EUR: {
          max_amount: 10000,
          min_amount: 100,
          rate: 0.85,
        },
        USD: {
          rate: 1,
          min_amount: 10,
          max_amount: 10000,
        },
      },
    },
    payment_method_key: "paypal",
    payment_method_type: "ewallet",
    processor_type: "apm",
    skip_customer_details: 0,
    templates: {
      QAMH8X2YW9X5S: {
        paypal_email: "gdlhbr@gmail.com",
        paypal_id: "QAMH8X2YW9X5S",
        template_token: "t2CwYO7rEkPekUl2",
      },
    },
    title: "PayPal",
  },
  120: {
    status: 0,
    image: CustomProcessorIcon,
    title: "Custom Processor",
    description: "TestPerfectM",
    option_id: 120,
    option_type: "gateway",
    card_types: [],
    additional_fields: [
      {
        name: "123",
        required: 0,
        default_value: "some value",
        data:
            "<h1>Privatbank IBAN 39675867399test</h1> " +
            "<div>Variable1: {{variable1}};</div> " +
            "<div>Variable2: {{variable2}};</div> " +
            "<div>Frontend_name: {{frontend_name}};</div>" +
            "<div>Pin: {{pin}} {{pin}};</div>" +
            "<div>Last Name: {{last_name}};</div>" +
            "<div>First Name: {{first_name}};</div>" +
            "<div>Variable3: {{variable3}};</div>",
        type: "html",
      },
    ],
    templates: [],
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {
          rate: 1.08,
          min_amount: 1,
          max_amount: 10000,
        },
      },
    },
    payment_method_type: "ewallet",
    processor_type: "custom",
    payment_method_key: "BT",
  },
  644: {
    title: "Neteller",
    image:
        "https://cdn-chinagw.s3.amazonaws.com/gateway_/gateway_5e4c4fc1c0433.png",
    description: "Neteller",
    option_id: 644,
    option_type: "gateway",
    card_types: [],
    additional_fields: [
      {
        default_value: "",
        is_account_identifier: 1,
        label: "Neteller Email",
        name: "Neteller_Email",
        required: 0,
        sort_order: 0,
        type: "text",
      },
    ],
    templates: {
      "netellertest_eur@neteller.com": {
        Neteller_Email: "netellertest_eur@neteller.com",
        template_token: "rwD9o6gKlcJhHd4D",
      },
      "test@test.com": {
        Neteller_Email: "test@test.com",
        template_token: "KggMZuB3cnB80ORt",
      },
    },
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {rate: 0.885979, min_amount: 0.89, max_amount: 8859.79},
        USD: {rate: 1, min_amount: 1, max_amount: 10000},
      },
    },
    payment_method_type: "ewallet",
    processor_type: "apm",
    payment_method_key: "NETEller",
    status: 0,
  },
  529: {
    title: "TrueLayer",
    image:
        newTrueLayerIcon,
    description: "TrueLayer",
    option_id: 529,
    option_type: "gateway",
    card_types: [],
    additional_fields: [
      {
        default_value: "",
        is_account_identifier: 1,
        label: "TrueLayer Email",
        name: "TrueLayer_Email",
        required: 0,
        sort_order: 0,
        type: "text",
      },
    ],
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {rate: 0.885979, min_amount: 0.89, max_amount: 8859.79},
        USD: {rate: 1, min_amount: 1, max_amount: 10000},
      },
    },
    payment_method_type: "ewallet",
    processor_type: "apm",
    payment_method_key: "TRUELAYER",
    status: 0,
  },
  421: {
    title: "Volt",
    image:
    newVoltIcon,
    description: "Volt",
    option_id: 421,
    option_type: "gateway",
    card_types: [],
    additional_fields: [
      {
        default_value: "test@test.com",
        is_account_identifier: 0,
        label: "Volt Email",
        name: "volt_email",
        required: 1,
        sort_order: 1,
        type: "text",
      },
      {
        name: "dynamic_1",
        label: "Dynamic field 1",
        default_value: "",
        type: "dynamic",
        required: 0,
      },
      {
        name: "email",
        label: "Email",
        default_value: "",
        type: "text",
        required: 0,
      },
      {
        name: "phone",
        label: "Phone",
        default_value: "",
        type: "text",
        required: 0,
      },
    ],
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {rate: 0.885979, min_amount: 0.89, max_amount: 8859.79},
        USD: {rate: 1, min_amount: 1, max_amount: 10000},
      },
    },
    payment_method_type: "ewallet",
    processor_type: "apm",
    payment_method_key: "VOLT",
    status: 0,
  },
  22: {
    title: "Help2Pay",
    image:
    newVoltIcon,
    description: "Help2Pay",
    option_id: 22,
    option_type: "gateway",
    card_types: [],
    additional_fields: [
      {
        default_value: "test@test.com",
        is_account_identifier: 0,
        label: "Help2Pay Email",
        name: "help2Pay_email",
        required: 1,
        sort_order: 1,
        type: "text",
      },
      {
        name: "dynamic_1",
        label: "Dynamic field 1",
        default_value: "",
        type: "dynamic",
        required: 0,
      },
      {
        name: "email",
        label: "Email",
        default_value: "",
        type: "text",
        required: 0,
      },
      {
        name: "phone",
        label: "Phone",
        default_value: "",
        type: "text",
        required: 0,
      },
    ],
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {rate: 0.885979, min_amount: 0.89, max_amount: 8859.79},
        USD: {rate: 1, min_amount: 1, max_amount: 10000},
      },
    },
    payment_method_type: "ewallet",
    processor_type: "apm",
    payment_method_key: "HELP2PAY",
    status: 0,
  },
  108: {
    title: "Ecommpay",
    image:
    newEcommpayIcon,
    description: "Ecommpay",
    option_id: 108,
    option_type: "gateway",
    card_types: [],
    additional_fields: [
    {
    default_value: "",
    is_account_identifier: 1,
    label: null,
    name: "paypal_id",
    required: 0,
    sort_order: 0,
    type: "hidden",
  },
    {
    default_value: "test@test.com",
    is_account_identifier: 0,
    label: "Ecommpay Email",
    name: "ecommpay_email",
    required: 1,
    sort_order: 1,
    type: "text",
  },
    {
    name: "dynamic_1",
    label: "Dynamic field 1",
    default_value: "",
    type: "dynamic",
    required: 0,
  },
    {
    name: "email",
    label: "Email",
    default_value: "",
    type: "text",
    required: 0,
  },
    {
    name: "phone",
    label: "Phone",
    default_value: "",
    type: "text",
    required: 0,
  },
    ],
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {rate: 0.885979, min_amount: 0.89, max_amount: 8859.79},
        USD: {rate: 1, min_amount: 1, max_amount: 10000},
      },
    },
    payment_method_type: "ewallet",
    processor_type: "apm",
    payment_method_key: "ECOMMPAY",
    status: 0,
  },
  1944: {
    title: "Skrill",
    image: newSkrillIcon,
    description: "Skrill",
    option_id: 1944,
    option_type: "gateway",
    card_types: [],
    additional_fields: [
      {
        default_value: "",
        is_account_identifier: 1,
        label: "Skrill email",
        name: "Skrill email",
        required: 0,
        sort_order: 0,
        type: "text",
      },
    ],
    templates: {
      "skrilltest_eur@paypal.com": {
        paypal_Email: "skrilltest_eur@paypal.com",
        template_token: "rwD9o6gKlcJhHd4D",
      },
      "test@test.com": {
        paypal_Email: "test@test.com",
        template_token: "KggMZuB3cnB80ORt",
      },
    },
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {rate: 0.885979, min_amount: 0.89, max_amount: 8859.79},
        USD: {rate: 1, min_amount: 1, max_amount: 10000},
      },
    },
    payment_method_type: "ewallet",
    processor_type: "apm",
    payment_method_key: "skrill",
    status: 0,
  },
  1502: {
    title: "Wire Transfer",
    image: newWireTransferIcon,
    description: "Wire Transfer",
    option_id: 1502,
    option_type: "gateway",
    card_types: [],
    additional_fields: [
      {
        // For powerSlide wire transfer
        // data: {
        //   EUR: '<!DOCTYPE html>\r\n<html lang="en">\r\n<head>\r\n </head> <div><p><span class="markdown-key">Deposit to EUR</span><span class="markdown-value">77012570 EUR MetaTrader 4 - MT4</span></p><p><span class="markdown-key">Bank Name</span><span class="markdown-value">Barclays</span></p><p><span class="markdown-key">Beneficiary</span><span class="markdown-value">AVA Trade EU Ltd</span></p><p><span class="markdown-key">Bank Code</span><span class="markdown-value">5014545</span></p><p><span class="markdown-key">Account Number</span><span class="markdown-value">87577674</span></p><p><span class="markdown-key">SWIFT</span><span class="markdown-value">NWBKGB2L</span></p><p><span class="markdown-key">IBAN</span><span class="markdown-value">NWBKGB2LNWBKGB2L</span></p><p><span class="markdown-key">Bank Branch &amp; Address</span><span class="markdown-value">NatWest, Knights Bridge Branch, 186 Bromton Road, London, UK. SW3 1HQ</span></p></div> </html>',
        //   USD: '<!DOCTYPE html>\r\n<html lang="en">\r\n<head>\r\n </head> <div><p><span class="markdown-key">Deposit to USD</span><span class="markdown-value">77012570 EUR MetaTrader 4 - MT4</span></p><p><span class="markdown-key">Bank Name</span><span class="markdown-value">Barclays</span></p><p><span class="markdown-key">Beneficiary</span><span class="markdown-value">AVA Trade EU Ltd</span></p><p><span class="markdown-key">Bank Code</span><span class="markdown-value">5014545</span></p><p><span class="markdown-key">Account Number</span><span class="markdown-value">87577674</span></p><p><span class="markdown-key">SWIFT</span><span class="markdown-value">NWBKGB2L</span></p><p><span class="markdown-key">IBAN</span><span class="markdown-value">NWBKGB2LNWBKGB2L</span></p><p><span class="markdown-key">Bank Branch &amp; Address</span><span class="markdown-value">NatWest, Knights Bridge Branch, 186 Bromton Road, London, UK. SW3 1HQ</span></p></div> </html>',
        //   BIF: '<!DOCTYPE html>\r\n<html lang="en">\r\n<head>\r\n </head> <div><p><span class="markdown-key">Deposit to BIF</span><span class="markdown-value">77012570 EUR MetaTrader 4 - MT4</span></p><p><span class="markdown-key">Bank Name</span><span class="markdown-value">Barclays</span></p><p><span class="markdown-key">Beneficiary</span><span class="markdown-value">AVA Trade EU Ltd</span></p><p><span class="markdown-key">Bank Code</span><span class="markdown-value">5014545</span></p><p><span class="markdown-key">Account Number</span><span class="markdown-value">87577674</span></p><p><span class="markdown-key">SWIFT</span><span class="markdown-value">NWBKGB2L</span></p><p><span class="markdown-key">IBAN</span><span class="markdown-value">NWBKGB2LNWBKGB2L</span></p><p><span class="markdown-key">Bank Branch &amp; Address</span><span class="markdown-value">NatWest, Knights Bridge Branch, 186 Bromton Road, London, UK. SW3 1HQ</span></p></div> </html>',
        // },
        data: '<!DOCTYPE html>\r\n<html lang="en">\r\n<head>\r\n </head> <div><p><span class="markdown-key">Deposit to EUR</span><span class="markdown-value">77012570 EUR MetaTrader 4 - MT4</span></p><p><span class="markdown-key">Bank Name</span><span class="markdown-value">Barclays</span></p><p><span class="markdown-key">Beneficiary</span><span class="markdown-value">AVA Trade EU Ltd</span></p><p><span class="markdown-key">Bank Code</span><span class="markdown-value">5014545</span></p><p><span class="markdown-key">Account Number</span><span class="markdown-value">87577674</span></p><p><span class="markdown-key">SWIFT</span><span class="markdown-value">NWBKGB2L</span></p><p><span class="markdown-key">IBAN</span><span class="markdown-value">NWBKGB2LNWBKGB2L</span></p><p><span class="markdown-key">Bank Branch &amp; Address</span><span class="markdown-value">NatWest, Knights Bridge Branch, 186 Bromton Road, London, UK. SW3 1HQ</span></p></div> </html>',
        default_value: "hidden",
        is_account_identifier: 1,
        label: null,
        name: "testField",
        required: 1,
        sort_order: 1,
        type: "html",
      },
    ],
    templates: {
      "wire_transfer_test_eur@paypal.com": {
        paypal_Email: "wire_transfer_test_eur@paypal.com",
        template_token: "rwD9o6gKlcJhHd4D",
      },
      "test@test.com": {
        paypal_Email: "test@test.com",
        template_token: "KggMZuB3cnB80ORt",
      },
    },
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {rate: 0.885979, min_amount: 0.89, max_amount: 8859.79},
        USD: {rate: 1, min_amount: 1, max_amount: 10000},
      },
    },
    payment_method_type: "ewallet",
    processor_type: "custom",
    payment_method_key: "wire_transfer",
    status: 0,
  },
  1510: {
    title: "Manual Bank Wire",
    image: newWireTransferIcon,
    description: "new processor Manual Bank wire",
    option_id: 1510,
    option_type: "gateway",
    card_types: [],
    additional_fields: [
      {
        name: "bank_details",
        label: "list of bank",
        default_value: "",
        data: JSON.stringify({
          EUR:{
            "Deutsche Bank AG": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "DE89370400440532013000",
              "Bank Code": "300",
              "Country Code": "DE",
              "Bank Account Number": "2013000"
            },
            "Commerzbank AG": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "DE17500700100947002400",
              "Bank Code": "400",
              "Country Code": "DE",
              "Bank Account Number": "2400"
            },
            "BNP Paribas": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "FR1420041010050500013M02606",
              "Bank Code": "100",
              "Country Code": "FR",
              "Bank Account Number": "02606"
            },
            "Societe Generale": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "FR7630006060110001057494941",
              "Bank Code": "200",
              "Country Code": "FR",
              "Bank Account Number": "057494941"
            },
            "Credit Agricole": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "FR7630066100500000100047852",
              "Bank Code": "150",
              "Country Code": "FR",
              "Bank Account Number": "00047852"
            },
            "Unicredit Bank AG": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "DE76400202705001234000",
              "Bank Code": "250",
              "Country Code": "DE",
              "Bank Account Number": "1234000"
            },
            "Intesa Sanpaolo": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "IT24V03069091010CC101030016",
              "Bank Code": "350",
              "Country Code": "IT",
              "Bank Account Number": "1030016"
            },
            "Banco Santander": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "ES9121000418450200051332",
              "Bank Code": "450",
              "Country Code": "ES",
              "Bank Account Number": "51332"
            },
            "CaixaBank": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "ES9100190128000000122357",
              "Bank Code": "550",
              "Country Code": "ES",
              "Bank Account Number": "122357"
            },
            "Banco Bilbao Vizcaya Argentaria": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "ES9201821828109001823421",
              "Bank Code": "650",
              "Country Code": "ES",
              "Bank Account Number": "1823421"
            },
            "KBC Bank NV": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "BE71068254845470",
              "Bank Code": "750",
              "Country Code": "BE",
              "Bank Account Number": "45470"
            },
            "ING Bank N.V.": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "NL91ABNA0417164300",
              "Bank Code": "850",
              "Country Code": "NL",
              "Bank Account Number": "17164300"
            },
          },
          USD: {
            "JPMorgan Chase Bank": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000008136542839",
              "Bank Code": "001",
              "Country Code": "US",
              "Bank Account Number": "8136542839"
            },
            "Citibank NA": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000001237485960",
              "Bank Code": "002",
              "Country Code": "US",
              "Bank Account Number": "1237485960"
            },
            "Wells Fargo Bank": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000007394958403",
              "Bank Code": "003",
              "Country Code": "US",
              "Bank Account Number": "7394958403"
            },
            "Bank of America": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000002485960434",
              "Bank Code": "004",
              "Country Code": "US",
              "Bank Account Number": "2485960434"
            },
            "US Bancorp": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000009567483920",
              "Bank Code": "005",
              "Country Code": "US",
              "Bank Account Number": "9567483920"
            },
            "PNC Financial Services": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000008457485932",
              "Bank Code": "006",
              "Country Code": "US",
              "Bank Account Number": "8457485932"
            },
            "Capital One Financial Corp": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000009847485932",
              "Bank Code": "007",
              "Country Code": "US",
              "Bank Account Number": "9847485932"
            },
            "TD Bank NA": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000004859604859",
              "Bank Code": "008",
              "Country Code": "US",
              "Bank Account Number": "4859604859"
            },
            "HSBC North America Holdings": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000009857485903",
              "Bank Code": "009",
              "Country Code": "US",
              "Bank Account Number": "9857485903"
            },
            "BMO Harris Bank NA": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000009857485906",
              "Bank Code": "010",
              "Country Code": "US",
              "Bank Account Number": "9857485906"
            },
            "State Farm Bank": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000009854485906",
              "Bank Code": "011",
              "Country Code": "US",
              "Bank Account Number": "9854485906"
            },
            "Fifth Third Bank": {
              "bankIconUrl": "https://picsum.photos/100",
              "IBAN": "US10000009854485907",
              "Bank Code": "012",
              "Country Code": "US",
              "Bank Account Number": "9854485907"
            },
          }
        }),
        type: "json",
        required: 1
      },
      // { name: "custom_html",
      //   label: "Custom html",
      //   data: '<!DOCTYPE html><html lang=\"en\"><head></head><body><h3 class="title-text">Your transaction is pending</h3><table><thead><tr><th></th><th></th></tr></thead><tbody><tr><td>Trace ID:</td><td>{{trace_id}}</td></tr><tr><td>Order ID:</td><td>{{order_id}}</td></tr><tr class="{{show_amount}}"><td>Amount:</td><td>{{amount}} {{currency}}</td></tr></tbody></table><h4 class="bank-details-title">Payment details:</h4><div>{{bank_details}}</div><div>{{download_pdf_button}}</div></body></html>',
      //   type: "html"
      // }
    ],
    payment_information: {
      base_currency: "USD",
      currency_list: {
        EUR: {rate: 0.885979, min_amount: 10, max_amount: 8859.79},
        USD: {rate: 1, min_amount: 1, max_amount: 10000},
      },
    },
    show_amount: 0,
    templates: [],
    payment_method_type: "ewallet",
    processor_type: "manual_bank_wire",
    payment_method_key: "manual_wire_transfer",
    status: 0,
  }
}
