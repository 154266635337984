import React from "react";
import { PraxisI18NConsumer } from "../../../context-components/context/internalization-context/internalization-context";

export const withI18N = (Wrapped) => {
  return (props) => {
    return (
      <PraxisI18NConsumer>
        {(praxisInternalization) => {
          return (
            <Wrapped
              {...props}
              lang={praxisInternalization.lang}
              langBack={praxisInternalization.langBack}
            />
          );
        }}
      </PraxisI18NConsumer>
    );
  };
};
