export const createFatalError = (payload = {}) => {
    return {
        type: 'CREATE_FATAL_ERROR',
        payload
    }
};
export const createValidationProfileError = (payload) => {
    return {
        type: 'VALIDATION_ERROR_PROFILE',
        payload
    }
};
export const clearErrors = () => {
    return {
        type: 'CLEAR_ERRORS',
    }
};

export const showAllErrors = () => {
    return {
        type: 'SHOW_ALL_ERRORS',
    }
};