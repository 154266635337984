import React from 'react';

const IconError = () => {
    return (
        <svg width="81" height="61" viewBox="0 0 81 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M67.1416 20.572L55.5256 9.43433H48.4033L41.0607 23.3037L26.6106 9.43433H19.4883L29.4448 34.4414L19.4883 59.4343H26.6106L41.0607 45.5791L48.4033 59.4343H55.5256L67.1416 48.2966L52.6767 34.4414L67.1416 20.572Z" fill="var(--main_white_const)" stroke="var(--neutral_12)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M60.0199 20.572L48.404 9.43433L33.9392 23.3037L19.489 9.43433L7.87305 20.572L22.3232 34.4414L7.87305 48.2966L19.489 59.4343L33.9392 45.5791L48.404 59.4343L60.0199 48.2966L45.5551 34.4414L60.0199 20.572Z" fill="var(--main_white_const)" stroke="var(--neutral_12)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M77.6245 47.6094L60.9746 57.2344" stroke="var(--neutral_12)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M5.4375 32.2966L7.375 41.3591" stroke="var(--neutral_12)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M70.1328 34.085L78.0403 41.9924" stroke="var(--neutral_12)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M70.1328 41.9924L78.0403 34.085" stroke="var(--neutral_12)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.66992 27.0208L6.87585 30.2344" stroke="var(--neutral_12)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.66992 30.2344L6.87585 27.0208" stroke="var(--neutral_12)" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};

export default IconError;
