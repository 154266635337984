export const previewResultPage = (status) => {
    return {
        status: 0,
        is_iframe: 0,
        new_attempt: 1,
        error_message:
            "[TEST] Transaction status: declined. Reason: Insufficient funds",
        transaction: {
            status: status ? status : 'approved', // approved / pending / rejected
            trace_id: 112617,
            amount: "120",
            bank_name: "Deutsche Bank AG",
            charge_amount: 120,
            charge_currency: "USD",
            currency: "EUR",
            transaction_id: "352461",
            payment_processor: "Test Card Processor",
            error_message:
                "[TEST] Transaction status: declined. Reason: Insufficient funds",
            processing_status: "GE-006", // GE-006 error parameter failure message modal
        },
        ob_decline_recovery: 0,
        submit_action: "https://compute-test.praxispay.com/post-deposit-redirect/AxiCorp USD/112579",
        submit_name: "Back to site",
        transaction_type: "pay-in",
        title: "Your deposit attempt failed [TEST] Transaction status: declined. Reason: Insufficient funds",
    }
}
