export const ko = {
  "Loading... Please Wait": "로딩 중 기다려주세요",
  yourTransactionIsPendingForPayOut: "인출 요청이 보류 중입니다",
  status1:
    "처리 은행 또는 결제 서비스 제공 업체에서 거래를 검토하고 있음을 유의하시기 바랍니다. 거래 상태에 대한 자세한 정보가 필요한 경우 지원팀에 문의하여 Trace ID : {{trace_id}}에 대한 지원을 요청하세요.",
  Status: "상태",
  "Deposit Review For Account:": "계정 입금 검토 :",
  "New Transaction": "새로운 거래",
  Update: "최신 정보",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "선택한 결제 방법이 별도의 페이지에서 열립니다. '진행'을 클릭하여 새 브라우저 창으로 이동하여 결제 프로세스를 계속하십시오.",
  dod_error_text: "계속하려면 입금 신고서를 읽고 동의해야합니다.",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "입금을 진행하려면 이용 약관에 동의해야합니다.",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "본인은 약관을 읽었으며 이에 동의 함을 인정합니다.",
  dod_url: "#",
  dod_url_text: "이용 약관",
  dod_text: "본인은 읽었 음을 인정하며 이에 동의합니다 {{dod_url}}",
  transactionSuccessfullyCanceled: "{{id}} 거래가 성공적으로 취소되었습니다.",
  "Fee should not exceed 20% of the amount":
    "수수료는 금액의 20 %를 초과 할 수 없습니다.",
  "My Info": "내 정보",
  "Billing info": "결제 정보",
  "Withdrawal Requests": "출금 요청",
  "No Withdrawal Requests found": "출금 요청이 없습니다.",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "취소",
  Amount: "양",
  "Last Update": "마지막 업데이트",
  "Payment Method": "결제 방법",
  TID: "시각",
  Action: "동작",
  "Confirmation required": "확인 필요",
  "Are you sure you want to cancel transaction": "거래를 취소 하시겠습니까?",
  Yes: "예",
  No: "아니",
  "Credit Card": "신용 카드 및 직불 카드",
  "Min-Max Amounts": "최소-최대 금액",
  "Fee (included)": "요금 (포함)",
  "- Amount between": "- 금액 사이",
  "I have the Promocode!": "프로모션 코드가 있습니다!",
  "Please enter the Promocode": "프로모션 코드를 입력하세요",
  Promocode: "프로모션 코드",
  noSavedTemplates: "저장된 카드 없음",
  noOptions: "옵션 없음",
  "Please confirm your billing details": "결제 세부 정보를 확인하십시오",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "처리 수수료",
  Fee: "요금",
  Total: "합계",
  customer_support_link_text: "고객 지원",
  terms_conditions_link_text: "이용 약관",
  "Edit Billing Info": "결제 정보 수정",
  chooseAnAccount: "계정 선택",
  "Requested to Receive": "수신 요청",
  "Receive With": "수신",
  "Unknown session": "알 수없는 세션",
  transactionId: "거래 ID",
  traceId: "추적 ID",
  save: "저장",
  fillTheBillingInformation: "아래 결제 정보를 입력하세요.",
  correctionsRequired: "수정 필요",
  billingInfo: "결제 정보",
  "Cancelled by client": "고객이 취소 함",
  "Your Cards": "귀하의 카드",
  Done: "끝난",
  redirect: "리디렉션",
  redirectingTo: "리디렉션 중",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "거래가 실패했습니다. 죄송합니다. 제공된 PayPal 계정이 다른 고객의 거래 계정에있는 Google 기록에 표시됩니다. 계정에 대한 도움이 필요한 경우 고객 서비스에 문의하세요.",
  "Not supported yet": "아직 지원되지 않음",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "죄송합니다. 출금 요청에 사용할 결제 세부 정보가 등록되지 않은 것 같습니다.",
  "Not available gateways found":
    "사용할 수없는 게이트웨이를 찾을 수 없습니다.",
  "Token is invalid": "토큰이 잘못되었습니다.",
  "Payment method not available. Please contact your site administrator.":
    "결제 수단을 사용할 수 없습니다. 사이트 관리자에게 문의하십시오.",
  cardIsNotValid: "카드가 유효하지 않습니다",
  invalidInputFormat: "잘못된 입력 형식입니다. {{length}} 자 이상이어야합니다.",
  invalidDateFormat: "잘못된 날짜 형식입니다.",
  maximumValueIs: "최대 값은",
  minimumValueIs: "최소값은",
  enterPayoutAmount:
    "사용 가능한 잔액보다 작거나 같은 지급 금액을 입력하십시오.",
  maxLength2: "최대 길이는 2 자입니다.",
  footer1:
    "일부 발급 은행은 온라인 엔터테인먼트를위한 신용 카드 사용을 제한했습니다. 위의 다른 타사 결제 방법 중 하나를 사용하거나 Google {{customer_support_url}} 수신자 부담 N / A 담당자에게 문의하여 계정에 자금을 입금하세요.\n" +
    "  참고 : 발급 은행에 따라 신용 카드 거래가 현금 서비스로 처리 될 수 있습니다. {{application_key}}는 신용 카드 발급 은행에서 설정 한이 거래와 관련된 현금 서비스 수수료에 대해 책임을지지 않습니다. 자세한 내용은 은행에 문의하십시오.",
  footer2:
    "시도한 거래에 대한 지원 또는 확인을 원하시면 {{customer_support_url}}에 문의하세요.\n" +
    "  참고 : 은행에 따라이 거래는 현금 서비스로 처리 될 수 있습니다. {{application_key}}는이 거래와 관련된 현금 서비스 수수료에 대해 책임을지지 않습니다. 거래 영수증에 달리 명시되지 않는 한 거래는 신용 카드 명세서에서 신용 카드 설명 자로 식별됩니다.",
  footer3:
    "모든 판매 대금 요청에는 {{terms_conditions_url}}이 적용됩니다. 도움이나 추가 정보가 필요한 경우 {{customer_support_url}}에 문의하세요. 요청이 제출되면 정보가 처리를 위해 계산원 부서로 전달됩니다. 지급이 처리되면 확인 이메일이 발송되며, 지급 검토 후 지급 금액과 처리 수수료 (해당되는 경우)가 계정에서 인출됩니다.",
  gatewaysTitle: "어떻게 지불 하시겠습니까?",
  gatewaysTitleDeposit: "입금 방법을 선택하세요",
  gatewaysTitleWithdrawal: "인출 방법을 선택하세요",
  payInTitle: "신용 카드 또는 직불 카드로 결제",
  allPaymentMethods: "모든 결제 방법",
  submit: "제출",
  useAddressOnFile: "등록 된 주소 사용",
  customerName: "고객 이름",
  country: "국가",
  province: "지방",
  city: "시티",
  postalCode: "우편 번호",
  street: "거리",
  proceed: "발하다",
  cardNumber: "카드 번호",
  cardHolder: "카드 홀더",
  cardHolderName: "카드 소지자 이름 (라틴어 만 해당)",
  cardExpires: "카드 만료",
  cvv: "CVV",
  firstName: "이름",
  lastName: "성",
  zipCode: "우편 번호",
  address: "주소",
  state: "상태",
  email: "이메일",
  phone: "전화",
  dateOfBirth: "생년월일",
  transactionStatus: "거래 상태",
  trace: "자취",
  amount: "양",
  min: "분",
  max: "최대",
  overrideLimits: "제한 무시",
  transaction: "트랜잭션",
  processed: "처리자",
  transactionRejected: "거래 거부",
  transactionApproved: "거래 승인",
  yourTransactionIsPending: "거래가 보류 중입니다",
  transactionAuthorized: "거래 승인",
  requestAccepted: "수락 된 요청",
  status2:
    "귀하의 인출 요청이 수락되었습니다. 추가 승인 및 처리는 영업일의 운영 시간 동안 실행됩니다. 이 기간 동안 신원 확인을 위해 임원진이 연락을 드릴 수 있습니다.",
  unexpectedErrorOccurred: "예상치 못한 오류가 발생했습니다.",
  status3:
    "죄송합니다. 거래를 처리하는 동안 문제가 발생했습니다. 아래에서 기술 세부 정보를 찾으십시오.",
  tryAgain: "다시 시도하십시오",
  somethingWentWrong: "문제가 발생했습니다 ...",
  newPayoutRequest: "새로운 지불 요청",
  makeAnotherPayment: "추가 결제",
  profile: "프로필",
  justOneMoreStep: "한 단계 만 더 ...",
  "profile-auth":
    "{{title}}을 (를) 사용하여 결제를 진행하려면 프로필을 작성하십시오.",
  payWith: "지불",
  ProceedWith: "진행",
  vtTitle: "가상 터미널 :",
  pay: "지불",
  deposit: "예금",
  withdraw: "빼다",
  withdrawal: "철수",
  hello: "여보세요,",
  currency: "통화",
  currentIp: "현재 IP :",
  UseCurrentIp: "현재 IP 사용 : ",
  UseCustomerIp: "고객 IP 사용 :",
  copyright: "저작권",
  chooseAmount: "금액 선택",
  cannotBeBlank: "비워 둘 수 없습니다.",
  noDepositFree: "입금 수수료가 없습니다. 인출 수수료가 없습니다.",
  next: "다음",
  backToSite: "사이트로 돌아 가기",
  maximumLength: "최대 길이 {{length}} 자",
  minimumLength: "최소 길이 {{length}} 자",
  emailIsNotValid: "이메일은 유효한 이메일 주소가 아닙니다.",
  phoneIsNotValid: "전화가 유효하지 않습니다",
  cardHolderInvalid:
    "카드 소지자가 유효하지 않습니다. 카드 소지자는 이름과 성을 포함해야합니다.",
  pleaseEnterPayout: "지급 금액보다 작거나 같은 금액을 입력하세요.",
  invalidAmount: "잘못된 금액",
  transactionWillAppear: "거래는 명세서에 다음과 같이 표시됩니다.",
  "Customer does not exist": "고객이 존재하지 않습니다",
  "Sorry no available gateways found.": "사용 가능한 게이트웨이가 없습니다.",
  "Incorrect currency": "잘못된 통화",
  "Sorry, not supported payment type":
    "죄송합니다. 지원되지 않는 결제 유형입니다.",
  "Payment method has no payment type": "결제 수단에 결제 유형이 없습니다.",
  "Incorrect payment type in payment method": "결제 수단의 잘못된 결제 유형",
  "Gateway limit exceeded": "게이트웨이 한도 초과",
  "Not supported transaction type": "지원되지 않는 거래 유형",
  "Sorry. Payout with credit card gateway not supported yet":
    "죄송합니다. 신용 카드 게이트웨이를 통한 지불은 아직 지원되지 않습니다.",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "죄송합니다.이 결제 수단은 지정된 결제 세부 정보에 적용되지 않습니다. 페이지를 새로 고침하고 다른 금액, 통화 또는 결제 방법을 시도하십시오.",
  "Back to site": "사이트로 돌아 가기",
  "Your transaction has been requested. Please wait for approval.":
    "거래가 요청되었습니다. 승인을 기다리십시오.",
  "Token expired": "토큰 만료",
  back: "뒤",
  "Transaction not found.": "거래를 찾을 수 없습니다.",
  "Continue in the New Window": "새 창에서 계속",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "이 창을 닫지 마십시오. 새 창에서 거래를 완료하면 다시 리디렉션됩니다. 보안 연결을 여는 새 창이 보이지 않습니까? 거래를 완료 할 수 있도록 도와 드리겠습니다.",
  "Click to continue": "계속하려면 클릭하세요",
  "Just One More Step...": "한 단계 만 더 ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "선택한 결제 방법이 새 페이지에서 열립니다. 마지막 단계로 이동하려면 아래의 '진행'버튼을 클릭하십시오.",
  Proceed: "발하다",
  "Opening a Secure Connection...": "보안 연결 열기 ...",
  "You will be redirected to complete your transaction in just a moment":
    "잠시 후 거래를 완료하도록 리디렉션됩니다.",
  "Close window": "창 닫기",
  "Partial approval confirmation": "부분승인 확인",
  PartiallyApprovedAmount:
    "귀하의 카드 잔액은 전체 금액을 입금하기에 충분하지 않습니다. 거래는 {{amount}} {{currency}}에 대해 부분적으로 승인되었습니다.",
  ConfirmPartialDeposit:
    "부분 입금액을 확인하려면 '확인'을 클릭하고 거래를 취소하려면 '취소'를 클릭하십시오. 아무런 조치도 취하지 않으면 5분 후 입금이 자동으로 확정됩니다.",
  fxRateSpreadText: "표시된 환율에는 모든 수수료와 수수료가 포함됩니다.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "로딩 중 기다려주세요",
};
