export const panoramic2_0Config = {
    theme: "panoramic-2-0-theme", // nova-theme, basic-theme
    router: "basic", // nova, basic
    components: {
        amount: "basic", // 'basic', slider
        showCardIcons: false, // show or hide card icons on credit-card
        error: "bottom", // top, bottom
        visualCard: false,
        cardNumber: "multiple", // basic, transform, multiple
        sliderMethods: "slider", // type of gateways list: separate, slider, beside
        profile: "checkbox" // profile in checkbox or in modal ("checkbox", "modal")
    }
};