import {adler32EncryptString} from "./adler32-encrypt-string";

let arr = [];
const hashString = {}


export const adler = (string, key) => {
    // if(!arr.includes(string)) arr.push(string)
    // console.log(arr)
    if(!string) return string;
    if(hashString[string]) return hashString[string]
    const res = `s-${adler32EncryptString(String(string+key))}`
    hashString[string] = res
    return res;
}

