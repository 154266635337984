export const classicConfig = {
    theme: "classic", // nova-theme, basic-theme
    router: "basic", // nova, basic
    components: {
        amount: "basic", // 'basic', slider
        showCardIcons: true, // show or hide card icons on credit-card
        error: "bottom", // top, bottom
        visualCard: true,
        cardNumber: "basic", // basic, transform
        sliderMethods: "separate", // type of gateways list: separate, slider, beside
        profile: "checkbox" // profile in checkbox or in modal ("checkbox", "modal")
    }
};