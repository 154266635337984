const changeObSettings = (state, action) => {
    if (state === undefined) {
        return {}
    }
    switch (action.type) {
        case  'CHANGE_OB_SETTINGS':
            return {...state.obSettings, ...action.payload};
        default:
            return state.obSettings;
    }
}

export {changeObSettings}