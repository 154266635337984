import React from 'react';

const SkeletonContainer = ({ flex, gap, width, children }) => {
    const containerStyle = {
        display: flex ? 'flex' : 'block',
        gap,
        width
    };
    
    return <div className="skeleton-wrap" style={containerStyle}>{children}</div>;
};

export default SkeletonContainer;
