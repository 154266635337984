import {shallowEqual, useSelector} from "react-redux";
import React from "react";
import {adler} from "../../utils/encrypt-functions/adler32-encrypt";
import {unicSelectors} from "../../variables/unic-selectors";
import {replace_all} from "../../utils/helper-functions/replace-all/replace_all";

export const withAdr = (Wrapped) => {
    const Hoc = (props) => {
        const store = useSelector(({adrToken, initTheme:{encryptSelectors}}) => ({
            adrToken, encryptSelectors
        }), shallowEqual);
    
        const {adrToken:key, encryptSelectors} = store;
        
        const adr = (str, encrypt=encryptSelectors) => {
            if(!encrypt || !unicSelectors.includes(str)) return str;
            return adler(str, key);
        }
        
        const sMinStyles = (arr, encrypt) => {
            
            const sortUnicSelectors = unicSelectors.sort().reverse();
    
            sortUnicSelectors.map(e => {
                const enc = adr(e, encrypt);
                arr = replace_all(arr, `.${e}`, `.${enc}`)
            });

            return arr
        }
    
        return <Wrapped {...props} s={adr} sMinStyles={sMinStyles}/>
    }
    return Hoc
}
