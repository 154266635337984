export const profile = {
  base_amount: "", // "0" for 0-auth scenario
  base_currency: "USD",
  frontend_name: "AxiCorp USD",
  status: 0,
  cc_form_provider: "cashier",
  transaction_type: "pay-in", // "pay-in" for 0-auth scenario
  intent: "payment", // "authorization" for 0-auth scenario
  promocode: 1,
  submit_action:
    "https://compute-test.praxispay.com/post-deposit-redirect/AxiCorp USD/112579",
  submit_name: "Back to site",
  session_type: "cashier",
  agent_name: null,
  current_ip: "85.159.5.231",
  customer_ip: "91.224.6.857",
  size_application: {
    width: null,
    height: null,
  },
  order_id: 'test-1234',
  request_email: 0,
  request_phone: 0,
  remove_customer_data: 0,
  customer: {
    pin: "24567",
    first_name: "Test",
    last_name: "User",
    full_name: "Test User",
    zip: "111",
    country: "US",
    address: "Street",
    city: "Valki",
    state: "AL",
    email_customer: "test@test.com",
    phone_customer: "35700000",
    dob: "01/01/1970",
    language: "en",
    profile: "1",
    balance: 300000,
    dod_acknowledgement_required: 0,
    variable1: "variable1-value",
    variable2: "variable2-value",
    variable3: "variable3-value",
  },
};
