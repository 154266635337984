export const es = {
  "Loading... Please Wait": "Cargando por favor espere",
  yourTransactionIsPendingForPayOut: "Su solicitud de retiro está pendiente",
  status1:
    "Tenga en cuenta que su transacción está siendo revisada por el banco de procesamiento o el proveedor de servicios de pago. Si desea obtener más información sobre el estado de su transacción, comuníquese con el servicio de asistencia para solicitar asistencia sobre el ID de seguimiento: {{trace_id}}",
  Status: "Estado",
  "Deposit Review For Account:": "Revisión de depósito para cuenta:",
  "New Transaction": "Nueva transacción",
  Update: "Actualizar",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "El método de pago que ha seleccionado se abre en una página separada. Haga clic en 'CONTINUAR' para navegar a una nueva ventana del navegador y continuar con el proceso de pago.",
  dod_error_text:
    "Debe leer y aceptar la Declaración de depósito para continuar",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Debe aceptar los Términos y Condiciones para poder proceder con su depósito",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Reconozco que he leído y acepto los Términos y Condiciones.",
  dod_url: "#",
  dod_url_text: "Términos y Condiciones",
  dod_text: "Reconozco que he leído y acepto los {{dod_url}}.",
  transactionSuccessfullyCanceled:
    "La transacción {{id}} se canceló correctamente",
  "Fee should not exceed 20% of the amount":
    "La tarifa no debe exceder el 20% del monto",
  "My Info": "Mi información",
  "Billing info": "Información de facturación",
  "Withdrawal Requests": "Solicitudes de retiro",
  "No Withdrawal Requests found": "No se encontraron solicitudes de retiro",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Cancelar",
  Amount: "Cantidad",
  "Last Update": "Última actualización",
  "Payment Method": "Método de pago",
  TID: "HORA",
  Action: "Acción",
  "Confirmation required": "Confirmación requerida",
  "Are you sure you want to cancel transaction":
    "¿Estás seguro de que deseas cancelar la transacción",
  Yes: "Si",
  No: "No",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Cantidades mínimas y máximas",
  "Fee (included)": "Cuota (incluida)",
  "- Amount between": "- Importe entre",
  "I have the Promocode!": "¡Tengo el Promocode!",
  "Please enter the Promocode": "Introduzca el código promocional",
  Promocode: "Código promocional",
  noSavedTemplates: "No se guardaron tarjetas",
  noOptions: "Sin opciones",
  "Please confirm your billing details": "Confirme sus datos de facturación",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Cuota de procesamiento",
  Fee: "Cuota",
  Total: "Total",
  customer_support_link_text: "Atención al cliente",
  terms_conditions_link_text: "Términos y Condiciones",
  "Edit Billing Info": "Editar información de facturación",
  chooseAnAccount: "elige una cuenta",
  "Requested to Receive": "Solicitado para recibir",
  "Receive With": "Recibir",
  "Unknown session": "Sesión desconocida",
  transactionId: "Transacción ID",
  traceId: "Rastro ID",
  save: "Salvar",
  fillTheBillingInformation:
    "Complete la información de facturación a continuación",
  correctionsRequired: "correcciones requeridas",
  billingInfo: "Información de facturación",
  "Cancelled by client": "Cancelado por el cliente",
  "Your Cards": "Sus tarjetas",
  Done: "Hecho",
  redirect: "Redirigir",
  redirectingTo: "Redireccionando a",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "La transacción no pudo ejecutarse. Lo lamentamos, pero la cuenta de PayPal indicada consta en nuestros registros como perteneciente a la cuenta de operaciones de otro cliente. En nuestro sitio web existe una restricción de una sola cuenta de operaciones activa por cliente. Para permitir que la cuenta de PayPal indicada pueda utilizarse con sus credenciales de acceso, o si necesita asistencia para su cuenta, sírvase ponerse en contacto con el servicio de atención al cliente.",
  "Not supported yet": "Todavía no es compatible",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Disculpas, parece que no tiene ningún detalle de pago registrado para usar en la solicitud de retiro",
  "Not available gateways found": "No se encontraron pasarelas disponibles",
  "Token is invalid": "El token no es válido",
  "Payment method not available. Please contact your site administrator.":
    "Forma de pago no disponible. Póngase en contacto con el administrador de su sitio.",
  cardIsNotValid: "La tarjeta no es valida",
  invalidInputFormat:
    "Formato de entrada inválido. Al menos {{length}} caracteres.",
  invalidDateFormat: "Formato de fecha inválido.",
  maximumValueIs: "El valor máximo es",
  chooseAmount: "elegir la cantidad",
  minimumValueIs: "Valor mínimo es",
  enterPayoutAmount:
    "Ingrese un monto de pago menor o igual a su saldo disponible.",
  maxLength2: "Longitud máxima 2 caracteres",
  footer1:
    "Algunos bancos emisores han restringido el uso de tarjetas de crédito para entretenimiento en línea. No dude en utilizar uno de los métodos de pago alternativos de terceros anteriores o comuníquese con nuestros representantes de {{customer_support_url}} sin cargo al N / A para financiar su cuenta. \n Tenga en cuenta: Dependiendo de su banco emisor, las transacciones con tarjeta de crédito podrían tratarse como un anticipo en efectivo. {{application_key}} no se hace responsable de ninguna tarifa de anticipo en efectivo involucrada en esta transacción, ya que esto lo establece el crédito banco emisor de la tarjeta. Consulte con su banco para obtener más detalles. ",
  footer2:
    "Para obtener ayuda o confirmación de un intento de transacción, comuníquese con {{customer_support_url}}. \n Nota: Dependiendo de su banco, esta transacción podría tratarse como un anticipo en efectivo. {{application_key}} no será responsable de cualquier cargo por adelanto de efectivo involucrado en esta transacción. La transacción se identificará en el estado de cuenta de su tarjeta de crédito como Descriptor de tarjeta de crédito, a menos que se indique lo contrario en el recibo de la transacción. ",
  footer3:
    "Todas las solicitudes de pago están sujetas a nuestras {{terms_conditions_url}}. Si necesita ayuda o más información, comuníquese con {{customer_support_url}}. Una vez enviada su solicitud, la información se reenvía a nuestro departamento de caja para su procesamiento. . Recibirá un correo electrónico como confirmación cuando se procese su pago. El monto del pago y la tarifa de procesamiento (si corresponde) se debitarán de su cuenta después de nuestra revisión de pago. ",
  gatewaysTitle: "Cómo le gustaría pagar?",
  gatewaysTitleDeposit: "Por favor, elija el método de depósito",
  gatewaysTitleWithdrawal: "Por favor, elija el método de retiro",
  payInTitle: "Pague con tarjeta de crédito o débito",
  allPaymentMethods: "todos los métodos de pago",
  submit: "Enviar",
  useAddressOnFile: "Usar dirección en el archivo",
  customerName: "Nombre del cliente",
  country: "País",
  province: "Provincia",
  city: "Ciudad",
  postalCode: "código postal",
  street: "Calle",
  proceed: "Continuar",
  cardNumber: "Número de tarjeta",
  cardHolder: "Titular de la tarjeta",
  cardHolderName: "Nombre del titular de la tarjeta",
  cardExpires: "La tarjeta caduca",
  cvv: "CVV",
  firstName: "Nombre de pila",
  lastName: "Apellido",
  zipCode: "Código postal",
  address: "Habla a",
  state: "Estado",
  email: "Email",
  phone: "Teléfono",
  dateOfBirth: "Fecha de nacimiento",
  transactionStatus: "estado de la transacción",
  trace: "Rastro",
  amount: "Cantidad",
  min: "min",
  max: "max",
  overrideLimits: "Anular límites",
  transaction: "Transacción",
  processed: "Procesada",
  transactionRejected: "Transacción rechazada",
  transactionApproved: "Transacción aprobada",
  yourTransactionIsPending: "Su transacción está pendiente",
  transactionAuthorized: "Transacción autorizada",
  requestAccepted: "Petición aceptada",
  status2:
    "Su solicitud de retiro ha sido aceptada. Tenga en cuenta que la autorización y el procesamiento adicionales se ejecutarán durante las horas de funcionamiento de los días hábiles. Durante ese tiempo, el personal ejecutivo puede contactarlo para confirmar la identidad.",
  unexpectedErrorOccurred: "Ocurrió un error inesperado",
  status3:
    "Nos disculpamos, algo salió mal al procesar su transacción. Encuentre los detalles técnicos a continuación: ",
  tryAgain: "Inténtalo de nuevo",
  somethingWentWrong: "Algo salió mal...",
  newPayoutRequest: "Nueva solicitud de pago",
  makeAnotherPayment: "Hacer otro pago",
  profile: "Perfil",
  justOneMoreStep: "Sólo un paso más...",
  "profile-auth":
    "Complete su perfil para continuar con el pago usando {{title}}",
  payWith: "Pagar con",
  ProceedWith: "Proceder con",
  vtTitle: "Terminal virtual: ",
  pay: "Pagar",
  deposit: "Depositar",
  withdraw: "Retirar",
  withdrawal: "Retirada",
  hello: "Hola,",
  currency: "Moneda",
  currentIp: "IP actual: ",
  UseCurrentIp: "Usar IP actual: ",
  UseCustomerIp: "Usar IP del cliente: ",
  copyright: "Derechos de autor",
  cannotBeBlank: "No puede estar en blanco.",
  noDepositFree: "Sin cuota de depósito. sin cargo por retiro.",
  next: "siguiente",
  backToSite: "volver al sitio",
  maximumLength: "Longitud máxima {{length}} caracteres",
  minimumLength: "Longitud mínima {{length}} caracteres",
  emailIsNotValid:
    "El correo electrónico no es una dirección de correo electrónico válida",
  phoneIsNotValid: "El teléfono no es válido",
  cardHolderInvalid:
    "El titular de la tarjeta no es válido. El titular de la tarjeta debe contener el nombre y el apellido",
  pleaseEnterPayout: "Ingrese un monto de pago menor o igual que",
  invalidAmount: "Cantidad no válida",
  transactionWillAppear: "Su transacción aparecerá en su estado de cuenta como",
  "Customer does not exist": "El cliente no existe",
  "Sorry no available gateways found.":
    "Lo sentimos, no se encontraron pasarelas disponibles.",
  "Incorrect currency": "Moneda incorrecta",
  "Sorry, not supported payment type":
    "Lo sentimos, no se admite el tipo de pago",
  "Payment method has no payment type":
    "El método de pago no tiene tipo de pago",
  "Incorrect payment type in payment method":
    "Tipo de pago incorrecto en el método de pago",
  "Gateway limit exceeded": "Límite de puerta de enlace excedido",
  "Not supported transaction type": "Tipo de transacción no compatible",
  "Sorry. Payout with credit card gateway not supported yet":
    "Lo siento. El pago con tarjeta de crédito aún no es compatible",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Nos disculpamos, este método de pago no se aplica a los detalles de pago especificados. Vuelva a cargar la página y pruebe una cantidad, moneda o método de pago diferente.",
  "Back to site": "Volver al sitio",
  "Your transaction has been requested. Please wait for approval.":
    "Su transacción ha sido solicitada. Por favor espere la aprobación.",
  "Token expired": "Token caducado",
  back: "volver",
  "Transaction not found.": "Transacción no encontrada.",
  "Continue in the New Window": "Continuar en la nueva ventana",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "No cierre esta ventana. Será redirigido de nuevo al finalizar la transacción en una nueva ventana. ¿No ve una nueva ventana que abre una conexión segura? Estamos aquí para ayudarlo a completar su transacción.",
  "Click to continue": "Haz click para continuar",
  "Just One More Step...": "Sólo un paso más...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "El método de pago que ha seleccionado se abrirá en una nueva página. Haga clic en el botón 'CONTINUAR' a continuación para navegar al paso final",
  Proceed: "Continuar",
  "Opening a Secure Connection...": "Abrir una conexión segura ...",
  "You will be redirected to complete your transaction in just a moment":
    "Será redirigido para completar su transacción en solo un momento",
  "Close window": "Cerrar ventana",
  "Partial approval confirmation": "Confirmación de aprobación parcial",
  PartiallyApprovedAmount:
    "El saldo de su tarjeta no tiene fondos suficientes para acreditar el monto total. La transacción fue aprobada parcialmente por {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Haga clic en 'Aceptar' para confirmar el monto del depósito parcial o en 'CANCELAR' para cancelar la transacción. El depósito se confirmará automáticamente después de 5 minutos si no se realiza ninguna acción.",
  fxRateSpreadText:
    "El tipo de cambio indicado incluye todas las tarifas y cargos.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Cargando... por favor espere",
};
