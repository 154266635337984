import {clearNumber} from "../helper-functions/clear-number";
import store from "../../state/store"
import {dec} from "../../shared-components/hoc-components/with-decode-pay-title";
import {encrypt} from "../encrypt-functions/encrypt";
import {getCardName} from "../helper-functions/get-card-name/get-card-name";
import {satoshiCoefficient} from "../../variables/satoshi-format";
import {round} from "../helper-functions/round/round";
import { encrypt_rsa_oaep } from "../encrypt-functions/encrypt_rsa_oaep";

export const formatCreditCardNumber = (value) => {
  if (!value) {
    return value;
  }

  const issuer = getCardName(value).toLowerCase();
  const clearValue = clearNumber(value);
  let nextValue;
  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 20)}`;
      break;
  }
  return nextValue.trim();
};
export const formatCVC = (value, prevValue, allValues = {}) => {
  if (
    typeof value === "object" &&
    +store.getState().gatewaysList.cvv_input_as_buttons === 1
  )
    return value;
  const clearValue = clearNumber(value);
  let maxLength = 4;

  if (allValues.number) {
    const issuer = getCardName(allValues.number);
    maxLength = issuer === "amex" ? 4 : 3;
  }

  return clearValue.slice(0, maxLength);
};
export const formatExpirationDate = (value) => {
  const clearValue = clearNumber(value);

  // 2/4 format
  if (clearValue.length === 6) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(4, 6)}`;
  }

  // 2/2 format
  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
};

export const changeFormatCreditData = async (data) => {
  const newData = { ...data };
  const {
    gatewaysList: { cvv_input_as_buttons },
    profileInfo: { transaction_type: type },
    initTheme: { communication_token: token },
    gatewayInfo: { cryptoList },
  } = store.getState();

  if (newData[dec(0)]) {
    if (cvv_input_as_buttons === 1 && type === "pay-in") {
      newData.cvv_button_keys = newData[dec(0)];
      newData[dec(0)] = "*".repeat(newData[dec(0)].length);
    } else {
      newData[dec(0)] = newData[dec(0)].replace(/\s+/g, "");
      newData[dec(0)] = await encrypt_rsa_oaep(newData[dec(0)]).then(
        (resolve) => resolve
      );
      //   newData[dec(0)] = encrypt(token, newData[dec(0)]); // old encrypt
    }
  }

  if (newData.amount) {
      const isCrypto = cryptoList.includes(newData.currency);
      newData.amount = isCrypto
          ? round(+newData.amount * satoshiCoefficient, 2)
          : +newData.amount;
  }
  
  if (newData[dec(1)]) {
    newData[dec(1)] = newData[dec(1)].replace(/\s+/g, "");
    newData[dec(1)] = await encrypt_rsa_oaep(newData[dec(1)]).then(
      (resolve) => resolve
    );
    // newData[dec(1)] = encrypt(token, newData[dec(1)]); // old encrypt
  }

  if (newData[dec(2)]) {
    newData[dec(2)] =
      newData[dec(2)].length === 5
        ? `${newData[dec(2)].slice(0, 2)}/20${newData[dec(2)].slice(3, 5)}`
        : newData[dec(2)];
  }

  if (newData[dec(4)]) {
    newData[dec(4)] = encrypt(token, newData[dec(4)]);
  }

  if (newData[dec(5)]) {
    newData[dec(5)] = encrypt(token, newData[dec(5)]);
  }

  return newData;
};

export const checkFormat = (payload) => {
  if (typeof payload !== "object") {
    console.error(
      "error: payload must be an object, \n method: validateCardFormat, \n file: validation-methods.js"
    );
  }
  Object.keys(payload).map((name) => {
    if (payload[name] && payload[name].length
        && typeof payload[name] === 'string'
        && typeof payload[name].replaceAll === 'function') {
      payload[name] = payload[name]?.replaceAll(/[<>]/gim, '')
    }
    const value = payload[name];
    const {profileInfo: {remove_customer_data}, focus} = store.getState();
    const isPhoneValidation = remove_customer_data && name === dec(4) && (focus === dec(4) || focus === 'phone');
    
    if (name === dec(1) && !value.includes("*"))
      return (payload[name] = formatCreditCardNumber(value));
    if (name === dec(2)) return (payload[name] = formatExpirationDate(value));
    if (name === dec(0) && !value.includes("*"))
      return (payload[name] = formatCVC(value));
    if (name === dec(3) && value) return payload[name] = value.replace(/[0-9]/g, "");
    if (isPhoneValidation || (!remove_customer_data && name === dec(4) && value)) {
      return (payload[name] = value
        .replace(/[^0-9\+\s]/g, "")
        .replace(/([+])[+]/g, "$1"));
    }
    if (name === "zip" && value)
      return (payload[name] = value.replace(/[^A-Za-z0-9]/g, "").toUpperCase());
    if (name === dec(5) && value)
      return (payload[name] = value
        .replace(/([.])[.]+/g, "$1")
        .replace(/^\s*(.*)\s*$/, "$1")
        .replace(/\s+/g, ""));
    if (name === "full_name" && value)
      return (payload[name] = value.replace(/[0-9]/g, ""));
    return (payload[name] = value);
  });
  return payload;
};
