export const getDeviceData = () => {
    return {
        user_agent : window?.navigator?.userAgent,
        language : window?.navigator?.language,
        timezone_offset : new Date()?.getTimezoneOffset(),
        color_depth : window?.screen?.colorDepth,
        pixel_depth : window?.screen?.pixelDepth,
        pixel_ratio : window?.devicePixelRatio,
        screen_height : window?.screen?.height,
        screen_width : window?.screen?.width,
        viewport_height : Math.max(document?.documentElement?.clientHeight || 0, window?.innerHeight || 0),
        viewport_width : Math.max(document?.documentElement?.clientWidth || 0, window?.innerWidth || 0),
        java_enabled : navigator?.javaEnabled() ? 1 : 0,
        javascript_enabled : 1
    };
}