import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import App from './app';
import PraxisService from './context-components/service.js'
import ErrorBoundry from "./shared-components/wp-components/error-boundary";
import {PraxisServiceProvider} from './context-components/context/praxis-context/praxis-context'
import {PraxisI18NProvider} from './context-components/context/internalization-context/internalization-context'
import store from './state/store'
import ReadTokenFromUrl from "./shared-components/wp-components/read-token-from-url";
import InternalizationService from "./context-components/internalization";
import ProcessingComputeData from "./shared-components/wp-components/processing-compute-data";
import ProcessingCustomerData from "./shared-components/wp-components/processing-customer-data";
import ConnectParentDomain from "./shared-components/wp-components/connect-parent-domain";
import {CallbacksContext} from './context-components/context/callbacks-context/callbacks-context'
import Callbacks from "./context-components/callbacks";
import PreparationsStyles from "./shared-components/wp-components/preparation-styles";
import {RedirectWindowContext} from "./context-components/context/redirect-window-context/redirect-window-context";
import RedirectWindow from "./context-components/redirect-window";
import DelayLoadingWrapper from "./shared-components/wp-components/delay-loading-wrapper";
import history from "./variables/history";

const praxisService = new PraxisService();
const praxisInternalization = new InternalizationService();
const callbacks = new Callbacks();
const redirect = new RedirectWindow();

const Start = (
    <DelayLoadingWrapper>
        <ReadTokenFromUrl>
                <ProcessingComputeData>
                    <ProcessingCustomerData>
                        <ConnectParentDomain>
                            <PreparationsStyles>
                                <App/>
                            </PreparationsStyles>
                        </ConnectParentDomain>
                    </ProcessingCustomerData>
                </ProcessingComputeData>
        </ReadTokenFromUrl>
    </DelayLoadingWrapper>
);


ReactDOM.render(
    <Provider store={store}>
        <PraxisServiceProvider value={praxisService}>
            <PraxisI18NProvider value={praxisInternalization}>
                <CallbacksContext.Provider value={callbacks}>
                    <RedirectWindowContext.Provider value={redirect}>
                        <Router history={history}>
                            <ErrorBoundry>
                                {Start}
                            </ErrorBoundry>
                        </Router>
                    </RedirectWindowContext.Provider>
                </CallbacksContext.Provider>
            </PraxisI18NProvider>
        </PraxisServiceProvider>
    </Provider>,
    document.getElementById('root')
);

