import {defineDecimalPlaces} from "./get-decimal-places";

export const mathCeilDecimal = (value, currency) => {
    const exp = defineDecimalPlaces(currency) * -1
    return decimalAdjust(value, exp);
}

const decimalAdjust = (value, exp) => {
    value = +value;
    exp = +exp;
    // Если значение не является числом, либо степень не является целым числом...
    if (isNaN(value)) return NaN;
    value = value.toFixed(10)
    // Сдвиг разрядов
    value = value.toString().split('e');
    value = Math.ceil(+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
    // Обратный сдвиг
    value = value.toString().split('e');
    return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
}
