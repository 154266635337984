import store from "../../state/store"

const threeChar = ['BHD','IQD','JOD','KWD','LYD','OMR','TND'];
const noChar = [
    'BIF', 'CLP', 'DJF', 'GNF', 'ISK', 'JPY', 'KMF', 'KRW',
    'PYG', 'RWF', 'UGX', 'UYI', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'
];

export const defineDecimalPlaces = (currency) => {
    const {gatewayInfo:{cryptoList=[]}} = store.getState();
    let decimalPlaces = 2;
    if(cryptoList.includes(currency)) decimalPlaces = 8;
    if(threeChar.includes(currency)) decimalPlaces = 3;
    if(noChar.includes(currency)) decimalPlaces = 0;
    return decimalPlaces
}

