export const cs = {
  "Loading... Please Wait": "Načítání prosím čekejte",
  yourTransactionIsPendingForPayOut: "Vaše žádost o výběr čeká na vyřízení",
  status1:
    "Uvědomte si, že vaši transakci kontroluje zpracovatelská banka nebo poskytovatel platebních služeb. Pokud byste chtěli získat více informací o stavu své transakce, kontaktujte podporu a požádejte o pomoc s ID trasování: {{trace_id}}",
  Status: "Postavení",
  "Deposit Review For Account:": "Kontrola vkladu pro účet:",
  "New Transaction": "Nová transakce",
  Update: "Aktualizace",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Způsob platby, který jste vybrali, se otevře na samostatné stránce. Kliknutím na 'POKRAČOVAT' přejděte do nového okna prohlížeče a pokračujte v procesu platby.",
  dod_error_text:
    "Chcete-li pokračovat, musíte si přečíst a přijmout prohlášení o vkladu",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Abyste mohli pokračovat ve vkladu, musíte přijmout Podmínky",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Beru na vědomí, že jsem si přečetl, a přijímám tímto Podmínky",
  dod_url: "#",
  dod_url_text: "Pravidla a podmínky",
  dod_text: "Beru na vědomí, že jsem četl, a přijímám tímto {{dod_url}}",
  transactionSuccessfullyCanceled: "Transakce {{id}} byla úspěšně zrušena",
  "Fee should not exceed 20% of the amount":
    "Poplatek by neměl překročit 20% částky",
  "My Info": "Moje informace",
  "Billing info": "Fakturační údaje",
  "Withdrawal Requests": "Žádosti o výběr",
  "No Withdrawal Requests found": "Nebyly nalezeny žádné žádosti o výběr",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "zrušení",
  Amount: "Množství",
  "Last Update": "Poslední aktualizace",
  "Payment Method": "Způsob platby",
  TID: "ČAS",
  Action: "Akce",
  "Confirmation required": "Potvrzení vyžadováno",
  "Are you sure you want to cancel transaction":
    "Opravdu chcete transakci zrušit",
  Yes: "Ano",
  No: "Ne",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Minimální a maximální částky",
  "Fee (included)": "Poplatek (v ceně)",
  "- Amount between": "- Částka mezi",
  "I have the Promocode!": "Mám promo kód!",
  "Please enter the Promocode": "Zadejte prosím promo kód",
  Promocode: "Promo kód",
  noSavedTemplates: "Žádné uložené karty",
  noOptions: "Žádné možnosti",
  "Please confirm your billing details": "Potvrďte prosím své fakturační údaje",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Poplatek za zpracování",
  Fee: "Poplatek",
  Total: "Celkový",
  customer_support_link_text: "Zákaznická podpora",
  terms_conditions_link_text: "Pravidla a podmínky",
  "Edit Billing Info": "Upravit fakturační údaje",
  chooseAnAccount: "Choose an account",
  "Requested to Receive": "Požadováno pro příjem",
  "Receive With": "Přijmout s",
  "Unknown session": "Neznámá relace",
  transactionId: "ID transakce",
  traceId: "Trace ID",
  save: "Uložit",
  fillTheBillingInformation: "Vyplňte prosím níže fakturační údaje",
  correctionsRequired: "požadované opravy",
  billingInfo: "Fakturační údaje",
  "Cancelled by client": "Zrušeno klientem",
  "Your Cards": "Vaše karty",
  Done: "Hotovo",
  redirect: "Redirect",
  redirectingTo: "Přesměrování na",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Transakce nebyla úspěšná. Je nám líto, ale uvedený účet PayPal se v našich záznamech zobrazuje pod obchodním účtem jiného zákazníka.“: „Transakce nebyla úspěšná. Je nám líto, ale uvedený účet PayPal se v našich záznamech zobrazuje pod jiným obchodní účet zákazníka, pokud potřebujete pomoc s účtem, kontaktujte zákaznický servis.",
  "Not supported yet": "Ještě není podporováno",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Omlouvám se, zdá se, že nemáte zaregistrovány žádné platební údaje, které byste mohli použít pro žádost o výběr“: „Omlouvám se, zdá se, že nemáte zaregistrované žádné platební údaje, které byste mohli použít pro žádost o výběr",
  "Not available gateways found": "Nebyly nalezeny brány",
  "Token is invalid": "Token je neplatný",
  "Payment method not available. Please contact your site administrator.":
    "Platební metoda není k dispozici. Kontaktujte svého správce webu.",
  cardIsNotValid: "Karta není platná",
  invalidInputFormat: "Neplatný vstupní formát. Minimálně {{length}} znaků.",
  invalidDateFormat: "Neplatný formát data.",
  maximumValueIs: "Maximální hodnota je",
  minimumValueIs: "Minimální hodnota je",
  enterPayoutAmount:
    "Zadejte částku platby menší nebo rovnou vašemu disponibilnímu zůstatku.",
  maxLength2: "Maximální délka 2 znaky.",
  footer1:
    "Některé vydávající banky omezily používání kreditních karet pro online zábavu. Můžete použít některou z výše uvedených alternativních platebních metod třetích stran nebo se obrátit na naše {{customer_support_url}} zástupce zdarma na N / A za účelem financování vašeho účtu. \\ n Poznámka: V závislosti na vaší vydávající bance mohou být transakce s kreditními kartami považovány za hotovostní zálohu. {{application_key}} nemůže nést odpovědnost za žádné poplatky za hotovostní zálohy spojené s touto transakcí, protože to stanoví banka vydávající kreditní kartu. Další podrobnosti získáte u své banky.",
  footer2:
    "O pomoc nebo potvrzení pokusu o transakci se obraťte na {{customer_support_url}}. \\ N Poznámka: V závislosti na vaší bance může být tato transakce považována za hotovostní zálohu. {{application_key}} nenese odpovědnost za žádné poplatky za hotovostní zálohy spojené s touto transakcí. Transakce bude na vašem výpisu z kreditní karty označena jako deskriptor kreditní karty, pokud není na potvrzení o transakci uvedeno jinak. ",
  footer3:
    "Všechny žádosti o výplatu podléhají našim {{terms_conditions_url}}. Pokud potřebujete pomoc nebo další informace, kontaktujte {{customer_support_url}}. Jakmile je váš požadavek odeslán, informace jsou předány ke zpracování našemu pokladnímu oddělení. Až bude zpracována vaše platba, obdržíte e-mail s potvrzením. Částka platby a poplatek za zpracování (budou-li relevantní) budou z vašeho účtu odečteny po kontrole platby.",
  gatewaysTitle: "Jak chcete platit?",
  gatewaysTitleDeposit: "Vyberte způsob vkladu",
  gatewaysTitleWithdrawal: "Vyberte metodu výběru",
  payInTitle: "Plaťte kreditní nebo debetní kartou",
  allPaymentMethods: "všechny platební metody",
  submit: "Submit",
  useAddressOnFile: "Použít adresu v souboru",
  customerName: "Jméno zákazníka",
  country: "Country",
  province: "provincie",
  city: "Město",
  postalCode: "PSČ",
  street: "Ulice",
  proceed: "Pokračovat",
  cardNumber: "Číslo karty",
  cardHolder: "Držitel karty",
  cardHolderName: "Jméno držitele karty (pouze latinka)",
  cardExpires: "Karta vyprší",
  cvv: "CVV",
  firstName: "Jméno",
  lastName: "Příjmení",
  zipCode: "PSČ",
  address: "Adresa",
  state: "Stát",
  email: "E-mailem",
  phone: "Telefon",
  dateOfBirth: "Datum narození",
  transactionStatus: "Stav transakce",
  trace: "Stopa",
  amount: "Částka",
  min: "min",
  max: "max",
  overrideLimits: "Přepsat limity",
  transaction: "Transakce",
  processed: "Zpracováno uživatelem",
  transactionRejected: "Transakce zamítnuta",
  transactionApproved: "Transakce schválena",
  yourTransactionIsPending: "Vaše transakce čeká na vyřízení",
  transactionAuthorized: "Transakce schválena",
  requestAccepted: "Žádost přijata",
  status2:
    "Vaše žádost o výběr byla přijata. Berte na vědomí, že další povolení a zpracování bude provedeno během provozních hodin pracovních dnů. Během této doby vás mohou kontaktovat vedoucí pracovníci pro potvrzení identity.",
  unexpectedErrorOccurred: "Došlo k neočekávané chybě",
  status3:
    "Omlouváme se, při zpracování vaší transakce se něco pokazilo. Níže naleznete technické podrobnosti:",
  tryAgain: "Zkusit znovu",
  somethingWentWrong: "Něco se pokazilo ...",
  newPayoutRequest: "Nová žádost o platbu",
  makeAnotherPayment: "Provést další platbu",
  profile: "Profil",
  justOneMoreStep: "Ještě jeden krok ...",
  "profile-auth":
    'Chcete-li pokračovat v platbě pomocí {{title}}", vyplňte prosím svůj profil, ',
  payWith: "Platit",
  ProceedWith: "Postupovat s",
  vtTitle: "Virtuální terminál:",
  pay: "Platit",
  deposit: "Vklad",
  withdraw: "Ustoupit",
  withdrawal: "Výběr",
  hello: "Ahoj",
  currency: "Měna",
  currentIp: "Aktuální IP:",
  UseCurrentIp: "Použít aktuální IP:",
  UseCustomerIp: "Use customer IP:",
  copyright: "Autorská práva",
  chooseAmount: "Vyberte částku",
  cannotBeBlank: "Nemůže být prázdné.",
  noDepositFree: "Žádný poplatek za vklad. Žádný poplatek za výběr.",
  next: "Další",
  backToSite: "zpět na web",
  maximumLength: "Maximální délka {{length}} znaků",
  minimumLength: "Minimální délka {{length}} znaků",
  emailIsNotValid: "E-mail není platná e-mailová adresa.",
  phoneIsNotValid: "Telefon není platný",
  cardHolderInvalid:
    "Držitel karty je neplatný. Držitel karty by měl obsahovat jméno a příjmení.",
  pleaseEnterPayout: "Zadejte částku platby menší nebo rovnou",
  invalidAmount: "Neplatná částka",
  transactionWillAppear: "Vaše transakce se ve výpisu zobrazí jako",
  "Customer does not exist": "Zákazník neexistuje",
  "Sorry no available gateways found.":
    "Litujeme, nenalezeny žádné dostupné brány.",
  "Incorrect currency": "Nesprávná měna",
  "Sorry, not supported payment type": "Je nám líto, nepodporovaný typ platby",
  "Payment method has no payment type": "Platební metoda nemá žádný typ platby",
  "Incorrect payment type in payment method":
    "Nesprávný typ platby ve způsobu platby",
  "Gateway limit exceeded": "Překročen limit brány",
  "Not supported transaction type": "Nepodporovaný typ transakce",
  "Sorry. Payout with credit card gateway not supported yet":
    "Je nám líto. Výplata s bránou kreditní karty ještě není podporována",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Omlouváme se, tato platební metoda není pro zadané platební údaje použitelná. Znovu načtěte stránku a zkuste jinou částku, měnu nebo platební metodu.",
  "Back to site": "Zpět na web",
  "Your transaction has been requested. Please wait for approval.":
    "Vaše transakce byla vyžádána. Počkejte prosím na schválení.",
  "Token expired": "Token vypršel",
  back: "ZADNÍ",
  "Transaction not found.": "Transakce nebyla nalezena",
  "Continue in the New Window": "Pokračujte v novém okně",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Nezavírejte prosím toto okno. Po dokončení transakce v novém okně budete přesměrováni zpět. Nevidíte nové okno otevírající zabezpečené připojení? Jsme tu, abychom vám pomohli dokončit vaši transakci.",
  "Click to continue": "Klikni pro pokračování",
  "Just One More Step...": "Ještě jeden krok ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Způsob platby, který jste vybrali, se otevře na nové stránce. Kliknutím na tlačítko 'POKRAČOVAT' níže přejdete k poslednímu kroku.",
  Proceed: "Pokračovat",
  "Opening a Secure Connection...": "Otevření zabezpečeného připojení ...",
  "You will be redirected to complete your transaction in just a moment":
    "Budete přesměrováni k dokončení transakce za okamžik",
  "Close window": "Zavřít okno",
  "Partial approval confirmation": "Částečné potvrzení schválení",
  PartiallyApprovedAmount:
    "Zůstatek na vaší kartě nemá dostatek prostředků k připsání celé částky. Transakce byla částečně schválena za {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Kliknutím na „OK“ potvrdíte částku částečného vkladu nebo na „ZRUŠIT“ transakci zrušíte. Pokud neprovedete žádnou akci, vklad bude automaticky potvrzen po 5 minutách.",
  fxRateSpreadText: "Uvedený směnný kurz zahrnuje všechny poplatky a platby.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Načítání prosím čekejte",
};
