import React, {useEffect, useState} from 'react'
import {withAdr} from "../hoc-components/with-adr";
import compose from "utils/helper-functions/compose";
import {minStyles} from "variables/min-styles";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {
    createStyles,
    defineBorderStyle,
    defineFontFamily,
    setVariablesStyle,
    getThemeEditorSettingsVariables,
    createColorPaletteVariables
} from "utils/helper-functions/create-styles";
import Loader from "../helper-components/loader/loader";
import {withPraxisService} from "../hoc-components";
import {perfBreak} from "utils/performance-functions/perf-break";
import InternalizationService from "../../context-components/internalization";
import {
    accentNegativePalleteDay,
    accentNegativePalleteNight,
    accentPositivePalleteDay,
    accentPositivePalleteNight,
    accentWarningPalleteDay,
    accentWarningPalleteNight,
    mainPalleteDay,
    mainPalleteNight,
    neutralPalleteDay,
    neutralPalleteNight,
    primaryPalleteDay,
    primaryPalleteNight
} from "@pano-project/assets/styles/variables/color_schema";
import {changeInitTheme} from "state/actions";
import {isPanoramicTheme} from "@pano-project/variables/is-panoramic-2-0-theme";
import {isDarkSystemAppearance} from "../../@pano-project/variables/theme-mode";
import {filterObject} from "../../utils/helper-functions/filter-object";

const i18n = new InternalizationService();

const PreparationStyles = (props) => {
    const {children, sMinStyles, praxisService: {timeInfoUpdate}} = props;
    const dispatch = useDispatch();
    const {encryptSelectors, cashier_theme} = useSelector(({
                                                               initTheme: {
                                                                   encryptSelectors,
                                                                   cashier_theme
                                                               }
                                                           }) =>
        ({cashier_theme, encryptSelectors}), shallowEqual);
    const {customization_settings} = useSelector(({
                                                 initTheme: {
                                                     theme_editor_settings:{customization_settings = {}} = {}
                                                 }
                                             }) =>
        ({customization_settings}), shallowEqual);
    let {appearance = '',
        brand_colors_light,
        brand_colors_dark,
        brand_text_colors_light,
        brand_text_colors_dark,
        button_style='',
        font=''
    } = customization_settings;
    const {getCustomStyles} = i18n;
    const [loader, setLoader] = useState(true);
    const [pciVariablesString, setPciVariablesString] = useState('');
    
    
    useEffect(() => {
        if (!isPanoramicTheme) {
            insertCustomCss();
            setLoader(false);
        } else {
            const themeAppearance = defineAppearance(appearance);
            const brandTextColorDark = brand_text_colors_dark ?? '#F2F2F2';
            const brandTextColorLight = brand_text_colors_light ?? '#F2F2F2';
            const primary_btn_color_text = themeAppearance === 'dark' ? brandTextColorDark : brandTextColorLight
            const themeObj = {
                border_style: defineBorderStyle(button_style),
                font_family: defineFontFamily(font),
                font_size: '16px',
                primary_btn_color_text,
                shadow_color: 'rgb(112, 144, 176)'
            }
            insertThemeVariables(themeAppearance, themeObj);
            setLoader(false);
        }
    }, [cashier_theme,
        button_style,
        font,
        appearance,
        brand_colors_dark,
        brand_colors_light,
        brand_text_colors_dark,
        brand_text_colors_light]
    )
    
    useEffect(() => {
        dispatch(changeInitTheme({pciVariablesStyle: `:root {${pciVariablesString}}`}));
    }, [pciVariablesString, appearance])
    
    const insertCustomCss = () => {
        if (encryptSelectors) encryptStyles();
        executeCashierCss(getCustomStyles());
    }
    
    const insertThemeVariables = (appearance, themeObj) => {
        const brandColorsDark = brand_colors_dark ?? '#0F758A';
        const brandColorsLight = brand_colors_light ?? '#0F768A';
        const colors = {primary: appearance === 'dark' ? brandColorsDark : brandColorsLight}
        const themeEditorVariables = getThemeEditorSettingsVariables(themeObj);
        setPciVariablesString(themeEditorVariables);
        setBrandColors(appearance, filterObject({obj:colors, allowEmpty: true}));
        setVariablesStyle(themeEditorVariables, `:root`,'theme-editor-settings');
    }

    const defineAppearance = (appearance) => {
        if(appearance === 'system') return isDarkSystemAppearance ? 'dark' : 'light';
        return appearance;
    }
    
    const executeCashierCss = () => {
        let customStyles = getCustomStyles();
        if (customStyles) {
            if (encryptSelectors) customStyles = sMinStyles(customStyles, encryptSelectors)
            createStyles(customStyles)
        }
    }
    
    const encryptStyles = () => {
        const start = perfBreak()
        createStyles(sMinStyles(minStyles))
        const createEncryptStyles = (perfBreak() - start).toFixed(3);
        timeInfoUpdate({createEncryptStyles})
    }
    
    const setBrandColors = (theme, colorsSet = {}) => {
        const isDarkTheme = theme === 'dark';
        const variablesSelector = `:root`;
        const mainPalette = isDarkTheme ? mainPalleteNight : mainPalleteDay;
        const primaryPalette = isDarkTheme ? primaryPalleteNight : primaryPalleteDay;
        const neutralPalette = isDarkTheme ? neutralPalleteNight : neutralPalleteDay;
        const accentNegativePalette = isDarkTheme ? accentNegativePalleteNight : accentNegativePalleteDay;
        const accentPositivePalette = isDarkTheme ? accentPositivePalleteNight : accentPositivePalleteDay;
        const accentWarningPalette = isDarkTheme ? accentWarningPalleteNight : accentWarningPalleteDay;
        const colorsSchema = {
            main: isDarkTheme ? '#121212' : '#FFFFFF',
            primary: isDarkTheme ? '#0F758A' : '#0F768A',
            neutral: isDarkTheme ? '#8D97AA' : '#8A94A8',
            accent_negative: isDarkTheme ? '#F5475B' : '#F53D53',
            accent_positive: isDarkTheme ? '#41C8B3' : '#3FC0AC',
            accent_warning: isDarkTheme ? '#FFA64D' : '#FFA64D',
            ...colorsSet
        }
        const main = createColorPaletteVariables(['main_0', colorsSchema.main], mainPalette);
        setVariablesStyle(main, variablesSelector, 'mainPalette');
        const primary = createColorPaletteVariables(['primary_active', colorsSchema.primary], primaryPalette);
        setVariablesStyle(primary, variablesSelector, 'primaryPalette');
        const neutral = createColorPaletteVariables(['neutral_active', colorsSchema.neutral], neutralPalette);
        setVariablesStyle(neutral, variablesSelector, 'neutralPalette');
        const accent_negative = createColorPaletteVariables(['accent_negative', colorsSchema.accent_negative], accentNegativePalette);
        setVariablesStyle(accent_negative, variablesSelector, 'accentNegativePalette');
        const accent_positive = createColorPaletteVariables(['accent_positive', colorsSchema.accent_positive], accentPositivePalette);
        setVariablesStyle(accent_positive, variablesSelector, 'accentPositivePalette');
        const accent_warning = createColorPaletteVariables(['accent_warning', colorsSchema.accent_warning], accentWarningPalette);
        setVariablesStyle(accent_warning, variablesSelector, 'accentWarningPalette');
        const pciColorsVariables = `${main} ${primary} ${neutral} ${accent_negative} ${accent_positive} ${accent_warning}`;
        setPciVariablesString((prev) => `${prev} ${pciColorsVariables}`);
    }
    
    if (loader) return <Loader/>;
    return children
}

export default compose(
    withAdr, withPraxisService()
)(PreparationStyles)


