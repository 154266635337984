import reducer from "./reducers";
import {composeWithDevTools} from '@redux-devtools/extension';
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {config} from "../config/config";

const store = process.env.NODE_ENV === 'development' || config?.reduxDevToolsEnabled ?
    createStore(reducer, composeWithDevTools(applyMiddleware(thunk))) : createStore(reducer, applyMiddleware(thunk))

export default store;
