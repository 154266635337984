import CryptoJS from "crypto-js";
import {config} from "../../config/config";

export const encrypt = (key, data="") => {
    const {configName:name} = config;
    let cipher = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
        iv: CryptoJS.enc.Utf8.parse(
            ['config3','config8'].includes(name)? '9Z9hMmFTPKhxDW01':
                   name === 'config2'? 'nTzMJDY1tgEaDRe4' : '908a1983De7027fD'
        ),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });
    return cipher.toString()
}