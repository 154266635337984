import {
    //   addFieldsWithDefaultValue,
    //   addFieldsWithTemplates,
    //   creditCardWithoutBaseCurrency,
    //   customProcessor,
    //   defaultAddFieldsAllTypesAndHTMLVariables,
    //   defaultCreditCardWithoutTemplates,
    defaultCreditCardWithTemplates,
    PayPal,
    neteller,
    Skrill,
    WireTransfer,
    ManualBankWire,
    TrueLayer, Volt, Ecommpay, Help2Pay
} from "./gateways";

export const gateways = {
  gateways: [
    // defaultCreditCardWithoutTemplates,
    defaultCreditCardWithTemplates,
    // creditCardWithoutBaseCurrency,
    // defaultAddFieldsAllTypesAndHTMLVariables,
    // addFieldsWithDefaultValue,
    // addFieldsWithTemplates,
    PayPal,
    neteller,
    Skrill,
      PayPal,
      neteller,
      Skrill,
    WireTransfer,
    ManualBankWire,
  ],
  status: 0,
  title: "Credit Card",
  send_get_available_gateways_request: 1,
  is_bdcc_window: 0
};

export const obGateways = {
  gateways: [
    TrueLayer,
      Volt, Ecommpay, Help2Pay
  ],
  status: 0,
  title: "Credit Card",
  send_get_available_gateways_request: 0,
  is_bdcc_window: 0
}
