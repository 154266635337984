import React, {useEffect, useState} from 'react'
import {withI18N} from "../hoc-components";
import compose from "../../utils/helper-functions/compose";
import Loader from "../helper-components/loader/loader";
import {config} from "../../config/config";
import {updateProfile} from "../../state/actions";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {createFatalError} from "../../state/actions/errors-actions";

const ProcessingCustomerData = ({children}) => {
    const location = useSelector(({location}) => location, shallowEqual);
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const isCallback = defineIsCallback();
        const callback = isCallback ? 1 : 0;
        dispatch(updateProfile(callback))
            .then((data) => {
                processingData(data).then(() => setLoader(false))
            })
    }, [updateProfile, location]);

    const defineIsCallback = () => {
        try {
            const url = new URL(location?.href);
            const queryKeys = Array.from(url?.searchParams?.keys());
            return queryKeys?.includes('callback');
        } catch (err) {
            console.error("Cant parse url", err);
            return false;
        }
    }

    const processingData = async(profile={}) => {
        const {customer:{country, balance}={}, base_amount, frontend_name:name, session_type, transaction_type:type} = profile;

        if (session_type === 'vt') {
            document.title = `Terminal | ${name? name : ""}`;
        } else if (session_type === 'cashier') {
            document.title = `${config.title} | ${name? name : ""}`;
        }

        if (type === "pay-out" && base_amount && balance && +base_amount > +balance) {
            dispatch(createFatalError("Please try again with lower amount."))
        }

        if (country) {
            document.documentElement.setAttribute("data-country", country);
        } 
    
    };

    if(loader) return <Loader/>;

    return children
};

export default compose(
    withI18N
)(ProcessingCustomerData)
