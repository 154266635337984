export const sk = {
  "Loading... Please Wait": "Načitáva sa, prosím čakajte",
  yourTransactionIsPendingForPayOut: "Vaša žiadosť o výber čaká na spracovanie",
  status1:
    "Upozorňujeme, že vašu transakciu kontroluje spracovateľská banka alebo poskytovateľ platobných služieb. Ak chcete získať viac informácií o stave vašej transakcie, kontaktujte podporu a požiadajte o pomoc so sledovacím ID: {{trace_id}}",
  Status: "Postavenie",
  "Deposit Review For Account:": "Kontrola vkladu pre účet:",
  "New Transaction": "Nová transakcia",
  Update: "Aktualizácia",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Spôsob platby, ktorý ste vybrali, sa otvorí na samostatnej stránke. Kliknutím na tlačidlo 'POSTUPOVAŤ' prejdete do nového okna prehliadača a pokračujete v procese platby.",
  dod_error_text:
    "Ak chcete pokračovať, musíte si prečítať a prijať vyhlásenie o vklade",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Ak chcete pokračovať v vklade, musíte prijať zmluvné podmienky",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Beriem na vedomie, že som si prečítal a týmto prijímam zmluvné podmienky",
  dod_url: "#",
  dod_url_text: "Podmienky",
  dod_text:
    "Beriem na vedomie, že som si prečítal a týmto prijímam zmluvné {{dod_url}}",
  transactionSuccessfullyCanceled: "Transakcia {{id}} bola úspešne zrušená",
  "Fee should not exceed 20% of the amount":
    "Poplatok by nemal presiahnuť 20% sumy",
  "My Info": "Moje informácie",
  "Billing info": "platobné údaje",
  "Withdrawal Requests": "Žiadosti o výber",
  "No Withdrawal Requests found": "Nenašli sa žiadne žiadosti o výber",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Zrušiť",
  Amount: "Množstvo",
  "Last Update": "Posledná aktualizácia",
  "Payment Method": "Spôsob platby",
  TID: "TIME",
  Action: "Akcia",
  "Confirmation required": "Je potrebné potvrdenie",
  "Are you sure you want to cancel transaction":
    "Naozaj chcete zrušiť transakciu",
  Yes: "Áno",
  No: "Nie",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Minimálne a maximálne sumy",
  "Fee (included)": "Poplatok (v cene)",
  "- Amount between": "- Suma medzi",
  "I have the Promocode!": "Mám promo kód!",
  "Please enter the Promocode": "Zadajte promo kód",
  Promocode: "Promo kód",
  noSavedTemplates: "Nie sú uložené žiadne karty",
  noOptions: "Žiadne možnosti",
  "Please confirm your billing details": "Potvrďte svoje fakturačné údaje",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Poplatok za spracovanie",
  Fee: "Poplatok",
  Total: "Celkom",
  customer_support_link_text: "Zákaznícka podpora",
  terms_conditions_link_text: "Podmienky",
  "Edit Billing Info": "Upraviť fakturačné údaje",
  chooseAnAccount: "Vyberte účet",
  "Requested to Receive": "Žiadosť o príjem",
  "Receive With": "Príjem s",
  "Unknown session": "Neznáma relácia",
  transactionId: "ID transakcie",
  traceId: "Stopové ID",
  save: "Uložiť",
  fillTheBillingInformation: "Vyplňte fakturačné údaje nižšie",
  correctionsRequired: "potrebné opravy",
  billingInfo: "platobné údaje",
  "Cancelled by client": "Zrušené klientom",
  "Your Cards": "Vaše karty",
  Done: "hotový",
  redirect: "Presmerovanie",
  redirectingTo: "Presmerováva sa na",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Transakcia nebola úspešná. Je nám ľúto, ale uvedený účet PayPal sa v našich záznamoch zobrazuje pod obchodným účtom iného zákazníka. Ak potrebujete so svojím účtom pomoc, kontaktujte zákaznícky servis.",
  "Not supported yet": "Zatiaľ nie je podporované",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Ospravedlňujeme sa, zdá sa, že nemáte zaregistrované žiadne platobné podrobnosti, ktoré by ste mohli použiť pri žiadosti o výber",
  "Not available gateways found": "Nenašli sa dostupné brány",
  "Token is invalid": "Token je neplatný",
  "Payment method not available. Please contact your site administrator.":
    "Spôsob platby nie je k dispozícii. Kontaktujte svojho správcu stránky.",
  cardIsNotValid: "Karta je neplatná",
  invalidInputFormat: "Neplatný vstupný formát. Minimálne {{length}} znakov.",
  invalidDateFormat: "Neplatný formát dátumu.",
  maximumValueIs: "Maximálna hodnota je",
  minimumValueIs: "Minimálna hodnota je",
  enterPayoutAmount:
    "Zadajte čiastku platby menšiu alebo rovnú vášmu disponibilnému zostatku.",
  maxLength2: "Maximálna dĺžka 2 znaky.",
  footer1:
    "Niektoré vydávajúce banky obmedzili použitie kreditných kariet na online zábavu. Neváhajte a využite niektorý z vyššie uvedených alternatívnych spôsobov platby tretích strán alebo kontaktujte našich {{customer_support_url}} zástupcov bezplatne na N / A, aby ste mohli financovať svoj účet.\n" +
    "  Poznámka: V závislosti na vydávajúcej banke sa transakcie kreditnou kartou môžu považovať za hotovostnú zálohu. Spoločnosť {{application_key}} nemôže niesť zodpovednosť za akékoľvek poplatky za hotovostné zálohy spojené s touto transakciou, pretože sú stanovené bankou, ktorá vydala kreditnú kartu. Ďalšie informácie získate od svojej banky.",
  footer2:
    "Ak potrebujete pomoc alebo potvrdenie pokusu o transakciu, kontaktujte {{customer_support_url}}.\n" +
    "  Poznámka: V závislosti od vašej banky môže byť táto transakcia považovaná za hotovostnú zálohu. {{application_key}} nebude zodpovedný za žiadne poplatky spojené s hotovostnou zálohou spojené s touto transakciou. Transakcia bude na vašom výpise z kreditnej karty identifikovaná ako deskriptor kreditnej karty, pokiaľ na vašom potvrdení o transakcii nie je uvedené inak.",
  footer3:
    "Na všetky žiadosti o platbu sa vzťahujú naše {{terms_conditions_url}}. Ak potrebujete pomoc alebo ďalšie informácie, kontaktujte {{customer_support_url}}. Po odoslaní vašej žiadosti sa tieto informácie postúpia nášmu pokladničnému oddeleniu na spracovanie. Po spracovaní platby dostanete e-mail s potvrdením. Suma platby a poplatok za spracovanie (ak sú uplatniteľné) budú z nášho účtu odpočítané po kontrole platby.",
  gatewaysTitle: "Ako by ste chceli platiť?",
  gatewaysTitleDeposit: "Vyberte spôsob vkladu",
  gatewaysTitleWithdrawal: "Vyberte spôsob výberu",
  payInTitle: "Plaťte kreditnou alebo debetnou kartou",
  allPaymentMethods: "všetky spôsoby platby",
  submit: "Predložiť",
  useAddressOnFile: "Použite adresu v zozname",
  customerName: "Meno zákazníka",
  country: "Krajina",
  province: "Provincie",
  city: "Mesto",
  postalCode: "Poštové smerovacie číslo",
  street: "Ulica",
  proceed: "Pokračovať",
  cardNumber: "Číslo karty",
  cardHolder: "Držiteľ karty",
  cardHolderName: "Meno držiteľa karty (iba latinka)",
  cardExpires: "Platnosť karty vyprší",
  cvv: "CVV",
  firstName: "Krstné meno",
  lastName: "Priezvisko",
  zipCode: "PSČ",
  address: "Adresa",
  state: "Štát",
  email: "Email",
  phone: "Telefón",
  dateOfBirth: "Dátum narodenia",
  transactionStatus: "Stav transakcie",
  trace: "Trace",
  amount: "Množstvo",
  min: "min",
  max: "max",
  overrideLimits: "Prepísať limity",
  transaction: "Transakcia",
  processed: "Spracované používateľom",
  transactionRejected: "Transakcia odmietnutá",
  transactionApproved: "Transakcia bola schválená",
  yourTransactionIsPending: "Vaša transakcia čaká na spracovanie",
  transactionAuthorized: "Transakcia bola autorizovaná",
  requestAccepted: "Žiadosť prijatá",
  status2:
    "Vaša žiadosť o výber bola prijatá. Berte na vedomie, že ďalšia autorizácia a spracovanie budú vykonané v prevádzkových hodinách pracovných dní. Počas tejto doby vás môžu kontaktovať riadiaci pracovníci s cieľom potvrdiť totožnosť.",
  unexpectedErrorOccurred: "Vyskytla sa neočakávaná chyba",
  status3:
    "Ospravedlňujeme sa, pri spracovaní vašej transakcie sa vyskytla chyba. Nižšie nájdete technické podrobnosti:",
  tryAgain: "Skúste to znova",
  somethingWentWrong: "Niečo sa pokazilo...",
  newPayoutRequest: "Nová žiadosť o platbu",
  makeAnotherPayment: "Uskutočniť ďalšiu platbu",
  profile: "Profil",
  justOneMoreStep: "Už len jeden krok ...",
  "profile-auth":
    "Vyplňte prosím svoj profil, aby ste mohli pokračovať v platbe pomocou nástroja {{title}}",
  payWith: "Platiť s",
  ProceedWith: "Pokračuj s",
  vtTitle: "Virtuálny terminál: ",
  pay: "Zaplať",
  deposit: "Záloha",
  withdraw: "Vyberte",
  withdrawal: "Odstúpenie",
  hello: "Ahoj,",
  currency: "Mena",
  currentIp: "Aktuálna adresa IP: ",
  UseCurrentIp: "Použiť aktuálnu IP: ",
  UseCustomerIp: "Použiť IP zákazníka: ",
  copyright: "Autorské práva",
  chooseAmount: "Zvoľte množstvo",
  cannotBeBlank: "Nemôže byť prázdne.",
  noDepositFree: "Žiadny poplatok za vklad. žiadny poplatok za výber.",
  next: "Ďalšie",
  backToSite: "späť na web",
  maximumLength: "Maximálna dĺžka {{length}} znakov",
  minimumLength: "Minimálna dĺžka {{length}} znakov",
  emailIsNotValid: "E-mail nie je platná e-mailová adresa.",
  phoneIsNotValid: "Telefón nie je platný",
  cardHolderInvalid:
    "Držiteľ karty je neplatný. Držiteľ karty by mal obsahovať meno a priezvisko.",
  pleaseEnterPayout: "Zadajte čiastku platby nižšiu alebo rovnú",
  invalidAmount: "Neplatná suma",
  transactionWillAppear: "Vaša transakcia sa na vašom výpise zobrazí ako",
  "Customer does not exist": "Zákazník neexistuje",
  "Sorry no available gateways found.":
    "Ľutujeme, nenašli sa žiadne dostupné brány.",
  "Incorrect currency": "Nesprávna mena",
  "Sorry, not supported payment type": "Ľutujeme, nepodporovaný typ platby",
  "Payment method has no payment type": "Spôsob platby nemá žiadny typ platby",
  "Incorrect payment type in payment method":
    "Nesprávny typ platby v spôsobe platby",
  "Gateway limit exceeded": "Bol prekročený limit brány",
  "Not supported transaction type": "Nepodporovaný typ transakcie",
  "Sorry. Payout with credit card gateway not supported yet":
    "Prepáč. Výplata pomocou brány kreditných kariet zatiaľ nie je podporovaná",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Ospravedlňujeme sa, tento spôsob platby sa na uvedené platobné podrobnosti nevzťahuje. Znova načítajte stránku a skúste inú sumu, menu alebo spôsob platby.",
  "Back to site": "Späť na stránku",
  "Your transaction has been requested. Please wait for approval.":
    "Vaša transakcia bola vyžiadaná. Počkajte prosím na schválenie.",
  "Token expired": "Platnosť tokenu skončila",
  back: "SPÄŤ",
  "Transaction not found.": "Transakcia sa nenašla",
  "Continue in the New Window": "Pokračujte v novom okne",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Nezatvárajte prosím toto okno. Po dokončení transakcie v novom okne budete presmerovaní späť. Nevidíte nové okno otvárajúce zabezpečené pripojenie? Sme tu, aby sme vám pomohli dokončiť transakciu.",
  "Click to continue": "Pokračujte kliknutím",
  "Just One More Step...": "Ešte jeden krok ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Spôsob platby, ktorý ste vybrali, sa otvorí na novej stránke. Kliknutím na nižšie uvedené tlačidlo 'POKROČIŤ' prejdete na posledný krok.",
  Proceed: "Pokračovať",
  "Opening a Secure Connection...": "Otvorí sa zabezpečené pripojenie ...",
  "You will be redirected to complete your transaction in just a moment":
    "Budete presmerovaní na dokončenie transakcie za chvíľu",
  "Close window": "Zatvor okno",
  "Partial approval confirmation": "Čiastočné potvrdenie schválenia",
  PartiallyApprovedAmount:
    "Zostatok na vašej karte nemá dostatok finančných prostriedkov na pripísanie celej sumy. Transakcia bola čiastočne schválená za {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Kliknutím na tlačidlo „OK“ potvrdíte čiastočnú čiastku vkladu alebo kliknutím na „ZRUŠIŤ“ transakciu zrušíte. Ak nevykonáte žiadnu akciu, záloha bude automaticky potvrdená po 5 minútach.",
  fxRateSpreadText: "Uvedený výmenný kurz zahŕňa všetky poplatky a platby.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Načitáva sa, prosím čakajte",
};
