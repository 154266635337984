export const hu = {
  "Loading... Please Wait": "Betöltés; kérem várjon",
  yourTransactionIsPendingForPayOut: "Visszavonási kérelme függőben van",
  status1:
    "Felhívjuk figyelmét, hogy tranzakcióját a feldolgozó bank vagy fizetési szolgáltató ellenőrzi. Ha további információt szeretne a tranzakció állapotáról, vegye fel a kapcsolatot az ügyfélszolgálattal, és kérjen segítséget a Trace ID azonosítóval kapcsolatban: {{trace_id}}",
  Status: "Állapot",
  "Deposit Review For Account:": "Betéti áttekintés a számlához:",
  "New Transaction": "Új tranzakció",
  Update: "Frissítés",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "A kiválasztott fizetési mód külön oldalon nyílik meg. Az új böngészőablakhoz való navigáláshoz és a fizetési folyamat folytatásához kattintson a 'Folytassa' gombra.",
  dod_error_text:
    "A továbblépéshez el kell olvasnia és el kell fogadnia a betéti nyilatkozatot",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "A befizetés folytatásához el kell fogadnia az Általános Szerződési Feltételeket",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Tudomásul veszem, hogy elolvastam és elfogadom az Általános Szerződési Feltételeket",
  dod_url: "#",
  dod_url_text: "Felhasználási feltételek",
  dod_text:
    "Tudomásul veszem, hogy elolvastam, és elfogadom a következőt: {{dod_url}}",
  transactionSuccessfullyCanceled: "A (z) {{id}} tranzakció sikeresen törölve",
  "Fee should not exceed 20% of the amount":
    "A díj nem haladhatja meg az összeg 20% -át",
  "My Info": "Saját információ",
  "Billing info": "Számlázási info",
  "Withdrawal Requests": "Visszavonási kérelmek",
  "No Withdrawal Requests found": "Nem található visszavonási kérelem",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Megszünteti",
  Amount: "Összeg",
  "Last Update": "Utolsó frissítés",
  "Payment Method": "Fizetési mód",
  TID: "IDŐ",
  Action: "Akció",
  "Confirmation required": "Megerősítés szükséges",
  "Are you sure you want to cancel transaction": "Biztosan törli a tranzakciót",
  Yes: "Igen",
  No: "Nem",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Min-Max összegek",
  "Fee (included)": "Díj (tartalmazza)",
  "- Amount between": "- Közötti összeg",
  "I have the Promocode!": "Megvan a Promo kód!",
  "Please enter the Promocode": "Kérjük, adja meg a promóciós kódot",
  Promocode: "Promóciós kód",
  noSavedTemplates: "Nincsenek kártyák mentve",
  noOptions: "Nincs lehetőség",
  "Please confirm your billing details":
    "Kérjük, erősítse meg számlázási adatait",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Feldolgozási díj",
  Fee: "Díj",
  Total: "Teljes",
  customer_support_link_text: "Vevőszolgálat",
  terms_conditions_link_text: "Felhasználási feltételek",
  "Edit Billing Info": "Számlázási adatok szerkesztése",
  chooseAnAccount: "Válasszon fiókot",
  "Requested to Receive": "Kért a fogadásra",
  "Receive With": "Fogadás vele",
  "Unknown session": "Ismeretlen munkamenet",
  transactionId: "Tranzakció azonosítója",
  traceId: "Nyomkövetési azonosító",
  save: "Megment",
  fillTheBillingInformation:
    "Kérjük, töltse ki az alábbi számlázási információkat",
  correctionsRequired: "javítások szükségesek",
  billingInfo: "Számlázási info",
  "Cancelled by client": "Az ügyfél törölte",
  "Your Cards": "A kártyáid",
  Done: "Kész",
  redirect: "Átirányítás",
  redirectingTo: "Átirányítás ide:",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "A tranzakció nem volt sikeres. Sajnáljuk, de a megadott PayPal-számla egy másik ügyfél kereskedelmi számlája alatt jelenik meg a nyilvántartásunkban. Ha segítségre van szüksége a fiókjával kapcsolatban, kérjük, lépjen kapcsolatba az ügyfélszolgálattal.",
  "Not supported yet": "Még nem támogatott",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Elnézést, úgy tűnik, hogy nincsenek olyan fizetési adatai, amelyek regisztrálva lennének az elvonási kérelemhez",
  "Not available gateways found": "Nem található átjáró",
  "Token is invalid": "A token érvénytelen",
  "Payment method not available. Please contact your site administrator.":
    "Fizetési mód nem érhető el. Kérjük, lépjen kapcsolatba a webhely rendszergazdájával.",
  cardIsNotValid: "A kártya nem érvényes",
  invalidInputFormat:
    "Érvénytelen beviteli formátum. Legalább {{length}} karakter.",
  invalidDateFormat: "Érvénytelen dátumformátum.",
  maximumValueIs: "A maximális érték:",
  minimumValueIs: "Minimális értéke:",
  enterPayoutAmount:
    "Kérjük, adja meg az elérhető egyenlegnél kisebb vagy azzal egyenlő kifizetési összeget.",
  maxLength2: "Legfeljebb 2 karakter hosszúságú.",
  footer1:
    "Néhány kibocsátó bank korlátozta a hitelkártyák használatát az online szórakoztatás céljából. Használja bátran a fenti harmadik féltől származó fizetési módok egyikét, vagy vegye fel a kapcsolatot a {{customer_support_url}} képviselőinkkel ingyenesen a N / A telefonszámon a fiókjának finanszírozásához.\n" +
    "  Kérjük, vegye figyelembe: A kibocsátó banktól függően a hitelkártya-tranzakciókat készpénz-előlegként lehet kezelni. A {{application_key}} nem tehető felelőssé a tranzakcióban felmerülő készpénzelőleg-díjakért, mivel ezt a hitelkártyát kibocsátó bank állapítja meg. További részletekért forduljon bankjához.",
  footer2:
    "Ha segítségre van szüksége vagy megkísérli a tranzakció megkísérlését, kérjük, forduljon a következőhöz: {{customer_support_url}}.\n" +
    "  Kérjük, vegye figyelembe: bankjától függően ez a tranzakció készpénz-előlegként kezelhető. A (z) {{application_key}} nem vállal felelősséget a tranzakcióban felmerülő készpénzelőleg-díjakért. A tranzakció a hitelkártya-kivonaton hitelkártya-leíróként lesz feltüntetve, hacsak a tranzakciós bizonylaton nem szerepel másképp.",
  footer3:
    "Minden kifizetési kérelemre a {{terms_conditions_url}} vonatkozik. Ha segítségre vagy további információra van szüksége, kérjük, lépjen kapcsolatba a következővel: {{customer_support_url}}. A kérelem benyújtását követően az információkat továbbítjuk pénztári részlegünkhöz feldolgozás céljából. A kifizetés feldolgozásakor e-mailt kapunk visszaigazolásként. A kifizetési összeget és a feldolgozási díjat (ha van ilyen) a kifizetés ellenőrzését követően az Ön számlájáról terheljük meg.",
  gatewaysTitle: "Hogyan szeretne fizetni?",
  gatewaysTitleDeposit: "Kérjük, válassza ki a befizetési módot",
  gatewaysTitleWithdrawal: "Kérjük, válassza ki az elállási módot",
  payInTitle: "Fizessen hitel- vagy betéti kártyával",
  allPaymentMethods: "minden fizetési módot",
  submit: "Beküldés",
  useAddressOnFile: "Használja a fájlban szereplő címet",
  customerName: "Ügyfél neve",
  country: "Ország",
  province: "Tartomány",
  city: "Város",
  postalCode: "irányítószám",
  street: "utca",
  proceed: "Folytassa",
  cardNumber: "Kártyaszám",
  cardHolder: "Kártyatartó",
  cardHolderName: "Kártyabirtokos neve (csak latinul)",
  cardExpires: "A kártya lejár",
  cvv: "CVV",
  firstName: "Keresztnév",
  lastName: "Vezetéknév",
  zipCode: "Irányítószám",
  address: "Cím",
  state: "Állapot",
  email: "Email",
  phone: "Telefon",
  dateOfBirth: "Születési dátum",
  transactionStatus: "Tranzakció állapota",
  trace: "Nyom",
  amount: "Összeg",
  min: "min",
  max: "max",
  overrideLimits: "Határértékek felülbírálása",
  transaction: "Tranzakció",
  processed: "Feldolgozta",
  transactionRejected: "Tranzakció elutasítva",
  transactionApproved: "Tranzakció jóváhagyva",
  yourTransactionIsPending: "Tranzakciója függőben van",
  transactionAuthorized: "Tranzakció engedélyezett",
  requestAccepted: "Kérés elfogadva",
  status2:
    "Kivonási kérelmét elfogadtuk. Felhívjuk figyelmét, hogy a további engedélyezés és feldolgozás a munkanapok nyitvatartási idejében történik. Ezalatt az ügyvezető személyzet felveheti Önnel a kapcsolatot személyazonosságának megerősítése érdekében.",
  unexpectedErrorOccurred: "Váratlan hiba történt",
  status3:
    "Elnézését kérjük, valami hiba történt a tranzakció feldolgozása közben. Az alábbiakban találja meg a műszaki részleteket: ",
  tryAgain: "Próbáld újra",
  somethingWentWrong: "Valami elromlott...",
  newPayoutRequest: "Új kifizetési kérelem",
  makeAnotherPayment: "Újabb fizetés",
  profile: "Profil",
  justOneMoreStep: "Csak még egy lépés ...",
  "profile-auth":
    "Kérjük, töltse ki profilját a fizetés folytatásához a következővel: {{title}}",
  payWith: "Fizessen",
  ProceedWith: "Folytathatja",
  vtTitle: "Virtuális terminál: ",
  pay: "Fizetés",
  deposit: "Letét",
  withdraw: "Visszavonás",
  withdrawal: "Visszavonás",
  hello: "Helló,",
  currency: "Valuta",
  currentIp: "Jelenlegi IP: ",
  UseCurrentIp: "Használja az aktuális IP-címet: ",
  UseCustomerIp: "Használja az ügyfél IP-címét: ",
  copyright: "szerzői jog",
  chooseAmount: "Válassza ki az összeget",
  cannotBeBlank: "Nem lehet üres.",
  noDepositFree: "Nincs betéti díj. nincs kivonási díj.",
  next: "Következő",
  backToSite: "vissza a helyszínre",
  maximumLength: "Maximális hosszúság {{length}} karakter",
  minimumLength: "Minimális hosszúság {{length}} karakter",
  emailIsNotValid: "Az e-mail nem érvényes e-mail cím.",
  phoneIsNotValid: "A telefon nem érvényes",
  cardHolderInvalid:
    "A kártya tulajdonosa érvénytelen. A kártya tulajdonosának tartalmaznia kell az utónevet és a vezetéknevet.",
  pleaseEnterPayout:
    "Kérjük, adjon meg kevesebb vagy egyenlő kifizetési összeget",
  invalidAmount: "Érvénytelen összeg",
  transactionWillAppear:
    "A tranzakció a kivonaton a következő néven jelenik meg:",
  "Customer does not exist": "Az ügyfél nem létezik",
  "Sorry no available gateways found.": "Sajnos nem található elérhető átjáró.",
  "Incorrect currency": "Helytelen pénznem",
  "Sorry, not supported payment type": "Sajnos nem támogatott fizetési típus",
  "Payment method has no payment type":
    "A fizetési módnak nincs fizetési típusa",
  "Incorrect payment type in payment method":
    "Helytelen fizetési mód a fizetési módban",
  "Gateway limit exceeded": "Az átjáró korlátja túllépve",
  "Not supported transaction type": "Nem támogatott tranzakciótípus",
  "Sorry. Payout with credit card gateway not supported yet":
    "Sajnálom. A hitelkártya-átjáróval történő kifizetés még nem támogatott",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Elnézését kérjük, ez a fizetési mód nem használható a megadott fizetési adatokra. Kérjük, töltse be újra az oldalt, és próbáljon meg más összeget, pénznemet vagy fizetési módot használni.",
  "Back to site": "Vissza a webhelyre",
  "Your transaction has been requested. Please wait for approval.":
    "Tranzakcióját kértük. Kérjük, várja meg a jóváhagyást.",
  "Token expired": "A token lejárt",
  back: "VISSZA",
  "Transaction not found.": "A tranzakció nem található",
  "Continue in the New Window": "Folytassa az Új ablakban",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Kérjük, ne zárja be ezt az ablakot. A tranzakció befejezésével egy új ablakban visszairányít. Nem lát egy új ablakot, amely biztonságos kapcsolatot nyit meg? Segítünk a tranzakció befejezésében.",
  "Click to continue": "Kattints a folytatáshoz",
  "Just One More Step...": "Csak még egy lépés ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "A kiválasztott fizetési mód új oldalon nyílik meg. Kérjük, kattintson az alábbi „FOLYTASSA” gombra az utolsó lépéshez való navigáláshoz.",
  Proceed: "Folytassa",
  "Opening a Secure Connection...": "Biztonságos kapcsolat megnyitása ...",
  "You will be redirected to complete your transaction in just a moment":
    "Átirányításra kerül, hogy a tranzakciót egy pillanat alatt befejezze",
  "Close window": "Ablak bezárása",
  "Partial approval confirmation": "Részleges jóváhagyás megerősítése",
  PartiallyApprovedAmount:
    "A kártya egyenlege nem elegendő a teljes összeg jóváírásához. A tranzakciót részben jóváhagyták {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Kérjük, kattintson az 'OK' gombra a részleges befizetés összegének megerősítéséhez, vagy a 'CANCEL' gombra a tranzakció törléséhez. A befizetést automatikusan megerősítik 5 perc elteltével, ha nem tesz semmit.",
  fxRateSpreadText:
    "A feltüntetett árfolyam tartalmazza az összes díjat és díjat.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Betöltés... kérem várjon",
};
