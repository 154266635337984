import React, { useState, useEffect } from 'react';
import { useSelector, shallowEqual } from "react-redux";
import {filterObject} from "utils/helper-functions/filter-object";
import {compose} from "redux";
import {withI18N} from "shared-components/hoc-components";
import FooterLinks from "./FooterLinks/FooterLinks";
import SpreadAmountText from "@pano-project/components/Amount/SpreadAmountText/SpreadAmountText";

const Footer = ({ langBack }) => {
    const [isFooterLinks, setIsFooterLinks] = useState(false);
    const { policies_data, dod_acknowledgement_required } = useSelector(({
        initTheme: { theme_editor_settings: { customization_settings: { policies_data } = {} } = {} }, profileInfo:{customer: {dod_acknowledgement_required} = {}}
    }) => ({
        policies_data,
        dod_acknowledgement_required
    }), shallowEqual);

    useEffect(() => {
        const filteredPoliciesData = filterObject({obj:policies_data, allowEmpty: true});
        if (policies_data?.render_policies_data && Object.keys(filteredPoliciesData || {}).length > 2) {
            setIsFooterLinks(true)
        } else {
            setIsFooterLinks(false)
        }
    }, [JSON.stringify(policies_data)])

    return (
        <div className={'footer'}>
            <div className={'footer-content'}>
                {
                    !!dod_acknowledgement_required ? <SpreadAmountText withNoteText={true}/> : ''
                }
                {isFooterLinks ?
                    <FooterLinks policies_data={policies_data} langBack={langBack}/> :
                    <p>{langBack('If you require assistance or technical support, please contact customer support.')}</p>
                }
            </div>
            {
                !isFooterLinks ? <div className={'copyright'}>
                    @ Copyright {new Date().getFullYear()}
                </div> : ''
            }
        </div>
    );
};

export default compose(withI18N)(Footer);