import React, {useState, useEffect, useRef} from 'react'
import Loader from "../helper-components/loader/loader";
import compose from "../../utils/helper-functions/compose";
import {withI18N} from "../hoc-components";

const DelayLoadingWrapper = ({children, langBack}) => {
    const [loader, setLoader] = useState(true)
    const initApp = useRef(true)
    
    useEffect(() => {
        window.addEventListener("message", receiveMessage);
        setTimeout(() => {
            window.removeEventListener("init-message", receiveMessage);
            if(initApp.current) setLoader(false)
        }, 200)
    }, [])
    
    
    const receiveMessage = (event) => {
        if(event.origin !== origin) return;
        if(event.data.not_loading){
            initApp.current = false;
        }
    };

    if (loader) return <Loader text={langBack('initialLoadingText', false, true)} showLoaderIcon={false} className={'initial-loading-text'}/>
    return children
}

export default compose(
    withI18N
)(DelayLoadingWrapper)