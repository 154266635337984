export const callbackResult = {
    status: 2,
    is_iframe: 0,
    new_attempt: 1,
    error_message: '[TEST] Transaction status: declined. Reason: Insufficient funds',
    transaction: {
        status: 'declined',
        trace_id: 112617,
        amount: "98.34",
        charge_amount: 112,
        charge_currency: "USD",
        currency: "EUR",
        transaction_id: '352461',
        payment_processor: 'Test Card Processor',
        error_message: '[TEST] Transaction status: declined. Reason: Insufficient funds'
    },
    submit_action: 'https://',
    submit_name: 'Back to site',
    transaction_type: "pay-out",
    redirect_url: "",
    title: 'Your deposit attempt failed [TEST] Transaction status: declined. Reason: Insufficient funds'
}