export const result = {
  status: 0,
  is_iframe: 0,
  new_attempt: 1,
  error_message:
    "[TEST] Transaction status: declined. Reason: Insufficient funds",
  transaction: {
    status: "declined", // declined for error // pending_async for pending // "error" for errors server //
    trace_id: 112617,
    amount: "120",
    bank_name: "Deutsche Bank AG",
    charge_amount: 120,
    charge_currency: "USD",
    currency: "EUR",
    transaction_id: "352461",
    payment_processor: "Test Card Processor",
    error_message:
      "[TEST] Transaction status: declined. Reason: Insufficient funds",
    processing_status: "GE-006", // GE-006 error parameter failure message modal
  },
  // for PRX-4622(4541) Retry for Insufficient funds flow uncomment "retry_settings" START
  // retry_settings: {
  //   show_amount_field: 1,
  //   predefined_options: [
  //     {
  //       "amount": 200.9,
  //       "currency": "USD"
  //     },
  //     {
  //       "amount": 150.75,
  //       "currency": "USD"
  //     },
  //     {
  //       "amount": 100.30,
  //       "currency": "USD"
  //     },
  //   ],
  //   payment_information: {
  //     base_currency: "USD",
  //     min_amount: "1",
  //     max_amount: "252",
  //     rate: 1,
  //     crypto: 0,
  //     currency_list: {
  //       USD: {
  //         min_amount: "1",
  //         max_amount: "300",
  //         rate: 1,
  //         crypto: 0,
  //       },
  //       EUR: {
  //         min_amount: "0.94",
  //         max_amount: "282",
  //         rate: 0.93827,
  //         crypto: 0,
  //       },
  //     },
  //   },
  //   fees: {
  //     USD: [
  //       {
  //         flat_fee_amount: null,
  //         is_included: 0,
  //         max_transaction_amount: 100,
  //         min_transaction_amount: 10,
  //         percentage_fee: 10
  //       },
  //       {
  //         flat_fee_amount: 2.0,
  //         is_included: 1,
  //         max_transaction_amount: 300,
  //         min_transaction_amount: 101,
  //         percentage_fee: null
  //       },
  //     ]
  //   },
  // },
  // for PRX-4622(4541) Retry for Insufficient funds flow uncomment "retry_settings" END
  ob_decline_recovery: 0,
  submit_action:
    "https://compute-test.praxispay.com/post-deposit-redirect/AxiCorp USD/112579",
  submit_name: "Back to site",
  transaction_type: "pay-in",
  title:
    "Your deposit attempt failed [TEST] Transaction status: declined. Reason: Insufficient funds",
  final_page_email: "support@merchant.com",
  final_page_phone: "050579396286",
  final_page_chat: "https://websiteonlinechat.com/ref-number/937315234",
};
