import React from 'react';

export const PraxisServiceContext = React.createContext();
const {
    Provider: PraxisServiceProvider,
    Consumer: PraxisServiceConsumer
} = PraxisServiceContext;

export {
    PraxisServiceConsumer,
    PraxisServiceProvider
}