export const id = {
  "Loading... Please Wait": "Memuat ... Harap Tunggu",
  yourTransactionIsPendingForPayOut: "Permintaan Penarikan Anda Menunggu",
  status1:
    "Perlu diketahui bahwa transaksi Anda sedang ditinjau oleh bank pemroses atau penyedia layanan pembayaran. Jika Anda menginginkan informasi lebih lanjut tentang status transaksi Anda, harap hubungi dukungan untuk meminta bantuan tentang Trace ID: {{trace_id}}",
  Status: "Status",
  "Deposit Review For Account:": "Review Deposit Untuk Rekening:",
  "New Transaction": "Transaksi Baru",
  Update: "Memperbarui",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Metode pembayaran yang Anda pilih terbuka di halaman terpisah. Silakan klik 'LANJUTKAN' untuk menavigasi ke jendela browser baru dan melanjutkan proses pembayaran.",
  dod_error_text:
    "Anda harus membaca dan menerima Deklarasi Deposit untuk melanjutkan",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Anda harus menerima Syarat dan Ketentuan untuk melanjutkan dengan deposit Anda",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Saya mengakui bahwa saya telah membaca, dan dengan ini menerima Syarat dan Ketentuan",
  dod_url: "#",
  dod_url_text: "Syarat dan ketentuan",
  dod_text:
    "Saya mengakui bahwa saya telah membaca, dan dengan ini menerima {{dod_url}}",
  transactionSuccessfullyCanceled: "Transaksi {{id}} berhasil dibatalkan",
  "Fee should not exceed 20% of the amount":
    "Bayaran tidak boleh melebihi 20% dari jumlahnya",
  "My Info": "Maklumat Saya",
  "Billing info": "Maklumat pengebilan",
  "Withdrawal Requests": "Permintaan Pengeluaran",
  "No Withdrawal Requests found": "Permintaan Pengeluaran tidak dijumpai",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Batal",
  Amount: "Jumlah",
  "Last Update": "Kemaskini terakhir",
  "Payment Method": "Kaedah Pembayaran",
  TID: "MASA",
  Action: "Tindakan",
  "Confirmation required": "Pengesahan diperlukan",
  "Are you sure you want to cancel transaction":
    "Adakah anda pasti mahu membatalkan transaksi",
  Yes: "Ya",
  No: "Tidak",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Jumlah Min-Max",
  "Fee (included)": "Biaya (termasuk)",
  "- Amount between": "- Jumlah antara",
  "I have the Promocode!": "Saya memiliki Kode Promosi!",
  "Please enter the Promocode": "Silakan masukkan Kode Promo",
  Promocode: "Kode promosi",
  noSavedTemplates: "Tidak Ada Kartu Tersimpan",
  noOptions: "Tidak ada pilihan",
  "Please confirm your billing details":
    "Harap konfirmasi detail penagihan Anda",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Biaya pengolahan",
  Fee: "Biaya",
  Total: "Total",
  customer_support_link_text: "Dukungan Pelanggan",
  terms_conditions_link_text: "Syarat dan ketentuan",
  "Edit Billing Info": "Edit Info Penagihan",
  chooseAnAccount: "Pilih akun",
  "Requested to Receive": "Diminta untuk Menerima",
  "Receive With": "Terima dengan",
  "Unknown session": "Sesi tidak dikenal",
  transactionId: "ID transaksi",
  traceId: "melacak ID",
  save: "Menyimpan",
  fillTheBillingInformation: "Silakan isi informasi penagihan di bawah ini",
  correctionsRequired: "diperlukan koreksi",
  billingInfo: "Info pembayaran",
  "Cancelled by client": "Dibatalkan oleh klien",
  "Your Cards": "Kartu Anda",
  Done: "Selesai",
  redirect: "Arahkan ulang",
  redirectingTo: "Mengarahkan kembali ke",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Transaksi tidak berhasil. Kami minta maaf tetapi akun PayPal yang diberikan muncul di catatan kami di bawah akun perdagangan pelanggan lain. Pelanggan terbatas pada satu akun perdagangan pelanggan aktif di situs kami. Untuk memungkinkan akun PayPal ini digunakan dengan login Anda, atau jika Anda memerlukan bantuan dengan akun Anda, silakan hubungi layanan pelanggan.",
  "Not supported yet": "Belum didukung",
  "Not available gateways found": "Tidak ditemukan gateway",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Permintaan maaf, tampaknya Anda tidak memiliki rincian pembayaran terdaftar untuk digunakan untuk permintaan penarikan",
  "Token is invalid": "Token tidak valid",
  chooseAmount: "pilih Jumlah",
  "Payment method not available. Please contact your site administrator.":
    "Metode pembayaran tidak tersedia. Silakan hubungi administrator situs Anda.",
  cardIsNotValid: "Kartu tidak valid",
  invalidInputFormat:
    "Format input tidak valid. Setidaknya {{length}} karakter.",
  invalidDateFormat: "Format tanggal tidak valid.",
  maximumValueIs: "Nilai maksimum adalah",
  minimumValueIs: "Nilai minimum adalah",
  enterPayoutAmount:
    "Silakan masukkan jumlah pembayaran kurang dari atau sama dengan Saldo Tersedia Anda.",
  maxLength2: "Panjang maksimal 2 karakter.",
  footer1:
    "Beberapa bank penerbit telah membatasi penggunaan kartu kredit untuk hiburan online. Silakan gunakan salah satu metode pembayaran pihak ketiga alternatif di atas atau hubungi Perwakilan {{customer_support_url}} kami bebas pulsa di N / A untuk mendanai akun Anda. \n Harap Catatan: Bergantung pada bank penerbit Anda, transaksi kartu kredit dapat diperlakukan sebagai penarikan tunai. {{application_key}} tidak bertanggung jawab atas biaya penarikan tunai apa pun yang terlibat dalam transaksi ini karena ini ditetapkan oleh kredit bank penerbit kartu. Konsultasikan dengan bank Anda untuk detail lebih lanjut. ",
  footer2:
    "Untuk bantuan atau konfirmasi transaksi yang dicoba, harap hubungi {{customer_support_url}}. \n Harap Dicatat: Bergantung pada bank Anda, transaksi ini dapat diperlakukan sebagai uang muka. {{application_key}} tidak akan bertanggung jawab atas semua biaya penarikan tunai yang terlibat dalam transaksi ini. Transaksi akan diidentifikasi pada laporan mutasi kartu kredit Anda sebagai Penjelas Kartu Kredit kecuali dinyatakan lain pada tanda terima transaksi Anda. ",
  footer3:
    "Semua permintaan pembayaran tunduk pada {{terms_conditions_url}} kami. Jika Anda memerlukan bantuan atau informasi lebih lanjut, harap hubungi {{customer_support_url}}. Setelah permintaan Anda dikirim, informasi tersebut diteruskan ke departemen kasir kami untuk diproses . Anda akan menerima email sebagai konfirmasi saat pembayaran Anda diproses. Jumlah pembayaran dan biaya pemrosesan (jika berlaku) akan didebit dari akun Anda setelah tinjauan pembayaran kami. ",
  gatewaysTitle: "Bagaimana Anda ingin membayar?",
  gatewaysTitleDeposit: "Silakan pilih metode setoran",
  gatewaysTitleWithdrawal: "Silakan pilih metode penarikan",
  payInTitle: "Bayar dengan Kartu Kredit atau Debit",
  allPaymentMethods: "semua metode pembayaran",
  submit: "Kirimkan",
  useAddressOnFile: "Gunakan alamat pada file",
  customerName: "Nama Pelanggan",
  country: "Negara",
  province: "Propinsi",
  city: "Kota",
  postalCode: "Kode Pos",
  street: "jalan",
  proceed: "Memproses",
  cardNumber: "Nomor kartu",
  cardHolder: "Pemegang Kartu",
  cardHolderName: "Nama pemegang kartu",
  cardExpires: "Kartu kedaluwarsa",
  cvv: "CVV",
  firstName: "Nama depan",
  lastName: "Nama keluarga",
  zipCode: "Kode Pos",
  address: "Alamat",
  state: "Negara",
  email: "Surel",
  phone: "Telepon",
  dateOfBirth: "Tanggal lahir",
  transactionStatus: "Status Transaksi",
  trace: "Jejak",
  amount: "Jumlah",
  min: "min",
  max: "maks",
  overrideLimits: "Timpa batas",
  transaction: "Transaksi",
  processed: "Diproses",
  transactionRejected: "Transaksi Ditolak",
  transactionApproved: "Transaksi Disetujui",
  yourTransactionIsPending: "Transaksi Anda Tertunda",
  transactionAuthorized: "Transaksi Resmi",
  requestAccepted: "Permintaan diterima",
  status2:
    "Permintaan penarikan Anda telah diterima. Harap diakui bahwa otorisasi dan pemrosesan lebih lanjut akan dilaksanakan selama jam operasional hari kerja. Selama waktu itu, Anda dapat dihubungi oleh staf eksekutif untuk konfirmasi identitas.",
  unexpectedErrorOccurred: "Terjadi Kesalahan Tidak Terduga",
  status3:
    "Kami mohon maaf, ada yang tidak beres saat memproses transaksi Anda. Silakan temukan detail teknis di bawah ini:",
  tryAgain: "Coba lagi",
  somethingWentWrong: "Ada yang salah...",
  newPayoutRequest: "Permintaan Pembayaran Baru",
  makeAnotherPayment: "Lakukan Pembayaran Lain",
  profile: "Profil",
  justOneMoreStep: "Hanya satu langkah lagi ...",
  "profile-auth":
    "Silakan lengkapi profil Anda untuk melanjutkan pembayaran menggunakan {{title}}",
  payWith: "Bayar menggunakan",
  ProceedWith: "Lanjutkan dengan",
  vtTitle: "Terminal Virtual:",
  pay: "Membayar",
  deposit: "Menyetorkan",
  withdraw: "Menarik",
  withdrawal: "Penarikan",
  hello: "Halo,",
  currency: "Mata uang",
  currentIp: "IP saat ini:",
  UseCurrentIp: "Gunakan IP saat ini:",
  UseCustomerIp: "Gunakan IP pelanggan:",
  copyright: "hak cipta",
  cannotBeBlank: "Tidak bisa kosong.",
  noDepositFree: "Tanpa biaya setoran. tidak ada biaya penarikan.",
  next: "Lanjut",
  backToSite: "Kembali ke situs",
  maximumLength: "Panjang maksimum {{length}} karakter",
  minimumLength: "Panjang minimum {{length}} karakter",
  emailIsNotValid: "Email bukan alamat email yang valid.",
  phoneIsNotValid: "Telepon tidak valid",
  cardHolderInvalid:
    "Tempat kartu tidak valid. Tempat kartu harus berisi nama depan dan nama belakang.",
  pleaseEnterPayout: "Silakan masukkan Jumlah pembayaran kurang dari atau sama",
  invalidAmount: "Jumlah tidak valid",
  transactionWillAppear:
    "Transaksi Anda akan muncul pada pernyataan Anda sebagai",
  "Customer does not exist": "Pelanggan tidak ada",
  "Sorry no available gateways found.":
    "Maaf, tidak ada gateway yang ditemukan.",
  "Incorrect currency": "Mata uang salah",
  "Sorry, not supported payment type": "Maaf, tidak didukung jenis pembayaran",
  "Payment method has no payment type":
    "Metode pembayaran tidak memiliki jenis pembayaran",
  "Incorrect payment type in payment method":
    "Jenis pembayaran salah dalam metode pembayaran",
  "Gateway limit exceeded": "Batas gerbang terlampaui",
  "Not supported transaction type": "Tidak didukung jenis transaksi",
  "Sorry. Payout with credit card gateway not supported yet":
    "Maaf. Pembayaran dengan gateway kartu kredit belum didukung",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Kami mohon maaf, metode pembayaran ini tidak berlaku untuk rincian pembayaran yang ditentukan. Harap muat ulang halaman dan coba jumlah, mata uang, atau metode pembayaran yang berbeda.",
  "Back to site": "Kembali ke situs",
  "Your transaction has been requested. Please wait for approval.":
    "Transaksi Anda telah diminta. Harap tunggu persetujuan.",
  "Token expired": "Token kedaluwarsa",
  back: "kembali",
  "Transaction not found.": "Batas gerbang terlampaui",
  "Continue in the New Window": "Lanjutkan di Jendela Baru",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Tolong jangan tutup jendela ini. Anda akan diarahkan kembali setelah menyelesaikan transaksi di jendela baru. Tidak melihat jendela baru membuka koneksi aman? Kami di sini untuk membantu Anda menyelesaikan transaksi Anda.",
  "Click to continue": "Klik untuk melanjutkan",
  "Just One More Step...": "Hanya Satu Langkah Lagi ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Metode pembayaran yang Anda pilih akan dibuka di halaman baru. Silakan klik tombol 'MEMPROSES' di bawah untuk menavigasi ke langkah terakhir",
  Proceed: "Memproses",
  "Opening a Secure Connection...": "Membuka Sambungan Aman ...",
  "You will be redirected to complete your transaction in just a moment":
    "Anda akan diarahkan untuk menyelesaikan transaksi Anda dalam beberapa saat",
  "Close window": "Tutup jendela",
  "Partial approval confirmation": "Konfirmasi persetujuan sebagian",
  PartiallyApprovedAmount:
    "Saldo kartu Anda tidak memiliki cukup dana untuk mengkreditkan jumlah penuh. Transaksi itu sebagian disetujui untuk {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Silakan klik 'OK' untuk mengonfirmasi jumlah deposit sebagian atau 'BATAL' untuk membatalkan transaksi. Deposit akan dikonfirmasi secara otomatis setelah 5 menit jika tidak ada tindakan yang diambil.",
  fxRateSpreadText:
    "Nilai tukar yang ditunjukkan mencakup semua biaya dan biaya.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Sedang memuat... Harap tunggu",
};
