const IconArrow = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
            <path d="M17.75 10.2344H4" stroke="var(--neutral_12)" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.625 4.60938L4 10.2344L9.625 15.8594" stroke="var(--neutral_12)" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default IconArrow
