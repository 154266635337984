export const eNumber = (x) => {
    if(String(x) === "0") return "0";
    if(!x) return "";
    if (Math.abs(+x) < 1.0) {
        const e = parseInt(x.toString().toLowerCase().split('e-')[1]);
        if (e) {
            x *= Math.pow(10,e-1);
            x = parseFloat(x.toFixed(2))
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    }
    // else {
    //     let e = parseInt(x.toString().split('+')[1]);
    //     if (e > 20) {
    //         e -= 20;
    //         x /= Math.pow(10,e);
    //         x += (new Array(e+1)).join('0');
    //     }
    // }
    return String(x);
}