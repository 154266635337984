import React from 'react'
import './basic-error-page.sass'
import Button from "../../../helper-components/button/button";
import {clientClickEvent} from "../../../../utils/custom-cashier-events/client-click-event";


const BasicErrorPage = (props) => {
    const {errorsInfo, langBack, history, s} = props;
    const {
        errors = {},
        errorType, back = false,
        submit_action = '',
        submit_name = '',
        generateErrorMessage
    } = errorsInfo;

    return (
        <div className={`${s('page-container')} ${s('error-container')}`}>
            <div className={`${s('error-page')} ${errorType ? s(`error-${errorType}`) : ''}`}>
                {
                    errorType && back ?
                        <div
                            className={`${s('back')}`}
                            onClick={() => {
                                clientClickEvent("back_button");
                                history.push("gateways");
                            }}>
                            ← {langBack("allPaymentMethods")}
                        </div>
                        : ""
                }
                <div>
                    {
                        generateErrorMessage(errors, errorType)
                    }
                </div>
                {
                    !submit_action ? "" :
                        <a href={submit_action} onClick={() => clientClickEvent('back_to_website_button')}><Button
                            className={`${s('button')}`} content={submit_name}/></a>
                }
            </div>
        </div>

    )
};

export default BasicErrorPage
