export const vi = {
  "Loading... Please Wait": "Tải vui lòng đợi",
  yourTransactionIsPendingForPayOut: "Yêu cầu rút tiền của bạn đang chờ xử lý",
  status1:
    "Xin lưu ý rằng giao dịch của bạn đang được ngân hàng xử lý hoặc nhà cung cấp dịch vụ thanh toán xem xét. Nếu bạn muốn biết thêm thông tin về trạng thái giao dịch của mình, vui lòng liên hệ với bộ phận hỗ trợ để yêu cầu hỗ trợ về ID theo dõi: {{trace_id}}",
  Status: "Trạng thái",
  "Deposit Review For Account:": "Xem xét tiền gửi cho tài khoản:",
  "New Transaction": "Giao dịch mới",
  Update: "Cập nhật",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Phương thức thanh toán bạn đã chọn sẽ mở trong một trang riêng biệt. Vui lòng nhấp vào 'TIẾN HÀNH' để điều hướng đến cửa sổ trình duyệt mới và tiếp tục quá trình thanh toán.",
  dod_error_text: "Bạn phải đọc và chấp nhận Tuyên bố Gửi tiền để tiếp tục",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Bạn phải chấp nhận các Điều khoản và Điều kiện để tiếp tục với khoản tiền gửi của mình",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Tôi xác nhận rằng tôi đã đọc và chấp nhận các Điều khoản và Điều kiện",
  dod_url: "#",
  dod_url_text: "Các điều khoản và điều kiện",
  dod_text: "Tôi xác nhận rằng tôi đã đọc và chấp nhận {{dod_url}}",
  transactionSuccessfullyCanceled: "Giao dịch {{id}} đã được hủy thành công",
  "Fee should not exceed 20% of the amount":
    "Phí không được vượt quá 20% số tiền",
  "My Info": "Thông tin của tôi",
  "Billing info": "Thông tin thanh toán",
  "Withdrawal Requests": "Yêu cầu rút tiền",
  "No Withdrawal Requests found": "Không tìm thấy yêu cầu rút tiền",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Huỷ bỏ",
  Amount: "Số tiền",
  "Last Update": "Cập nhật cuối cùng",
  "Payment Method": "Phương thức thanh toán",
  TID: "THỜI GIAN",
  Action: "Hoạt động",
  "Confirmation required": "Yêu cầu xác nhận",
  "Are you sure you want to cancel transaction":
    "Bạn có chắc chắn muốn hủy giao dịch không",
  Yes: "Đúng",
  No: "Không",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Số tiền Tối thiểu-Tối đa",
  "Fee (included)": "Phí (đã bao gồm)",
  "- Amount between": "- Số tiền giữa",
  "I have the Promocode!": "Tôi có Mã khuyến mãi!",
  "Please enter the Promocode": "Vui lòng nhập Mã khuyến mãi",
  Promocode: "Mã khuyến mại",
  noSavedTemplates: "Không có thẻ nào được lưu",
  noOptions: "Không có lựa chọn",
  "Please confirm your billing details":
    "Vui lòng xác nhận chi tiết thanh toán của bạn",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Phí xử lý",
  Fee: "Học phí",
  Total: "Toàn bộ",
  customer_support_link_text: "Hỗ trợ khách hàng",
  terms_conditions_link_text: "Các điều khoản và điều kiện",
  "Edit Billing Info": "Chỉnh sửa Thông tin Thanh toán",
  chooseAnAccount: "chọn một tài khoản",
  "Requested to Receive": "Yêu cầu nhận",
  "Receive With": "Nhận với",
  "Unknown session": "Phiên không xác định",
  transactionId: "ID giao dịch",
  traceId: "ID theo dõi",
  save: "Tiết kiệm",
  fillTheBillingInformation: "Vui lòng điền thông tin thanh toán bên dưới",
  correctionsRequired: "yêu cầu chỉnh sửa",
  billingInfo: "Thông tin thanh toán",
  "Cancelled by client": "Đã hủy bởi khách hàng",
  "Your Cards": "Thẻ của bạn",
  Done: "Làm xong",
  redirect: "Chuyển hướng",
  redirectingTo: "Chuyển hướng đến",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Giao dịch không thành công. Chúng tôi xin lỗi nhưng tài khoản PayPal được cung cấp xuất hiện trong hồ sơ của chúng tôi dưới tài khoản giao dịch của khách hàng khác. Khách hàng được giới hạn ở một tài khoản giao dịch khách hàng đang hoạt động trên trang web của chúng tôi. Để cho phép tài khoản PayPal này được sử dụng với thông tin đăng nhập của bạn hoặc nếu bạn cần bất kỳ sự trợ giúp nào với tài khoản của mình, vui lòng liên hệ với dịch vụ khách hàng.",
  "Not supported yet": "Chưa được hỗ trợ",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Xin lỗi, có vẻ như bạn không có bất kỳ chi tiết thanh toán nào được đăng ký để sử dụng cho yêu cầu rút tiền",
  "Not available gateways found": "Không tìm thấy cổng thông tin",
  "Token is invalid": "Mã thông báo không hợp lệ",
  "Payment method not available. Please contact your site administrator.":
    "Phương thức thanh toán không có sẵn. Vui lòng liên hệ với quản trị viên trang web của bạn.",
  cardIsNotValid: "Thẻ không hợp lệ",
  chooseAmount: "chọn số lượng",
  invalidInputFormat:
    "Định dạng đầu vào không hợp lệ. Ít nhất {{length}} ký tự.",
  invalidDateFormat: "Định dạng ngày tháng hợp lệ.",
  maximumValueIs: "Giá trị tối đa là",
  minimumValueIs: "Giá trị tối thiểu là",
  enterPayoutAmount:
    "Vui lòng nhập Số tiền xuất chi nhỏ hơn hoặc bằng Số dư khả dụng của bạn.",
  maxLength2: "Độ dài tối đa 2 ký tự.",
  footer1:
    "Một số ngân hàng phát hành đã hạn chế việc sử dụng thẻ tín dụng để giải trí trực tuyến. Vui lòng sử dụng một trong các phương thức thanh toán thay thế của bên thứ ba ở trên hoặc liên hệ với {{customer_support_url}} Đại diện của chúng tôi theo số điện thoại miễn phí N / A để nạp tiền tài khoản của bạn. \n Xin lưu ý: Tùy thuộc vào ngân hàng phát hành của bạn, các giao dịch thẻ tín dụng có thể được coi như một khoản ứng trước tiền mặt. {{application_key}} không chịu trách nhiệm cho bất kỳ khoản phí ứng tiền mặt nào liên quan đến giao dịch này vì khoản này được xác lập bởi tín dụng ngân hàng phát hành thẻ. Tham khảo ý kiến ​​ngân hàng của bạn để biết thêm chi tiết. ",
  footer2:
    "Để được hỗ trợ hoặc xác nhận một giao dịch đã thử, vui lòng liên hệ với {{customer_support_url}}. \n Xin Lưu ý: Tùy thuộc vào ngân hàng của bạn, giao dịch này có thể được coi là ứng tiền mặt. {{application_key}} sẽ không chịu trách nhiệm bất kỳ khoản phí ứng trước tiền mặt nào liên quan đến giao dịch này. Giao dịch sẽ được xác định trên bảng sao kê thẻ tín dụng của bạn dưới dạng Công cụ mô tả thẻ tín dụng trừ khi được nêu khác trên biên lai giao dịch của bạn. ",
  footer3:
    "Tất cả các yêu cầu thanh toán đều tuân theo {{terms_conditions_url}} của chúng tôi. Nếu bạn cần hỗ trợ hoặc thêm thông tin, vui lòng liên hệ với {{customer_support_url}}. Sau khi yêu cầu của bạn được gửi, thông tin sẽ được chuyển tiếp đến bộ phận thu ngân của chúng tôi để xử lý . Bạn sẽ nhận được email xác nhận khi khoản thanh toán của bạn được xử lý. Số tiền thanh toán và phí xử lý (nếu có) sẽ được ghi nợ vào tài khoản của bạn sau khi xem xét khoản thanh toán của chúng tôi. ",
  gatewaysTitle: "Bạn muốn thanh toán thế nào?",
  gatewaysTitleDeposit: "Vui lòng chọn phương thức gửi tiền",
  gatewaysTitleWithdrawal: "Vui lòng chọn phương thức rút tiền",
  payInTitle: "Thanh toán bằng thẻ tín dụng hoặc thẻ ghi nợ",
  allPaymentMethods: "tất cả các phương thức thanh toán",
  submit: "Gửi đi",
  useAddressOnFile: "Sử dụng địa chỉ trong hồ sơ",
  customerName: "tên khách hàng",
  country: "Quốc gia",
  province: "Tỉnh",
  city: "Thành phố",
  postalCode: "mã bưu điện",
  street: "đường phố",
  proceed: "Tiến hành",
  cardNumber: "Số thẻ",
  cardHolder: "Chủ thẻ",
  cardHolderName: "Tên chủ thẻ",
  cardExpires: "Thẻ hết hạn",
  cvv: "CVV",
  firstName: "Tên đầu tiên",
  lastName: "Họ",
  zipCode: "Mã Bưu Chính",
  address: "Địa chỉ",
  state: "Tiểu bang",
  email: "E-mail",
  phone: "Điện thoại",
  dateOfBirth: "Ngày sinh",
  transactionStatus: "Trạng thái giao dịch",
  trace: "Dấu vết",
  amount: "Số tiền",
  min: "tối thiểu",
  max: "tối đa",
  overrideLimits: "Giới hạn ghi đè",
  transaction: "Giao dịch",
  processed: "Xử lý",
  transactionRejected: "Giao dịch bị từ chối",
  transactionApproved: "Giao dịch được chấp nhận",
  yourTransactionIsPending: "Giao dịch của bạn đang chờ xử lý",
  transactionAuthorized: "Giao dịch ủy quyền",
  requestAccepted: "Yêu cầu được chấp nhận",
  status2:
    "Yêu cầu rút tiền của bạn đã được chấp nhận. Xin lưu ý rằng việc ủy quyền và xử lý tiếp theo sẽ được thực hiện trong giờ hoạt động của ngày làm việc. Trong thời gian đó, bạn có thể được nhân viên điều hành liên hệ để xác nhận danh tính.",
  unexpectedErrorOccurred: "Đã xảy ra lỗi không mong muốn",
  status3:
    "Chúng tôi xin lỗi, đã xảy ra sự cố trong khi xử lý giao dịch của bạn. Vui lòng tìm các chi tiết kỹ thuật dưới đây: ",
  tryAgain: "Thử lại",
  somethingWentWrong: "Đã xảy ra lỗi ...",
  newPayoutRequest: "Yêu cầu xuất chi mới",
  makeAnotherPayment: "Thanh toán khác",
  profile: "Hồ sơ",
  justOneMoreStep: "Chỉ một bước nữa thôi ...",
  "profile-auth":
    "Vui lòng hoàn tất hồ sơ của bạn để tiến hành thanh toán bằng {{title}}",
  payWith: "Thanh toán bằng",
  ProceedWith: "Tiếp tục với",
  vtTitle: "Thiết bị đầu cuối ảo: ",
  pay: "Trả",
  deposit: "Tiền gửi",
  withdraw: "Rút tiền",
  withdrawal: "Rút tiền",
  hello: "Xin chào,",
  currency: "Tiền tệ",
  currentIp: "IP hiện tại: ",
  UseCurrentIp: "Sử dụng IP hiện tại: ",
  UseCustomerIp: "Sử dụng IP của khách hàng: ",
  copyright: "Bản quyền",
  cannotBeBlank: "Không thể chừa trống.",
  noDepositFree: "Không có phí đặt cọc. không có phí rút tiền.",
  next: "Kế tiếp",
  backToSite: "Quay lại trang web",
  maximumLength: "Độ dài tối đa {{length}} ký tự",
  minimumLength: "Độ dài tối thiểu {{length}} ký tự",
  emailIsNotValid: "Email không phải là địa chỉ email hợp lệ.",
  phoneIsNotValid: "Điện thoại không hợp lệ",
  cardHolderInvalid: "Chủ thẻ không hợp lệ. Chủ thẻ nên chứa tên và họ.",
  pleaseEnterPayout: "Vui lòng nhập số tiền xuất chi ít hơn hoặc bằng",
  invalidAmount: "Số tiền không hợp lệ",
  transactionWillAppear:
    "Giao dịch của bạn sẽ xuất hiện trên bảng sao kê của bạn dưới dạng",
  "Customer does not exist": "Khách hàng không tồn tại",
  "Sorry no available gateways found.":
    "Xin lỗi không có cổng thông tin có sẵn được tìm thấy.",
  "Incorrect currency": "Tiền tệ không chính xác",
  "Sorry, not supported payment type": "Xin lỗi, không hỗ trợ loại thanh toán",
  "Payment method has no payment type":
    "Phương thức thanh toán không có loại thanh toán",
  "Incorrect payment type in payment method":
    "Loại thanh toán không chính xác trong phương thức thanh toán",
  "Gateway limit exceeded": "Vượt quá giới hạn cổng",
  "Not supported transaction type": "Loại giao dịch không được hỗ trợ",
  "Sorry. Payout with credit card gateway not supported yet":
    "Lấy làm tiếc. Xuất chi với cổng thẻ tín dụng chưa được hỗ trợ",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Chúng tôi xin lỗi, phương thức thanh toán này không áp dụng cho các chi tiết thanh toán được chỉ định. Vui lòng tải lại trang và thử một số tiền, tiền tệ hoặc phương thức thanh toán khác.",
  "Back to site": "Quay lại trang web",
  "Your transaction has been requested. Please wait for approval.":
    "Giao dịch của bạn đã được yêu cầu. Xin vui lòng chờ phê duyệt.",
  "Token expired": "Mã thông báo đã hết hạn",
  back: "trở lại",
  "Transaction not found.": "Không tìm thấy giao dịch.",
  "Continue in the New Window": "Tiếp tục trong Cửa sổ mới",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Vui lòng không đóng cửa sổ này. Bạn sẽ được chuyển hướng trở lại khi kết thúc giao dịch trong một cửa sổ mới. Bạn không thấy một cửa sổ mới mở ra một kết nối an toàn? Chúng tôi ở đây để giúp bạn hoàn thành giao dịch của mình.",
  "Click to continue": "Click để tiếp tục",
  "Just One More Step...": "Chỉ một bước nữa ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Phương thức thanh toán bạn đã chọn sẽ được mở trong một trang mới. Vui lòng nhấp vào nút 'TIẾN HÀNH' bên dưới để điều hướng đến bước cuối cùng.",
  Proceed: "Tiến hành",
  "Opening a Secure Connection...": "Mở một kết nối an toàn ...",
  "You will be redirected to complete your transaction in just a moment":
    "Bạn sẽ được chuyển hướng để hoàn tất giao dịch của mình chỉ trong giây lát",
  "Close window": "Đóng cửa sổ",
  "Partial approval confirmation": "Xác nhận phê duyệt một phần",
  PartiallyApprovedAmount:
    "Số dư thẻ của bạn không đủ tiền để ghi có toàn bộ số tiền. Giao dịch đã được chấp thuận một phần cho {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Vui lòng nhấp vào 'OK' để xác nhận một phần số tiền ký quỹ hoặc 'HỦY' để hủy giao dịch. Khoản tiền gửi sẽ được xác nhận tự động sau 5 phút nếu không có hành động nào được thực hiện.",
  fxRateSpreadText:
    "Tỷ giá hối đoái được chỉ định bao gồm tất cả các khoản phí và lệ phí.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Tải vui lòng đợi",
};
