export const darkConfig = {
    theme: "dark-theme", // nova-theme, basic-theme
    router: "basic", // nova, basic
    components: {
        amount: "basic", // 'basic', slider
        showCardIcons: false, // show or hide card icons on credit-card
        error: "bottom", // top, bottom
        visualCard: false,
        cardNumber: "multiple", // basic, transform, multiple
        sliderMethods: "beside", // type of gateways list: separate, slider, beside
        profile: "modal" // profile in checkbox or in modal ("checkbox", "modal")
    }
};