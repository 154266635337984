import {customProcessor, docCreditCard, docEwalet} from "mock-data/mock-api/gateways";
import store from "../../state/store"
import {theme} from "mock-data/mock-api/test-theme";
import {profile} from "mock-data/mock-api/test-get-profile";
import {gateways, obGateways} from "mock-data/mock-api/test-get-gateways";
import {result} from "mock-data/mock-api/test-pay-with-gateway";
import {save} from "mock-data/mock-api/test-save-data";
import {removeTransactions, transactions} from "mock-data/mock-api/get-transactions";
import {options} from "mock-data/mock-api/test-get-dinamic-options";
import {callbackResult} from "mock-data/mock-api/callback-result";
import {partialApprovedResult} from "mock-data/mock-api/test-partial-approve-pay";
import {getCurrentGateway} from "mock-data/mock-api/gateway-details";
import {getProcessingGatewaysList} from "mock-data/mock-api/get-processing-gateway-list";
import {payWithManageRetryRequest} from "mock-data/mock-api/pay-with-manage-retry-request";
import {graphqlData} from "mock-data/mock-api/test-get-graphql-data";

export const getTestData = (key, params={},useSettings=false) => {
    let {option_id, ob_decline_recovery, } = params;
    let data={};
    if(useSettings) changeData();
    
    if(key === "get-transactions") data = transactions;
    if(key === "delete-transaction") data = removeTransactions;
    if(key === "get-customer-data") data = profile;
    if(key === "callback") data = callbackResult;
    if(key === "get-gateway-details") data = getCurrentGateway[option_id];
    if(key.includes("get-gateway-list") || key.includes("get-payment-method-list")) data = ob_decline_recovery ? obGateways:  gateways;
    if(key === "pay-with-gateway") data = result;
    if(key === "get-dynamic-options") data = options;
    if(key === "save-data") data = save;
    if(key === "get-asset-data") data = theme;
    if(key === "partial-approve") data = partialApprovedResult;
    if(key === "get-processing-gateway-list") data = getProcessingGatewaysList;
    if(key === "manage-retry-request") data = payWithManageRetryRequest;
    if(key === 'test-get-graphql-data') data = graphqlData;
    return data;
};

const changeData = () => {
    const {data_doc=""} = store.getState().location;
    // const data_doc = "MCwzMDAwLDEwMCwwLDAsMCwwLGRlLURFLDAsLDAsMCwwLGNsYXNzaWMsMCwwLDA=";
    const settings = window.atob(data_doc).split(",");

    const currentGateway = settings[16] === "0"? docCreditCard : settings[16] === "1"? docEwalet : customProcessor;
    const {payment_information:{currency_list={}}={}} = currentGateway;
    profile.base_amount = settings[2];
    profile.customer.balance = settings[1];
    profile.transaction_type = settings[15] === "0"? 'pay-in' : "pay-out";
    profile.customer.phone = settings[4] === "1"? "" : "0990938373";
    profile.session_type = settings[10] === "0"? "cashier" : "vt";
    if(settings[0] === "1") currentGateway.additional_fields = null;
    result.is_iframe = settings[6];
    result.redirect_url = settings[9];
    result.transaction.status = settings[17];
    currentGateway.payout_condition = settings[8] === "1"? "same_account" : null;
    currentGateway.skip_customer_details = settings[11];
    if(settings[12] === "1") currentGateway.templates = [];
    if(settings[3] === "1") delete currency_list[profile.base_currency];
    if(settings[3] === "2") {delete currency_list[profile.base_currency]; delete currency_list["EUR"]}
    if(settings[5] === "1") Object.keys(currency_list).map(el => {
        delete currency_list[el].min_amount;
        delete currency_list[el].max_amount;
    });
    gateways.gateways=[currentGateway];
    theme.locale=settings[7];
    theme.theme=settings[13];
};
export const graphqlMockRequest = async (query = [], delay = 0) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            let data = {}
            const cashier = getTestData('test-get-graphql-data');
            query.forEach((el) => {
                if(cashier[el]) {
                    data = {...data, [el]: cashier[el]}
                }
            })
            const response = query?.length > 0 ? data : cashier;
            console.log('mock-graph-ql-data', response);
            resolve(response);
        }, delay)
    })
}
