export const sv = {
  "Loading... Please Wait": "Laddar ... Vänta",
  yourTransactionIsPendingForPayOut: "Din uttagsbegäran väntar",
  status1:
    "Tänk på att din transaktion granskas av bearbetningsbanken eller betaltjänstleverantören. Om du vill ha mer information om status för din transaktion, vänligen kontakta support för att begära hjälp med Trace ID: {{trace_id}}",
  Status: "Status",
  "Deposit Review For Account:": "Insättningsgranskning för konto:",
  "New Transaction": "Ny transaktion",
  Update: "Uppdatering",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Den valda betalningsmetoden öppnas på en separat sida. Klicka på 'Fortsätt' för att navigera till ett nytt webbläsarfönster och fortsätta betalningsprocessen.",
  dod_error_text:
    "Du måste läsa och acceptera insättningsdeklarationen för att kunna fortsätta",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Du måste acceptera villkoren för att kunna fortsätta med din insättning",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Jag erkänner att jag har läst och accepterar härmed villkoren",
  dod_url: "#",
  dod_url_text: "villkoren",
  dod_text: "Jag erkänner att jag har läst och accepterar härmed {{dod_url}}",
  transactionSuccessfullyCanceled: "Transaktionen {{id}} avbröts",
  "Fee should not exceed 20% of the amount":
    "Avgiften bör inte överstiga 20% av beloppet",
  "My Info": "Min info",
  "Billing info": "Faktureringsinfo",
  "Withdrawal Requests": "Återkallanden",
  "No Withdrawal Requests found": "Inga uttagsbegäranden hittades",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Annullera",
  Amount: "Belopp",
  "Last Update": "Senaste uppdateringen",
  "Payment Method": "Betalningsmetod",
  TID: "TID",
  Action: "Handling",
  "Confirmation required": "Konfirmation Krävs",
  "Are you sure you want to cancel transaction":
    "Är du säker på att du vill avbryta transaktionen",
  Yes: "Ja",
  No: "Nej",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Min-Max belopp",
  "Fee (included)": "Avgift (ingår)",
  "- Amount between": "- Belopp mellan",
  "I have the Promocode!": "Jag har Promocode!",
  "Please enter the Promocode": "Ange kampanjkoden",
  Promocode: "Rabattkod",
  noSavedTemplates: "Inga kort sparade",
  noOptions: "Inga alternativ",
  "Please confirm your billing details": "Bekräfta dina faktureringsuppgifter",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Administrativ avgift",
  Fee: "Avgift",
  Total: "Total",
  customer_support_link_text: "Kundsupport",
  terms_conditions_link_text: "Villkor",
  "Edit Billing Info": "Redigera faktureringsinformation",
  chooseAnAccount: "Välj ett konto",
  "Requested to Receive": "Begärt att få",
  "Receive With": "Ta emot med",
  "Unknown session": "Okänd session",
  transactionId: "Transaktions ID",
  traceId: "spårnings-ID",
  save: "Spara",
  fillTheBillingInformation: "Fyll i faktureringsinformationen nedan",
  correctionsRequired: "korrigeringar krävs",
  billingInfo: "Faktureringsinfo",
  "Cancelled by client": "Avbruten av klienten",
  "Your Cards": "Dina kort",
  Done: "Gjort",
  redirect: "Dirigera om",
  redirectingTo: "Omdirigerar till",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    " Transaktionen lyckades inte. Vi beklagar, men PayPal-konto du angav uppkommer i vårt system under en annan kunds handelskonto. Kunder är begränsade till ett aktivt kundhandelskonto på vår sajt. För att möjliggöra att detta PayPal-konto kan användas med ditt inloggning eller om du behöver någon annan hjälp med ditt konto, var god och kontakta kundtjänst.",
  "Not supported yet": "Inte stöds ännu",
  "Not available gateways found": "Inga tillgängliga gateways hittade",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Jag ber om ursäkt, det verkar som om du inte har registrerat någon betalningsinformation för begäran om uttag",
  "Token is invalid": "Token är ogiltig",
  "Payment method not available. Please contact your site administrator.":
    "Betalningsmetod inte tillgänglig. Kontakta din webbplatsadministratör.",
  cardIsNotValid: "Kortet är inte giltigt",
  invalidInputFormat:
    "Ogiltigt inmatningsformat. Åtminstone {{length}} tecken.",
  invalidDateFormat: "Ogiltigt datumformat.",
  maximumValueIs: "Maxvärdet är",
  chooseAmount: "Välj belopp",
  minimumValueIs: "Minsta värde är",
  enterPayoutAmount:
    "Ange en utbetalning Belopp mindre än eller lika med din tillgängliga saldo.",
  maxLength2: "Maximal längd 2 tecken.",
  footer1:
    "Vissa utgivande banker har begränsat användningen av kreditkort för onlineunderhållning. Använd gärna någon av de alternativa betalningsmetoderna från tredje part ovan eller kontakta våra {{customer_support_url}} representanter gratis på N / A för att finansiera ditt konto. \n Obs! Beroende på din utgivande bank kan kreditkortstransaktioner behandlas som ett kontantförskott. {{application_key}} kan inte hållas ansvarig för eventuella kontantförskottsavgifter som är involverade i denna transaktion eftersom detta fastställs av krediten. kortets utfärdande bank. Kontakta din bank för mer information. ",
  footer2:
    "För hjälp eller bekräftelse av ett försök till transaktion, vänligen kontakta {{customer_support_url}}. \n Obs! Beroende på din bank kan denna transaktion behandlas som ett kontantförskott. {{application_key}} ansvarar inte för eventuella kontantförskottsavgifter som är inblandade i denna transaktion. Transaktionen kommer att identifieras på ditt kreditkortsutdrag som kreditkortsbeskrivare såvida inte annat anges på ditt transaktionskvitto. ",
  footer3:
    "Alla utbetalningsförfrågningar omfattas av våra {{terms_conditions_url}}. Om du behöver hjälp eller ytterligare information, vänligen kontakta {{customer_support_url}}. När din begäran har skickats vidarebefordras informationen till vår kassadepartement för behandling . Du kommer att få ett e-postmeddelande som en bekräftelse när din utbetalning behandlas. Utbetalningsbeloppet och behandlingsavgiften (om tillämpligt) debiteras från ditt konto efter vår utbetalningsgranskning. ",
  gatewaysTitle: "Hur vill du betala?",
  gatewaysTitleDeposit: "Välj insättningsmetod",
  gatewaysTitleWithdrawal: "Välj uttagsmetod",
  payInTitle: "Betala med kredit- eller betalkort",
  allPaymentMethods: "alla betalningsmetoder",
  submit: "Skicka in",
  useAddressOnFile: "Använd adress på fil",
  customerName: "Köparens namn",
  country: "Land",
  province: "Provins",
  city: "Stad",
  postalCode: "Postnummer",
  street: "Gata",
  proceed: "Fortsätt",
  cardNumber: "Kortnummer",
  cardHolder: "Korthållare",
  cardHolderName: "Kortinnehavarens namn",
  cardExpires: "Kortet löper ut",
  cvv: "CVV",
  firstName: "Förnamn",
  lastName: "Efternamn",
  zipCode: "Postnummer",
  address: "Adress",
  state: "stat",
  email: "E-post",
  phone: "Telefon",
  dateOfBirth: "Födelsedatum",
  transactionStatus: "transaktionsstatus",
  trace: "Spår",
  amount: "Belopp",
  min: "min",
  max: "max",
  overrideLimits: "Överstyr gränser",
  transaction: "Transaktion",
  processed: "Bearbetade",
  transactionRejected: "Transaktion avvisas",
  transactionApproved: "Transaktion godkänd",
  yourTransactionIsPending: "Din transaktion väntar",
  transactionAuthorized: "Transaktion godkänd",
  requestAccepted: "Begäran accepteras",
  status2:
    "Din begäran om uttag har godkänts. Vänligen bekräfta att ytterligare godkännande och behandling kommer att utföras under arbetstidens öppettider. Under den tiden kan du bli kontaktad av verkställande personal för att bekräfta identitet.",
  unexpectedErrorOccurred: "Oväntat fel inträffade",
  status3:
    "Vi ber om ursäkt, något gick fel vid bearbetningen av din transaktion. Vänligen hitta de tekniska detaljerna nedan: ",
  tryAgain: "Försök igen",
  somethingWentWrong: "Något gick fel...",
  newPayoutRequest: "Ny utbetalningsbegäran",
  makeAnotherPayment: "Gör en annan betalning",
  profile: "Profil",
  justOneMoreStep: "Bara ett steg till ...",
  "profile-auth":
    "Fyll i din profil för att fortsätta med betalningen med {{title}}",
  payWith: "Betala med",
  ProceedWith: "Fortsätt med",
  vtTitle: "Virtuell terminal: ",
  pay: "Betala",
  deposit: "Deposition",
  withdraw: "Dra tillbaka",
  withdrawal: "Uttag",
  hello: "Hej,",
  currency: "Valuta",
  currentIp: "Nuvarande IP: ",
  UseCurrentIp: "Använd aktuell IP: ",
  UseCustomerIp: "Använd kund-IP: ",
  copyright: "upphovsrätt",
  cannotBeBlank: "Kan inte vara tom.",
  noDepositFree: "Ingen insättningsavgift. ingen uttagningsavgift.",
  next: "Nästa",
  backToSite: "Tillbaka till webbplatsen",
  maximumLength: "Maximal längd {{length}} tecken",
  minimumLength: "Minsta längd {{length}} tecken",
  emailIsNotValid: "E-postadressen är inte en giltig e-postadress.",
  phoneIsNotValid: "Telefonen är inte giltig",
  cardHolderInvalid:
    "Korthållaren är ogiltig. Korthållaren ska innehålla förnamn och efternamn.",
  pleaseEnterPayout: "Ange en utbetalning Belopp mindre än eller lika",
  invalidAmount: "Ogiltigt belopp",
  transactionWillAppear:
    "Din transaktion kommer att visas i ditt uttalande som",
  "Customer does not exist": "Kunden finns inte",
  "Sorry no available gateways found.":
    "Tyvärr hittades inga tillgängliga gateways.",
  "Incorrect currency": "Fel valuta",
  "Sorry, not supported payment type": "Tyvärr stöds inte betalningstyp",
  "Payment method has no payment type":
    "Betalningsmetoden har ingen betalningstyp",
  "Incorrect payment type in payment method":
    "Fel betalningstyp i betalningsmetod",
  "Gateway limit exceeded": "Gateway-gränsen överskred",
  "Not supported transaction type": "Inte stöds transaktionstyp",
  "Sorry. Payout with credit card gateway not supported yet":
    "Förlåt. Utbetalning med kreditkort gateway stöds inte ännu",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Vi ber om ursäkt, den här betalningsmetoden är inte tillämplig för den angivna betalningsinformationen. Ladda om sidan igen och prova ett annat belopp, valuta eller betalningsmetod.",
  "Back to site": "Tillbaka till webbplatsen",
  "Your transaction has been requested. Please wait for approval.":
    "Din transaktion har begärts. Vänta på godkännande.",
  "Token expired": "Token gick ut",
  back: "tillbaka",
  "Transaction not found.": "Transaktionen hittades inte.",
  "Continue in the New Window": "Fortsätt i det nya fönstret",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Stäng inte det här fönstret. Du kommer att omdirigeras tillbaka när transaktionen är klar i ett nytt fönster. Ser du inte ett nytt fönster som öppnar en säker anslutning? Vi är här för att hjälpa dig att slutföra din transaktion.",
  "Click to continue": "Klicka för att fortsätta",
  "Just One More Step...": "Bara ett steg till ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Betalningsmetoden som du har valt öppnas på en ny sida. Klicka på knappen 'FORTSÄTT' nedan för att navigera till det sista steget.",
  Proceed: "Fortsätt",
  "Opening a Secure Connection...": "Öppna en säker anslutning ...",
  "You will be redirected to complete your transaction in just a moment":
    "Du kommer att omdirigeras för att slutföra din transaktion på bara ett ögonblick",
  "Close window": "Stäng fönstret",
  "Partial approval confirmation": "Delvis godkännandebekräftelse",
  PartiallyApprovedAmount:
    "Ditt kortbalans har inte tillräckliga medel för att tillgodoräkna dig hela beloppet. Transaktionen godkändes delvis för {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    'Klicka på "OK" för att bekräfta delbeloppet eller "AVBRYT" för att avbryta transaktionen. Depositionen kommer att bekräftas automatiskt efter 5 minuter om inga åtgärder vidtas.',
  fxRateSpreadText:
    "Den angivna växelkursen inkluderar alla avgifter och avgifter.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Laddar... Var god vänta",
};
