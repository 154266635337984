import React from 'react'
import '../header.sass'
import compose from "../../../../utils/helper-functions/compose";
import {withI18N} from "../../../hoc-components";
import {useSelector, shallowEqual} from "react-redux";
import {withAdr} from "../../../hoc-components/with-adr";

const Greeting = ({langBack, setBillingModal, s}) => {
    const store = useSelector(({profileInfo, resultOfPay}) => ({profileInfo, resultOfPay}), shallowEqual);
    const {profileInfo, resultOfPay} = store;
    const {session_type=resultOfPay.session_type, customer:{full_name}={}} = profileInfo;

    let greeting = !full_name? "" : `${langBack('hello')}`;
    let virtualTerminal = (
        <span className={`${s('title-wrapper')}`}>
            <i className="fas fa-exclamation-triangle"></i>
            <span>{langBack('vtTitle')}</span>
        </span>
    );

    return(
        <div className={`${s('header-customer')}`}>
            {session_type === 'vt' ? virtualTerminal : greeting}
            <span className={`${s('header-username')}`} onClick={setBillingModal}>
                {full_name}
            </span>
        </div>
    )
}


export default compose(
    withI18N, withAdr
)(Greeting)
