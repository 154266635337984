import React, {memo, useEffect, useRef, useState} from "react";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Loader from "../../helper-components/loader/loader";
import "./requested-transactions.sass"
import {setPendingTransactions} from "../../../state/actions";
import TransactionsList from "./transactions-list/transactions-list";
import {withI18N, withPraxisService} from "../../hoc-components";
import compose from "../../../utils/helper-functions/compose";
import ConfirmWindow from "../../helper-components/confirm-window/confirm-window";
import {CheckedLogo} from "../../../@cashier-project/assets/images/checked-logo";
import {withAdr} from "../../hoc-components/with-adr";

const RequestedTransactions = memo((props) => {
    const {transactionList} = useSelector(({pendingTransactions}) => pendingTransactions, shallowEqual);
    const dispatch = useDispatch();
    const {praxisService:{serverDeleteTransaction, serverGetTransactions}, lang, langBack, s} = props;

    const [localLoading, setLocalLoading] = useState(false);
    const [error, setError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [confirmWindow, setConfirmWindow] = useState(false);
    const deletedTraceId = useRef(null);
    
    useEffect(() => {
        if(transactionList === undefined){
            setLocalLoading(true);
            getServerTransactions();
        }
    }, []);
    
    const getServerTransactions = () => {
        serverGetTransactions()
            .then(({data}) => {
                dispatch(setPendingTransactions(data));
                setLocalLoading(false);
            })
            .catch(() => {
                setShowSuccess(false);
                setLocalLoading(false);
                setError("Failed to get transactions");
                dispatch(setPendingTransactions(null));
            })
    }

    const deleteTransaction = () => {
        const id = deletedTraceId.current;
        setLocalLoading(true)
        serverDeleteTransaction(id)
            .then(({data}={}) => {
                setLocalLoading(false)
                if(data.status === 0){
                    setError(false)
                    setShowSuccess(true)
                    let array = transactionList.filter(el => id !== el.trace_id)
                    if(!array.length) array = null;
                    dispatch(setPendingTransactions(array))
                }
                if(data.status === 1) {
                    setShowSuccess(false)
                    setError(data.description?
                        data.description : `Failed to delete transaction ${deletedTraceId.current}`)
                }
            })
            .catch(() => {
                setShowSuccess(false);
                setLocalLoading(false);
                setError("Failed to delete transaction");
                dispatch(setPendingTransactions(null));
            })
    }
    
    if(!transactionList) {
        return (
            <div className={`${s('transactions-pending-table')}`}>
                {
                    transactionList === null?
                        <div className={`${s('not-transactions-found')}`}>
                            {langBack('No Withdrawal Requests found')}
                        </div> : <Loader/>
                }
            </div>
        )
    }

    return (
        <>
            {localLoading ? <Loader/> : ''}

            {
                confirmWindow?
                    <ConfirmWindow
                        confirm={() => {setConfirmWindow(false); deleteTransaction()}}
                        reject={() => setConfirmWindow(false)}
                        text={`${langBack('Are you sure you want to cancel transaction')} ${deletedTraceId.current}?`}
                    /> : ""
            }

            <div className={`${s('transactions-pending-table')}`}>
                 <TransactionsList
                     tableClass={`${s('table')} ${s('transactions-list')}`}
                     transactions={transactionList}
                     deleteTransaction={(id) => {setConfirmWindow(true); deletedTraceId.current = id}}
                 />
            </div>
            <div className={`${s('transactions-status-container')}`}>
                <div className={`${s('delete-transaction-error')}`}>
                    {error? langBack(error) : ""}
                </div>
                {
                    showSuccess?
                        <div className={`${s('success-transaction')}`}>
                            <CheckedLogo/>{lang("transactionSuccessfullyCanceled", {id:deletedTraceId.current})}
                        </div> : ""
                }
            </div>
        </>
    );
})

export default compose(
    withPraxisService(), withI18N, withAdr
)(RequestedTransactions);

