export const ar = {
  "Loading... Please Wait": "جاري التحميل انتظر من فضلك",
  yourTransactionIsPendingForPayOut: "طلب الانسحاب الخاص بك معلق",
  status1:
    "يرجى العلم أن معاملتك قيد المراجعة من قبل بنك المعالجة أو مزود خدمة الدفع. إذا كنت ترغب في الحصول على مزيد من المعلومات حول حالة معاملتك ، فيرجى الاتصال بالدعم لطلب المساعدة بشأن معرف التتبع: {{trace_id}}",
  Status: "الحالة",
  "Deposit Review For Account:": "مراجعة الإيداع للحساب:",
  "New Transaction": "معاملة جديدة",
  Update: "تحديث",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "يتم فتح طريقة الدفع التي حددتها في صفحة منفصلة. الرجاء النقر فوق 'متابعة' للانتقال إلى نافذة متصفح جديدة ومتابعة عملية الدفع.",
  dod_error_text: "يجب عليك قراءة وقبول إعلان الإيداع للمتابعة",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "يجب عليك قبول الشروط والأحكام من أجل متابعة إيداعك",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "أقر بأنني قد قرأت البنود والشروط وأوافق عليها",
  dod_url: "#",
  dod_url_text: "الأحكام والشروط",
  dod_text: "أقر بأنني قد قرأت ، وأوافق بموجب هذا على {{dod_url}}",
  transactionSuccessfullyCanceled: "تم إلغاء المعاملة {{id}} بنجاح",
  "Fee should not exceed 20% of the amount":
    "يجب ألا تتجاوز الرسوم 20٪ من المبلغ",
  "My Info": "معلوماتي",
  "Billing info": "معلومات الفواتير",
  "Withdrawal Requests": "طلبات الانسحاب",
  "No Withdrawal Requests found": "لم يتم العثور على طلبات سحب",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "إلغاء",
  Amount: "كمية",
  "Last Update": "اخر تحديث",
  "Payment Method": "طريقة الدفع او السداد",
  TID: "زمن",
  Action: "عمل",
  "Confirmation required": "التأكيد مطلوب",
  "Are you sure you want to cancel transaction":
    "هل أنت متأكد أنك تريد إلغاء الصفقة",
  Yes: "نعم",
  No: "لا",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "الحد الأدنى والحد الأقصى للمبالغ",
  "Fee (included)": "الرسوم (مشمولة)",
  "- Amount between": "- المبلغ بين",
  "I have the Promocode!": "لدي الرمز الترويجي!",
  "Please enter the Promocode": "الرجاء إدخال الرمز الترويجي",
  Promocode: "رمز ترويجي",
  noSavedTemplates: "لا توجد بطاقات محفوظة",
  noOptions: "لا يوجد خيارات",
  "Please confirm your billing details": "يرجى تأكيد تفاصيل الفواتير الخاصة بك",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "كلفة المعالجة",
  Fee: "مصاريف",
  Total: "مجموع",
  customer_support_link_text: "دعم العملاء",
  terms_conditions_link_text: "الأحكام والشروط",
  "Edit Billing Info": "تحرير معلومات الفواتير",
  chooseAnAccount: "اختيار حساب",
  "Requested to Receive": "تم طلب الاستلام",
  "Receive With": "تسلم",
  "Unknown session": "جلسة غير معروفة",
  transactionId: "رقم المعاملة",
  traceId: "معرف التتبع",
  save: "حفظ",
  fillTheBillingInformation: "يرجى ملء معلومات الفواتير أدناه",
  correctionsRequired: "التصحيحات المطلوبة",
  billingInfo: "معلومات الفواتير",
  "Cancelled by client": "ألغى العميل",
  "Your Cards": "بطاقاتك",
  Done: "منجز",
  redirect: "إعادة توجيه",
  redirectingTo: "إعادة التوجيه إلى",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    " لم تكن الصفقة ناجحة. عذرًا، ولكن حساب باي بال المقدم يظهر في سجلاتنا تحت حساب تداول عميل آخر. يحصل العملاء على حساب تداول عميل نشط واحد فقط على موقعنا. للسماح باستخدام هذا الحساب باي بال مع تسجيل الدخول الخاص بك، أو إذا كنت بحاجة إلى أي مساعدة في حسابك، يرجى الاتصال بخدمة العملاء.",
  "Not supported yet": "غير معتمدة حتى الآن",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "عذرًا ، يبدو أنه ليس لديك أي تفاصيل دفع مسجلة لاستخدامها في طلب السحب",
  "Not available gateways found": "تم العثور على بوابات غير متوفرة",
  "Token is invalid": "الرمز غير صالح",
  "Payment method not available. Please contact your site administrator.":
    "طريقة الدفع غير متاحة. يرجى الاتصال بمسؤول الموقع.",
  cardIsNotValid: "البطاقة غير صالحة",
  invalidInputFormat: "تنسيق إدخال غير صالح. {{length}} من الأحرف على الأقل.",
  invalidDateFormat: "تنسيق التاريخ غير صالح.",
  maximumValueIs: "القيمة القصوى هي",
  minimumValueIs: "القيمة الدنيا هي",
  enterPayoutAmount: "يرجى إدخال مبلغ دفع أقل من أو يساوي رصيدك المتاح.",
  maxLength2: "الحد الأقصى للطول 2 أحرف.",
  footer1:
    "فرضت بعض البنوك المُصدِرة للبطاقات قيودًا على استخدام بطاقات الائتمان للترفيه عبر الإنترنت. يُرجى عدم التردد في استخدام إحدى طرق الدفع البديلة للجهات الخارجية أعلاه أو الاتصال {{customer_support_url}} بممثلينا مجانًا على N / A للتمويل حسابك. \n الرجاء ملاحظة: اعتمادًا على البنك الذي أصدر البطاقة ، يمكن معاملة معاملات بطاقة الائتمان كسلفة نقدية. لا يمكن تحميل {{application_key}} مسؤولية أي رسوم سلفة نقدية متضمنة في هذه المعاملة حيث يتم تحديد ذلك من خلال الائتمان البنك الذي أصدر البطاقة. استشر البنك الذي تتعامل معه للحصول على مزيد من التفاصيل. ",
  footer2:
    "للمساعدة أو تأكيد محاولة إجراء معاملة ، يرجى الاتصال بـ {{customer_support_url}}. \n يرجى ملاحظة: اعتمادًا على البنك الذي تتعامل معه ، يمكن معاملة هذه المعاملة كسلفة نقدية. لن يكون {{application_key}} مسؤولاً عن أي رسوم سلفة نقدية متضمنة في هذه المعاملة. سيتم تحديد المعاملة في بيان بطاقة الائتمان الخاصة بك باعتبارها واصف بطاقة الائتمان ما لم يُذكر خلاف ذلك في إيصال المعاملة. ",
  footer3:
    "تخضع جميع طلبات الدفع لـ {{terms_conditions_url}}. إذا كنت بحاجة إلى مساعدة أو مزيد من المعلومات ، فيرجى الاتصال بـ {{customer_support_url}}. بمجرد إرسال طلبك ، تتم إعادة توجيه المعلومات إلى قسم الصراف لدينا للمعالجة . ستتلقى رسالة بريد إلكتروني كتأكيد عند معالجة العائد الخاص بك. سيتم خصم مبلغ العائد ورسوم المعالجة (إن وجدت) من حسابك بعد مراجعة العائد. ",
  gatewaysTitle: "كيف تريد الدفع؟",
  gatewaysTitleDeposit: "يرجى اختيار طريقة الإيداع",
  gatewaysTitleWithdrawal: "يرجى اختيار طريقة السحب",
  payInTitle: "الدفع باستخدام بطاقة الائتمان أو الخصم",
  allPaymentMethods: "جميع طرق الدفع",
  submit: "إرسال",
  useAddressOnFile: "استخدم العنوان في الملف",
  customerName: "اسم الزبون",
  country: "بلد",
  province: "المحافظة",
  city: "مدينة",
  postalCode: "الكود البريدى",
  street: "شارع",
  proceed: "تقدم",
  cardNumber: "رقم البطاقة",
  cardHolder: "حامل البطاقة",
  cardHolderName: "اسم حامل البطاقة (لاتيني فقط)",
  cardExpires: "تنتهي صلاحية البطاقة",
  cvv: "CVV",
  firstName: "الاسم الاول",
  lastName: "الكنية",
  zipCode: "الرمز البريدي",
  address: "عنوان",
  state: "حالة",
  email: "البريد الإلكتروني",
  phone: "هاتف",
  dateOfBirth: "تاريخ الولادة",
  transactionStatus: "حالة عملية",
  trace: "أثر",
  amount: "كمية",
  min: "دقيقة",
  max: "ماكس",
  overrideLimits: "تجاوز الحدود",
  transaction: "عملية تجارية",
  processed: "تمت معالجتها",
  transactionRejected: "تم رفض المعاملة",
  transactionApproved: "تمت الموافقة على المعاملة",
  yourTransactionIsPending: "معاملتك معلقة",
  transactionAuthorized: "أذن المعاملة",
  requestAccepted: "تم قبول الطلب",
  status2:
    "تم قبول طلب السحب الخاص بك. يُرجى العلم بأنه سيتم تنفيذ المزيد من التفويض والمعالجة خلال ساعات العمل في أيام العمل. خلال تلك الفترة ، قد يتم الاتصال بك من قبل الموظفين التنفيذيين لتأكيد الهوية.",
  unexpectedErrorOccurred: "حدث خطأ غير متوقع",
  status3:
    "نعتذر ، حدث خطأ أثناء معالجة معاملتك. يرجى الاطلاع على التفاصيل الفنية أدناه:",
  tryAgain: "حاول مجددا",
  somethingWentWrong: "هناك خطأ ما...",
  newPayoutRequest: "طلب عائد جديد",
  makeAnotherPayment: "إجراء عملية دفع أخرى",
  profile: "الملف الشخصي",
  justOneMoreStep: "مجرد خطوة أخرى...",
  "profile-auth": "يرجى إكمال ملفك الشخصي لمتابعة الدفع باستخدام {{title}}",
  payWith: "ادفع من خلال",
  ProceedWith: "يستمر مع",
  vtTitle: "المحطة الافتراضية:",
  pay: "دفع",
  deposit: "الوديعة",
  withdraw: "انسحب",
  withdrawal: "انسحاب",
  hello: "مرحبا،",
  currency: "عملة",
  currentIp: "IP الحالي:",
  UseCurrentIp: "استخدام IP الحالي:",
  UseCustomerIp: "استخدام IP الخاص بالعميل:",
  copyright: "حقوق النشر",
  chooseAmount: "اختيار مبلغ",
  cannotBeBlank: "لا يمكن أن يكون فارغا.",
  noDepositFree: "بدون رسوم إيداع. بدون رسوم سحب.",
  next: "التالى",
  backToSite: "العودة إلى الموقع",
  maximumLength: "الحد الأقصى للطول {{length}} من الأحرف",
  minimumLength: "الحد الأدنى للطول {{length}} حرفًا",
  emailIsNotValid: "البريد الإلكتروني ليس عنوان بريد إلكتروني صالح.",
  phoneIsNotValid: "الهاتف غير صالح",
  cardHolderInvalid:
    "حامل البطاقة غير صالح. يجب أن يحتوي حامل البطاقة على الاسم الأول والاسم الأخير.",
  pleaseEnterPayout: "الرجاء إدخال مبلغ دفع أقل من أو يساوي",
  invalidAmount: "مبلغ غير صحيح",
  transactionWillAppear: "ستظهر معاملتك على بيانك باسم",
  "Customer does not exist": "العميل غير موجود",
  "Sorry no available gateways found.": "عذرا لم يتم العثور على بوابات متاحة.",
  "Incorrect currency": "عملة غير صحيحة",
  "Sorry, not supported payment type": "عذرا ، نوع الدفع غير معتمد",
  "Payment method has no payment type": "طريقة الدفع ليس لها نوع دفع",
  "Incorrect payment type in payment method":
    "نوع الدفع غير صحيح في طريقة الدفع",
  "Gateway limit exceeded": "تم تجاوز حد البوابة",
  "Not supported transaction type": "نوع المعاملة غير معتمد",
  "Sorry. Payout with credit card gateway not supported yet":
    "آسف. الدفع باستخدام بوابة بطاقة الائتمان غير معتمد حتى الآن",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "نعتذر ، لا تنطبق طريقة الدفع هذه على تفاصيل الدفع المحددة. يرجى إعادة تحميل الصفحة وتجربة مبلغ أو عملة أو طريقة دفع مختلفة.",
  "Back to site": "العودة الى الموقع",
  "Your transaction has been requested. Please wait for approval.":
    "تم طلب معاملتك. يرجى الانتظار للحصول على الموافقة.",
  "Token expired": "انتهت صلاحية الرمز",
  back: "عودة",
  "Transaction not found.": "لم يتم العثور على المعاملة.",
  "Continue in the New Window": "استمر في النافذة الجديدة",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "الرجاء عدم إغلاق هذه النافذة. ستتم إعادة توجيهك مرة أخرى عند الانتهاء من المعاملة في نافذة جديدة. ألا ترى نافذة جديدة تفتح اتصالاً آمنًا؟ نحن هنا لمساعدتك على إكمال معاملتك.",
  "Click to continue": "انقر للمتابعة",
  "Just One More Step...": "مجرد خطوة أخرى...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "سيتم فتح طريقة الدفع التي حددتها في صفحة جديدة. الرجاء النقر فوق الزر 'متابعة' أدناه للانتقال إلى الخطوة الأخيرة",
  Proceed: "تقدم",
  "Opening a Secure Connection...": "فتح اتصال آمن ...",
  "You will be redirected to complete your transaction in just a moment":
    "ستتم إعادة توجيهك لإكمال معاملتك في غضون لحظة",
  "Close window": "أغلق النافذة",
  "Partial approval confirmation": "تأكيد الموافقة الجزئي",
  PartiallyApprovedAmount:
    "رصيد بطاقتك لا يحتوي على أموال كافية لإيداع المبلغ بالكامل. تمت الموافقة جزئيًا على الصفقة بمبلغ {{amount}} {{currency}} دولارًا أمريكيًا.",
  ConfirmPartialDeposit:
    'الرجاء النقر فوق "موافق" لتأكيد مبلغ الإيداع الجزئي أو "إلغاء" لإلغاء المعاملة. سيتم تأكيد الإيداع تلقائيًا بعد 5 دقائق إذا لم يتم اتخاذ أي إجراء.',
  fxRateSpreadText: "يشمل سعر الصرف المشار إليه جميع الرسوم والتكاليف.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newCCDetails: "Credit Card Details",
  newCCProceed: "Proceed with Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newWithdrawAmount: "Withdraw Amount",
  newTotalCharge: "Total Charge",
  newChooseCurrency: "Choose Currency",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newWithdraw: "Withdraw",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "جاري التحميل يرجى الانتظار",
};
