export const it = {
  "Loading... Please Wait": "Attendere il caricamento prego",
  yourTransactionIsPendingForPayOut:
    "La tua richiesta di prelievo è in sospeso",
  status1:
    "Tieni presente che la tua transazione è in fase di revisione da parte della banca di elaborazione o del fornitore di servizi di pagamento. Se desideri maggiori informazioni sullo stato della transazione, contatta l'assistenza per richiedere assistenza su Trace ID: {{trace_id}}",
  Status: "Stato",
  "Deposit Review For Account:": "Revisione del deposito per l'account:",
  "New Transaction": "Nuova transazione",
  Update: "Aggiornare",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Il metodo di pagamento selezionato si apre in una pagina separata. Fare clic su 'PROCEDERE' per passare a una nuova finestra del browser e continuare il processo di pagamento.",
  dod_error_text:
    "È necessario leggere e accettare la Dichiarazione di deposito per poter procedere",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Devi accettare i Termini e condizioni per procedere con il deposito",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Riconosco di aver letto e accetto i Termini e condizioni",
  dod_url: "#",
  dod_url_text: "Termini e condizioni",
  dod_text: "Riconosco di aver letto e accetto i {{dod_url}}",
  transactionSuccessfullyCanceled:
    "La transazione {{id}} è stata annullata con successo",
  "Fee should not exceed 20% of the amount":
    "La commissione non deve superare il 20% dell'importo",
  "My Info": "Le mie informazioni",
  "Billing info": "Informazioni di fatturazione",
  "Withdrawal Requests": "Richieste di prelievo",
  "No Withdrawal Requests found": "Nessuna richiesta di prelievo trovata",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Annulla",
  Amount: "Quantità",
  "Last Update": "Ultimo aggiornamento",
  "Payment Method": "Metodo di pagamento",
  TID: "TEMPO",
  Action: "Azione",
  "Confirmation required": "Conferma richiesta",
  "Are you sure you want to cancel transaction":
    "Sei sicuro di voler annullare la transazione",
  Yes: "Sì",
  No: "No",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Importi min-max",
  "Fee (included)": "Tassa (inclusa)",
  "- Amount between": "- Importo tra",
  "I have the Promocode!": "Ho il codice promozionale!",
  "Please enter the Promocode": "Inserisci il codice promozionale",
  Promocode: "Codice promozionale",
  noSavedTemplates: "Nessuna carta salvata",
  noOptions: "Nessuna opzione",
  "Please confirm your billing details":
    "Si prega di confermare i dettagli di fatturazione",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Tassa di elaborazione",
  Fee: "Tassa",
  Total: "Totale",
  customer_support_link_text: "Servizio Clienti",
  terms_conditions_link_text: "Termini e condizioni",
  "Edit Billing Info": "Modifica informazioni di fatturazione",
  chooseAnAccount: "Scegli un account",
  "Requested to Receive": "Richiesto di ricevere",
  "Receive With": "Ricevi con",
  "Unknown session": "Sessione sconosciuta",
  transactionId: "ID transazione",
  traceId: "ID traccia",
  save: "Salva",
  fillTheBillingInformation:
    "Si prega di compilare le informazioni di fatturazione di seguito",
  correctionsRequired: "correzioni richieste",
  billingInfo: "Informazioni di fatturazione",
  "Cancelled by client": "Annullato dal cliente",
  "Your Cards": "Le tue carte",
  Done: "Fatto",
  redirect: "Reindirizzare",
  redirectingTo: "Reindirizzamento a",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "La transazione non è andata a buon fine. Ci risulta che il conto PayPal fornito sia collegato al conto di trading di un altro cliente. I clienti possono avere un massimo di un conto di trading attivo per persona sul nostro sito. Per poter utilizzare questo conto PayPal con i tuoi dati di accesso, o se hai bisogno di assistenza sul tuo conto, contatta il servizio clienti.",
  "Not supported yet": "Non ancora supportato",
  "Not available gateways found": "Trovati gateway non disponibili",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Ci scusiamo, sembra che non ci siano dettagli di pagamento registrati da utilizzare per la richiesta di prelievo",
  "Token is invalid": "Il token non è valido",
  "Payment method not available. Please contact your site administrator.":
    "Metodo di pagamento non disponibile. Si prega di contattare l'amministratore del sito.",
  cardIsNotValid: "La carta non è valida",
  chooseAmount: "scegli l'importo",
  invalidInputFormat:
    "Formato di input non valido. Almeno {{length}} caratteri.",
  invalidDateFormat: "Formato data non valido.",
  maximumValueIs: "Il valore massimo è",
  minimumValueIs: "Il valore minimo è",
  enterPayoutAmount:
    "Inserisci un importo di pagamento inferiore o uguale al saldo disponibile.",
  maxLength2: "Lunghezza massima 2 caratteri.",
  footer1:
    "Alcune banche emittenti hanno limitato l'uso delle carte di credito per l'intrattenimento online. Non esitare a utilizzare uno dei metodi di pagamento di terze parti alternativi sopra indicati o contatta i nostri {{customer_support_url}} rappresentanti al numero verde N / A per finanziare il tuo account. \n Nota: a seconda della banca emittente, le transazioni con carta di credito potrebbero essere trattate come un anticipo in contanti. {{application_key}} non può essere ritenuto responsabile per eventuali commissioni di anticipo in contanti coinvolte in questa transazione poiché questo è stabilito dal credito banca di emissione della carta. Consulta la tua banca per ulteriori dettagli. ",
  footer2:
    "Per assistenza o conferma di un tentativo di transazione, contatta {{customer_support_url}}. \n Nota: a seconda della banca, questa transazione potrebbe essere trattata come un anticipo in contanti. {{application_key}} non sarà responsabile per eventuali commissioni di anticipo in contanti coinvolte in questa transazione. La transazione sarà identificata sull'estratto conto della carta di credito come Descrittore della carta di credito, salvo diversa indicazione sulla ricevuta della transazione. ",
  footer3:
    "Tutte le richieste di pagamento sono soggette ai nostri {{terms_conditions_url}}. Se hai bisogno di assistenza o ulteriori informazioni, contatta {{customer_support_url}}. Una volta inviata la tua richiesta, le informazioni vengono inoltrate al nostro reparto cassa per l'elaborazione . Riceverai un'e-mail di conferma quando il pagamento sarà elaborato. L'importo del pagamento e la commissione di elaborazione (se applicabile) verranno addebitati sul tuo account dopo la nostra verifica del pagamento. ",
  gatewaysTitle: "Come volete pagare?",
  gatewaysTitleDeposit: "Scegli il metodo di deposito",
  gatewaysTitleWithdrawal: "Scegli il metodo di prelievo",
  payInTitle: "Paga con carta di credito o di debito",
  allPaymentMethods: "tutti i metodi di pagamento",
  submit: "Invia",
  useAddressOnFile: "Usa l'indirizzo sul file",
  customerName: "Nome del cliente",
  country: "Nazione",
  province: "Provincia",
  city: "Città",
  postalCode: "Codice postale",
  street: "strada",
  proceed: "Procedere",
  cardNumber: "Numero di carta",
  cardHolder: "Titolare della carta",
  cardHolderName: "Nome del titolare",
  cardExpires: "La carta scade",
  cvv: "CVV",
  firstName: "Nome di battesimo",
  lastName: "Cognome",
  zipCode: "Cap",
  address: "Indirizzo",
  state: "Stato",
  email: "E-mail",
  phone: "Telefono",
  dateOfBirth: "Data di nascita",
  transactionStatus: "Stato della transazione",
  trace: "Tracciare",
  amount: "Quantità",
  min: "min",
  max: "max",
  overrideLimits: "Sostituisci limiti",
  transaction: "Transazione",
  processed: "Processed",
  transactionRejected: "Transazione rifiutata",
  transactionApproved: "Transazione approvata",
  yourTransactionIsPending: "La tua transazione è in sospeso",
  transactionAuthorized: "Transazione autorizzata",
  requestAccepted: "Richiesta accettata",
  status2:
    "La tua richiesta di prelievo è stata accettata. Si informa che ulteriori autorizzazioni ed elaborazioni verranno eseguite durante le ore operative dei giorni lavorativi. Durante quel periodo, potresti essere contattato dallo staff esecutivo per la conferma dell'identità.",
  unexpectedErrorOccurred: "Si è verificato un errore imprevisto",
  status3:
    "Ci scusiamo, qualcosa è andato storto durante l'elaborazione della transazione. Di seguito sono riportati i dettagli tecnici: ",
  tryAgain: "Riprova",
  somethingWentWrong: "Qualcosa è andato storto...",
  newPayoutRequest: "Nuova richiesta di pagamento",
  makeAnotherPayment: "Effettua un altro pagamento",
  profile: "Profilo",
  justOneMoreStep: "Solo un altro passo ...",
  "profile-auth":
    "Completa il tuo profilo per procedere con il pagamento utilizzando {{title}}",
  payWith: "Paga con",
  ProceedWith: "Procedi con",
  vtTitle: "Terminale virtuale: ",
  pay: "pagare",
  deposit: "Depositare",
  withdraw: "ritirarsi",
  withdrawal: "Ritiro",
  hello: "Ciao,",
  currency: "Moneta",
  currentIp: "IP corrente: ",
  UseCurrentIp: "Usa IP attuale: ",
  UseCustomerIp: "Usa IP cliente: ",
  copyright: "Diritto d'autore",
  cannotBeBlank: "Non può essere vuoto.",
  noDepositFree:
    "Nessuna commissione di deposito. nessuna commissione di prelievo.",
  next: "Il prossimo",
  backToSite: "torna al sito",
  maximumLength: "Lunghezza massima {{length}} caratteri",
  minimumLength: "Lunghezza minima {{length}} caratteri",
  emailIsNotValid: "L'email non è un indirizzo email valido.",
  phoneIsNotValid: "Il telefono non è valido",
  cardHolderInvalid:
    "Il titolare della carta non è valido. Il titolare della carta deve contenere il nome e il cognome.",
  pleaseEnterPayout: "Inserisci un importo di pagamento inferiore o uguale",
  invalidAmount: "Importo non valido",
  transactionWillAppear:
    "La tua transazione apparirà sul tuo estratto conto come",
  "Customer does not exist": "Il cliente non esiste",
  "Sorry no available gateways found.":
    "Spiacenti, non sono stati trovati gateway disponibili.",
  "Incorrect currency": "Valuta errata",
  "Sorry, not supported payment type":
    "Spiacenti, tipo di pagamento non supportato",
  "Payment method has no payment type":
    "Il metodo di pagamento non ha alcun tipo di pagamento",
  "Incorrect payment type in payment method":
    "Tipo di pagamento errato nel metodo di pagamento",
  "Gateway limit exceeded": "Limite gateway superato",
  "Not supported transaction type": "Tipo di transazione non supportato",
  "Sorry. Payout with credit card gateway not supported yet":
    "Scusate. Pagamento con gateway per carte di credito non ancora supportato",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Ci scusiamo, questo metodo di pagamento non è applicabile per i dettagli di pagamento specificati. Ricarica la pagina e prova un importo, una valuta o un metodo di pagamento diversi.",
  "Back to site": "Torna al sito",
  "Your transaction has been requested. Please wait for approval.":
    "La tua transazione è stata richiesta. Si prega di attendere l'approvazione.",
  "Token expired": "Token scaduto",
  back: "indietro",
  "Transaction not found.": "Limite di gate superato",
  "Continue in the New Window": "Continua nella nuova finestra",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Si prega di non chiudere questa finestra. Al termine della transazione verrai reindirizzato in una nuova finestra. Non vedi una nuova finestra che apre una connessione sicura? Siamo qui per aiutarti a completare la transazione.",
  "Click to continue": "Clicca per continuare",
  "Just One More Step...": "Solo un altro passo ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Il metodo di pagamento che hai selezionato verrà aperto in una nuova pagina. Fare clic sul pulsante 'PROCEDERE' di seguito per passare alla fase finale.",
  Proceed: "Procedere",
  "Opening a Secure Connection...": "Apertura di una connessione protetta ...",
  "You will be redirected to complete your transaction in just a moment":
    "Verrai reindirizzato per completare la transazione in un attimo",
  "Close window": "Chiudi la finestra",
  "Partial approval confirmation": "Conferma parziale di approvazione",
  PartiallyApprovedAmount:
    "Il saldo della tua carta non ha fondi sufficienti per accreditare l'intero importo. L'operazione è stata parzialmente approvata per {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    'Fare clic su "OK" per confermare l\'importo del deposito parziale o "CANCEL" per annullare la transazione. Il deposito sarà confermato automaticamente dopo 5 minuti se non viene intrapresa alcuna azione.',
  fxRateSpreadText:
    "Il tasso di cambio indicato include tutte le commissioni e le spese.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Attendere... il caricamento prego",
};
