const createToken = (state, action) => {
    if (state === undefined) {
        return false
    }
    switch (action.type) {
        case 'TOKEN_INIT':
            return action.payload;
        default:
            return state.token;
    }
}

export {createToken}