export const unicSelectors = [
    "value", "type-title", "transactions-page-title", "transactions-page", "transactions-list", "total-fee", "total", "title-wrapper",
    "title-text", "title-container", "title", "table", "slider-item", "slider-container", "save-button-container", "save-button",
    "row-col", "row", "read-only", "promocode-title-container", "promocode-title", "promocode-modal", "promocode-input",
    "promocode", "profile-modal", "processing-fee", "pay-with-title-box", "pay-fields-container", "note-error", "not-found-page",
    "no-saved-constructors-components", "new-transaction", "multiple-separator", "multiple-select-container", "multiple-options", "multiple-option",
    "multiple-input", "multiple-icon", "multiple-dropdown-container", "modal-title", "modal-header", "header-logo", "modal-container", "modal",
    "logo-title-img", "logo-title", "logo-container", "loader-container", "input-wrap", "input-item", "input-icon", "input", "img", "icon", "html-add-fields",
    "gateways-container", "gateway-item", "form-container", "form", "footer", "fixed", "fee-not-included", "fee-included", "fee-container",
    "fee", "error-page", "error-container", "error", "dod-checkbox", "disabled", "cvv-icon", "cvv", "current-slide",
    "current-currency", "currency", "credit-add-container", "copyright", "convert-limits-container", "convert-component",
    "content", "close-button", "close", "checkbox-label", "checkbox-input", "checkbox-content", "checkbox-container", "checkbox-accept-container",
    "cause-an-error", "cardExpBlock", "cardCvvBlock", "button", "btn-wrapper", "bold-transactions-title", "billing-info-modal",
    "billing-info-container", "billing-info", "basic-amount", "back", "amount-wrapper", "amount-page", "amount-input-container", "amount-container",
    "amount", "add_fields_templates", "checkbox-error", "page-container", "pay-method", "result-container", "pay-in", "table-container",
    "status-image", "info",  "gradient", "content-bold", "method-wrapper", "col-wrapper", "add-fields-container", "visible-fields",
    "custom-html", "main-method", "no-deposit-free", "main-header", "image", "content-box", "info", "transaction-status-wrapper",
    "header-virtual_terminal", "title-wrapper", "header-customer", "header-username", "vt-settings-wrapper", "result-charge-amount",
    "vt-settings-title-wrapper", "wrapper", "wrap", "ip-item", "active", "header-profile-container", "header-profile-toggle",
    "header-profile-menu", "header-profile-menu-item", "item-info", "default", "classic", "default-theme", "dark-theme", "nova-theme", "supreme-theme",
    "virtual_terminal", "show", "hide", "has-error", "focus", "customer-link", "numpad-wrap", "numpad-input-cover", "numpad-cover", "cvv-numpad",
    "numpad-container", "item", "item-done", "numpad-item", "item-reset", "new-card-option", "pci-card-holder", "pci-card-holder-input", "error-message", "red-text",
    "status", "declined", "approved", "pending", "pending_async", "authorized", "requested", "requested_payout", "left-gateways-list",
    "list-item", "compact", "visual-card-wrapper", "unknown", "JCB", "AMEX", "DinersClub", "VISA", "MasterCard", "Discover",
    "Maestro", "visual-card", "back-side", "main-side-content", "visual-card-content", "cc-logo-row", "cc-logo-chip",
    "cc-logo-type", "cc-num-row", "num", "cc-column", "cc-t", "title-field", "cc-cn", "focus-field", "cc-data-row", "cc-data-left",
    "cc-c", "cc-data-right", "back-side-content", "cc-bc-line", "cc-bc-cont", "cc-bc-input-text", "cc-bc-text", "font-19",
    "font-16", "slider-amount-wrapper", "currency-wrap", "currency-select", "cash-slider", "cash-slider-top", "cash-slider-currency",
    "total-input", "keyboard-icon", "cardNumberBlock", "cardNumberWrapper", "cardNumber-arrow", "open", "card-list", "use-address-checkbox",
    "card-types", "img-box", "vt-checkbox-wrapper", "amount-limits-component", "limits-amount-symbol", "limits-container",
    "limits-interval", "min-limit", "max-limit", "iframe-redirect-container", "redirect-iframe", "transactions-modal",
    "transactions-pending-table", "not-transactions-found", "delete-transaction-error", "success-transaction", "deleteTransactionBtn",
    "confirm-container", "confirm-window", "confirmation-required", "confirm-title", "confirm-btn-wrapper", "title-none",
    "credit-cart-simulator", "with-constructors-components", "card-number-select", "billing-info-error", "billing-info-error-icon",
    "required-row", "stop-redirect-button", "back-site-container", "back-to-site-button", "result-redirect", "auto-redirect-button",
    "auto-redirect-text", "has-icon", "pci-container", "button-wrapper", "add-action-button", "override-limits-checkbox", "arrow-wrapper",
    "window-redirect-container", "content-text", "blocked", "button-outline", "select-options-row", "loading", "transactions-status-container", 'not-editable',
    'input-editor-icon'
]
