export const tr = {
  "Loading... Please Wait": "Yükleniyor lütfen bekleyin",
  yourTransactionIsPendingForPayOut: "Para Çekme Talebiniz Beklemede",
  status1:
    "Lütfen işleminizin işlem yapan banka veya ödeme hizmeti sağlayıcısı tarafından incelenmekte olduğunu unutmayın. İşleminizin durumuyla ilgili daha fazla bilgi isterseniz, İzleme Kimliği ile ilgili yardım istemek için lütfen destekle iletişime geçin: {{trace_id}}",
  Status: "Durum",
  "Deposit Review For Account:": "Hesap İçin Para Yatırma İncelemesi:",
  "New Transaction": "Yeni İşlem",
  Update: "Güncelleme",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Seçtiğiniz ödeme yöntemi ayrı bir sayfada açılır. Yeni bir tarayıcı penceresine gitmek ve ödeme işlemine devam etmek için lütfen 'DEVAM ET' düğmesini tıklayın.",
  dod_error_text:
    "Devam etmek için Para Yatırma Beyanını okuyup kabul etmelisiniz",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Depozitonuza devam etmek için Şartlar ve Koşulları kabul etmelisiniz",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Şartlar ve Koşulları okuduğumu ve burada kabul ettiğimi onaylıyorum",
  dod_url: "#",
  dod_url_text: "Şartlar ve koşullar",
  dod_text: "{{dod_url}} okuduğumu ve burada kabul ettiğimi onaylıyorum",
  transactionSuccessfullyCanceled: "{{İd}} işlemi başarıyla iptal edildi",
  "Fee should not exceed 20% of the amount":
    "Ücret, tutarın% 20'sini geçmemelidir",
  "My Info": "Bilgilerim",
  "Billing info": "fatura bilgisi",
  "Withdrawal Requests": "Para Çekme Talepleri",
  "No Withdrawal Requests found": "Para Çekme İsteği bulunamadı",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Cancel",
  Amount: "Miktar",
  "Last Update": "Son Güncelleme",
  "Payment Method": "Ödeme şekli",
  TID: "ZAMAN",
  Action: "Aksiyon",
  "Confirmation required": "Onay Gerekli",
  "Are you sure you want to cancel transaction":
    "İşlemi iptal etmek istediğinizden emin misiniz",
  Yes: "Evet",
  No: "Hayır",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Min-Max Miktarlar",
  "Fee (included)": "Ücret (dahil)",
  "- Amount between": "- arasındaki miktar",
  "I have the Promocode!": "Promosyon Kodum var!",
  "Please enter the Promocode": "Lütfen Promosyon Kodunu girin",
  Promocode: "Promosyon kodu",
  noSavedTemplates: "Kart Kaydedilmedi",
  noOptions: "Seçenek yok",
  "Please confirm your billing details":
    "Lütfen fatura ayrıntılarınızı onaylayın",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "İşlem ücreti",
  Fee: "Ücret",
  Total: "Toplam",
  customer_support_link_text: "Müşteri desteği",
  terms_conditions_link_text: "Şartlar ve koşullar",
  "Edit Billing Info": "Fatura Bilgilerini Düzenle",
  chooseAnAccount: "Bir hesap seçin",
  "Requested to Receive": "Alınması İstendi",
  "Receive With": "Şununla Al",
  "Unknown session": "Bilinmeyen oturum",
  transactionId: "İşlem Kimliği",
  traceId: "izleme kimliği",
  save: "Kayıt etmek",
  fillTheBillingInformation: "Lütfen aşağıdaki fatura bilgilerini girin",
  correctionsRequired: "düzeltmeler gerekli",
  billingInfo: "fatura bilgisi",
  "Cancelled by client": "Müşteri tarafından iptal edildi",
  "Your Cards": "Kartlarınız",
  Done: "Bitti",
  redirect: "Yönlendirme",
  redirectingTo: "Adresine yönlendiriliyor",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "İşlem başarılı olmadı. Üzgünüz ancak verdiğiniz PayPal hesabı kayıtlarımıza göre başka bir müşterinin yatırım hesabına bağlı görünüyor. Müşteriler sitemizde yalnızca bir tane aktif müşteri yatırım hesabına sahip olabilir. Bu PayPal hesabının oturumunuzda kullanılmasına izin vermek için ya da hesabınızla ilgili herhangi bir destek almak için lütfen müşteri hizmetleriyle iletişime geçin.",
  "Not supported yet": "Henüz desteklenmiyor",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Özür dileriz, para çekme isteği için kullanmak üzere kayıtlı herhangi bir ödeme detayınız yok gibi görünüyor",
  "Not available gateways found": "Kullanılabilir ağ geçidi bulunamadı",
  "Token is invalid": "Jeton geçersiz",
  "Payment method not available. Please contact your site administrator.":
    "Ödeme yöntemi mevcut değil. Lütfen site yöneticinize başvurun.",
  cardIsNotValid: "Kart geçerli değil",
  invalidInputFormat: "Geçersiz giriş biçimi. En az {{length}} karakter.",
  invalidDateFormat: "Geçersiz tarih biçimi.",
  maximumValueIs: "Maksimum değer",
  chooseAmount: "miktar seç",
  minimumValueIs: "Minimum değer",
  enterPayoutAmount:
    "Lütfen Kullanılabilir Bakiyenize eşit veya daha az bir ödeme tutarı girin.",
  maxLength2: "Maksimum uzunluk 2 karakter.",
  footer1:
    "Bazı kartı veren bankalar, çevrimiçi eğlence için kredi kartlarının kullanımını kısıtladı. Lütfen yukarıdaki alternatif 3. taraf ödeme yöntemlerinden birini kullanmaktan çekinmeyin veya fon sağlamak için {{customer_support_url}} Temsilcilerimizle ücretsiz olarak iletişime geçin Hesabınız. \n Lütfen Unutmayın: Kartı veren bankanıza bağlı olarak, kredi kartı işlemleri nakit avans olarak değerlendirilebilir. {{application_key}}, bu işlemle ilgili nakit avans ücretlerinden sorumlu tutulamaz, çünkü bu kredi tarafından belirlenir kartı veren banka. Daha fazla ayrıntı için bankanıza danışın. ",
  footer2:
    "Denenen bir işlemle ilgili yardım veya onay için lütfen {{customer_support_url}} ile iletişime geçin. \n Lütfen Unutmayın: Bankanıza bağlı olarak bu işlem nakit avans olarak değerlendirilebilir. {{application_key}} sorumlu olmayacaktır. bu işlemle ilgili nakit avans ücretleri. İşlem, işlem makbuzunuzda aksi belirtilmedikçe, kredi kartı ekstrenizde Kredi Kartı Tanımlayıcısı olarak belirtilecektir. ",
  footer3:
    "Tüm ödeme istekleri {{terms_conditions_url}} 'e tabidir. Yardıma veya daha fazla bilgiye ihtiyacınız varsa, lütfen {{customer_support_url}} ile iletişime geçin. İsteğiniz gönderildikten sonra, bilgiler işlenmek üzere kasiyer departmanımıza iletilir . Ödemeniz işlendiğinde onay olarak bir e-posta alacaksınız. Ödeme miktarı ve işlem ücreti (varsa) ödeme incelememizin ardından hesabınızdan düşülecektir. ",
  gatewaysTitle: "nasıl ödemek istersin?",
  gatewaysTitleDeposit: "Lütfen para yatırma yöntemini seçin",
  gatewaysTitleWithdrawal: "Lütfen para çekme yöntemini seçin",
  payInTitle: "Kredi Kartı veya Banka Kartıyla Ödeme",
  allPaymentMethods: "tüm ödeme yöntemleri",
  submit: "Submit",
  useAddressOnFile: "Dosyadaki adresi kullan",
  customerName: "müşteri adı",
  country: "ülke",
  province: "Bölge",
  city: "Kent",
  postalCode: "Posta kodu",
  street: "sokak",
  proceed: "İlerlemek",
  cardNumber: "Kart numarası",
  cardHolder: "Kart sahibi",
  cardHolderName: "Kart Sahibinin Adı",
  cardExpires: "Kartın Sona Ermesi",
  cvv: "CVV",
  firstName: "İsim",
  lastName: "Soyadı",
  zipCode: "Posta kodu",
  address: "Adres",
  state: "Durum",
  email: "E-posta",
  phone: "Telefon",
  dateOfBirth: "Doğum tarihi",
  transactionStatus: "İşlem Durumu",
  trace: "iz",
  amount: "Miktar",
  min: "min",
  max: "max",
  overrideLimits: "Sınırları geçersiz kıl",
  transaction: "işlem",
  processed: "İşlenmiş",
  transactionRejected: "İşlem Reddedildi",
  transactionApproved: "İşlem Onaylandı",
  yourTransactionIsPending: "İşleminiz Bekliyor",
  transactionAuthorized: "İşlem Yetkilendirildi",
  requestAccepted: "İstek Kabul edildi",
  status2:
    "Para çekme talebiniz kabul edildi. Lütfen, iş günlerinin çalışma saatleri içinde daha fazla yetkilendirme ve işleme alınacağını kabul edin. Bu süre zarfında, kimlik onayı için yönetici personel ile iletişime geçebilirsiniz.",
  unexpectedErrorOccurred: "Beklenmedik hata oluştu",
  status3:
    "Özür dileriz, işleminiz yapılırken bir şeyler ters gitti. Lütfen aşağıdaki teknik ayrıntıları bulun: ",
  tryAgain: "Tekrar deneyin",
  somethingWentWrong: "Bir şeyler yanlış gitti...",
  newPayoutRequest: "Yeni Ödeme Talebi",
  makeAnotherPayment: "Başka Ödeme Yap",
  profile: "Profil",
  justOneMoreStep: "Sadece bir adım daha...",
  "profile-auth":
    "Kullanarak ödemeye devam etmek için lütfen profilinizi tamamlayın {{title}}",
  payWith: "İle ödemek",
  ProceedWith: "İle ilerlemek, devam etmek",
  vtTitle: "Sanal Terminal: ",
  pay: "Ödemek",
  deposit: "Depozito",
  withdraw: "Çekil",
  withdrawal: "Para çekme",
  hello: "Merhaba,,",
  currency: "Para birimi",
  currentIp: "Mevcut IP: ",
  UseCurrentIp: "Mevcut IP'yi kullan: ",
  UseCustomerIp: "Müşteri IP'sini kullan: ",
  copyright: "telif hakkı",
  cannotBeBlank: "Boş bırakılamaz.",
  noDepositFree: "Depozito ücreti yok. para çekme ücreti yok.",
  next: "Sonraki",
  backToSite: "Siteye geri dön",
  maximumLength: "Maksimum uzunluk {{length}} karakter",
  minimumLength: "Minimum uzunluk {{uzunluk}} karakter",
  emailIsNotValid: "E-posta geçerli bir e-posta adresi değil.",
  phoneIsNotValid: "Telefon geçerli değil",
  cardHolderInvalid:
    "Kart sahibi geçersiz. Kart sahibinin adı ve soyadı içermesi gerekir.",
  pleaseEnterPayout: "Lütfen küçük veya eşit bir ödeme tutarı girin",
  invalidAmount: "Geçersiz tutar",
  transactionWillAppear: "İşleminiz ekstrenizde şu şekilde görünecektir:",
  "Customer does not exist": "Müşteri mevcut değil",
  "Sorry no available gateways found.":
    "Üzgünüz, kullanılabilir ağ geçidi bulunamadı.",
  "Incorrect currency": "Yanlış para birimi",
  "Sorry, not supported payment type": "Üzgünüz, desteklenmeyen ödeme türü",
  "Payment method has no payment type": "Ödeme yönteminde ödeme türü yok",
  "Incorrect payment type in payment method":
    "Ödeme yönteminde yanlış ödeme türü",
  "Gateway limit exceeded": "Ağ geçidi sınırı aşıldı",
  "Not supported transaction type": "Desteklenmeyen işlem türü",
  "Sorry. Payout with credit card gateway not supported yet":
    "Afedersiniz. Kredi kartı ağ geçidi ile ödeme henüz desteklenmiyor",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Özür dileriz, bu ödeme yöntemi belirtilen ödeme ayrıntıları için geçerli değildir. Lütfen sayfayı yeniden yükleyin ve farklı bir tutar, para birimi veya ödeme yöntemi deneyin.",
  "Back to site": "Siteye geri dön",
  "Your transaction has been requested. Please wait for approval.":
    "İşleminiz istendi. Lütfen onay için bekleyin.",
  "Token expired": "Jetonun süresi doldu",
  back: "geri",
  "Transaction not found.": "İşlem bulunamadı.",
  "Continue in the New Window": "Yeni Pencerede Devam Et",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Lütfen bu pencereyi kapatmayın. İşlemi yeni bir pencerede bitirdikten sonra tekrar yönlendirileceksiniz. Güvenli bir bağlantı açan yeni bir pencere görmüyor musunuz? İşleminizi tamamlamanıza yardımcı olmak için buradayız.",
  "Click to continue": "Devam etmek için tıklayın",
  "Just One More Step...": "Sadece bir adım daha...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Seçtiğiniz ödeme yöntemi yeni bir sayfada açılacaktır. Son adıma gitmek için lütfen aşağıdaki 'İLERLEMEK' düğmesine tıklayın.",
  Proceed: "İlerlemek",
  "Opening a Secure Connection...": "Güvenli Bağlantı Açma ...",
  "You will be redirected to complete your transaction in just a moment":
    "İşleminizi kısa süre içinde tamamlamak için yönlendirileceksiniz",
  "Close window": "Pencereyi kapat",
  "Partial approval confirmation": "Kısmi onay onayı",
  PartiallyApprovedAmount:
    "Kart bakiyenizde, tutarın tamamını kredilendirmek için yeterli bakiye yok. İşlem kısmen {{amount}} {{currency}} için onaylandı.",
  ConfirmPartialDeposit:
    "Kısmi para yatırma tutarını onaylamak için lütfen 'Tamam'ı veya işlemi iptal etmek için 'İPTAL'i tıklayın. Herhangi bir işlem yapılmazsa depozito 5 dakika sonra otomatik olarak onaylanacaktır.",
  fxRateSpreadText: "Belirtilen döviz kuru, tüm ücretleri ve ücretleri içerir.",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Yükleniyor.. Lütfen bekleyiniz.",
};
