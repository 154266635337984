import React, {useEffect, useState} from 'react'
import {withRouter} from "react-router";
import compose from "../../utils/helper-functions/compose";

export const withCurrentPage = (Wrapped) => {
    const Hoc = (props) => {

        const {location:{pathname:locationPathname}} = props;
        const [currentPage, setCurrentPage] = useState('')
    
        useEffect(() => {
            const currentPageName = getCurrentPage(locationPathname)
            setCurrentPage(currentPageName)
        }, [locationPathname])
        
        const getCurrentPage = (pathname) => {
            switch(true) {
                case pathname.includes('gateways'): return 'gatewaysPage';
                case pathname.includes('amount'): return 'amountPage';
                case pathname.includes('payment-method'): return 'paymentPage';
                case pathname.includes('result-of-pay'): return 'resultPage';
                case pathname.includes('error'): return 'errorPage'
                case pathname.includes('redirect'): return 'redirectPage'
                default: return 'unknown'
            }
        }
    
        return <Wrapped {...props} currentPage={currentPage} />
    }
    return compose(withRouter)(Hoc)
}
