export const getProcessingGatewaysList = {
    "status": 0,
    "description": "List of cascade gateways genereted successfully",
    "error_details": "",
    "available_gateways": [
        {
            "hash": "uEKoZsz0OQnGFwL6KTqV1HOSjpuP8fGT",
            "filter_currencies": ["USD","EUR","GBP"],
            "bdcc": {
                "bdcc_currency": "GBP",
                "bdcc_conversion_rate": 1.1504
            }
        },
        {
            "hash": "ZNgK4uUdlaRgQypNCT5I-XPPwQYkqyZm",
            "filter_currencies": ["EUR","GBP"],
            "bdcc": null
        }
    ],
    "skipped_gateways": [
        {
            "hash": "ZuEKoZsz0OQnNgK4uUdlaRgQypNCT5I",
            "reason": "Country filter did not pass. Card type filter did not pass."
        }
    ]
}