import React, {useEffect, useRef, useState} from 'react'
import compose from "../../../utils/helper-functions/compose";
import {withI18N} from "../../hoc-components";
import {withRouter} from "react-router";
import {withAdr} from "../../hoc-components/with-adr";
import {useSelector, shallowEqual} from "react-redux";
import {postMessage} from "../../../utils/helper-functions/post-message";
import BasicErrorPage from "./basic-error-page/basic-error-page";


const ErrorPageContainer = (props) => {
    const {errorType, langBack, history, back = true, s, children} = props;
    const store = useSelector(({
                                   profileInfo: {submit_name, submit_action}, errors: {app_fatal_error},
                                   processingGatewaysList: {error_details: processing_error_details}
                               }) => ({
        submit_name, submit_action, app_fatal_error, processing_error_details
    }), shallowEqual)
    const {submit_name = langBack('backToSite'), submit_action, app_fatal_error, processing_error_details = ''} = store;
    const profileInfo = useSelector(({profileInfo}) => profileInfo, shallowEqual);
    const {currentDomain} = useSelector(({initTheme}) => initTheme, shallowEqual);
    const [errors, setErrors] = useState(basicErrors);
    
    useEffect(() => {
        const message = generateErrorMessage(errors, errorType);
        const customerInfo = (profileInfo && currentDomain) ? {
            cid: profileInfo?.customer?.pin,
            amount: profileInfo?.base_amount,
            currency: profileInfo?.base_currency,
            order_id: profileInfo?.order_id,
        } : {};

        handleErrorPostMessage(errorType, message, customerInfo, currentDomain);
    }, [])
    
    const handleErrorPostMessage = (errorType, message, customerInfo = {}, currentDomain = "*") => {
        postMessage({
                event_type: errorType ? "transaction_failed" : 'cashier_loading_failed',
                message,
                customerInfo
            },
            currentDomain);
    }
    
    const generateErrorMessage = (errors, errorType) => {
        if (errorType && errors[errorType] && typeof errors[errorType] === 'string') {
            if (+errorType === 9) {
                return (
                    <>
                        {langBack(errors[errorType])}
                        {
                            processing_error_details ?
                                <span className={s('error-message')}>
                                    <span>{`${langBack('Server error details:')} `}</span> {langBack(processing_error_details)}
                                </span> : ''
                        }
                    </>
                )
            }
            return langBack(errors[errorType]);
        }
        
        if (Object.keys(app_fatal_error).length === 0) {
            return langBack("somethingWentWrong");
        }
        
        if (typeof app_fatal_error === "string") {
            return langBack(app_fatal_error)
        }
        
        return Object.keys(app_fatal_error).map((el, ind) => <div
            key={ind}>{langBack(app_fatal_error[el])}</div>)
    }

    const errorsInfo = {
        errors,
        errorType,
        back,
        submit_action,
        submit_name,
        generateErrorMessage,
        setErrors
    }
    
    return (
        <>
            {
                children ?
                React.Children.map(children, child => {
                    return React.cloneElement(child, {
                        errorsInfo,
                        langBack,
                        history,
                        back,
                        s
                    })
                }) :
                    <BasicErrorPage
                        errorsInfo={errorsInfo}
                        langBack={langBack}
                        history={history}
                        back={back}
                        s={s}
                    />
            }
        </>
    )
};

const basicErrors = {
    1: 'Payment method not available. Please contact your site administrator.',
    2: 'Token is invalid',
    3: 'Not available gateways found',
    4: 'Not supported yet',
    5: "Apologies, it appears that you don't have any payment details registered to use for withdrawal request",
    6: "Unknown session",
    7: "Session expired",
    8: "Something went wrong. Please contact your system administrator (Code: 101)",
    9: "For the combination of card number, amount and currency there are no available payment gateways. Please try another credit card or select an alternative payment method.",
    10: "Can't get session information"
};

export default compose(
    withRouter, withI18N, withAdr
)(ErrorPageContainer)
