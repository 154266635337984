import {config} from "../../config/config";

export const getToken = () => {
    const location = {...window.location};
    return location.pathname.split("/")[1] ?? '';
}
export const isTestData = getToken() === 'test-data';
export const getTokenFromUrl = (type = '') => {
    const token =  getToken();
    const testToken = config?.graphqlTestAuthToken
    if(type === 'graphql') {
        if(isTestData && !testToken) {
            console.error('graphqlTestAuthToken in config not provided');
            return;
        }
        return isTestData ? testToken : token;
    }
    return token;
}
