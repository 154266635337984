import {ar} from "./ar";
import {de} from "./de";
import {el} from "./el";
import {en} from "./en";
import {es} from "./es";
import {fi} from "./fi";
import {fr} from "./fr";
import {he} from "./he";
import {id} from "./id";
import {it} from "./it";
import {jp} from "./jp";
import {nl} from "./nl";
import {pl} from "./pl";
import {pt} from "./pt";
import {ru} from "./ru";
import {sv} from "./sv";
import {th} from "./th";
import {tr} from "./tr";
import {vi} from "./vi";
import {zh_CHS} from "./zh_CHS";
import {zh_CHT} from "./zh_CHT";
import {ms} from './ms';
import {hi} from './hi';
import {cs} from './cs';
import {tl} from './tl';
import {hu} from './hu';
import {sk} from './sk';
import {ko} from './ko';
import {lt} from './lt';
import {kh} from './kh';
import {ka} from './ka';
import {bg} from './bg';
import {hy} from './hy';
import {da} from './da';
import {et} from './et';
import {lv} from './lv';
import {no} from './no';
import {sr} from './sr';
import {sl} from './sl';
import {ta} from './ta';
import {uk} from './uk';
import {sq} from './sq';

export const getDictionary = (key) => {
    let currentLang;
    if(key === "ar-SA" || key === "ar") currentLang = ar;
    else if(key === "de-DE" || key === "de") currentLang = de;
    else if(key === "el-GR" || key === "el") currentLang = el;
    else if(key === "en-GB" || key === "en") currentLang = en;
    else if(key === "es-ES" || key === "es") currentLang = es;
    else if(key === "es-SV") currentLang = es;
    else if(key === "fi-FI" || key === "fi") currentLang = fi;
    else if(key === "fr-FR" || key === "fr") currentLang = fr;
    else if(key === "he-IL" || key === "he") currentLang = he;
    else if(key === "id-ID" || key === "id") currentLang = id;
    else if(key === "it-IT" || key === "it") currentLang = it;
    else if(key === "ja-JP" || key === "ja") currentLang = jp;
    else if(key === "nl-NL" || key === "nl") currentLang = nl;
    else if(key === "pl-PL" || key === "pl") currentLang = pl;
    else if(key === "pt-PT" || key === "pt") currentLang = pt;
    else if(key === "ru-RU" || key === "ru") currentLang = ru;
    else if(key === "sv-SE" || key === "sv") currentLang = sv;
    else if(key === "th-TH" || key === "th") currentLang = th;
    else if(key === "tr-TR" || key === "tr") currentLang = tr;
    else if(key === "vi-VN" || key === "vi") currentLang = vi;
    else if(key === "zh-CN" || key === "zh") currentLang = zh_CHS;
    else if(key === "zh-TW") currentLang = zh_CHT;
    else if(key === "ms-MY" || key === "ms") currentLang = ms;
    else if(key === "hi-IN" || key === "hi") currentLang = hi;
    else if(key === "cs-CZ" || key === "cs") currentLang = cs;
    else if(key === "tl-PH" || key === "tl") currentLang = tl;
    else if(key === "en-PH") currentLang = tl;
    else if(key === "hu-HU" || key === "hu") currentLang = hu;
    else if(key === "sk-SK" || key === "sk") currentLang = sk;
    else if(key === "ko-KR" || key === "ko") currentLang = ko;
    else if(key === "lt-LT" || key === "lt") currentLang = lt;
    else if(key === "kh-KH" || key === "kh") currentLang = kh;
    else if(key === "bg-BG" || key === "bg") currentLang = bg;
    else if(key === "hy-AM" || key === "hy") currentLang = hy;
    else if(key === "da-DK" || key === "da") currentLang = da;
    else if(key === "et-EE" || key === "et") currentLang = et;
    else if(key === "ka-GE" || key === "ka") currentLang = ka;
    else if(key === "lv-LV" || key === "lv") currentLang = lv;
    else if(key === "sl-SL" || key === "sl") currentLang = sl;
    else if(key === "nb-NO" || key === "no-NO" || key === "no") currentLang = no;
    else if(key === "sr-SP" || key === "sr") currentLang = sr;
    else if(key === "ta-IN" || key === "ta") currentLang = ta;
    else if(key === "uk-UA" || key === "uk") currentLang = uk;
    else if(key === "sq-AL" || key === "sq") currentLang = sq;
    else currentLang = en;
    
    return currentLang
};