export const tl = {
  "Loading... Please Wait": "Naglo-load ... Mangyaring Maghintay",
  yourTransactionIsPendingForPayOut:
    "Nakabinbin ang Iyong Kahilingan sa Pag-withdraw",
  status1:
    "Mangyaring magkaroon ng kamalayan na ang iyong transaksyon ay sinusuri ng processing bank o service provider ng pagbabayad. Kung nais mo ng karagdagang impormasyon tungkol sa katayuan ng iyong transaksyon, mangyaring makipag-ugnay sa suporta upang humiling ng tulong sa Trace ID: {{trace_id}}",
  Status: "Katayuan",
  "Deposit Review For Account:": "Pagrepaso ng Deposit Para sa Account:",
  "New Transaction": "Bagong Transaksyon",
  Update: "Update",
  "The payment method you have selected opens in a separate page. Please click 'PROCEED' to navigate to a new browser window and continue the payment process.":
    "Ang paraan ng pagbabayad na napili mo ay bubukas sa isang magkakahiwalay na pahina. Mangyaring i-click ang 'Magpatuloy' upang mag-navigate sa isang bagong window ng browser at ipagpatuloy ang proseso ng pagbabayad.",
  dod_error_text:
    "Dapat mong basahin at tanggapin ang Deklarasyon ng Deposit upang magpatuloy",
  "You must accept Terms and Conditions in order to proceed with your deposit":
    "Dapat mong tanggapin ang Mga Tuntunin at Kundisyon upang magpatuloy sa iyong deposito",
  "I acknowledge that I have read, and do hereby accept the Terms and Conditions":
    "Kinikilala ko na nabasa ko, at tinatanggap ko ito sa Mga Tuntunin at Kundisyon",
  dod_url: "#",
  dod_url_text: "Mga Tuntunin at Kundisyon",
  dod_text: "Kinikilala ko na nabasa ko, at tinatanggap ko ito sa {{dod_url}}",
  transactionSuccessfullyCanceled:
    "Matagumpay na nakansela ang transaksyon na {{id}}",
  "Fee should not exceed 20% of the amount":
    "Ang bayarin ay hindi dapat lumagpas sa 20% ng halaga",
  "My Info": "Ang Impormasyon ko",
  "Billing info": "Impormasyon sa pagsingil",
  "Withdrawal Requests": "Mga Kahilingan sa Pag-atras",
  "No Withdrawal Requests found":
    "Walang nahanap na Mga Kahilingan sa Pag-menarik",
  payoutCancelledSuccessfully: "Payout cancelled successfully",
  depositCancelledSuccessfully: "Deposit cancelled successfully",
  Cancel: "Kanselahin",
  Amount: "Halaga",
  "Last Update": "Huling Update",
  "Payment Method": "Paraan ng Pagbayad",
  TID: "PANAHON",
  Action: "Kilos",
  "Confirmation required": "Kailangan ng kumpirmasyon",
  "Are you sure you want to cancel transaction":
    "Sigurado ka bang nais mong kanselahin ang transaksyon",
  Yes: "Oo",
  No: "Hindi",
  "Credit Card": "Credit and Debit Card",
  "Min-Max Amounts": "Mga Min-Max na Halaga",
  "Fee (included)": "Bayad (kasama)",
  "- Amount between": "- Halaga sa pagitan",
  "I have the Promocode!": "Mayroon akong Promo code!",
  "Please enter the Promocode": "Mangyaring ipasok ang Promo code",
  Promocode: "Promo code",
  noSavedTemplates: "Walang Nai-save na Card",
  noOptions: "Walang pagpipilian",
  "Please confirm your billing details":
    "Mangyaring kumpirmahin ang iyong mga detalye sa pagsingil",
  customer_support_phone: "",
  customer_support_email: "",
  terms_conditions_url: "#",
  customer_support_url: "#",
  "Processing fee": "Bayad sa pagpoproseso",
  Fee: "Bayad",
  Total: "Kabuuan",
  customer_support_link_text: "Suporta sa Customer",
  terms_conditions_link_text: "Mga Tuntunin at Kundisyon",
  "Edit Billing Info": "I-edit ang Impormasyon sa Pagsingil",
  chooseAnAccount: "Pumili ng isang account",
  "Requested to Receive": "Humiling na Makatanggap",
  "Receive With": "Tumanggap Sa",
  "Unknown session": "Hindi kilalang session",
  transactionId: "Transaction ID",
  traceId: "Bakas ang ID",
  save: "Magtipid",
  fillTheBillingInformation:
    "Mangyaring punan ang impormasyon sa pagsingil sa ibaba",
  correctionsRequired: "kinakailangang pagwawasto",
  billingInfo: "Impormasyon sa pagsingil",
  "Cancelled by client": "Kinansela ng client",
  "Your Cards": "Ang iyong mga Card",
  Done: "Tapos na",
  redirect: "Pag-redirect",
  redirectingTo: "Pag-redirect sa",
  "The transaction was not successful. We’re sorry but the PayPal account provided appears in our records under another customer’s trading account.":
    "Ang transaksyon ay hindi matagumpay. Humihingi kami ng paumanhin ngunit ang ibinigay na PayPal account ay lilitaw sa aming mga talaan sa ilalim ng trading account ng isa pang customer, kung nangangailangan ka ng anumang tulong sa iyong account, mangyaring makipag-ugnay sa serbisyo sa customer.",
  "Not supported yet": "Hindi pa suportado",
  "Apologies, it appears that you don't have any payment details registered to use for withdrawal request":
    "Paumanhin, lilitaw na wala kang anumang mga detalye sa pagbabayad na nakarehistro upang magamit para sa kahilingan sa pag-atras",
  "Not available gateways found": "Hindi nahanap ang mga magagamit na gateway",
  "Token is invalid": "Di-wasto ang token",
  "Payment method not available. Please contact your site administrator.":
    "Hindi magagamit ang paraan ng pagbabayad. Mangyaring makipag-ugnay sa administrator ng iyong site.",
  cardIsNotValid: "Hindi wasto ang card",
  invalidInputFormat:
    "Di-wastong format ng pag-input. Hindi bababa sa {{length}} mga character.",
  invalidDateFormat: "Di-wastong format ng petsa.",
  maximumValueIs: "Ang maximum na halaga ay",
  minimumValueIs: "Minimum na halaga ay",
  enterPayoutAmount:
    "Mangyaring maglagay ng isang halaga ng payout na mas mababa sa o katumbas ng iyong Magagamit na Balanse.",
  maxLength2: "Maximum na haba ng 2 character.",
  footer1:
    "Ang ilang mga nag-isyu ng mga bangko ay pinaghigpitan ang paggamit ng mga credit card para sa online entertainment. Mangyaring huwag mag-atubiling gamitin ang isa sa mga kahaliling paraan ng pagbabayad ng ika-3 partido sa itaas o makipag-ugnay sa aming {{customer_support_url}} Mga Kinatawan na walang bayad sa N / A upang pondohan ang iyong account.\n" +
    "  Mangyaring Tandaan: Depende sa iyong nag-isyu ng bangko, ang mga transaksyon sa credit card ay maaaring tratuhin bilang isang cash advance. Ang {{application_key}} ay hindi maaaring managot para sa anumang mga cash advance fees na kasangkot sa transaksyong ito dahil itinatag ito ng nag-isyu ng bangko ng credit card. Kumunsulta sa iyong bangko para sa karagdagang mga detalye.",
  footer2:
    "Para sa tulong o kumpirmasyon ng isang tangkang transaksyon mangyaring makipag-ugnay sa {{customer_support_url}}.\n" +
    "  Mangyaring Tandaan: Depende sa iyong bangko ang transaksyong ito ay maaaring tratuhin bilang isang cash advance. Hindi mananagot ang {{application_key}} para sa anumang mga cash advance fees na kasangkot sa transaksyong ito. Ang transaksyon ay makikilala sa iyong credit card statement bilang Credit Card Descriptor maliban kung nakasaad kung hindi man sa iyong resibo ng transaksyon.",
  footer3:
    "Ang lahat ng mga kahilingan sa pagbabayad ay napapailalim sa aming {{term_conditions_url}}. Kung nangangailangan ka ng tulong o karagdagang impormasyon, mangyaring makipag-ugnay sa {{customer_support_url}}. Kapag naisumite na ang iyong kahilingan, ipinapasa ang impormasyon sa aming kagawaran ng kahera para sa pagproseso. Makakatanggap ka ng isang email bilang kumpirmasyon kapag naproseso ang iyong bayad. Ang halaga ng bayad at bayad sa pagpoproseso (kung naaangkop) ay mai-debit mula sa iyong account kasunod ng aming pagsusuri sa payout.",
  gatewaysTitle: "Paano mo gustong magbayad?",
  gatewaysTitleDeposit: "Mangyaring pumili ng paraan ng deposito",
  gatewaysTitleWithdrawal: "Mangyaring pumili ng paraan ng pag-atras",
  payInTitle: "Magbayad gamit ang Credit o Debit Card",
  allPaymentMethods: "lahat ng mga paraan ng pagbabayad",
  submit: "Ipasa",
  useAddressOnFile: "Gumamit ng address sa file",
  customerName: "Pangalan ng Customer",
  country: "Bansa",
  province: "Lalawigan",
  city: "Lungsod",
  postalCode: "Postal code",
  street: "Kalye",
  proceed: "Magpatuloy",
  cardNumber: "Numero ng card",
  cardHolder: "May-ari ng Card",
  cardHolderName: "Pangalan ng May-ari ng Card (Latin lamang)",
  cardExpires: "Mag-e-expire ang Card",
  cvv: "CVV",
  firstName: "Pangalan",
  lastName: "Huling pangalan",
  zipCode: "Zip Code",
  address: "Tirahan",
  state: "Estado",
  email: "Email",
  phone: "Telepono",
  dateOfBirth: "Araw ng kapanganakan",
  transactionStatus: "Katayuan sa Transaksyon",
  trace: "Bakas",
  amount: "Halaga",
  min: "min",
  max: "max",
  overrideLimits: "Pag-override ng mga limitasyon",
  transaction: "Transaksyon",
  processed: "Pinroseso Ni",
  transactionRejected: "Tinanggihan ang Transaksyon",
  transactionApproved: "Naaprubahan ang transaksyon",
  yourTransactionIsPending: "Nakabinbin ang Iyong Transaksyon",
  transactionAuthorized: "Pinahintulutan ang Transaksyon",
  requestAccepted: "Tinanggap ang Kahilingan",
  status2:
    "Ang iyong kahilingan sa pag-atras ay tinanggap. Mangyaring kilalanin na ang karagdagang pahintulot at pagproseso ay isasagawa sa oras ng pagpapatakbo ng mga araw ng negosyo. Sa panahong iyon, maaaring makipag-ugnay sa iyo ng executive staff para sa kumpirmasyon sa pagkakakilanlan.",
  unexpectedErrorOccurred: "Hindi inaasahang Error na nangyari",
  status3:
    "Humihingi kami ng paumanhin, may nangyari habang pinoproseso ang iyong transaksyon. Mangyaring hanapin ang mga teknikal na detalye sa ibaba:",
  tryAgain: "Subukang Muli",
  somethingWentWrong: "May nangyaring mali ...",
  newPayoutRequest: "Bagong Kahilingan sa Payout",
  makeAnotherPayment: "Gumawa ng Isa Pa Bayad",
  profile: "Hugis",
  justOneMoreStep: "Isang hakbang pa lang ...",
  "profile-auth":
    "Mangyaring kumpletuhin ang iyong profile upang magpatuloy sa pagbabayad gamit ang {{title}}",
  payWith: "Magbayad Sa",
  ProceedWith: "Magpatuloy sa",
  vtTitle: "Virtual Terminal: ",
  pay: "Magbayad",
  deposit: "Magdeposito",
  withdraw: "Umatras",
  withdrawal: "Pag-atras",
  hello: "Kamusta,",
  currency: "Pera",
  currentIp: "Kasalukuyang IP:",
  UseCurrentIp: "Gumamit ng kasalukuyang IP: ",
  UseCustomerIp: "Gumamit ng IP ng customer: ",
  copyright: "Karapatang magpalathala",
  chooseAmount: "Pumili ng halaga",
  cannotBeBlank: "Hindi pwedeng blangko.",
  noDepositFree: "Walang bayad sa deposito. walang bayad sa pag-atras.",
  next: "Susunod",
  backToSite: "bumalik sa site",
  maximumLength: "Maximum na haba ng {{length}} na mga character",
  minimumLength: "Minimum na haba ng {{length}} na mga character",
  emailIsNotValid: "Ang email ay hindi wastong email address.",
  phoneIsNotValid: "Ang telepono ay hindi wasto",
  cardHolderInvalid:
    "Di-wasto ang may hawak ng card. Dapat maglaman ang may-ari ng card ng apelyido at apelyido.",
  pleaseEnterPayout:
    "Mangyaring maglagay ng isang halaga ng payout na mas mababa sa o pantay",
  invalidAmount: "Di-wastong halaga",
  transactionWillAppear:
    "Lilitaw ang iyong transaksyon sa iyong pahayag bilang",
  "Customer does not exist": "Wala ang customer",
  "Sorry no available gateways found.":
    "Paumanhin walang nahanap na magagamit na mga gateway.",
  "Incorrect currency": "Maling pera",
  "Sorry, not supported payment type":
    "Paumanhin, hindi suportado ang uri ng pagbabayad",
  "Payment method has no payment type":
    "Ang paraan ng pagbabayad ay walang uri ng pagbabayad",
  "Incorrect payment type in payment method":
    "Maling uri ng pagbabayad sa paraan ng pagbabayad",
  "Gateway limit exceeded": "Lumagpas sa limitasyon sa gateway",
  "Not supported transaction type": "Hindi suportadong uri ng transaksyon",
  "Sorry. Payout with credit card gateway not supported yet":
    "Pasensya na Hindi pa sinusuportahan ang pagbabayad na may credit card gateway",
  "We apologize, this payment method is not applicable for the payment details specified. Please reload the page and try a different amount, currency or payment method.":
    "Humihingi kami ng paumanhin, ang pamamaraang ito sa pagbabayad ay hindi nalalapat para sa tinukoy na mga detalye sa pagbabayad. Mangyaring i-load muli ang pahina at subukan ang ibang halaga, pera o paraan ng pagbabayad.",
  "Back to site": "Bumalik sa site",
  "Your transaction has been requested. Please wait for approval.":
    "Hiniling ang iyong transaksyon. Mangyaring maghintay para sa pag-apruba.",
  "Token expired": "Nag-expire na ang token",
  back: "BUMALIK",
  "Transaction not found.": "Hindi nahanap ang transaksyon",
  "Continue in the New Window": "Magpatuloy sa Bagong Window",
  "Please do not close this window. You will be redirected back upon finishing the transaction in a new window. Don`t see a new window opening a secure connection? We`re here to help you to complete your transaction.":
    "Mangyaring huwag isara ang window na ito. Ire-redirect ka pabalik sa pagtatapos ng transaksyon sa isang bagong window. Huwag makakita ng isang bagong window na nagbubukas ng isang ligtas na koneksyon? Narito kami upang matulungan kang makumpleto ang iyong transaksyon.",
  "Click to continue": "I-click upang magpatuloy",
  "Just One More Step...": "Isang Hakbang Pa Lang ...",
  "The payment method that you have selected will be open in a new page. Please click the 'PROCEED' button below in order to navigate to the final step.":
    "Ang paraan ng pagbabayad na iyong napili ay bubuksan sa isang bagong pahina. Mangyaring i-click ang pindutang 'PROCEED' sa ibaba upang mag-navigate sa huling hakbang.",
  Proceed: "Magpatuloy",
  "Opening a Secure Connection...": "Pagbukas ng isang Secure na Koneksyon ...",
  "You will be redirected to complete your transaction in just a moment":
    "Ire-redirect ka upang makumpleto ang iyong transaksyon sa isang sandali lamang",
  "Close window": "Isara ang bintana",
  "Partial approval confirmation": "Bahagyang kumpirmasyon ng pag-apruba",
  PartiallyApprovedAmount:
    "Ang iyong balanse sa card ay walang sapat na mga pondo upang mai-credit ang buong halaga. Ang transaksyon ay bahagyang naaprubahan para sa {{amount}} {{currency}}.",
  ConfirmPartialDeposit:
    "Mangyaring i-click ang 'OK' upang kumpirmahin ang bahagyang halaga ng deposito o 'CANCEL' upang kanselahin ang transaksyon. Awtomatiko na makukumpirma ang deposito pagkalipas ng 5 minuto kung walang aksyon na gagawin.",
  fxRateSpreadText:
    "Kasama sa ipinahiwatig na exchange rate ang lahat ng bayarin at singil.\n",
  newNext: "Next",
  newChoosePaymentMethod: "Choose Payment Method",
  newQuickDeposit: "Quick Deposit",
  newViaCreditCard: "Via Credit Card",
  newMin: "Min",
  newMax: "Max",
  newDepositAmount: "Deposit Amount",
  newTotalCharge: "Total Charge",
  newEnterAmount: "Enter Amount",
  newDepositAmountMustBe:
    "Deposit amount must be between {{currency}} {{min}} - {{max}}. Over {{max}} requires multiple deposits",
  newFee: "Fee",
  newExchangeRate: "Exchange rate",
  newFeeIncludes: "* Total charge includes the fees",
  newPaymentSecure:
    "Your payment is secure. Your card details will not be shared with others.",
  newCardNumber: "Card Number",
  newExpireData: "Expiration date",
  newCardHolderName: "Card holder name",
  newCvv: "CVV",
  newDeposit: "Deposit",
  newTermsOf: "Terms of conditions",
  newChooseCreditCard: "Choose credit card",
  newAddPayMethod: "Add a payment method",
  newPlus: "+",
  newAdd: "Add",
  newWhatIsCvv: "What is CVV?",
  newWhatIsCvvDescription:
    "A CVV is the 3 or 4 digit number on your card that adds an extra layer of security.",
  newMissingInformation: "Missing Information",
  newMissingDetails:
    "We are missing few details, please complete the below and click continue to submit your Deposit",
  newContinue: "Continue",
  newTransactionId: "Transaction ID",
  newDepositFailed: "Deposit failed",
  newFundsDeposit:
    "You do not have enough funds to deposit. Please enter a lower amount in order to start trading with us.",
  newContactOur: "Please contact our Customer Support Via:",
  newEmail: "Email",
  newPhone: "Phone",
  newChat: "Live chat",
  newOr: "Or",
  newChangeAmount: "Change Amount",
  newTryAgain: "Try Again",
  newClickHere: "Click here",
  newMyAccount: "My Account",
  newAnyBonuses:
    "Any bonuses will be added to your account only once your documents have been verified.",
  newToUpload: "to upload your documents. We wish you successful trading.",
  newDepositCompleted: "Deposit Completed",
  newThankYou: "Thank you for your deposit",
  newTradingImmediately:
    "You may begin trading immediately. An email with your deposit details was sent to your registered email address.",
  newTradeNow: "Trade Now",
  newTransactionFailed: "Transaction Failed",
  newWeCouldNotDeposit:
    "We could not process your deposit. Please try another deposit method or contact customer service.",
  newTransactionPending: "Transaction Pending",
  newClosed: "Close",
  newInvalidExpireData: "Card is expired",
  newThankYouPending:
    "Thank You. Your transaction is currently pending and waiting confirmation status. Your account will be immediately updated once confirmation is received.",
  initialLoadingText: "Naglo-load... Mangyaring maghintay",
};
